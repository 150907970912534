.main > .account-details-section {
    margin:0 0 30px;
}
.account-details-section .dl-horizontal {
    margin:15px 0;

    dt {
        width:130px;
    }

    dd {
        margin-left:140px;
    }
}
.account-details-section dt,
.account-details-section .txt-medium {
    font-size:16px;
}
.account-details-section .txt-medium {
    margin-right:7px;
}
.account-details-section .form-action {
    margin:18px 0 0;
}
.account-details-section .form-action .btn {
    padding-top:6px;
    padding-bottom:6px;
}