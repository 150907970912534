 /* highcharts */
 .legend {
    color:#8b8b8b;
    font-weight:normal;
    white-space:nowrap;
}
.legend:hover {
    opacity:.8
}
.legend strong {
    color:#363a3d;
}
.ico-legend {
    width:12px;
    height:12px;
    border-radius:2px;
}
.legend-small {
    font-size:10px;
    margin-left:8px;
}
.legend-small .ico-legend {
    width:8px;
    height:8px;
    margin-right:3px;
    border-width:2px;
}
.legend-large .legend {
    font-size:21px;
}
.legend-large .ico-legend {
    width:29px;
    height:29px;
}
.timeline .chart-wrap {
    min-height:100px;
}
/*.timeline .chart-loading {
    background:url($src-image + 'indicators/loader_gray_48.gif') no-repeat 50% 50%;
}*/
.timeline .chart {
    width:100%; 
}
.timeline .chart-pie {
    max-width:96px;
}
.statistics-section .highcharts-container {
    margin:auto;
}


/* [ highcharts ] */
.highcharts-container {
    overflow:visible !important;
}
.highcharts-container:hover {
    z-index:10 !important;
}


/* [ ui-sortable ] */
.ui-sortable-placeholder {
    height:140px;
    float:left;
    width:166px;
    margin-bottom:20px;
    border:0;
    outline:1px dashed #d3d5d9;
    border-radius:2px;
    visibility:visible !important;
    box-sizing:border-box;
}


/* tooltip na zeroclipboard*/
.zeroclipboard-is-hover ~ .tip {
    opacity:1;
    visibility:visible;
}
