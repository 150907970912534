.imgL {float:left; clear:left; margin:0 20px 10px 0;}
    .imgR {float:right; clear:right; margin:0 0 10px 20px;}
    .flex img {
        display:block;
        height:auto;
        margin:0 auto;
        max-width:100%;
    }
    .img-decorate {
        border-radius:2px;
        border:3px solid #fff;
        background-color:#f6f6f6;
        box-shadow:0 0 0 1px rgba(0,0,0,0.1);
    }
    .thumb-title {
        text-align:center;
        width:116px;
        display:block;
        overflow:hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        background:#fff;
        margin:14px 0 0;
        padding:0;
        border-radius:20px;
    }

/* [ google maps (options fix) ] */
    .map-wrap {
        margin:0 auto;
        display:block;
    }
    .map-wrap img {
        max-width:none;
    }

@import '../../../../../shared/web/views/src/scss/__partials/figure';
/* [ thumb gallery (with title) ] */
.files-thumbs-section .figure-wrap:hover .thumb-title {
    color:#fff;
    /* background:#6ebe3b; */ /* brand */
}

@import '../../../../../shared/web/views/src/scss/__partials/avatar';
@import '../../../../../shared/web/views/src/scss/__partials/button';

.btn-leave-message {
    @include x-btn-default();
}
.btn-leave-message.active {
    box-shadow:inset 0 2px 0 rgba(0,0,0,.1);
}
.btn-leave-message:hover {
    @include x-btn-default-hover();
}