.step-items {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 auto 20px;
    padding: 0;
    max-width: 500px;
}
.step-item {
    margin-top: 0;
    position: relative;
    flex-grow: 1;
    flex-basis: 1em;
    &:not(:first-child):before {
        background-color: #e1e5ee;
        height: 2px;
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        width: calc(100% - 40px);
        left: calc(-50% + 20px);
        top: 9px;
    }
    &.is-previous {
        cursor: pointer;
        .step-details {
            color: #a7afc6;
        }
    }
    &.is-active {
        .step-details {
            color: #1b1f2b;
        }
    }
    &.is-previous,
    &.is-active {
        .step-marker,
        &:not(:first-child):before {
            background-color: #6ebe3b;
        }
    }
}
.step-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #4a4a4a;
}
.step-marker {
    background-color: #a7afc6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    svg {
        width: 10px;
        height: 20px;
    }
}
.step-details {
    font-family: $font-roboto-medium;
    margin-top: 8px;
    color: #a7afc6;
}
