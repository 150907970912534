.user-type .list-tiles.x-small .col {
    margin:0 10px;
}
.user-type .list-tiles.large .col {
    margin:0 33px 20px;
}
.user-type .list-tiles.medium .col {
    margin:0 11px 20px;
}
/*.user-type > li,*/
.participant-type > li {
    margin:0;
}
.invite-section .content {
    margin-bottom:80px;
}
.invite-section .specify-minheight {
    min-height:90px;
}
.invite-section .input-group [data-icon='p'] {
    color:#d1d1d1;
}
.invite-section .unit-arrow {
    &, &:before {
        display:inline-block;
        width:0;
        height:0;
        border:14px solid transparent;
        position:absolute;
    }

    & {
        bottom:100%;
        left:0;
        z-index:4;
        border-bottom-color:rgba(0,0,0,0.1);

        &:before {
            content:'';
            top:-13px;
            left:-14px;
            z-index:3;
            border-bottom-color:#fff;
        }
    }

}

.choose-template .page-wrap {
    width:980px;
}
.choose-template .tab-ft {
    margin:45px 0 0;
}
.choose-template .tab-ft .notify {
    margin-right:8px;
}
.summary-invite-section .unit-bd h3 svg {
    @include x-svg-size(20);
}
.invite-section .list-presenters {
    margin-left:-50px;
}
.invite-section .list-presenters .col {
    margin:20px 0 0 49px;
}
.invite-add-presenter {
    .list-presenters {
        margin: 0 -15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .col {
            margin: 20px 10px 0;
            width: auto;
            flex-grow: 0;
            .thumb-title {
                margin: 14px auto 0;
            }
        }
    }
}
.invite-section .overlap {
    position: absolute;
    right: 0;
    top: -45px;
    @media (max-width: 768px) {
        top: -27px;
    }
    .overlap-info {
        @media (max-width: 768px) {
            padding: 5px;
            font-size: 16px;
        }
        svg {
            @media (max-width: 768px) {
                height: 16px;
                width: 16px;
            }
        }
    }
}
.invite-section {
    .invitation-attendees-limit {
        bottom: 100%;
        padding-bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: 768px) {
            top: 0;
            right: 100%;
            padding-right: 8px;
            left: unset;
            bottom: unset;
            padding-bottom: 0;
            transform: translateX(0);
        }
        .tip-wrap {
            &:after {
                border-top-color: rgba(38, 39, 41, 0.9);
                top: 100%;
                left: 50%;
                margin-left: -6px;
                @media (max-width: 768px) {
                    border-top-color: transparent;
                    border-left-color: rgba(38, 39, 41, 0.9);
                    top: 0;
                    margin-top: 12px;
                    margin-left: 0;
                    left: 100%;
                }
            }
        }
    }
    &__test-email {
        @media (max-width: 768px) {
            display: block !important;
        }
        .positioned-content {
            @media (max-width: 768px) {
                position: static;
                transform: translateY(0);
                text-align: center;
                margin-top: 10px;
            }
        }
    }
    .invitation-segments-group {
        .desc {
            @media (max-width: 576px) {
                width: 100%;
            }
        }
    }
}