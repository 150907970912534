.addons-section .content {
    padding:0 0 5px;
    &.slider-addons {
        @media only screen and (max-width: 767px) {
            margin: auto;
            overflow-x: scroll;
        }
    }
}
.addons-section .header .options {
    margin-top:16px;
}

/* [ slider step addons ] */
.addons-section .list-pictogram a {
    height:120px;
}
.addons-section .slider-step-wrap {
    margin:105px -6px 30px;
    @media only screen and (max-width: 767px) {
        width: 710px;
        margin-right: 0;
        margin-left: 0;
    }
}
.slider-step-wrap .list-pictogram {
    position:absolute;
    bottom:0;
    left:0
}
.slider-step-wrap .list-pictogram .pictogram-item:before,
.slider-step-wrap .list-pictogram .pictogram-item:after {
    content:'';
    display:block;
    position:absolute;
    width:0;
    height:0;
    left:50%;
    margin-left:-8px;
    border:8px solid transparent;
}
.slider-step-wrap .list-pictogram .pictogram-item:before {
    border-bottom-color:#efefef;
    z-index:2;
    bottom:26px;
}
.slider-step-wrap .list-pictogram .pictogram-item:after {
    border-bottom-color:#232728;
    opacity:.08;
    z-index:1;
    bottom:28px;
}
.slider-step-wrap .list-pictogram a {
    padding-bottom:40px;
}
.slider-step-wrap .list-pictogram a:before {
    content:'';
    display:block;
    position:absolute;
    bottom:8px;
    left:50%;
    margin-left:-3px;
    width:6px;
    height:6px;
    background:#fff;
    border-radius:6px;
    box-shadow:0 1px 0 rgba(0,0,0,.1);
}




/*old*/
.addons-boxes .col {
    display:inline-block;
    vertical-align:top;
    width:216px;
    margin-left:15px;
}
.addons-boxes .unit-hd {
    padding-right:5px;
    padding-left:5px;
    letter-spacing:-1px;
    text-align:center;
}
.addons-boxes .unit-bd {
    color:#606365;
    font-size:12px;
}
.addons-boxes [data-icon] {
    color:#d1d1d1;
    font-size:76px;
    line-height:1;
    text-align:center;
    display:block;
    height:77px;
    margin:-10px auto 10px;
}
.addons-boxes [data-icon='r'] {
    font-size:70px;
}
/*.addons-boxes .unit:hover [data-icon] {
    color:#6fbf3b;
}*/

.old-modal {
    text-align: center;
    height: auto;
    min-height: 100%;
    background: url('/panel/images/popup/bg_light.jpg') 0 0 repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    &__wrapper {
        padding: 0 30px;
    }
    h2 {
        color:#6ebe3b;
        font-size:47px;
        margin:0 0 .5em;
        font-weight:normal;
        line-height: 1.5em;
        letter-spacing: -2px;
    }
    p {
        margin: 16px 0;
        font-size: 16.8px;
        line-height: 1.6;
        color: #555;
        text-shadow:rgba(255, 255, 255, .8) 0 1px 1px;
    }

}