.paid-access {

    &-summary {
        display: table;
        width: auto;
        padding: 30px 0 10px;
        margin: auto;
        text-align: center;
    }
    &-cell {
        display: inline-block;
        vertical-align: middle;
        @media (max-width: 768px) {
            display: block;
            min-width: 200px;
        }
    }

    &-element {
        position: relative;
        padding: 10px 0;
    }
    
    &-svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 50px;
        
        svg {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 0;
            top: 50%;
            margin-top: -25px;
            color: #6ebe3b;
            stroke-width: 2px;
        }
        
    }
    
    &-desc {
        padding-left: 65px;
        text-align: left;
        
        .title {
            font-size: 12px;
            text-transform: uppercase;
            color: #6ebe3b;
            text-align: left;
        }
        
        .value {
            font-size: 24px;
            line-height: 24px;
            color: #5c6273;
            
            &.equal {
                color: #282b31;
            }
            
        }
        
    }
    
    &-separator {
        text-align: center;
        font-size: 18px;
        color: #a7afc6;
        width: 50px;
        @media (max-width: 768px) {
            margin: 0 auto;
        }
            
        &.equal {
            font-size: 24px;
        }
        
    }
    
    &-search {
        
        input[type='search'] {
            box-shadow: none;
        }

        input[type='search']::placeholder {
            font-style: italic;
        }
        
        .input-group-addon[data-icon='p'] {
            color: #c2c2c2;
        }
        
    }
    
}
.paid-access-helper {
    margin: 0 100px;
    @media (max-width: 991px) {
        margin: 0 20px;
    }
    @media (max-width: 768px) {
        width: 250px;
        margin: auto;
    }
    &__info {
        position: relative;
        background: #ebf9fe;
        border-radius: 3px;
        padding: 8px 8px 8px 35px;
        display: inline-block;
        @media (max-width: 768px) {
            margin-top: 15px;
        }
        &-svg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 35px;
            svg {
                position: absolute;
                width: 14px;
                height: 14px;
                margin-top: -7px;
                top: 50%;
                left: 10px;
                color: #00afec;
            }
        }
        &-desc {
            font-size: 11px;
            color: #6b7183;
        }
    }
    &__tip {
        margin-left: 25px;
        &-star {
            float: left;
            position: relative;
            overflow: hidden;
            color: #6ebe3b;
        }
        &-desc {
            font-size: 11px;
            color: #6b7183;
            margin-left: 8px;
        }
    }
}