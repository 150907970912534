.footer {

    &-marketing {
        background-color: #1b1f2b;
        background-size: cover;
        position: relative;
        overflow: hidden;

        .helper.pos-bl5 {
            bottom: 100%;
            left: 85%;
            width: 165px;
        }

        .page-wrap {
            width: 980px;
        }

        .swiper-container {
            width: 100%;
            height: 330px;
            transition: height .2s ease-in-out;
            @media screen and (max-width: 992px) {
                height: 580px;
            }
        }
        .swiper-slide {
            @media screen and (max-width: 992px) {
                display: flex;
                align-items: center;
            }
        }
        .swiper-nav {
            color: $color-gray-blue;
        }
        .arrow-left {
            margin-right: 30px;
        }
        .arrow-right  {
            margin-left: 30px;
        }

        .bx {
            height: 330px;
            padding: 0 177px 0 0;
            transition: height .2s ease-in-out;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: 992px) {
                height: auto;
                background: none !important;
                padding-right: 0 !important;
                margin-bottom: 25px;
            }
            .hd {
                display: flex;
                align-items: center;
                &.dir-column {
                    flex-direction: column;
                    align-items: baseline;
                }
            }
            h2 {
                font-size: 24px;
                font-family: 'robotoregular';
                line-height: 32px;
                color: #f9f9f9;
                margin: 0 0 30px;
            }
            p {
                font-size: 16px;
                font-family: 'robotoregular';
                line-height: 24px;
                color: $color-gray-blue;
                margin: 0 28px 47px 0;
            }
            &-mobile-app {
                background: url($src-image + 'feature/mobile-app.png') no-repeat 100% 100%;
                padding-right:550px;

                .btn-group-mobile {
                    .btn-mobile {
                        display: inline-block;
                        text-decoration: none;
                        margin: 5px 15px 5px 0;
                        &:last-child {
                            margin-right: 0;
                        }
                        img {
                            display: block;
                            height: 40px;
                            max-width: 135px;
                        }
                    }
                }
                a {
                    color: $color-link;
                    &:hover {
                        color: $color-link-hover;
                    }
                }
            }
            &-desktop-app {
                background: url($src-image + 'feature/desktop-app.png') no-repeat 100% 100%;
                padding-right:550px;
            }
            &-video {
                background: url($src-image + 'feature/video.png') no-repeat 100% 50%;
                padding-right:550px;
            }
            &-blog {
                background: url($src-image + 'feature/blog.png') no-repeat 100% 50%;
                padding-right:550px;
            }
            &-config {
                background: url($src-image + 'feature/config.png') no-repeat 100% 50%;
                padding-right:550px;
            }
            &-survey {
                background: url($src-image + 'feature/ico_survey.png') no-repeat 100% 100%;
                padding-right: 420px;

                p {
                    font-size: 28px;
                }

                h2 {
                    font-size: 35px;
                    margin-top: 14px;
                }

                .btn {
                    min-width: 192px;

                    &-wrap {
                        margin-top: 30px;
                    }

                }

            }

        }

        .col {
            float: left;
            width: 50%;
        }

        .button-footer {
            font-family: 'robotobold';
            line-height: 14px;
            padding: 15px 17px;
            text-transform: uppercase;
            min-width: 130px;
            margin-right: 20px;
            margin-bottom: 20px;
            &:last-child {
                margin-right: 0;
            }
        }

        &-minimized {

            .bx,
            .swiper-container {
                height: 86px;
            }

            .bx {
                background: none;
                padding: 22px 25px;

                h2 {
                    text-align: center;
                }

                .hd {
                    padding: 0;
                }

                .bd {
                    height: 0;
                    padding: 0;
                    overflow: hidden;
                }

            }

            .bx-survey {

                &.bx h2 {
                    font-size: 21px;
                    margin-top: 8px;

                    br {
                        display: none;
                    }

                }

            }
        }

        .options {
            position: absolute;
            bottom: 0;
            right: 2%;
            z-index: 11;
            @media screen and (max-width: 992px) {
                right: 5%;
            }
        }

        .swiper-pagination {
            float: left;
            margin-right: 10px;
        }

        .toggle-hide {
            min-width: 60px;
        }

    }

}

[lang='br'],
[lang='pt'] {
    .footer-marketing .btn {
        margin-top: 15px;
    }
}

[lang='de'] .footer-marketing p,
[lang='ru'] .footer-marketing p {
    font-size:14px;
}

[lang='ru'] .footer-marketing .btn.large,
[lang='ru'] .tab-ft .effect-enlarge .btn-txt {
    font-size:18px;
}