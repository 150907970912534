.webinars-list,
.meetings-list {
    @media (max-width: 991px) {
        max-width: 100%;
    }
    .dropdown-category {
        padding: 0 30px;
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
            padding: 0 10px;
        }
        &__name {
            color: #b6b6b6;
            font-size: 11px;
            font-family: 'robotoregular';
            line-height: 11px;
            text-transform: uppercase;
            margin: 0 18px 0 0;
        }
        &__line {
            height: 1px;
            width: 100%;
            background-color: #dee2e6;
        }
    }
    .dropdown-item {
        padding: 12px 30px 12px 120px !important;
        font-size: 14px;
        z-index: 1;
        font-family: 'robotoregular';
        white-space: normal;
        transition: background-color 0.25s ease-in-out;
        text-transform: none !important;
        position: relative;
        @media (max-width: 991px) {
            padding: 10px 20px 10px 85px !important;
        }
        &__name {
            color: #000;
            font-size: 21px;
            line-height: 21px;
            margin: 0 0 10px;
            vertical-align: text-top;
            display: inline-block;
            @media (max-width: 991px) {
                font-size: 18px;
                line-height: 18px;
            }
        }
        .upgrade-pack {
            font-size: 11px;
            font-family: 'robotomedium';
            padding: 2px 4px;
            line-height: 11px;
        }
        &__desc {
            color: #6d6f71;
            font-size: 12px;
            line-height: 17px;
            margin: 0;
            @media (max-width: 991px) {
                font-size: 11px;
                line-height: 16px;
            }
        }
        &.live-webinar {
            svg { color: #6ebe3b; }
        }
        &.automated {
            svg { color: #0b23a0; }
        }
        &.ondemand {
            svg { color: #2e6fde; }
        }
        &.meeting {
            svg { color: #6ebe3b; }
        }
        &.adhoc {
            svg { color: #20242e; }
        }
        &:hover,
        &:focus {
            background-color: #f8f8f8;
        }
        svg {
            position: absolute;
            width: 50px;
            height: 50px;
            color: #1b1f2a;
            left: 35px;
            top: 50%;
            transform: translateY(-50%);
            @media (max-width: 991px) {
                width: 35px;
                height: 35px;
                left: 20px;
            }
        }
        &:before {
            content: none !important;
        }
    }
}
.webinars-list {
    margin-bottom: 7px;
}