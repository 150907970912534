.integration-section .content {
    padding-bottom:50px;
}
.integrations-plugin .app-hd .app-ico-status {
    display:none;
}
.integration-section .expand .main-arrow {
    left:55px;
}
.integrations-plugin .expand .main-arrow {
    left:32px;
}

.integration-section .helper {
    transform:rotate(-5deg);
}
.integration-section .helper.pos-tl2 {
    margin-left:105px;
    top:-20px;
}
.integration-section .helper.pos-tl2 h4 {
    text-indent:-40px;
}
.integration-section .helper.pos-tl2 .helper-bd:before {
    top:30px;
    left:-85px;
}
.integration-section .helper.pos-tr2 {
    margin-right:45px;
    top:8px;
    white-space:nowrap;
}
.integration-section .helper.pos-tr2 .helper-bd:before {
    right:-40px;
}

.integrate-paypal-tutorial-wrapper {
    
    .copable {
        font-weight: bold;
    }
    
    .copy-to-clipboard {
        font-size: 0.9em;
        cursor: pointer;
        color: #00afec !important;
        
        &:hover {
            color: #ff800f !important;
        }
        
    }
    .f-holder input {
        font-size: 16px;
        color: #737373;
        padding-top: .5em;
        padding-bottom: .5em;
        box-shadow: none;
    }
}
.integrate-payu {
    &-lower-alpha {
            list-style-type: lower-alpha;
    }
}