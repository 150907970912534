@import "bootstrap/bootstrap";
@import "const/_colors.scss";
@import "const/fonts.scss";
@import "const/mixins.scss";
@import "const/_base.scss";
@import "const/_variables.scss";

* {
  box-sizing: border-box;
}
.page-wrap {
  box-sizing: content-box;
}

dt {
  font-weight: normal;
}

@import "components/_overview.scss";
@import "components/_structure.scss";
@import "../../../../../shared/web/views/src/scss/components/_overspread.scss";
@import "components/_typo.scss";
@import "components/_list.scss";
@import "components/_caret.scss";
@import "components/_flags.scss";
@import "components/_images.scss";
@import "components/_dropdowns.scss";
@import "components/_tip.scss";
@import "components/_helper-arrow.scss";
@import "components/_button-groups.scss";
@import "components/_confirm-elements.scss";
@import "components/_button-add-new.scss";
@import "components/_overlap.scss";
@import "components/_form.scss";
@import "../../../../../shared/web/views/src/scss/__partials/pagination";
@import "components/_social.scss";
@import "components/_payments-method.scss";
@import "components/_nav-tab.scss";
@import "components/_table.scss";
@import "components/_unit-boxes.scss";
@import "components/_tab-content.scss";
@import "components/_module-list.scss";
@import "components/_list-editable.scss";
@import "components/_nested-horizontal.scss";
@import "components/_list-chat.scss";
@import "components/_list-subaccounts.scss";
@import "components/_subaccounts-video.scss";
@import "components/navbar.scss";
@import "components/_cover.scss";
@import "components/_menu-option.scss";
@import "components/_footer.scss";
@import "components/footer-marketing.scss";
@import "components/_header-title.scss";
@import "components/_reminders.scss";
@import "components/_search-result.scss";
@import "components/_person-book.scss";
@import "components/_drag-drop.scss";
@import "components/_table-search.scss";
@import "components/_app-integrate-items.scss";
@import "components/_dashboard.scss";
@import "components/_timeline.scss";
@import "components/_meeting-section.scss";
@import "components/_statistic-section.scss";
@import "components/_addon-section.scss";
@import "components/_expand-section.scss";
@import "components/_library-section.scss";
@import "components/_messages-section.scss";
@import "components/_import-cp-section.scss";
@import "components/_address-section.scss";
@import "components/_add-section.scss";
@import "components/_contact-section.scss";
@import "components/_settings-page.scss";
@import "components/_account-details.scss";
@import "components/_payment-section.scss";
@import "components/_language.scss";
@import "components/_summary-section.scss";
@import "components/_statistic-page.scss";
@import "components/_password-page.scss";
@import "components/_dialplan-page.scss";
@import "components/_integration-page.scss";
@import "components/_event-room-editor.scss";
@import "components/_event-list.scss";
@import "components/_event-edit.scss";
@import "components/_invite.scss";
@import "components/_presenters.scss";
@import "components/_subaccountans.scss";
@import "components/_refferal.scss";
@import "components/_editor-elements.scss";
@import "components/_ui-widgets.scss";
@import "components/_drafts.scss";
@import "components/_group-font.scss";
@import "../../../../../shared/web/views/src/scss/components/_animation.scss";
@import "components/_prefix.scss";
@import "components/_webinar-banner.scss";
@import "components/_popup-mobile.scss";
@import "components/_event-collapse.scss";
@import "components/_new-webinar-room.scss";
@import "components/webinar-published_recording.scss";
@import "components/claim";
@import "components/rebranding";
@import "components/beta-room";
@import "components/new-room-feedback";
@import "components/paid-access";
@import "components/whats-new";
@import "components/sidebar";
@import "components/webinar-start-banner";
@import "components/breakout-rooms";
@import "components/_verify-email";
@import "components/_banner_component";
@import "components/_banner_progress_bar";
@import "../../../../../shared/web/views/src/scss/mixin/_breakpoints.scss";

@import "components/webrtc-beta-modal.scss";

@import "components/_around-room";
@import "components/_countdown";
@import "components/expired";

@import "promotions/basic/promo";
@import "webinars/basic/webinar";
@import "promotions/banner";
@import "promotions/counter";

@import "webinars/webinarFlywheel/modal-webinar-flywheel";
@import "components/modal";

@import "components/billing";
@import "components/pricing";
@import "components/pricing-panel";
@import "components/_loading-picture";
@import "components/_step-process";

.cm-switch-row__title {
  padding-right: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #1b1f2b;
}
