.list-editable .list-editable-item {
    margin:2px 0;
    padding:5px 0;
    position:relative;
    border-radius:2px;
    &.text-break {
        word-break: break-word;
    }
}
.list-editable .form-action {
    text-align:right;
    display:none;
    float:right;
    position:absolute;
    top:1px;
    right:0;
}
.list-editable .form-action li {
    vertical-align:middle;
}
.list-editable .list-editable-label {
    text-align:right;
    float:left;
    width:112px;
    margin-left:10px;
}
.list-editable .list-editable-control {
    margin:0 10px;
    position:relative;
}
.list-editable .list-editable-label + .list-editable-control {
    margin-left:148px;
    -ms-word-break:break-all;
    word-wrap:break-word;
    word-break:keep-all;
    -webkit-hyphens:auto;
       -moz-hyphens:auto;
        -ms-hyphens:auto;
            hyphens:auto;
}
.list-editable-hov:hover .form-action-hov {
    display:block;
}
.list-editable-active:hover .form-action-hov {
    display:none;
}
.list-editable-active .form-action-active,
.list-editable-active:hover .form-action-active {
    display:block;
}
/*.list-editable-hov:hover {
    background:rgba(107,185,57,.2);
}*/
.list-editable .list-editable-active,
.list-editable .list-editable-active:hover {
    background:#e5e5e5;
}
.list-editable .editable .form-control {
    margin:8px 0 0;
}
.list-editable .editable .form-control:first-child {
    margin-top:2px;
}
.list-editable .f-val {
    cursor:pointer;
}
.list-editable .check {
    top:3px;
}
.list-editable .switch {
    margin-top:-4px;
}
.list-editable .switch-small {
    margin-top:5px;
}
/* elementy ktore maja byc podmieniane kiedy nadajemy klase .list-editable-active */
.list-editable-item .list-editable-area-active,
.list-editable-active .list-editable-area-inactive {
    display:none;
}
.list-editable-active .list-editable-area-active {
    display:inline-block;
}


.list-editable .list-editable-control .form-block {
    margin:-4px 115px -1px 0;
}
.list-editable .list-editable-label + .list-editable-control .form-block {
    margin:-4px 115px -6px 0;
}
.list-editable.medium .list-editable-label + .list-editable-control .form-block {
    margin:-7px 150px -6px -14px;
}
/* uswiawienie form action w pozycji prawy dolny róg */
.list-editable .list-editable-item.pos-br .list-editable-control .form-block {
    margin-right:0;
}
.list-editable .list-editable-item.pos-br .form-action-active {
    position:static;
    margin-top:10px;
}
.list-editable .form-block {
    display:none;
}
.list-editable-active .form-block {
    display:block;
}
.list-editable.medium .list-editable-item {
    padding:11px 0;
}
.list-editable.medium .list-editable-label {
    letter-spacing:-1px;
    line-height:1.2;
    margin-top:3px;
    margin-bottom:3px;
}
.list-editable.medium .list-editable-label + .list-editable-control {
    color:#555;
}
.list-editable.medium .list-editable-label,
.list-editable.medium .list-editable-element {
    font-size:16px;
}
.list-editable-hov .f-edit {
    margin-right:60px;
}
.list-editable.medium .form-action-active {
    top:6px;
}
