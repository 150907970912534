.account .avatar-wrap {
    vertical-align:middle;
    margin-right:5px;
}
.account .avatar.x-small {
    background:none;

    [data-icon='P'] {
        font-size:16px;
        bottom:3px;
        left:2px;
    }
}
.account .username {
    display:inline-block;
    vertical-align:middle;
    line-height:20px;
    max-width:140px;
}
.account .dropdown-menu {
    min-width:315px;
}
.account .dropdown-menu-user {
    float:none;
    padding:13px 18px;

    .name {
        max-width:212px;
    }
    .avatar {
        border-width:2px;
        width:39px;
        height:50px;

        [data-icon='P'] {
            font-size:49px;
            bottom:0;
        }
        img {
            display:block;
        }
    }

    .avatar-wrap,
    .name {
        vertical-align:middle;
    }
    .avatar,
    .name {
        /*color:#6ebe3b;*/
        font-size:28px;
        letter-spacing:-1px;
    }
    .account-balance-info {
        color:#363a3d;
        font-size:16px;
        line-height:1;
        display:block;
        margin-top:8px;
    }
}

.account .dropdown-list-icons a {
    padding:5px 31px;
    line-height:1.7;
}
.account .dropdown-list-icons li > a:before {
    left:15px;
}
.account .dropdown-list-icons .has-own-ico .ico-left {
    left:11px;
}

.account .btn-success,
.account .btn-success:hover {
    color:#fff;
}
