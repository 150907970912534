.nav-pills li {
    position:relative;
}
.nav-pills a {
    color:#363a3d;
}
.nav-pills a:hover {
    color:$color-link-hover;
}
///*.nav-pills .active,
//.nav-pills .active a {
//    color:#6ebe3b;
//}*/
.nav-pills .badge {
    margin:0 0 0 10px;
    vertical-align:middle;
}
h2 + .nav-pills-wrap {
    float:right;
    margin-top:10px;
    margin-bottom:10px;
    font-size:16px;
}
@media (max-width: 991px) {
    h2 + .nav-pills-wrap {
        float:left;
        clear: both;
        margin-top: 0;
        margin-bottom: 20px;
    }
}
h2 + .nav-pills-wrap .nav-pills .spacer {
    margin:0 10px;
}


/* [ nav tabs ] */
.nav-tabs-title {
    text-transform:uppercase;
    font-size: 12px;
}
.nav-tabs-wrap {
    box-shadow:inset 0 -1px 0 rgba(0,0,0,.1);
}
.nav-tabs,
.nav-tabs li {
    @include x-list-init;
}
.nav-tabs li,
.nav-tabs li > a {
    display:inline-block;
}
.nav-tabs *,
.nav-tabs-opaque * {
    vertical-align:middle;
}
.nav-tabs li,
.nav-tabs-opaque li {
    vertical-align:bottom;
}
.nav-tabs a svg,
.nav-tabs-opaque svg {
    @include x-svg-size(18);
    stroke-width:8;
}
.nav-tabs,
.nav-tabs li > a {
    color:#555;
}
.nav-tabs li > a {
    padding:8px 17px;
    border-bottom:2px solid transparent;
    transition:border-bottom-color 0.3s ease, color 0.3s ease;
}
.nav-tabs li > a:hover {
    border-bottom-color:#ccc;
}
.nav-tabs li > a:hover,
.nav-tabs .active > a {
    color:#363a3d;
}
.nav-tabs-wrap .dropdown-toggle {
    padding:7px 14px;
    box-shadow:inset 0 1px 2px rgba(0,0,0,.23), 0 0 0 1px rgba(0,0,0,.1);
}
.nav-tabs {
    @media (max-width: 576px) {
        .ico-left {
            margin-right: 0;
        }
        .nav-tabs-title {
            display: none;
        }
    }
}
/* [ special header with nav + bg ] */
.nav-tabs-wrap.navbar-header .nav-tabs li > a {
    padding:12px 17px;
}

/* [ nav tabs opaque] */

.nav-tabs-opaque {
    @media (max-width: 991px) {
        svg {
            margin: 0;
        }
        .nav-tabs-title {
            display: none;
        }
    }
}
.nav-tabs-opaque a {
    display:block;
    padding:14px 10px;
    position:relative;
    z-index:1;
    bottom:-1px;
    border:1px solid rgba(0,0,0,0);
    border-bottom-width:0;
}
.nav-tabs-opaque .active,
.nav-tabs-opaque .active a,
.nav-tabs-opaque .ui-tabs-active,
.nav-tabs-opaque .ui-tabs-active a {
    color:#363a3d;
}
.nav-tabs-opaque .active a,
.nav-tabs-opaque .ui-tabs-active a {
    background:#fff;
     border-top-left-radius:2px;
    border-top-right-radius:2px;
    border-color:rgba(0,0,0,0.1);
    //box-shadow:0 0 1px rgba(0,0,0,.22);
}
.nav-tabs-opaque .status {
    font-size:8px;
    letter-spacing:0;
    position:absolute;
    right:15px;
    top:3px;

    .ico {
        width:5px;
        height:5px;
        margin-right:3px;
    }
}

/* justify */
.nav-tabs-justify .nav-tabs-opaque {
    display:table;
    width:100%;
}
.nav-tabs-justify .nav-tabs-opaque li {
    text-align:center;
    display:table-cell;
}