.subaccounts-video {
    position:relative;
}
.subaccounts-video .ico-play {
    text-align:center;
    line-height:88px;
    cursor:pointer;
    top:56px;
    left:137px;
    position:absolute;
    z-index:4;
    width:94px;
    height:94px;
    background-color:$color-success;
    border:2px solid #fff;
    border-radius:100%;
    opacity:.87;
    color:#fff;
    transition: all 0.4s ease-in-out;
}
.subaccounts-video .ico-play:hover {
    box-shadow:inset 0 0 0 110px #fff, inset 0 0 0 16px rgba(255,255,255,.8), 0 1px 2px rgba(0,0,0,.1);
}
.subaccounts-video .ico-play [data-icon] {
    font-size:34px;
    transition:color .3s ease-in-out;
}
.subaccounts-video .ico-play [data-icon]:before {
    right:-4px;
}
.subaccounts-video .ico-play:hover [data-icon] {
    color:$color-success;
}
@import '../../../../../shared/web/views/src/scss/__partials/popup_fancybox';
@import '../../../../../shared/web/views/src/scss/__sections/upload_image';