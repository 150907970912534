.webinar-start-banner {
    background: #6ebe3b;

    td {
        position: relative;

        &:first-child:before {
            background: url(/account_panel/images/webinar-banners/webinar-start-banner-presenters.jpg) no-repeat center right;
            background-size: cover;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 707px;
        }

    }

    .cell-date [data-icon] {
        color: #fff;
    }
    .cell-date .svg-stroke {
        color: #fff;
    }

    .cell-date strong {
        position: relative;
        color: #fff;
    }

    .cell-date time {
        position: relative;
        color: #fff;
    }

    .desc h6 {
        a {
            color: #fff;
        }

        .truncate {
            font-size: 16px;
            max-width: 420px !important;
        }
    }

    .cell-desc-small {
        font-size: 13px;
        margin: 2px 0 0;

        .presenter {
            color: #fff;

            label {
                font-family: $font-roboto-regular;
                color: #c8f7aa;
                cursor: auto;
            }

        }

    }

    .btn.normal {
        font-size: 12px;
        padding: 4px 12px 6px;
    }

}