.webinar-published_recording {
    
    &-container {
        
        &-modal {
            padding: 50px 70px 70px;
            
            * {
                box-sizing: border-box;
            }
            
            h2,
            p {
                color: #292d38;
            }
            
            p {
                margin-top: 0;
                margin-bottom: 10px;
            }
            
        }
        
        @media (max-width: 767px) {
            
            &-modal {
                padding: 15px 35px 35px;
            }
            
        }
        
    }
    
    &-url {
        margin-bottom: 30px;
        
        .link {
            position: relative;
            padding: 8px 12px;
            border-radius: 3px;
            border: 1px solid #dfe0e1;
            
            .copy {
                
                &-wrap {
                    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%);
                    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 20%);
                    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 20%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
                    position: absolute;
                    padding-left: 25px;
                    padding-right: 10px;
                    right: 0;
                    top: 8px;
                    bottom: 8px;
                }
                
                &-to-clipboard {
                    background-color: #00afec;
                    display: inline;
                    padding: .2em .6em;
                    font-size: 12px;
                    font-weight: normal;
                    letter-spacing: 0;
                    color: #fff !important;
                    text-align: center;
                    text-transform: uppercase;
                    white-space: nowrap;
                    vertical-align: middle;
                    border-radius: .25em;
                    
                    &:hover {
                        background-color: #ff800f;
                    }
                    
                }
                
            }
            
        }
        
    }
    
    &-share {
        margin-bottom: 30px;
        
        .social {

            [class*='social-ico-'] {
                width: 40px;
                height: 40px;
                border-radius: 4px;
                overflow: initial;

                svg {
                    width: 22px;
                    height: 22px;
                    margin-top: -11px;
                    margin-left: -11px;

                }

            }

            .mark {
                width: 20px;
                height: 20px;
                position: absolute;
                background: #6ebe3b;
                right: -8px;
                bottom: -8px;
                border-radius: 50%;
                border: 3px solid #fff;

                svg {
                    width: 10px;
                    height: 10px;
                    margin-top: -5px;
                    margin-left: -5px;
                }

            }

        }
        
    }
    
    &-footer {
        
        .list-published_recording {
            padding-left: 0;
            margin-top: 10px;
            list-style: none;
            color: #5c6273;

            li {
                display: inline-block;
                margin: 0 10px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.break {
                    position: relative;
                    margin: 0;

                    &:before {
                        content: '';
                        position: absolute;
                        background: #bec0c7;
                        top: 50%;
                        width: 1px;
                        height: 6px;
                        margin-top: -3px;
                    }

                }
                
            }

        }
        
    }
    
    &-send {
        position: relative;
        margin: 30px 0;
        
        &-icon {
            position: absolute;
            top: 50%;
            margin-top: -75px;
            @media (max-width: 991px) {
                top: auto;
                margin-top: auto;
                left: 50%;
                margin-left: -75px;
            }
            
            svg {
                width: 150px;
                height: 150px;
                color: #6ebe3b;
            }
            
        }
        
        &-body {
            padding: 15px 0 15px 185px;
            @media (max-width: 991px) {
                padding: 185px 0 15px;
            }
                
            h3 {
                font-family: $font-roboto-regular;
                font-size: 24px;
                color: #6ebe3b;
                margin-bottom: 10px;
            }
            
            p {
                font-family: $font-roboto-light;
                font-size: 18px;
                color: #262626;
            }
            
        }
        
    }
    
}

