.summary-section dt,
.summary-section dd {
    font-size:16px;
}
.summary-section dt {
    width:175px;
    @media (max-width: 768px) {
        width: 100%;
        float: none;
        text-align: left;
        margin-bottom: 10px;
    }
}
.summary-section dd {
    color:#606365;
    margin:0 0 20px 200px;
    @media (max-width: 768px) {
        margin-left: 50px;
    }
}
.summary-section .list-ordered dt,
.summary-section .list-ordered dd {
    font-size:14px;
}
.summary-section .list-ordered dd {
    color:#363a3d;
}
.summary-section .dl-horizontal {
    margin-top:20px;
}
.summary-section .unit-ft .options .btn-link {
    font-size:14px;
    padding-top:0;
    padding-bottom:0;
    padding-left:0;
}

.summary-section .clipboard {
    float: left;
    margin-top: -25px;
    margin-left: 200px;
    cursor: pointer;
}
