.fancybox-close:before,
.fancybox-nav span:before,
.calendar .c-events-wrapper:after,
.check:before,
.alert-content:before,
.alert-content-inline:before,
.dialog-content:before,
.list-checked > li:before,
.select2-search:after,
.select2-search-choice-close:before,
.gv_navWrap > div:before,
.gv_panelNavPrev:before,
.gv_panelNavNext:before,
.gv_showOverlay:before {
    font-family:'untitledregular';
}

h1, h2, h3, h4,
.social dt,
.unit-title-x-large .unit-title,
.unit-solid .unit-hd,
.c-events-day-number,
.c-day-with-one-event.c-day-with-dragged-event .c-events-day-number,
.event-info .time,
.keypad .num,
.timezone .tzBtn,
.list-ordered > li:before,
.trumbowyg-modal-title,
.trumbowyg-modal-box .trumbowyg-modal-button {
    font-family:'robotolight', Helvetica,Arial,sans-serif;
}

h2 + .nav-pills-wrap .nav-pills,
.account .dropdown-menu-user .name,
.pagination,
.list-tiles h5,
.list-tiles small,
.list-tiles .label,
.list-tiles .tiles-title,
.lead,
.lead strong,
.book .letter,
.personal-data dt,
.statistics-page h3,
.keypad dd,
.api-section input[type='text'],
.account-settings-section .list-editable input[type='text'],
.account-settings-section .list-editable .list-editable-element,
.alert-content,
.dialog,
.dialog-title,
.app-item,
.list-choose-separated .comment,
.address-section .form-label,
blockquote,
.legend {
    font-family:'robotolight',Helvetica,Arial,sans-serif;
}
.alert-referral .alert-content strong,
.list-step-hd:before {
    font-weight:normal;
    font-family:'robotomedium',Helvetica,Arial,sans-serif;
}


/* wyjatki dla brandingu */
.popup-subaccounts-intro strong {
    font-family:'robotobold', Helvetica,Arial,sans-serif !important;
}
.font-reklame,
.helper h4 {
    font-family:'reklame_script_regularregular', 'lobsterregular',Helvetica,Arial,sans-serif !important;
}
