.countdown {
    
    &-wrap {
        padding: 20px 0 30px;
    }
    
    &-title {
        margin: 0;
        padding: 10px !important;
        font-size: 16px !important;
        font-weight: normal !important;
        color: #292d38;
    }

    &-box {
        border-radius: 5px;
        background: #0F131D;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        box-shadow: 0 15px 29px 0 rgba(41, 45, 56, 0.26);
        max-width: 425px;
        border-radius: 10px;
    }
    
    &-clock {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-ics-title {
        text-align: center;
        color: white;
        padding: 0 !important;
        padding-bottom: 10px !important;
        margin-bottom: 10px;
        font-size: 16px !important;
    }

    &-ics {
        padding-top: 15px;
        padding-bottom: 30px;
    }

    &-ics-button-wrapper {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center; 
    }

    &-ics-button {
        display: flex;
        align-items: center;
        background: #1B1F2B;
        border-radius: 100px;
        color: white !important;
        text-decoration: none;
        font-family: 'robotobold';
        height: 30px;
        padding: 0px 15px;
    }

    &-ics-button__text {
        margin-left: 5px;
    }

    @include xs {
        &-ics-button__text {
            display: none;
        }
    }

    &-ics-button:not(:last-child) {
        margin-right: 20px;
    }

    &-ics-button-img {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
    }

    
    
    &-item {
        position: relative;
        width: 25%;
        padding: 10px 10px 15px;
        
        &:before {
            content: ':';
            position: absolute;
            top: 50%;
            right: 0;
            margin-right: -5px;
            margin-top: -24px;
            width: 10px;
            height: 34px;
            font-size: 18px;
            font-weight: bold;
            color: #a7afc6;
        }
        
        &:last-child:before {
            content: none;
        }
        
    }
    
    &-time {
        display: block;
        font-size: 34px;
        color: #fff;
    }
    
    &-label {
        display: block;
        text-transform: uppercase;
        font-size: 10px;
        color: #a7afc6;
    }
    &-end {
        display: none;
        &__wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 32px 0;
            @media (max-width: 768px){
                padding: 16px 0;
            }
        }
        &__svg {
            svg {
                width: 120px;
                height: 120px;
                fill: currentColor;
                @media (max-width: 768px){
                    width: 80px;
                    height: 80px;
                }
                @media (max-width: 576px) {
                    width: 50px;
                    height: 50px;
                }
            }
        }
        &__content {
            margin-left: 15px;
            text-align: left;
            @media (max-width: 768px) {
                margin-left: 10px;
            }
            @media (max-width: 576px) {
                margin-left: 5px;
            }
        }
        &__title {
            color: #292d38;
            font-size: 18px;
            line-height: 26px;
            font-family: $font-roboto-regular;
            max-width: 220px;
            margin: 0 0 15px;
            @media (max-width: 768px){
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 10px;
            }
        }
        &__desc {
            color: #5c6273;
            font-size: 12px;
            line-height: 14px;
            font-family: $font-roboto-light;
            margin: 0;
            @media (max-width: 768px){
                font-size: 11px;
            }
        }
    }
    
}
