@use 'sass:math';
/* [ unstyled ] */
.list-unstyled {
    padding-left:0;
    list-style:none;
}
.list-inline {
    @extend .list-unstyled;

    > li {
        display:inline-block;
        margin-right:10px;

        &:first-child {
            @media (max-width: 768px) {
                margin-bottom: 15px;
            }
        }

        &:last-child {
            margin-right:0;
            @media (max-width: 768px) {
                margin-top: 15px;
            }
        }
    }
}
#confirmation_buttons .list-inline {
    > li {
        margin-right: 0;
    }
}


/* [ vertical ] */
.vr-list,
.vr-list li,
.nav-pills,
.nav-pills li,
.nav-tabs-opaque,
.nav-tabs-opaque li {
   @include x-list-init;
}
.vr-list li,
.nav-pills li,
.nav-tabs-opaque li {
   display:inline-block;
}
.vr-list li,
.nav-pills li {
   font-size: 12px;
   color: #b3b3b3;
}
.vr-list li.vr-list-label,
.nav-pills li.vr-list-label {
    margin-right: 6px;
}
.vr-list .spacer,
.nav-pills .spacer {
    $height: 9px;
    $margin: 5px;
    $color: #d8d8d8;

    width: auto;
    height: $height;
    border: 0;
    background: none;
    border-right: 1px dotted $color;
    margin: 0 $margin;
    vertical-align: middle;

    &-solid {
        border-right: 1px solid $color;
        margin: 0 18px;
    }

    $size: 6;
    @while $size > 0 {
        &-#{$size}x {
            height: $height * $size;
            margin: 0 math.div($margin * ($size + 1), 2);
        }
        $size: $size - 1
    }
}


/* [ dl horizontal ] */
.dl-horizontal {
    margin:30px 0;
}
.dl-horizontal dt,
.dl-horizontal dd {
    line-height:1.6em;
    min-height:22px;
}
.dl-horizontal dt {
    text-align:right;
    float:left;
    clear:left;
    width:110px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    font-family: "robotoregular";
    color: #363a3d;
    @media (max-width: 576px) {
        width: 100%;
        text-align: left;
        float: none;
    }
}
.dl-horizontal dd {
    margin:0 0 12px 120px;
    @media (max-width: 576px) {
        text-align: left;
        margin-left: 50px;
    }
}
.dl-horizontal dd .txt-featured {
    position:relative;
    top:-3px;
    padding-bottom:2px;
    vertical-align:middle;
}
.dl-horizontal .form-inline .form-group {
    margin:-1px 0 15px;
}

/* [ dl inline] */
.dl-inline {
    dt, dd {
        display:inline-block;
        vertical-align:middle;
    }
    dt {
        margin-right:15px;
    }
}


/* [ dl vertical ] */
.dl-vertical {
    margin:30px 0;

    dt {
        margin:20px 0 3px;

        &:first-child {
            margin-top:0;
        }
    }

    dd {
        margin:3px 0;

        &:last-child {
            margin-bottom:0;
        }
    }

}


/* [ loose inline elements ] */
.list-hd {
    margin-right:10px;
    display:inline-block;
}
.list-hd + *,
.list-dd {
    display:inline-block;
    /*vertical-align:middle;*/
}
.list-dd + .list-hd {
    margin-left:10px;
    margin-right:0;
}
