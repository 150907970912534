.reminders {
    position:relative;
}
.reminders .form-group {
    margin-bottom:10px;
}
.reminders .form-control:first-child {
    width:165px;
    padding-right:0;
}
.reminders .notify {
    margin-left:10px;
    @media (max-width: 767px) {
        margin-left: 0;
    }
}
.reminders .btn-link-delete [data-icon]:before {
    top:0;
}
.reminders .btn-wrap {
    font-size:12px;
}
.reminders .list-check .form-control,
.reminders .list-check .form-control:first-child,
.reminders .list-check .form-group {
    display:block;
    width:auto;
    margin:10px 0;
    padding:0;
}
.reminders .list-check .form-control:first-child {
    margin-top:0;
}
.reminders .list-check .form-control:last-child {
    margin-bottom:0;
}
.reminders .dropdown-info {
    min-width:100%;
}
.reminders .form-inline .form-group .form-control.notify {
    @media (max-width: 767px) {
        display: table-row;
    }
}