.overlap.pos-tr,
    .overlap.pos-tl {
        position:absolute;
        top:0;
        margin-top:25px;
    }
    .overlap.pos-tl {
        right:100%;
        margin-right:-1px;
    }
    .overlap.pos-tr {
        left:100%;
        margin-left:-1px;
    }
    .overlap.pos-tr .overlap-info {
        box-shadow:inset 10px 0 7px -10px rgba(0,0,0,.5), 0 2px 0 rgba(0,0,0,.11);
        border-bottom-right-radius:2px;
           border-top-right-radius:2px;
    }
    .overlap.pos-tl .overlap-info {
        box-shadow:inset -10px 0 7px -10px rgba(0,0,0,.5), 0 2px 0 rgba(0,0,0,.11);
        border-bottom-left-radius:2px;
           border-top-left-radius:2px;
    }
    .overlap.pos-tl .flat {
        box-shadow:inset -5px 0 10px -7px rgba(0,0,0,.3);
    }
    .overlap.pos-tr .flat {
        box-shadow:inset 5px 0 10px -7px rgba(0,0,0,.3);
    }
    .overlap-info {
        color:#fff;
        padding: 10px 8px;
        line-height:1;
    }
    .overlap.large .overlap-info {
        font-size:24px;

        svg {
            @include x-svg-size(22);
            top:-2px;
        }
    }
    /*.overlap-success {
        background:#6ebe3b;
        background:-webkit-linear-gradient(top,  #79cb45 0%,#6ebe3b 100%);
        background:linear-gradient(to bottom,  #79cb45 0%,#6ebe3b 100%);
    }
    .overlap-success.flat {
        background:#73c13e;
    }*/
    .overlap-default.flat {
        background:#fff;
    }
    .overlap.pos-tr .overlap-default.flat {
        box-shadow: 0px 12px 35px 0px rgba(16, 36, 95, 0.10);
    }
    .overlap.pos-tl .overlap-default.flat {
        box-shadow: 0px 12px 35px 0px rgba(16, 36, 95, 0.10);
    }



    .overlap-warning.flat {
        background:#ffb242;
    }
    .overlap-error {
        background:#ff3b00;
        background:-webkit-linear-gradient(top,  #ff3b00 0%,#ff4900 100%);
        background:linear-gradient(to bottom,  #ff3b00 0%,#ff4900 100%);
    }

    .table-flat .overlap {
        bottom:0;
        margin:0;
    }
    .table-flat .overlap-info {
        height:100%;
        padding:0;
        width:34px;
    }
    .overlap .rotate {
        white-space:nowrap;
        display:block;
        padding:0 19px 0 0;
        line-height:34px;
        position:absolute;
        bottom:15px;
        transform:rotate(-90deg);
        transform-origin:17px;
    }
    .overlap .truncate {
        width:67px;
    }