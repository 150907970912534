/* [ section upload image ] */
    .upload-image-file .choose-photo {
        color:#bebebe;
        font-size:16px;
        margin:40px 0 0;
    }
    .upload-image-file .choose-photo a,
    .upload-image-file .choose-photo a:hover {
        color:#363a3d;
    }
    .upload-image-file .choose-photo a {
        font-size:24px;
        letter-spacing:-1px;
        text-align:center;
        text-shadow:0 1px 0 #fff;
        display:inline-block;
        height:193px;
        min-width:173px;
        border-radius:2px;
        border:1px solid rgba(0,0,0,0);
    }
    .upload-image-file .choose-photo a:hover {
        background:#fff;
        border-color:rgba(0,0,0,0.1);
    }
    .upload-image-file .choose-photo .ico {
        color:#d3d5d9;
        font-size:150px;
        line-height:1;
        display:block;
        height:147px;
    }
    .upload-image-file .choose-photo a:hover .ico {
        color:#363a3d;
    }
    .upload-image-cam .canvas {
        text-align:center;
        position:relative;
        overflow:hidden;
        width:434px;
        height:240px;
        margin:0 auto;
    }