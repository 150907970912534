/* [ caret list ] */
.list-caret,
.list-caret > li,
.list-caret-item {
    @include x-list-init;
}
.list-caret-item {
    display: block;
    position: relative;
    padding: 5px 5px 5px 30px;

    &:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        line-height: 0;
        position: absolute;
        left: 15px;
        top: 50%;
        margin-top: -3px;
        border: 3px solid rgba(0, 0, 0, 0);
        //border-left-color:#6ebe3b;
    }
}
.list-caret-medium {
    font-size: 16px;

    .list-caret-item:before {
        top: 14px;
        margin-top: 0;
    }
}
.list-caret-large {
    font-size: 18px;

    .list-caret-item {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

/* [ special order list ] */
.list-ordered,
.list-ordered > li {
    @include x-list-init;
}
.list-ordered {
    border-top: 1px solid #f0f0f0;
    counter-reset: item;
}
.list-ordered > li {
    padding: 15px 0;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
}
.list-ordered > li:before {
    content: counter(item);
    counter-increment: item;
    color: #606365;
    font-size: 107px;
    line-height: 0.8;
    text-align: center;
    opacity: 0.1;
    min-width: 70px;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.list-ordered .dl-horizontal {
    margin: 0;
    position: relative;
    z-index: 3;
}
.list-ordered .dl-horizontal dt {
    width: 80px;
}
.list-ordered .dl-horizontal dd {
    margin: 0 0 5px 95px;
}
.list-ordered .dl-horizontal small {
    color: #606365;
    font-size: 12px;
}

/* [ list step ] */
.list-step,
.list-step-item {
    @include x-list-init;
}
.list-step-item {
    padding: 28px;
    margin: 0 auto 30px;
    border-radius: 2px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.list-step {
    counter-reset: step;
}
.list-step-hd {
    font-size: 28px;
    margin-left: 34px;

    &:before {
        content: counter(step);
        counter-increment: step;
        display: inline-block;
        //color:#6ebe3b;
        font-size: 12px;
        vertical-align: 6px;
        text-align: center;
        line-height: 19px;
        width: 20px;
        height: 20px;
        margin: 0 14px 0 -34px;
        border-radius: 50%;
        border: 1px solid $color-current;
        box-sizing: border-box;
    }
}
.list-step-bd {
    color: #96989e;
    font-size: 19px;
    margin: 0;
}

/* [ list choosen ] */
.list-choose,
.list-choose > li,
.list-bar,
.list-bar > li {
    @include x-list-init;
}
.list-choose .item {
    padding: 15px;
}
.list-choose .active,
.list-bar .item {
    background: #fff;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    //box-shadow:0 0 1px rgba(0,0,0,.22);
}
.list-choose .active {
    pointer-events: none;
}
.list-choose .col-name {
    color: inherit;
    font-size: 18px;
}
.list-choose .img-decorate {
    border-width: 2px;
}
.list-choose .dsp-table {
    table-layout: fixed;
}
.list-choose .col-progress {
    width: 300px;
}
.list-choose .col-ico {
    width: 50px;
}
.list-choose .col-info {
    color: #555;
    font-size: 12px;
    white-space: nowrap;
    padding: 0 20px 0 10px;
    width: 45px;
}

.list-choose-separated .item {
    padding: 10px 0;
    border-top: 1px dotted #cfcfcf;
}
.list-choose-separated .item:first-child {
    padding-top: 0;
    border-top: 0;
}
.list-choose-separated .item:last-child {
    padding-bottom: 0;
}
.list-choose-separated .col-ico {
    width: 16px;
}
.list-choose-separated [data-icon*="caret-right"] {
    border-left-color: #75c940;
}
.list-choose-separated .comment {
    color: #606365;
    margin: 15px 0 15px 16px;
    padding: 0;
    border-width: 0;
    //box-shadow:none;
}

.list-bar .item {
    padding: 10px 15px;
    margin: 12px 0;
}
.list-bar .item .dsp-cell {
    padding: 0 5px;
}
.list-bar .col-action {
    text-align: right;
    width: 15%;
}
.list-bar .col-state {
    width: 35%;
}
.list-bar .caption-small {
    margin-top: 0;
}
.list-bar .item-empty {
    background-color: transparent;
    border: 2px dashed rgba(0, 0, 0, 0.1);
}
.list-bar .item-empty .col-name {
    opacity: 0.3;
}

/* [ list pictogram ] */
.list-pictogram {
    &,
    .pictogram-item {
        @include x-list-init;
        text-align: center;
    }

    & {
        display: table;
        margin: 0 auto;
        width: 100%;
    }
    .pictogram-item {
        display: table-cell;
        position: relative;
        vertical-align: top;
        box-sizing: border-box;
    }

    a {
        color: #606365;
        line-height: 1.2;
        display: block;
    }
}
.list-pictogram-hd,
.list-pictogram-bd {
    font-family: inherit;
    font-size: inherit;
    margin: 5px 5px 0;
}
.pictogram-item .pictogram {
    color: #d2d2d2;
    display: block;
    height: 44px;

    svg {
        @include x-svg-size(44);
        display: block;
        margin: 0 auto;
    }
}
.pictogram-item .pictogram-addon {
    color: #fff;
    font-size: 10px;
    line-height: 1;
    text-align: center;
    background-color: #d2d2d2;
    border: 3px solid #f7f7f7;
    display: block;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    padding: 0;
    height: 16px;
    width: 16px;
    margin-left: 16px;
    top: 13px;
    transform: translateX(-50%);
}
.pictogram-addon svg {
    @include x-svg-size(8);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -4px;
    margin-left: -4px;
    // transform:translate(-50%,-50%);
}
.pictogram-addon .svg-stroke {
    stroke-width: 15;
}
/*.list-pictogram a:hover .pictogram,
.list-pictogram .active .pictogram,
.list-pictogram a:hover .list-pictogram-hd,
.list-pictogram .active .list-pictogram-hd {
    color:#6ebe3b;
}
.list-pictogram a:hover .pictogram-addon,
.list-pictogram .active .pictogram-addon {
    background-color:#6ebe3b;
}*/
.pictogram-success.pictogram-addon {
    background-color: $color-success;
}
.pictogram-error.pictogram-addon {
    background-color: $color-error;
}

.list-pictogram-medium {
    .pictogram-item {
        padding: 20px 30px;
        width: 235px;
        border-right: 1px solid #eee;

        &:last-child {
            border-right: 0;
        }
    }

    .list-pictogram-hd {
        color: #3d464d;
        font-size: 24px;
    }

    .list-pictogram-bd {
        color: #969696;
        line-height: 1.5;
        margin-top: 18px;
    }

    a {
        height: auto;
        position: relative;
    }

    .pictogram {
        height: 80px;
        margin: 16px 0;

        svg {
            @include x-svg-size(80);
        }
    }

    .pictogram-addon {
        border-width: 6px;
        height: 34px;
        width: 34px;
        top: 45px;
        margin-left: 40px;

        svg {
            @include x-svg-size(18);

            margin-top: -9px;
            margin-left: -9px;
        }
    }
}

.list-pictogram-large {
    .pictogram-item {
        padding: 0 30px;
        border-right: 1px solid #eee;

        &:last-child {
            border-right: 0;
        }
    }

    .pictogram {
        height: 140px;
        margin: 16px 0;

        svg {
            @include x-svg-size(140);
        }
    }

    .pictogram-addon {
        height: 66px;
        width: 66px;
        top: 96px;
        margin-left: 70px;
        border-width: 6px;

        svg {
            @include x-svg-size(34);
            margin-top: -17px;
            margin-left: -17px;
        }
    }

    .list-pictogram-hd {
        color: #3d464d;
        font-size: 30px;
    }

    .list-pictogram-bd {
        color: #969696;
        line-height: 1.5;
        margin-top: 18px;
    }

    .unit {
        background-color: transparent;
        margin-bottom: 0;
        box-shadow: none;
        border-color: transparent;
    }
}

.list-pictogram-horizontal {
    .pictogram-item {
        display: table;
        margin: 15px 0;
    }

    .pictogram-holder,
    .pictogram-desc {
        display: table-cell;
        vertical-align: middle;
    }

    .list-pictogram-hd {
        margin-top: 0;
    }

    .pictogram-desc {
        padding-left: 20px;
    }

    .pictogram-holder {
        position: relative;
        padding: 0;
    }

    .pictogram {
        height: 26px;

        svg {
            @include x-svg-size(26);
            vertical-align: middle;
        }
    }
}

/* [ Tip change lang ] */
.change-lang {
    position: absolute;
    z-index: 99;

    &-flag {
        position: relative;
        text-transform: uppercase;
        color: #a7afc5;
        padding: 0 5px 0 25px;
        font-size: 12px;

        svg {
            position: absolute;
            width: 16px !important;
            height: 16px !important;
            left: 5px;
            top: 50%;
            margin-top: -8px;
            stroke-width: 1;
        }
    }

    .choice {
        background-color: #444956;
    }

    a {
        position: relative;
        color: inherit;
        padding: 3px 24px 3px 7px;
        margin: 0 3px;
        border-radius: 3px;
        display: block;
        text-transform: uppercase;

        svg {
            position: absolute;
            width: 10px;
            height: 10px;
            right: 5px;
            top: 50%;
            margin-top: -5px;
            stroke-width: 1;
        }

        &:hover {
            background-color: #444956;
        }
    }
}
.change-lang.pos-tr {
    top: 10px;
    right: 10px;
    left: auto;
}
.change-lang.pos-tl {
    top: 10px;
    left: 10px;
    right: auto;
}
.change-lang .tip.pos-t {
    min-width: 150px;
    left: -82px;
}

/* [ alert dialplan ] */
///*.alert-upgrade {
//    width:708px;
//    font-size:15px;
//    line-height:1.2;
//    margin-top:20px;
//
//    .alert-title {
//        font-size:24px;
//    }
//
//    .alert-content {
//        padding:14px;
//    }
//}*/
.alert-dialplan {
    width: 100%;
    margin-top: 0;
    position: relative;

    .alert-title {
        font-size: 21px;
        margin: 0;
    }

    .alert-content {
        padding: 14px;
        display: block;
        background: rgba(255, 255, 255, 0.34);

        &:hover {
            background: rgba(255, 255, 255, 1);
        }
    }

    a span {
        color: $color-link;
    }

    [data-icon="R"] {
        color: #606365;
        opacity: 0.26;
    }

    .helper {
        width: 165px;
        bottom: 100%;
        margin-bottom: 20px;
    }
}

.alert-started {
    width: auto;

    .alert-content {
        padding: 15px 20px;
        .btn-wrap .btn {
            min-width: 180px;
            &:not([href]) {
                color: $white;
            }
            &:first-child {
                margin-bottom: 4px;
            }
            &:last-child {
                margin-top: 4px;
            }
        }
    }
    .alert-title {
        font-size: 22px;
        margin-bottom: 2px;
    }

    .alert-desc {
        font-family: $font-roboto-regular;
        font-size: 12px;
        a {
            text-decoration: underline;
        }
    }
}

.alert-referral {
    width: auto;
    max-width: 708px;
    font-size: 18px;
    margin-top: 20px;

    .alert-content {
        color: #464a55;
        padding: 20px 24px;
        background: #fbfbfc 106% 26%
            url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDUwIDUwIiBzdHlsZT0id2lkdGg6MTUwcHg7aGVpZ2h0OjE1MHB4OyI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4gICAgLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNlZmYxZjU7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwO308L3N0eWxlPjxnPiAgICA8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIzMyIgY3k9IjE3IiByPSIxNSIvPiAgICA8bGluZSBjbGFzcz0ic3QwIiB4MT0iMzMiIHkxPSIyMyIgeDI9IjMzIiB5Mj0iMjUiLz4gICAgPGxpbmUgY2xhc3M9InN0MCIgeDE9IjMzIiB5MT0iOSIgeDI9IjMzIiB5Mj0iMTEiLz4gICAgPHBhdGggY2xhc3M9InN0MCIgZD0iTTMwLDIwYzAsMS43LDEuMywzLDMsM2MxLjcsMCwzLTEuMywzLTNjMC0xLjctMS4zLTMtMy0zYy0xLjcsMC0zLTEuMy0zLTNjMC0xLjcsMS4zLTMsMy0zYzEuNywwLDMsMS4zLDMsMyIvPiAgICA8bGluZSBjbGFzcz0ic3QwIiB4MT0iMTciIHkxPSIzOSIgeDI9IjE3IiB5Mj0iNDEiLz4gICAgPGxpbmUgY2xhc3M9InN0MCIgeDE9IjE3IiB5MT0iMjUiIHgyPSIxNyIgeTI9IjI3Ii8+ICAgIDxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNCwzNmMwLDEuNywxLjMsMywzLDNjMS43LDAsMy0xLjMsMy0zYzAtMS43LTEuMy0zLTMtM2MtMS43LDAtMy0xLjMtMy0zYzAtMS43LDEuMy0zLDMtM2MxLjcsMCwzLDEuMywzLDMiLz4gICAgPHBhdGggY2xhc3M9InN0MCIgZD0iTTE4LjEsMTguMUMxNy43LDE4LDE3LjQsMTgsMTcsMThDOC43LDE4LDIsMjQuNywyLDMzYzAsOC4zLDYuNywxNSwxNSwxNWM4LjMsMCwxNS02LjcsMTUtMTUgYzAtMC40LDAtMC43LTAuMS0xLjEiLz48L2c+PC9zdmc+")
            no-repeat;
        background-size: 150px 150px;
    }
}

.alert-integrations {
    width: auto;
    max-width: 709px;
    margin-top: 20px;

    .alert-content {
        padding-left: 60px;

        svg {
            position: absolute;
            top: 50%;
            margin-top: -15px;
            left: 20px;
            width: 30px;
            height: 30px;
            color: #00aeef;
        }
    }
}

/* [ message upgrade free ] */
.message-upgrade {
    font-size: 17px;
    line-height: 1.2;
    text-align: left;
    padding: 0.3em;
    .container {
        .in {
            padding: 15px 10px;
            .form-group {
                padding-left: 45px;
                width: 100%;
            }
            .form-label .ico-left {
                left: 0;
            }
        }
    }
}
.message-upgrade h6,
.message-upgrade .btn,
.message-upgrade .message-close {
    color: inherit;
    text-decoration: none;
}
.message-upgrade h6 {
    font-family: $font-roboto-bold;
    font-size: 17px;
    margin: 0 0 3px;
    color: #fff;
    font-weight: 400;
    span {
        margin: 0;
        border-radius: 4px;
        background-color: #00769f;
        box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
    }
    @media (max-width: 767px) {
        font-size: 12px;
    }
}
.message-upgrade .form-group {
    width: 100%;
    padding-left: 40px;
    position: relative;
}
.message-upgrade .form-inline {
    @media (max-width: 992px) {
        padding-left: 0;
        padding-right: 0;
    }
}
.message-upgrade .form-label .ico-left {
    @include x-svg-size(30);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    &.svg-stroke {
        stroke-width: 2;
        color: #006f95;
    }
}
.message-upgrade p {
    margin: 0;
    strong {
        font-family: $font-roboto-bold;
        text-decoration: underline;
    }
}
.message-upgrade p,
.message-upgrade .btn {
    font-size: 13px;
    @media (max-width: 767px) {
        font-size: 10px;
    }
}
.message-upgrade .btn.btn-default {
    padding: 7px;
    font-family: $font-roboto-bold;
    color: #1b1f2b;
    box-shadow: 0px 3px 10px 0px rgba(0, 76, 103, 0.28);
    transition: box-shadow 0.5s ease-in-out;
    &:hover,
    &:focus {
        box-shadow: 0px 8px 27px 0px rgba(0, 76, 103, 0.52);
    }
}
.message-upgrade .message-close {
    cursor: pointer;
    font-size: 15px;
    margin-left: 1.5em;
}

/* [ kafelki do wyboru extrasow ] */
.list-tiles .col {
    display: inline-block;
    vertical-align: top;
    width: 133px;
    margin: 0 0 10px;
}
.list-tiles .f-holder {
    padding: 2px;
    margin: 9px 0 25px;
}
.list-tiles .unit {
    margin: 0 auto;
    padding: 0;
    height: 100px;
    border-radius: 3px;
}
.list-tiles .unit-bd {
    text-align: center;
    padding: 2px;
    line-height: 1.2;
    display: table;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.list-tiles .dsp-cell {
    width: 100%;
}
.list-tiles small {
    font-size: 12px;
}
.list-tiles small .price {
    color: #363a3d;
}
.list-tiles .tiles-title {
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 8px;
    border-bottom: 1px solid #ebebeb;
}
.list-tiles .tiles-info {
    color: #363a3d;
    font-size: 60px;
    display: block;
    line-height: 0.9;
    letter-spacing: -4px;
}
.list-tiles.small {
    margin-left: -6px;
}
.list-tiles.small .col {
    width: 113px;
    margin: 0 0 10px 6px;
}
.list-tiles.small .tiles-info {
    font-size: 45px;
    letter-spacing: -2px;
    margin-top: 7px;
}
.list-tiles.small small {
    font-size: 12px;
}
.list-tiles.small .unit {
    height: 93px;
}
.list-tiles h5 {
    margin: 0 0 5px;
    letter-spacing: 0;
}
.list-tiles h5 + small {
    font-size: 14px;
    color: $color-link;
    letter-spacing: 0;
}
.list-tiles .unit:hover h5 + small {
    color: $color-link-hover;
}
.list-tiles .label {
    display: none;
}
.list-tiles .current,
.list-tiles .future,
.list-tiles .popular {
    background: #c4c4c4;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}
.list-tiles .current .label,
.list-tiles .future .label,
.list-tiles .popular .label {
    color: #363a3d;
    height: 28px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    background: #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 5px;
}
.list-tiles .current-small .label,
.list-tiles .future .label {
    text-transform: none;
    white-space: normal;
    line-height: 12px;
    font-size: 10px;
}
.list-tiles .current,
.list-tiles .current .label{
    background: #6ebe3b;
}
.list-tiles-box-big {
    margin-left: -120px;
    margin-right: -120px;
}
.list-tiles-box .col {
    width: 203px;
    margin: 0 0 20px 20px;
}
.list-tiles-box .unit {
    height: 172px;
    margin: 0;
}
.list-tiles-box .unit-bd {
    height: 107px;
    padding: 0 15px;
}
.list-tiles-box .tiles-title {
    font-size: 16px;
    padding: 15px 15px 0;
    position: static;
    border-bottom-width: 0;
}
.list-tiles-box .tiles-info {
    font-size: 75px;
}
.list-tiles-box .form-inline .form-group {
    margin-top: 10px;
    margin-bottom: 0;
}
.list-tiles-box .form-inline .form-label {
    padding-right: 5px;
}
.list-tiles-box .form-inline .f-holder {
    margin: 0;
    padding: 0;
}
.list-tiles-box .form-inline .form-control {
    width: 50px;
}

.unit-circle {
    border-radius: 50% !important;
    // box-shadow:0 0 1px rgba(0,0,0,.2);
    background: #fff;
    background: -webkit-linear-gradient(top, #ffffff 0%, #f8f8f8 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #f8f8f8 100%);
}
/*.hide-check:checked + .unit-circle {
    color:#6ebe3b;
}*/
.list-tiles.x-small .col,
.list-tiles.medium .col,
.list-tiles.large .col {
    width: auto;
}
.list-tiles.x-small .unit-circle {
    width: 50px;
    height: 50px;

    svg {
        @include x-svg-size(22);
    }

    .unit-bd {
        min-height: 40px;
    }
}
.list-tiles h3 {
    margin: 20px 0 0;
}
.list-tiles.medium .col {
    max-width: 180px;
}
.list-tiles.medium .unit-circle {
    width: 106px;
    height: 106px;
    &--line-height {
        line-height: 106px;
    }
    svg {
        @include x-svg-size(34);
    }
    .ico-hourglass {
        @include x-svg-size(30);
    }

    .ico-copy_details,
    .ico-tick {
        margin-right: -8px;
    }
}
.list-tiles.medium h3 {
    font-size: 18px;
}

.list-tiles.large .unit-circle {
    width: 166px;
    height: 166px;

    svg {
        @include x-svg-size(66);
        stroke-width: 4;
    }
}

.list-tiles [class*="pod-"] {
    color: #bcbcbc;
    font-size: 20px;
    position: absolute;
    border: 1px solid #e9e9e9;
    background: rgba(255, 255, 255, 0.7);
}
.list-tiles [class*="pod-"]:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.list-tiles .pod-share:before {
    content: "\7B";
}
.list-tiles .pod-chat:before {
    content: "t";
}
.list-tiles .pod-attendee-list:before {
    content: "\2014";
}
.list-tiles .pod-camera-and-voice:before {
    content: "I";
}
.list-tiles .pod-files:before {
    content: "\7C";
}

/* [ opcjie dla sekcji/przy naglowkach ] */
.options {
    text-align: right;
    float: right;
    &--left {
        float: left;
        text-align: left;
    }
}
.options [data-icon="G"]:before {
    top: 1px;
    font-size: 16px;
}
.header .options + h2 {
    float: left;
}
.header .options {
    margin-top: 0;
    h4 {
        margin-bottom: 0;
    }
}
.webinar-room-options {
    position: relative;
    &:hover {
        &:after,
        &:before {
            visibility: visible;
        }
    }
    .dropdown-wrap:after,
    .dropdown-wrap:before {
        display: none;
    }
    &:after,
    &:before {
        content: "";
        position: absolute;
        right: 0;
        bottom: -4px;
        width: 0;
        height: 0;
        border: 4px solid transparent;
        z-index: 9999;
        visibility: hidden;
    }
    &:before {
        border-bottom-color: rgba(0, 0, 0, 0.5);
    }
    &:after {
        border-bottom-color: #fcfcfc;
    }
    &__dropdown {
        left: -20px !important;
        padding-top: 3px !important;
        @media only screen and (max-width: 768px) {
            width: 355px;
            left: 0 !important;
            right: 0;
        }
    }
    &__cta {
        font-size: 12px;
        svg {
            width: 18px;
            height: 18px;
            stroke-width: 1;
        }
    }
    &__item {
        display: flex !important;
        align-items: flex-start;
        padding: 13px 20px 25px !important;
    }
    &__name {
        font-size: 16px;
        margin-top: 12px;
        margin-bottom: 17px;
    }
    &__desc {
        font-size: 12px;
        color: #848687;
        line-height: 18px;
        margin: 0;
    }
}

/* wraper dla elementow ktore się podmieniaja */
.inplace-txt,
.inplace-message {
    margin: 3px 0 0;
}

/* [ pyments method cards ] */
[class*="ico-card-"] {
    background-image: url($src-image + "icons_card.png");
    background-repeat: no-repeat;
    width: 51px;
    height: 33px;
}
.ico-card-visa {
    background-position: 0 0;
}
.ico-card-mastercard,
.ico-card-mc {
    background-position: -58px 0;
}
.ico-card-americanexpress,
.ico-card-ae {
    background-position: -117px 0;
}
.ico-card-discover {
    background-position: -175px 0;
}
.ico-card-maestro {
    background-position: -234px 0;
}
.ico-card-payu {
    background-position: -292px 0;
}
.ico-card-paypal {
    width: 80px;
    height: 31px;
    background-position: -350px 0;
}

/* [ toggle element dla sekcji ] */
.toggle-hide {
    cursor: pointer;
    /*background:#7ab637;*/
    color: #fff;
    float: right;
    font-size: 12px;
    padding: 2px 10px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

/* [ POD components - rebranding room ] */
.rebranding-section .pod {
    position: absolute;
}
.rebranding-section .room-page .btn {
    border-radius: 2px;
}
.pod {
    color: #4b4b4b;
    min-height: 130px;
}
.pod-in {
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    /* space */
    position: absolute;
    top: 5px;
    right: 5px;
    left: 5px;
    bottom: 5px;
}
.pod .pod-hd,
.pod .pod-ft {
    margin: 0;
    color: #666;
    background: #e7e8e7;
    box-sizing: border-box;
}
.pod .pod-hd {
    font-size: 11px;
    text-align: center;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.07);
    height: 26px;
    padding: 3px 10px;
    overflow: hidden;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.pod .pod-hd .dsp-table {
    height: 20px;
}
.pod .pod-hd .title {
    text-align: left;
}
.pod .pod-hd .actions {
    text-align: right;
    white-space: nowrap;
    box-sizing: border-box;
}
.pod .actions .ico {
    fill: #666;
    margin-left: 5px;
    cursor: pointer;
}
.pod .pod-bd,
.pod .pod-ft {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.pod .pod-bd {
    top: 26px;
    overflow: hidden;
    background: #fff;
}
.pod .pod-ft {
    padding: 3px 0;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.07);
}
.pod .footer-in {
    padding: 3px 6px;
}
.pod .pod-hd .pod-title {
    font-weight: bold;
}
.pod .f-holder {
    margin: 0;
}
.pod .pod-hd .btn {
    padding: 0 0.7em;
}
.pod-camera-and-voice .pod-in {
    background: #191a1e url($src-image + "room/rebranding/cam_pattern.jpg")
        repeat 0 0;
}
.pod-camera-and-voice .pod-bd {
    background: url($src-image + "room/rebranding/cam_light.jpg") no-repeat 50%
        0;
}
.pod-camera-and-voice .ico-video-special {
    color: #fff;
    position: absolute;
    opacity: 0.1;
    top: 40%;
    left: 50%;
    font-size: 100px;
    line-height: 1;
    transform: translate(-50%, -50%);
}
.pod-camera-and-voice .pod-ft {
    color: #4b4b4b;
    font-weight: bold;
    font-size: 13px;
    padding-left: 10px;
}
.pod-files .pod-ft {
    text-align: right;
    box-shadow: none;
    background: transparent;
}
.pod-chat .pod-ft .btn-wrap {
    width: 10px;
    padding-left: 10px;
}
