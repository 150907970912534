.main > .payment-section {
    margin: 0 0 30px;
}
.payment-section .addnew-wrap.small {
    .addnew {
        width: 51px;
    }
    .addnew-info {
        margin-left: 20px;
    }
}
.payment-section .expand,
.payment-section .expand h4 {
    margin: 0;
}
.payment-section .expand .header {
    padding-top: 25px;
}
.payment-section .bx-primary .header {
    padding-top: 0;
}
.payment-section .expand .content > .form-group,
.payment-section .bx-primary .form-group {
    margin: 0 0 15px;
}
.payment-section .bx-primary .form-group {
    font-size: 16px;
}
.payment-section [class*="ico-card-"] {
    margin-top: 1px;
    margin-right: 7px;
    @media (max-width: 575px) {
        margin-bottom: 7px;
    }
}
.payment-section .authorization-info {
    display: block;
    margin-top: -5px;
    min-height: 35px;
}
.payment-section .expand .col-wrap {
    margin: 0 0 0 -4%;
}
.payment-section .expand .col {
    margin-left: 4%;
    width: 48%;
}
.payment-section .expand .col:first-child {
    width: 44%;
}
.payment-section .expand .form-action {
    margin: 0 0 30px;
}
.payment-section .form-mercury-billing {
    .form-control {
        &.input {
            width: 100%;
            display: block;
            cursor: text;
            background: #fff;
            border-radius: 2px;
            box-shadow: inset 1px 1px 0 #f7f7f7,
                0 1px 0 rgba(255, 255, 255, 0.75);
            border: 1px solid rgba(0, 0, 0, 0.13);
            box-sizing: border-box;
            height: 34px;
            color: #555;
            &.is-invalid {
                border-color: $color-error;
                &.braintree-hosted-fields-focused {
                    border-color: $color-error;
                }
                & + label.error {
                    display: inline-block;
                }
            }
            &.braintree-hosted-fields-focused {
                border-color: $color-success;
            }
            &:focus + label.error,
            &.braintree-hosted-fields-focused + label.error {
                opacity: 0;
                visibility: hidden;
            }
            &.is-valid {
                border-color: $color-success;
            }
            &:not(.is-invalid):focus {
                border-color: $color-success;
            }
        }
        &.input[type="email"] {
            padding: 0.3em 0.8em;
        }
    }
    .form-inline .form-group .form-control {
        padding: 0;
    }
    .error {
        display: none;
        color: $color-error;
        font-size: 11px;
        text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
        margin: 0;
        opacity: 1;
        z-index: 10;
        border-radius: 2px;
        position: absolute;
        right: 0;
        top: 100%;
        transform: translateY(0);
        transition: all 0.2s ease-out;
    }
}
