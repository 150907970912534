@-moz-document url-prefix() {
    /*.btn::-moz-focus-inner {
    border:0;
    padding:0;
    margin-top:-2px;
    margin-bottom:-2px;
    }*/
    .close {
        line-height:13px;
    }
    select {
        padding-top:6px;
    }
    select.small {
        padding-left:3px;
        padding-top:3px;
        width:150%;
    }
    .select select {
        width:150%;
    }
    .figure-wrap .figure-obj,
    .list-component-images img,
    .components .flex img {
        width:100%;
    }
    .list-component-images.list-tiles .unit-bd {
        height:100px;
    }
    .contact-section .dsp-table.specify-height {
        height:674px;
    }
    .check:before {
        top:0;
    }
    .popup-file-preview img {
        max-width:640px;
    }
    /* fix dla FF dla agendy bez komponentow */
    .agenda-section .rebranding .room-page-middle {
        height:auto;
    }

}

/* fix dla IE9,10,11 */
@media screen and (min-width:0\0) {
    /* http://stackoverflow.com/questions/20198137/image-label-for-input-in-a-form-issue-in-ie11 */
    form .hide-check + label img {
        display:inline-block;
        pointer-events:none;
    }
    .figure-wrap .figure-obj,
    .list-component-images img,
    .components .flex img,
    .flex img {
        width:100%;
    }
}

@media screen and (max-device-width:800px) {
    body {width:980px;}
}

.arrow-pin-top .ico-arrow-pin {
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
}
.arrow-pin-bottom .ico-arrow-pin {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}