@keyframes alert-spin{
    100%{
        transform:rotate(360deg);
    }
}
%around-small-box {
    display: flex;
    align-items: center;
}
.around-pages-main {
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.page-around-room-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    padding: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
}
.page-around-room-footer {
    bottom: 0;
    background-image: url('/account_panel/images/room/footer_shadow.png') !important;
    background-color: transparent !important;
    box-shadow: none !important;
    @media only screen and (max-width: 767px) {
        height: 50px !important;
        margin-top: -50px !important;
    }
    .social, p {
        margin: 0 !important;
        float: none !important;
    }
}
@media (min-width: 1200px) {
    .container--profile-page {
        max-width: 1170px;
    }
}
.page-around-room {
    position: relative;
    background-color: $color-white;
    border-radius: 5px;
    max-width: 735px;
    margin: 0 auto;
    &--reset {
        background-color: initial;
        border-radius: 0;
    }
    &--space {
        padding: 35px 40px;
    }
    &--connection_tester {
        max-width: 980px;
    }
    &--profile {
        max-width: 1200px;
    }
    &--sales {
        margin-top: -20px;
    }
    &-sales {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        padding-left: 0;
        @media (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start;
        }
        &__icon {
            width: 30px;
            height: 30px;
            border: 1px solid $color-cm;
            border-radius: 50%;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                color: $color-cm;
                height: 20px;
                width: 20px;
            }
        }
        &__email {
            display: flex;
            align-items: center;
            font-size: 16px;
            margin-right: 45px;
            @media (max-width: 576px) {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
        &__phone {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: $color-dark;
        }
    }
    &__ondemand_btn_wrap {
        display: block;
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 35px;
        border-bottom: 1px solid $color-white-dirty;
        margin: 0 25px 20px;
        @media only screen and (max-width: 576px) {
            margin: 0 0 20px;
        }
    }
    .option-presenter {
        padding-right: 15px;
        padding-bottom: 15px;
    }
    .change-lang .tip.pos-t {
        // left: 0;
        .tip-wrap {
            padding: 10px;
            &:after {
                margin-right: 0;
                border-width: 5px;
                right: 50px;
                left: initial;
            }
        }
        &.tip-info {
            .tip-wrap {
                font-size: 11px;
            }
            li {
                display: inline-block;
                margin: 0;
                width: 50%;
                a {
                    margin: 4px 3px;
                }
            }
        }
    }
    &__svg {
        width: 40px;
        height: 40px;
        stroke-width: 1px;
        color: currentColor;
    }
    &__social {
        width: 20px;
        height: 20px;
        svg {
            height: 20px !important;
            width: 20px !important;
        }
    }
    &__copyrights {
        color: $color-white;
        a {
            color: inherit;
        }
    }
    &__labels {
        label {
            color: $color-gray;
        }
    }
    &__wrap {
        padding: 0 30px;
        @media only screen and (max-width: 576px) {
            padding: 0;
        }
        #profile-page &,
        .room-main & {
            padding: 0;
        }

    }
    &__content {
        max-width: 992px;
        margin: 0 auto;
    }
    &__event-details {
        padding: 40px 0 35px;
        &--profile {
            padding: 10px 0 0 0;
        }
        &--sales {
            padding-top: 0;
        }
    }
    &__padding {
        padding: 40px 25px 35px;
    }
    &__event-details-border {
        width: 100%;
        margin: 0 55px;
        border-bottom: 1px solid $color-white-dirty;
        padding-bottom: 20px;
        @media only screen and (max-width: 576px) {
            margin: 0 30px;
        }
    }
    &__connection-tester {
        width: 100%;
        margin: 0 30px;
        padding-bottom: 20px;
        @media only screen and (max-width: 767px) {
            margin: 0;
            padding: 0 15px 25px;
        }
        &-wrap {
            padding: 50px 0;
            @media only screen and (max-width: 767px) {
                padding: 30px 0 0;
            }
        }
    }
    &__room-info {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
            color: $color-gray-blue;
            span {
                color: $color-dark;
            }
        }
    }
    &__form {
        max-width: 630px;
        margin: 0 auto;
        padding: 0 15px;
        .form-label {
            font-size: 14px !important;
            line-height: 18px;
            margin-bottom: 0;
            color: $color-dark;
        }
    }
    &__fieldset {
        max-width: 500px;
        margin: 0 auto;
    }
    &__send {
        font-size: 18px !important;
        font-family: 'robotomedium' !important;
        text-transform: uppercase;
        letter-spacing: 0 !important;
        margin-bottom: 5px;
        min-width: 135px;
    }
    &__logo {
        width: 140px;
        height: 25px;
        margin-bottom: 25px;
    }
    &__title {
        font-size: 32px;
        font-family: $font-roboto-regular;
        text-align: center;
        color: $color-dark;
        word-break: break-word;
        margin: 0 auto 30px !important;
        max-width: 80%;
        @media only screen and (max-width: 576px) {
            margin-bottom: 20px;
        }
        &--less-mb {
            margin-bottom: 20px !important;
        }
        &--sales {
            border-top: 1px solid $color-white-dirty;
            padding-top: 25px;
            margin-top: 9px !important;
            margin-bottom: 35px !important;
        }
    }
    &__subtitle {
        font-size: 18px;
        font-family: $font-roboto-medium;
        text-align: center;
        color: $color-dark;
        margin-bottom: 20px;
    }
    &-when {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        @media only screen and (max-width: 576px) {
            flex-direction: column;
            padding: 0 50px;
        }
        &__box {
            display: flex;
            align-items: flex-start;
            margin: 0 35px;
            @media only screen and (max-width: 576px) {
                margin: 10px auto;
                width: 280px;
            }
            @media only screen and (max-width: 450px) {
                width: 100%;
            }
        }
        p {
            margin: 0;
        }
        &__type {
            font-size: 12px;
            color: $color-gray-blue;
        }
        &__data {
            font-size: 16px;
            color: $color-dark;
        }
        &__timezone {
            font-size: 11px;
            color: $color-info;
            display: block;
            margin-top: -5px;
        }
        &__ico {
            stroke-width: 1;
            width: 40px;
            height: 40px;
            color: currentColor;
            margin-right: 4px;
        }
    }
    &__perma-content {
        padding: 60px 0 50px;
        h3 {
            font-family: $font-roboto-regular;
            font-size: 26px;
            color: $color-dark;
            text-align: center;
            margin: 0;
        }
    }
    &-presenters {
        display: flex;
        align-items: flex-start;
        margin-bottom: 40px;
        #profile-page-tab & {
            margin-bottom: 0;
        }
        &__wrap {
            border-bottom: 1px solid $color-white-dirty;
            padding-top: 20px;
            padding-bottom: 20px;
            #profile-page-tab & {
                border: 0;
            }
        }
        &__avatar {
            max-width: 115px;
            margin: 0 auto;
            box-sizing: content-box;
            @media (max-width: 768px) {
                float: none !important;
            }
            .avatar-decorate {
                border: 0;
                border-radius: 0;
                box-shadow: none;
                width: 115px;
                height: 115px;
                object-fit: contain;
            }
            [data-icon='P'] {
                font-size: 125px !important;
                bottom: -40px !important;
            }
        }
        &__info {
            padding-left: 30px;
            @media only screen and (max-width: 767px) {
                padding-left: 0;
            }
            .social {
                margin-bottom: 0;
            }
        }
        &__label {
            font-size: 14px;
            font-family: $font-roboto-regular;
            color: $color-gray-blue;
            margin-bottom: 10px;
            max-width: 120px;
            text-align: right;
            @media only screen and (max-width: 767px) {
              text-align: left;
              padding-right: 4px;
              max-width: max-content;
            }
        }
        &__data {
            font-size: 14px;
            font-family: $font-roboto-regular;
            color: $color-gray;
            @media only screen and (max-width: 767px) {
                padding-left: 4px;
            }
            &.truncate {
                max-width: 500px;
                @media only screen and (max-width: 767px) {
                    max-width: 100%;
                }
            }
        }
        &__name {
            font-family: $font-roboto-regular;
            font-size: 26px !important;
            line-height: 26px;
            font-family: $font-roboto-regular;
            color: $color-dark;
            margin-bottom: 0;
            padding: 0 !important;
        }
        &__profiles {
            font-size: 12px;
            color: $color-gray-blue;
            margin: 0 0 5px;
            text-align: right;
            @media only screen and (max-width: 576px) {
                text-align: left;
                margin-top: 15px;
            }
        }
    }
    &-embed {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
        &__wrap {
            border-bottom: 1px solid $color-white-dirty;
            padding-bottom: 40px;
        }
    }
    &__desc {
        font-family: $font-roboto-light;
        font-size: 16px;
        color: $color-gray;
        line-height: 24px;
        margin-top: 13px;
        margin-bottom: 13px;
        img {
            margin: 10px auto;
            max-width: 100%;
        }
        em {
            font-style: italic;
        }
    }
    &__desc-proccess {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 35px;
        color: $color-dark;
        padding: 0 30px;
        @media only screen and (max-width: 767px) {
            padding: 0;
        }
    }
    &__btn-action {
        font-size: 12px !important;
        font-family: $font-roboto-medium;
        letter-spacing: 0 !important;
        text-transform: uppercase;
        padding: 10px 15px;
    }
    &__btn-large {
        padding: 15px 25px;
        text-transform: uppercase;
        &--dark {
            background: $color-dark;
            color: $color-gray-blue;
            margin-left: 20px;
            box-shadow: none;
            transition: box-shadow 0.5s ease !important;
            &:hover {
                box-shadow: 0px 7px 10px 0px rgba(45, 49, 60, 0.15);
            }
        }
    }
    &-text {
        border-bottom: 1px solid $color-white-dirty;
        padding-bottom: 30px !important;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &-download {
        margin: 30px 0;
        &__wrap {
            padding-bottom: 30px;
            border-bottom: 1px solid $color-white-dirty;
        }
        &__title {
            font-size: 16px !important;
            font-family: $font-roboto-regular;
            color: $color-dark;
            padding: 0 !important;
        }
    }
    &-message {
        margin: 30px 0;
        &__wrap {
            border-bottom: 1px solid $color-white-dirty;
            padding-bottom: 30px;
        }
        &__title {
            font-size: 16px !important;
            font-family: $font-roboto-regular;
            color: $color-dark;
            padding: 0 !important;
        }
        &__desc {
            font-size: 16px;
            color: $color-gray;
            line-height: 24px;
            margin: 0 0 5px !important;
        }
        &__recorder {
            position: relative;
            padding-left: 25px !important;
            &:before {
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 1px solid $color-white;
                background-color: #ff0000;
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
            }
        }
    }
    &-agenda {
        border-bottom: 1px solid $color-white-dirty;
        padding-bottom: 30px !important;
        margin-bottom: 30px;
        word-break: break-word;
        margin-top: 10px;
        &__title {
            font-size: 16px !important;
            font-family: $font-roboto-regular;
            color: $color-dark;
            padding: 0 !important;
        }
        &__content {
            padding: 0 !important;
            border: 0 !important;
            ol,ul {
                padding-left: 20px;
            }
            p, li {
                font-size: 16px;
                color: $color-gray;
                line-height: 24px;
                margin: 0 0 5px !important;

                em {
                    font-style: italic;
                }
            }
            p {
                margin: 0 0 15px !important;

                em {
                    font-style: italic;
                }
            }
        }
    }
    &-form {
        .required-label {
            position: relative;
        }
        .required .page-around-room-form__label:after {
            content: '*';
            font-family: "Courier New", Courier, monospace;
            font-size: 16px;
            font-weight: bold;
            display: inline-block;
            margin-left: 3px;
            color: $color-success;
        }
        .required .form-label:before, .required .f-holder:before {
            display: none;
        }
        &__fields {
            background-color: inherit;
            padding: 30px 45px;
            margin-bottom: 40px;
            width: 100%;
            &--grey {
                background-color: #f2f3f7;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
            @media only screen and (max-width: 576px) {
                padding: 0 15px;
            }
        }
        &__invoice_checkbox_wrapper {
            margin: 10px 0 0 -15px;
            display: flex;
        }
        &__invoice_checkbox_divider {
            border-top: unset;
            border-bottom: 1px solid rgba(225, 229, 238, 1);
            margin-top: 10px;
        }
        &__label {
            font-size: 14px;
            font-weight: 600;
            color: $color-gray;
            position: relative;
            width: 100%;
            &.required-label {
                &:after {
                    content: '*';
                    font-family: "Courier New", Courier, monospace;
                    font-size: 16px;
                    font-weight: bold;
                    display: inline-block;
                    margin-left: 3px;
                    color: $color-success;
                }
            }
        }
        &__field {
            border: 1px solid #d7dae5;
            box-shadow: none !important;
        }
        &__preresend {
            width: 250px;
            font-size: 11px;
            margin: 25px auto 0;
            color: $color-gray;
            position: relative;
        }
        &__check_connection {
            margin-bottom: 0;
            font-size: 12px;
            color: $color-gray;
        }
        &__resend-box {
            min-width: 450px;
            @media only screen and (max-width: 576px) {
                min-width: 100%;
            }
        }
        &__resend-wrap {
            width: 450px;
            padding: 6px 12px !important;
            @media only screen and (max-width: 576px) {
                width: 290px;
                margin: 0 auto;
            }
            &:after {
                left: 0% !important;
                right: 0;
                margin: 0 auto !important;
            }
            .btn-wrap {
                margin-left: 12px !important;
            }
            .form-label{
                padding-right: 12px;
            }
            .form-label,
            .btn-txt {
                font-size: 14px;
                @media only screen and (max-width: 576px) {
                    font-size: 11px;
                }
            }
            .form-control {
                max-width: 66%;
                flex-grow: 1;
                padding-right: 0 !important;
            }
        }
        &__required {
            position: absolute;
            font-size: 12px;
            color: $color-gray;
            right: 0;
            top: 50%;
            max-width: 150px;
            &:before {
                content: '*';
                font-family: "Courier New", Courier, monospace;
                font-size: 16px;
                font-weight: bold;
                vertical-align: -2px;
                display: inline-block;
                height: 16px;
                color: $color-success;
                margin-right: 2px;
            }
        }
        @media (max-width: 767px) {
            &__required {
                position: relative;
                max-width: none;
                margin-bottom: 10px;
            }
        }
        .dropdown-item {
            white-space: normal;
        }
    }
    &-completed {
        &__svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 30px;
            svg {
                stroke-width: 2px;
                height:140px;
                width:140px;
                color: $color-success;
            }
        }
        &__content {
            display: inline-block;
            vertical-align: middle;
            max-width: 260px;
            text-align: left;
            p {
                font-size: 26px;
                line-height: 26px;
                font-family: $font-roboto-regular;
                color: $color-success;
                margin-top: 0;
                margin-bottom: 12px;
            }
            .status-txt {
                font-size: 16px;
                font-family: $font-roboto-light;
                line-height: 24px;
                color: $color-gray;
                margin-bottom: 0;
            }
        }
    }
    &__webinars {
        padding-bottom: 30px;
        svg {
            stroke-width: 1;
            color: inherit;
            margin-right: 10px;
        }
    }
    // view for host after webinar
    &-articles {
        border-radius: 5px;
        margin-top: 8px;
        &__header {
            font-size: 16px;
            font-family: $font-roboto-bold;
            color: $color-dark;
            margin: 30px 15px 40px;
            svg {
                height: 35px;
                width: 35px;
            }
        }
        &__single {
            padding-bottom: 25px;
            margin: 0 15px 25px;
            border-bottom: 1px solid $color-white-dirty;
            font-family: $font-roboto-regular;
            .label {
                font-family: $font-roboto-medium;
            }
        }
        &__date {
            font-size: 12px;
            color: $color-gray-blue;
        }
        &__title {
            font-size: 21px;
            font-family: $font-roboto-bold;
            color: $color-dark;
            margin-top: 15px;
        }
        &__desc {
            font-size: 14px;
            font-family: $font-roboto-regular;
            color: $color-gray;
            p:last-child {
                margin-bottom: 0;
            }
        }
        &__more {
            font-size: 14px;
            color: $color-info;
        }
        &__back {
            text-align: center;
            margin-top: 10px;
            margin-bottom: 35px;
            a {
                font-size: 12px;
                font-family: $font-roboto-bold;
            }
        }
    }
    &-stats {
        border-bottom: 1px solid $color-white-dirty;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        text-align: center;
        padding-top: 35px;
        padding-bottom: 60px;
        margin: 0px 30px 8px;
        position: relative;
        &__wrapper {
            padding: 0 30px !important;
        }
        &__name {
            font-size: 24px;
            font-family: $font-roboto-bold;
            color: $color-dark;
            margin-top: 20px;
            margin-bottom: 60px;
        }
        &__data {
            font-size: 16px;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 25px;
            color: $color-dark;
        }
        &__content {
            border: 1px solid $color-white-dirty;
            border-radius: 5px;
            height: 100%;
            padding: 0 25px;
            @media only screen and (max-width: 992px) {
                margin: 0 auto;
                max-width: 100%;
                padding: 0 10px;
            }
            svg {
                margin-top: 25px;
                width: auto !important;
                height: auto !important;
                max-width: 55px;
                max-height: 45px;
                stroke-width: 2 !important;
            }
            span {
                font-size: 46px;
                line-height: 38px;
                font-family: $font-roboto-bold;
                color: $color-dark;
                margin: 20px 0;
                display: block;
            }
            a {
                font-size: 12px;
                font-family: $font-roboto-bold;
                @media only screen and (max-width: 576px) {
                    margin-bottom: 22px;
                }
            }
        }
        &__desc {
            font-size: 12px;
            color: $color-gray-blue;
            &--data {
                line-height: 18px;
                @media only screen and (max-width: 767px) {
                    line-height: 12px;
                }
            }
        }
    }
    &-events {
        color: $color-dark;
        &__title {
            font-size: 24px;
            line-height: 24px;
            font-family: $font-roboto-bold;
        }
        &__desc {
            font-size: 16px;
            line-height: 24px;
            font-family: 'robotoregular';
        }
    }
    &__video {
        video {
            height: 400px !important;
        }
    }
    &-webinar-published_recording {
        font-family: 'robotoregular';
        text-align: center;
        border-top: 1px solid $color-white-dirty;
        margin-top: 15px;
        padding-top: 30px;
        padding-bottom: 45px;
        &__panel {
            @media only screen and (max-width: 767px) {
                margin-bottom: 20px;
            }
        }
        &__icon {
            margin-bottom: 15px;
            svg {
                display: inline-block;
                vertical-align: middle;
                height: 24px;
                width: 24px;
                stroke-width: 2px;
            }
        }
        &__title {
            margin-left: 12px;
            font-size: 18px;
            color: $color-dark;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;
            font-family: 'robotoregular';
        }
        &__body {
            .rating-wrap {
                position: relative;
                width: 118px;
                height: 24px;
                margin: 0 auto;
                svg {
                    width: 114px;
                    height: 26px;
                    color: $color-white;
                }
            }
            .rating-mask {
                background: #d8d8d8;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 8px;
                right: 8px;
            }
            .rating-progress {
                background: $color-success;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
            }
        }
        &__value {
            font-size: 52px;
            line-height: 52px;
            color: $color-dark;
            margin-top: 0;
            margin-bottom: 5px;
        }
        &__value-desc {
            font-size: 16px;
            color: $color-dark;
            margin: 0;
        }
    }
    &__other-events {
        text-align: right;
        padding: 0 30px;
        margin-bottom: 40px;

        svg {
            stroke-width: 2 !important;
            width: 20px;
            height: 20px;
            vertical-align: -5px;
            margin-right: 5px;
        }
    }
    &-unablelist {
        color: #000;
        list-style: none;
        display: flex;
        align-items: self-start;
        &--sales {
            justify-content: center;
        }
        &__item {
            text-align: center;
            width: 50%;
            &--sales {
                margin: 0 13px;
                width: auto;
            }
        }
    }
    .faq__title {
        font-size: 16px;
        font-family: $font-roboto-regular;
        color: $color-dark;
    }
    .faq__content {
        font-size: 16px;
        font-family: $font-roboto-light;
        color: $color-gray;
        margin-bottom: 50px;
    }
    .faq__line {
        margin: 0;
        padding-bottom: 30px;
        border-top: 1px solid $color-white-dirty;
    }
    &__alert {
        margin: 0 30px;
        .alert {
            margin-top: 0;
        }
        @media only screen and (max-width: 576px) {
            margin: 0 15px;
        }
        &-animation {
            .alert-svg svg {
                animation: alert-spin 2s ease infinite;
            }
        }
    }
    &__boxes {
        @include alignCenterContent(true);
    }
    &__box {
        width: 260px;
        height: 90px;
        display: flex;
        align-items: center;
        padding: 0 25px;
        margin-bottom: 25px;
        border-radius: 4px;
        border: 1px solid $color-white-dirty;
        transition: background-color 0.5s ease, border-color 0.5s ease;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            background-color: $color-cm;
            border-color: $color-cm;
            svg {
                fill: $color-white !important;
            }
            span {
                color: $color-white;
            }
        }
        svg {
            width: 48px;
            height: 48px;
            margin-right: 25px;
            fill: $color-cm;
            transition: fill 0.5s ease;
        }
        span {
            font-size: 16px;
            line-height: 24px;
            font-family: $font-roboto-medium;
            color: $color-dark;
            transition: color 0.5s ease;
        }
    }
    &__text-with-redirect {
        font-size: 12px;
        color: $color-gray;
        margin: 15px 0;
    }
}

.webrtc-thanks {
    font-family: $font-roboto-regular;
    color: $color-dark;
    padding: 35px 50px 45px;
    @media only screen and (max-width: 576px) {
        padding: 20px;
    }
    &__title {
        font-family: $font-roboto-regular;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 35px;
    }
    &__desc {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 30px;
        &--short {
            max-width: 500px;
        }
    }
    &__circle {
        width: 135px;
        height: 135px;
        background-color: $color-white;
        border: 2px solid $color-success;
        border-radius: 50%;
        margin: 0 auto 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        background-image: none;
        background-size: cover;
        background-position: center center;
        transition: box-shadow .2s ease-in-out;
        @media only screen and (max-width: 576px) {
            width: 100px;
            height: 100px;
        }
        svg {
            color: $color-success;
            width: 50px !important;
            height: 50px !important;
            stroke-width: 2px !important;
            @media only screen and (max-width: 576px) {
                width: 40px !important;
                height: 40px !important;
            }
        }
        &:hover {
            border: 0;
            box-shadow: 0 0 20px 5px rgb(214, 216, 222);
            svg {
                color: $color-white;
            }
        }
        &--training {
            &:hover {
                background-image: url($src-image + 'thankyou/training_sessions.png');
            }
        }
        &--courses {
            &:hover {
                background-image: url($src-image + 'thankyou/online_courses.png');
            }
        }
        &--demos {
            &:hover {
                background-image: url($src-image + 'thankyou/demos.png');
            }
        }
        &--more {
            &:hover {
                background-image: url($src-image + 'thankyou/more.png');
            }
        }
    }
    &__learn {
        font: {
            family: $font-roboto-medium;
            size: 16px;
        }
        line-height: 16px;
        color: $color-white;
        background-color: $color-success;
        border-radius: 50px;
        text-transform: uppercase;
        padding: 17px 28px;
        margin: 15px 0 20px;
        max-width: 200px;
        transition: box-shadow .5s ease-in-out;
        &:hover {
            color: $color-white;
            box-shadow: 0px 7px 10px 0px rgba(45, 49, 60, 0.15);
        }
    }
    &__try {
        font: {
            family: $font-roboto-medium;
            size: 16px;
        }
        text-decoration: underline;
        color: $color-info;
    }
}
.before-send-tooltip,
.after-send-tooltip {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}
.after-send-tooltip {
    align-items: center;
    width: 100%;
    height: 34px;
    padding-top: 2px;
    .ico-from_email {
        width: 30px;
        height: 25px;
        @media only screen and (max-width: 576px) {
            display: none;
        }
    }
    p {
        font-size: 12px;
        line-height: 14px;
        width: calc(100% - 35px);
        @media only screen and (max-width: 576px) {
            font-size: 10px;
            line-height: 12px;
            width: 100%;
        }
    }
}
.resend-tip {
    font-family: $font-roboto-regular;
    font-size: 11px;
    line-height: 14px;
    margin-top: 4px;
    color: #f55155;
    @media only screen and (max-width: 576px) {
        margin-top: 2px;
        font-size: 10px;
        line-height: 12px;
    }
}
.remember-me {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

#cookiescript_badge {
    bottom: 45px !important;
}

.attendee-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 16px;
    padding: 20px;
    background: #e8eaf1;
    border-radius: 5px;
}

.attendee-checkbox-label {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  span {
    font-weight: bold;
  }
}
