.table-search {
    table-layout:fixed;
}
.table-search td:first-child {
    padding-left:30px;
    @media (max-width: 768px) {
        padding-left: 5px;
    }
}
.table-search td:last-child {
    padding-right:30px;
    @media (max-width: 768px) {
        padding-right: 5px;
    }
}
.table-search .operations {
    float:left;
    margin:2px 0 0;
}
.table-search .thumb {
    width:100px;
    padding-left:15px;
    @media (max-width: 768px) {
        display: none;
    }
}
.table-search .desc {
    width:480px;
    @media (max-width: 768px) {
        width: 250px;
    }
}
.table-search .thumb + .desc,
.table-search .expand .desc {
    width:350px;
    @media (max-width: 768px) {
        width: 250px;
    }
}
.table-search .desc .social {
    padding-left:35px;
    position:relative;
    @media (max-width: 768px) {
        padding-left: 5px;
    }
}
.table-search .desc .social [class*='social-ico-'] {
    position:absolute;
    top:5px;
    left:3px;
    @media (max-width: 768px) {
        display: none;
    }
}
.table-search .desc .title {
    font-size:24px;
    font-weight:normal;
    @media (max-width: 768px) {
        font-size: 16px;
    }
}
.table-search .desc .truncate {
    max-width:350px;
    display:inline-block;
    vertical-align:middle;
    @media (max-width: 768px) {
        max-width: 250px;
    }
}
.table-search .book {
    width:100%;
    min-height:80px;
    height:auto;
    max-height:180px;
    margin:10px 0;
    // color:#363a3d;
}
.table-search .nano > .nano-pane {
    right:5px;
}
.table-search .book table {
    table-layout:fixed;
}
.table-search .book td {
    border-top:0;
    padding-top:3px;
    padding-bottom:3px;
}
.table-search .book .name:hover {
    background:none;
    cursor:default;
}
.table-search .book tr:hover td {
    background:rgba(205,205,205,.2);
}
.table-search .expand .book .social {
    padding-left:29px;
    @media (max-width: 768px) {
        padding: 0 5px;
    }
}
.table-search .expand .book [class*='social-ico-'] {
    left:0;
    top:4px;
}
.table-search .book .cell-action {
    width:50px;
}
.table-search .book .mail {
    text-align:right;
    width:250px;
    @media (max-width: 768px) {
        text-align: left;
        padding: 0 5px;
    }
    @media (max-width: 576px) {
        width: 200px;
    }
}
.table-search .book .mail a {
    color:inherit;
}
.invitation-segments-group {
    .book {
        .desc {
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}