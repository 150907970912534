.results td {
    font-size:14px;
    padding:10px 12px 10px 0;
    .status {
        white-space: nowrap;
    }
}
.results th:first-child,
.results td:first-child {
    padding-left:12px;
}
.results .name .truncate{
    max-width: 240px;
    @media (max-width: 991px) {
        max-width:150px;
    }
}
.results .company .truncate,
.results .phone .truncate {
    max-width: 100px;
}
.results .email .truncate{
	width: 300px;
	@media (max-width: 991px) {
    	width:250px;
	}
}
.results .cell-action .btn-txt {
	white-space: nowrap;
}
.results tbody .email [href^='mailto:'] {
    color:#363a3d;
}
.results tbody .name {
    font-weight:bold;
}