.popup-new-mobile-app {
    background-attachment: scroll;
    background-image: url(/account_panel/images/feature/mobile-app-popup.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    height: 100%;
    width: 100%;
    display: table;
    
    .mobile-app {
        padding: 20px;

        .tag {
            display: inline-block;
            position: relative;
            padding: 10px 15px 10px 152px;

            .label {
                display: inline-block;
                padding: 7px 12px;
                line-height: 1;
                margin: 0;
                border-radius: 25px;
                font-size: 10px;

                &-mobile {
                    background-color: #ff4200;
                }

            }

            svg {
                position: absolute;
                width: auto;
                max-width: 140px;
                height: 28px;
                left: 0px;
                top: 50%;
                margin-top: -14px;
            }

        }

        .btn-group-mobile {

            .btn-mobile {
                display: inline-block;
                text-decoration: none;
                margin: 5px 0;

                &:first-child {
                    margin-right: 15px;
                }

                svg {
                    color: #000;
                    display: block;
                    height: 35px;
                    width: auto;
                    max-width: 120px;
                }

            }

        }

        h2 { 
            width: 400px;
        }
        
        &-checkbox {
            background: #272a31;
            position: absolute;
            height: 40px;
            width: 100%;
            bottom: 0;

            .f-holder {
                padding: 8px;
                font-family: $font-roboto-regular;
                font-size: 12px;
                text-align: center;
                color: #a7adb8;
                
                .check {
                    background: transparent;
                    border: 1px solid #858c99;
                    box-shadow: none;
                    width: 14px;
                    height: 14px;
                    
                    &::before {
                        top: -1px;
                    }
                    
                }

            }

        }

    }

}