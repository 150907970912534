.helper {
    color:#606365;
    font-size:16px;
    min-width:130px;
    position:absolute;
    z-index:99;
    @media screen and (max-width: 992px) {
        display: none;
    }
}
.helper .helper-bd {
    position:relative;
    text-align:left;
}
.helper .helper-bd:before {
    content:'';
    display:block;
    position:absolute;
    z-index:100;
    background-repeat:no-repeat;
    /*background:url($src-image + 'helper_arrows.png') no-repeat;*/
}
.helper .ico-close {
    color:#c5c8d0;
    font-size:20px;
    line-height:20px;
    text-align:center;
    width:20px;
    opacity:0;
    visibility:hidden;
    position:absolute;
    z-index:101;
    top:-6px;
    right:-8px;
    cursor:pointer;
}
.helper h4 {
    color:#4b4b4b;
    margin:0;
    font-size:35px;
    line-height:1;
    text-shadow:0 1px 0 #fff;
    letter-spacing:0
}
.helper h4 em {
    font-size:26px;
    text-indent:5px;
}
.helper p {
    line-height:1.4;
    margin:10px 0 0;
}
.helper:hover h4 {
    color:inherit;
}
.helper:hover .ico-close {
    opacity:1;
    visibility:visible;
}

.helper.pos-tr,
.helper.pos-tr2,
.helper.pos-br,
.helper.pos-br2,
.helper.pos-br3,
.helper.pos-br5 {
    right:100%;
    margin-right:15px;
}
.helper.pos-tl,
.helper.pos-tl2,
.helper.pos-bl,
.helper.pos-bl2,
.helper.pos-bl3,
.helper.pos-bl4,
.helper.pos-bl5 {
    left:100%;
    margin-left:15px;
}
.helper.pos-tr .helper-bd:before,
.helper.pos-tl .helper-bd:before {
    width:58px;
    height:44px;
}
.helper.pos-tr .helper-bd:before {
    background-position:-46px -114px;
    right:-27px;
    top:-37px;
}
.helper.pos-tl .helper-bd:before {
    background-position:-104px -114px;
    left:-32px;
    top:-50px;
}
.helper.pos-br .helper-bd:before,
.helper.pos-bl .helper-bd:before,
.helper.pos-bl4 .helper-bd:before {
    width:52px;
    height:53px;
}
.helper.pos-br .helper-bd:before {
    background-position:-52px -59px;
    left:100%;
    bottom:-60px;
    margin-left:-47px;
}
.helper.pos-bl .helper-bd:before,
.helper.pos-bl4 .helper-bd:before {
    background-position:-104px -59px;
}

.helper.pos-bl .helper-bd:before {
    left:-18px;
    bottom:-60px;
}
.helper.pos-bl4 .helper-bd:before {
    left:-58px;
    bottom:-30px;
}

.helper.pos-tr2 .helper-bd:before,
.helper.pos-tl2 .helper-bd:before {
    width:64px;
    height:29px;
}
.helper.pos-tr2 .helper-bd:before {
    background-position:-40px -29px;
    bottom:5px;
    right:-65px;
}
.helper.pos-tl2 .helper-bd:before {
    background-position:-104px -29px;
    left:-80px;
    top:-4px;
}
.helper.pos-br2 .helper-bd:before,
.helper.pos-br3 .helper-bd:before,
.helper.pos-bl2 .helper-bd:before,
.helper.pos-bl3 .helper-bd:before {
    width:76px;
    height:28px;
}
.helper.pos-br2 .helper-bd:before,
.helper.pos-br3 .helper-bd:before {
    background-position:-28px 0;
}
.helper.pos-br2 .helper-bd:before {
    right:-27px;
    top:-32px;
}
.helper.pos-br3 .helper-bd:before {
    right:-65px;
    top:-8px;
}
.helper.pos-bl2 .helper-bd:before,
.helper.pos-bl3 .helper-bd:before {
    background-position:-104px 0;
}
.helper.pos-bl2 .helper-bd:before {
    left:-34px;
    top:-36px;
}
.helper.pos-bl3 .helper-bd:before {
    right:104%;
    top:-7px;
}
.helper.pos-bl5 .helper-bd,
.helper.pos-br5 .helper-bd {
    margin-bottom:80px;
}
.helper.pos-bl5 .helper-bd:before,
.helper.pos-br5 .helper-bd:before {
    width:36px;
    height:78px;
    top:100%;
    left:100%;
    margin:0 0 0 -18px;
}
.helper.pos-bl5 .helper-bd:before {
    background-position:-104px -231px;
}
.helper.pos-br5 .helper-bd:before {
    background-position:-68px -231px;
}

.helper.pos-tr-large .helper-bd:before,
.helper.pos-tl-large .helper-bd:before {
    width:76px;
    height:28px;
}
.helper.pos-tr-large .helper-bd:before {
    background-position:0 -159px;
    bottom:2px;
    right:-107px;
}
.helper.pos-tl-large .helper-bd:before {
    background-position: -28px 0;
    left: 82px;
    top: -36px;
}
[lang='ru'] .helper.pos-tl-large .helper-bd:before {
    background-position: -105px -30px;
    left: -80px;
    top: -12px;
}