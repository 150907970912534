//colory dla buttona typu default
@mixin x-btn-default() {
    color:#363a3d;
    background:#f3f3f3;
    background:-webkit-linear-gradient(top,  #fff 0%,#f3f3f3 100%);
    background:linear-gradient(to bottom,  #fff 0%,#f3f3f3 100%);
    box-shadow:inset 0 -2px 0 rgba(255,255,255,.46), 0 2px 0 rgba(0,0,0,.1);
}
@mixin x-btn-default-hover() {
    color:$color-link;
    background:#eee;
    background:-webkit-linear-gradient(top,  #fafafa 0%,#eee 100%);
    background:linear-gradient(to bottom,  #fafafa 0%,#eee 100%);
}