.comment {
    color:#3d464d;
    font-size:12px;
    line-height:20px;
    padding:9px 12px;
    position:relative;
    background:#fff;
    border-radius:2px;
    border:1px solid rgba(0,0,0,0.1);
    //box-shadow:0 0 1px rgba(0,0,0,.1);

    &:before, &:after {
        content:'';
        position:absolute;
        z-index:4;
        top:20px;
        border:8px solid transparent;
    }

    &:before {
        z-index:3;
    }
}
.comment-title {
    font-size:11px;
    line-height:22px;
    overflow:hidden;
}
.comment-name {
    font-weight:bold;
    text-transform:uppercase;
}
.comment-options {
    color:#bdc0c8;
    float:right;
}
.list-chat .separate-title {
    margin:20px -28px;

    &:first-child {
        margin-top:0;
    }
}
.list-chat .separate-title-in {
    color:#bdc0c8;
    font-size:11px;
    font-weight:bold;
    text-transform:uppercase;
    background:#f7f7f7;
}
.list-chat .cover {
    margin:0 0 20px;
}
.list-chat .cover-figure,
.list-chat .cover-content {
    display:block;
    padding:0;
}
.comment-layout-1 .cover-figure {
    float:left;
}
.comment-layout-1 .cover-content {
    margin-left:67px;
}
.comment-layout-1 .comment:before {
    left:-17px;
    border-right-color:rgba(0,0,0,.1);
}
.comment-layout-1 .comment:after {
    left:-16px;
    border-right-color:#fff;
}
.comment-layout-2 .cover-figure {
    float:right;
}
.comment-layout-2 .cover-content {
    margin-right:67px;
}
.comment-layout-2 .comment:before {
    right:-17px;
    border-left-color:rgba(0,0,0,.1);
}
.comment-layout-2 .comment:after {
    right:-16px;
    border-left-color:#fff;
}