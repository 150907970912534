/*---------------------------------------------------------------------------------------------
[ Pagination ]
*/
    .pagination {
        color:#606365;
        font-size:14px;
        text-align:center;

        &, ul, ol, li {
            @include x-list-init;
        }

        li, a, span {
            display:inline-block;
        }

        li {
            margin:0 1px;
        }

        a, span {
            min-width:19px;
            height:19px;
            line-height:18px;
        }

        .active {
            a, span {
                color:#606365;
                background-color:#fff;
                border-radius:19px;
            }
        }

        table &, .table & {
            max-width:300px;
            margin:0 auto;
        }
    }

    /* stylowane carety przez wymuszona strukture plugina */
    .pagination .next span,
    .pagination .prev span {
        text-indent:-999em;
        overflow:hidden;
        width:0;
        height:0;
        border-top:4px solid rgba(0, 0, 0, 0);
        border-bottom:4px solid rgba(0, 0, 0, 0);
    }
    .pagination .next span {
        border-right:0 dotted;
        border-left:4px solid $color-current;
        margin-left:4px;
    }
    .pagination .prev span {
        border-left:0 dotted;
        border-right:4px solid $color-current;
        margin-right:4px;
    }