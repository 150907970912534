@mixin hyperlink() {
  .hyperlink-wrapper {
    .hyperlink-container {
      display: flex;
      flex-direction: column;


      .hyperlink-element {
        width: 100%;
      }

      .hyperlink-url {
        line-height: 0.85rem;
        word-break: break-all;
      }

      .hyperlink-url-details {
        line-height: 0.8rem;
        font-size: 10px;
        color: #999;
        word-break: break-all;
      }
    }
  }
}
