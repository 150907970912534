//po kliknieciu
    [data-selector='dropdown'],
    //po hover
    .dropdown {
        position:relative;
        display:inline-block;
    }
    .dropdown-wrap {
        &:after,
        &:before {
            content:'';
            display:inline-block;
            position:absolute;
            border:6px solid transparent;
            margin:0;
        }
    }

    %dropdown-layout-position {
        z-index:1000;
        position:absolute;
    }

    // dropdown skeleton
    .dropdown-layout {
        
        &.dropdown-menu {
            margin-top: 0;
        }
        
        & {
            display:none;
            text-align:left;
            min-width:90px;

            .dropdown:hover > & {
                display:block;
            }
        }

        &[class*='pos-t'] {
            @extend %dropdown-layout-position;
            padding:8px 0 0;
            top:100%;

            .dropdown-wrap:before {
                top:-13px;
                z-index:9;
                border-bottom-color:rgba(0,0,0,0.1);
            }

            .dropdown-wrap:after {
                top:-12px;
                z-index:10;
                border-bottom-color:#fcfcfc;
            }

            &.has-dropdown-header .dropdown-wrap:after {
                border-bottom-color:#f7f7f7;
            }
        }

        &[class*='pos-b'] {
            @extend %dropdown-layout-position;
            padding:0 0 8px;
            bottom:100%;

            .dropdown-wrap:before {
                bottom:-13px;
                z-index:9;
                border-top-color:rgba(0,0,0,0.1);
            }

            .dropdown-wrap:after {
                bottom:-12px;
                z-index:10;
                border-top-color:#fcfcfc;
            }

            &.has-dropdown-footer .dropdown-wrap:after {
                border-top-color:#f7f7f7;
            }
        }

        &.pos-tl,
        &.pos-bl {
            left:0;

            .dropdown-wrap:before,
            .dropdown-wrap:after {
                /*left:21px;*/
                left:10px;
            }
        }

        &.pos-tc,
        &.pos-bc {
            left:50%;
            transform:translateX(-50%);

            .dropdown-wrap:before,
            .dropdown-wrap:after {
                left:50%;
                margin-left:-2px;
            }
        }

        &.pos-tr,
        &.pos-br {
            right:0;


            .dropdown-wrap:before,
            .dropdown-wrap:after {
                right:10px;
            }
        }

        .dropdown-wrap {
            position:relative;
            border-radius:3px;
            border:1px solid rgba(0,0,0,0.1);
            background-clip:padding-box;
            background:#fcfcfc;
            // box-shadow:0 0 1px rgba(0,0,0,.35);
            // padding:5px 0;
        }


        //ikona close dropdown
        &.has-dropdown-close {

            .dropdown-hd {
                padding-right:22px;
            }

            .dropdown-close {
                border:0;
                background:none;
                display:block;
                position:absolute;
                top:5px;
                right:10px;
                cursor:pointer;
                opacity:.5;

                &:hover {
                    opacity:1;
                }

                .ico-x {
                    @include x-svg-size(10);
                }
            }
        }

        .table tfoot & td {
            background:none;
        }
    }


    // type: info
    .dropdown-info {
        cursor:default;
        min-width:190px;

        .dropdown-hd {
            // color:#606365;
            font-size:12px;
            font-weight:normal;
            padding:7px 10px;
            background-color:#f7f7f7;

            border-bottom:1px solid #eaeaea;
            border-top-left-radius:1px;
            border-top-right-radius:1px;

            .title {
                text-transform:uppercase;
            }
        }

        .dropdown-ft {
            // color:#606365;
            font-size:12px;
            font-weight:normal;
            padding:7px 10px;
            background-color:#f7f7f7;

            border-top:1px solid #eaeaea;
            border-bottom-left-radius:1px;
            border-bottom-right-radius:1px;
        }

        .dropdown-bd {
            padding:5px 10px;
        }
    }


    // type: success
    .dropdown-success {

        .dropdown-hd {
            color:#fff;
            font-weight:bold;
            //background-color:#6ebe3b;
        }

        .dropdown-close {
            color:#fff;
        }

        // &[class*='pos-t'].has-dropdown-header .dropdown-wrap:after {
        //     border-bottom-color:#6ebe3b;
        // }
    }


    // type: menu
    .dropdown-menu {
        background-color: transparent;
        border: 0;
        left: auto;
        ul, li {
            @include x-list-init;
        }
        .dropdown-bd {
            margin:5px 0;
        }
        li,
        .dropdown-item {
            display:block;
        }

        li > a,
        li > .dropdown-link {
            color:#606365;

            float:none;
            display:block;
            font-weight:normal;
            border:0;
            padding:5px 10px;

            &:hover {
                // color:#606365;
                background-color:#f5f5f5;
                text-decoration:none;
            }
        }
        .dropdown-bd li > a,
        .dropdown-bd li > .dropdown-link {
            font-size:12px;
            text-transform:uppercase;
        }
        .spacer {
            margin:5px 0;
            border-bottom:0;
            padding:0;
            overflow:hidden;
        }

        //modul usera w dropdown-menu
        .dropdown-menu-user {
            display:block;
            padding:10px;

            &:hover {
                background-color:transparent;
            }

            .avatar-wrap {
                display:block;
                margin-right:20px;
            }
        }
    }


    //lista menu z ikonkami w dropdown
    .dropdown-list-icons {

        li > a {
            padding-left:24px;
            position:relative;
            cursor: pointer;
        }
        li > a:before {
            content:'';
            position:absolute;
            display:inline-block;
            width:0;
            height:0;
            line-height:0;
            left:10px;
            top:12px;
            border:3px solid transparent;
            //border-left-color:#6ebe3b;
        }

        .has-own-ico {
            > a:before {
                display:none;
            }

            .ico-left {
                @include x-svg-size(10);
                stroke-width:12;
                position:absolute;
                left:8px;
                top:10px;
            }

            .ico-x {
                @include x-svg-size(8);
                color:$color-error;
                top:11px;
            }
        }
    }


    //type menu: file list
    .dropdown-list-file {
        width:540px;
        @media (max-width: 767px) {
            width: 100%;
            max-width: 320px;
        }

        .dropdown-bd {
            padding:10px 0;
        }

        .nano-content,
        .content {
            padding:0;
        }

        table {
            table-layout:fixed;
        }

        tr:first-child td {
            border-top:0;
        }
        td {
            padding:12px 10px 12px 0;
            @media (max-width: 767px) {
                padding: 5px;
                font-size: 12px !important;
            }
        }
        .cell-icon {
            width:48px;

            .hide-check + label {
                display:inline;
                padding-left:18px;
            }
        }
        .thumb {
            width:60px;
            figure {
                @media (max-width: 767px) {
                    margin-bottom: 0;
                }
            }

            [data-icon] {
                font-size:40px !important;
            }

            [data-icon="b"]:before {
                top:0;
            }
        }
        .figure {
            width:42px;
        }
        .desc {
            width:260px;
        }
        .nano {
            height:256px;
        }
        .nano > .nano-pane {
            right:5px;
        }
        .cover-empty {
            margin-top:70px;
            margin-bottom:70px;
        }

        .has-height-full .cover-empty {
            margin-top:0;
            margin-bottom:0;
        }
    }