.expired {
	background-color: #fff;
	padding: 60px 0 80px;
	height: 100%;
	min-height: 100vh;
	&__picture {
		height: 570px;
		@include media-breakpoint-down(md) {
			height: 500px;
			margin-bottom: 50px;
		}
		@include media-breakpoint-down(sm) {
			height: 300px;
		}
		&:before {
			background: url($src-image + 'expired/free-trial-expired.png') no-repeat center;
			background-size: contain;
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 15px;
			right: 15px;
			@include media-breakpoint-down(md) {
				background-position: center;
			}
		}
	}
	&__title {
		margin-bottom: 55px;
	}
	&__desc {
		margin-bottom: 55px;
		font-size: 18px;
		color: #555555;
	}
	&__paragraph {
		font-family: $font-roboto-light;
		margin: 0 0 40px;
	}
	&__item {
		font-family: $font-roboto-light;
		position: relative;
		margin-bottom: 20px;
		padding-left: 40px;
		&:last-child {
			margin-bottom: 0;
		}
		&:before {
			background-color: #6ebe3b;
			content: '';
			width: 5px;
			height: 5px;
			position: absolute;
			top: 12px;
			left: 0;
			border-radius: 50%;
		}
	}
	&__last-minute {
		font-family: $font-roboto-light;
		font-size: 18px;
		strong {
			font-family: $font-roboto-bold;
		}
	}
}