.statistics-section .options,
.meetings-section .options {
    margin-top: -25px;
}
.meetings-section th ul {
    display: inline-block;
}
.meetings-section th ul .active {
    color: #939393;
}
.meetings-section .desc {
    width: 440px;
}
.meetings-section .cell-action {
    @media (max-width: 767px) {
        vertical-align: middle;
        padding: 0.25rem;
        width: 100px;
    }
}
.meetings-section .desc h6 a {
    display: inline-block;
}
.meetings-section .desc h6 a .label {
    margin: 0;
}
.meetings-section .cell-action .btn {
    min-width: 90px;
    white-space: nowrap;
}
.meetings-section .desc h6 .truncate {
    display: inline-block;
    vertical-align: middle;
    max-width: 335px;
    padding-right: 5px;
    @media (max-width: 767px) {
        max-width: 150px;
        font-size: 15px;
    }
}
.meetings-section .desc .cell-desc-small .truncate {
    max-width: 750px;
    @media (max-width: 1200px) {
        max-width: 650px;
    }
    @media (max-width: 992px) {
        max-width: 335px;
    }
    @media (max-width: 767px) {
        max-width: 170px;
    }
    @media (max-width: 375px) {
        max-width: 140px;
    }
}
.meetings-section .desc .vr-list .spacer {
    @media (max-width: 768px) {
        display: none;
    }
}
.view-options.pos-tr {
    position: absolute;
    z-index: 1;
    top: 14px;
    right: 17px;
}
