.nested {
    display:inline-block;
    vertical-align:middle;
    padding:8px;
    &--options {
        padding: 8px 5px;
    }
    >div:not(.dsp-none) {
        display: inline-block;
    }
}
.nested .nested {
    padding:0 3px;
}
.nest-horizontal .nested:first-child {
    padding-left:8px;
    padding-right:0;
}
.nest-horizontal .thumb {
    min-width:59px;
    padding:8px 0 6px 8px;  
}
.nest-horizontal .thumb [data-icon] {
    font-size:48px;
    line-height:.7em;
}
.nest-horizontal svg {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    color: #c2c2c2;
}
.nest-horizontal .nested .nested:first-child {
    padding-left:0;
}
.nest-horizontal .nested:last-child {
    padding-right:0;
}
.nest-horizontal .nest-item {
    padding:0;
    margin:10px -8px;
    position:relative;
}
.nest-item .form-action,
.nest-item:hover .form-action {
    top:50%;
    right:15px;
    transform:translateY(-50%);
}
.nested .form-group {
    margin:0;
}
.nested-txt-medium {
    font-size:18px;
}
