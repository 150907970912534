.expand {
    position: relative;
    color: #606365;
    margin-bottom: 50px;
    border-top: 0;
    background: #f1f1f1 url($src-image + "extras/pattern.jpg") repeat 0 0;
    box-shadow: inset 0 3px 2px -2px rgba(0, 0, 0, 0.17),
        inset 0 -1px 0 rgba(0, 0, 0, 0.02);
}
.expand .header h2 {
    padding-top: 25px;
    text-shadow: 0 1px 0 #fff;
    margin: 0;
    text-align: center;
}
.expand .content {
    padding: 20px 0;
}
.expand p {
    margin: 0 0 22px;
}
.expand .form-inline .f-holder {
    margin: 0;
    padding: 0;
}
.expand .header .page-wrap {
    padding: 23px 25px 0;
}
.expand .nav-tabs-wrap .page-wrap {
    padding: 5px 25px 0;
}
.expand .nav-tabs-wrap .dropdown {
    margin-top: 2px;
}
.expand .nav-tabs-wrap .dropdown-menu {
    width: 190px;
}
.expand .main-arrow {
    display: block;
    width: 22px;
    height: 13px;
    background: url($src-image + "extras/pattern_arrow.png") no-repeat 0 0;
    position: absolute;
    bottom: 100%;
    margin-bottom: -3px;
}
.expand .helper {
    margin-top: -46px;
}
.expand .helper h4 {
    font-size: 24px;
}
.expand .helper p {
    font-size: 12px;
    margin-top: 10px;
}

/* slide - bar/nav */
.slide-nav {
    position: absolute;
    top: 50%;
    margin-top: -26px;
    display: inline-block;
    height: 26px;
    width: 22px;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    font-size: 22px;
    cursor: pointer;
}
.slide-nav:hover {
    /*color:#73c243;*/
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.85);
}
.slide-nav.prev {
    left: -22px;
}
.slide-nav.next {
    right: -22px;
}

/* expand username */
.user-addon-tab input[type="text"] {
    font-size: 27px;
    padding: 0 12px;
    line-height: 1;
}
.user-addon-tab form {
    width: 452px;
    margin: 0 auto;
    @media (max-width: 767px) {
        width: 100%;
    }
}
.user-addon-tab .form-control .tip {
    min-width: 270px;
}

/* expand subaccounts */
.addons-subaccouts-section .list-tiles-box .col {
    width: 136px;
    margin-left: 6px;
}
.addons-subaccouts-section .list-tiles-box.list-tiles .unit {
    height: 162px;
}
.addons-subaccouts-section .list-tiles-box .unit-bd {
    padding: 0 10px;
}
.addons-subaccouts-section .list-tiles-box .unit-solid .unit-title-small {
    line-height: 1.2;
}
.addons-subaccouts-section .list-tiles-box .unit-title-small .unit-title {
    color: #373737;
    font-size: 12px;
}
.addons-subaccouts-section .pack-price {
    font-size: 30px;
    margin: 10px 0 0;
}
.addons-subaccouts-section .pack-time {
    font-size: 15px;
}
.addons-subaccouts-section .list-tiles-box .form-group {
    margin-top: 0;
}

.addons-subaccouts-section .list-tiles .unit-bd {
    height: calc(100% - 28px);
}
.addons-subaccouts-section .list-tiles .box-tile-subaccouts .unit-bd {
    height: 100%;
    padding-top: 28px;
    .unit-title-small {
        position: absolute;
        border-bottom: 1px solid #e3e3e3;
        top: 0;
        left: 0;
        right: 0;
        padding: 5px;
        .unit-title {
            color: #373737;
            font-size: 12px;
            font-family: "robotolight", Helvetica, Arial, sans-serif;
        }
    }
}
.addons-subaccouts-section .list-tiles .price {
    font-size: 25px;
}
.addons-subaccouts-section .list-tiles .unit {
    height: 120px;
}
.addons-subaccouts-section .list-tiles .unit .unit-title-small {
    line-height: 1.2;
}
.addons-subaccouts-section .list-tiles .unit .unit-title-small .unit-title {
    color: #373737;
    font-size: 12px;
}
.addons-subaccouts-section .list-tiles .tiles-info {
    font-size: 36px;
    margin: 0.2em 0;
}

/* addons management */
.addons-manage-section .list-bar {
    margin: 30px 0;
}
.addons-manage-section .list-bar .col-name {
    color: #373737;
    font-size: 16px;
}

/* expand storage */
.storage-addon-tab .list-tiles .tiles-info {
    font-size: 36px;
    margin: 0.2em 0;
}
