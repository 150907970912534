.tip,
.tip-wrap:after {
    display:inline-block;
    position:absolute;
    z-index:99;
}
.tip {
    color:#fff;
    font-size:14px;
    text-align:center;
    min-width:34px;
    max-width:200px;
    text-shadow:none;
}
.tip-wrap {
    padding:2px 6px;
    background:rgba(38,39,41,.9);
    border-radius:2px;
    position:relative;
}
.tip-wrap:after {
    content:'';
    width:0;
    height:0;
    border:6px solid transparent;
}
.tip.pos-tc,
.tip.pos-bc {
    left:50%;
    transform:translateX(-50%);
}
/* tip position on top - arrow bottom */
.tip.pos-b,
.tip.pos-bc {
    bottom:100%;
    padding-bottom:8px;
}
.tip.pos-b .tip-wrap:after,
.tip.pos-bc .tip-wrap:after {
    border-top-color:rgba(38,39,41,.9);
    top:100%;
    left:50%;
    margin-left:-6px;
}
/* tip position right -arrow left */
.tip.pos-l {
    /*left:100%;*/
    padding-left:8px;
}
.tip.pos-l .tip-wrap:after,
.tip.pos-tl .tip-wrap:after {
    border-right-color:rgba(38,39,41,.9);
    right:100%;
    top:50%;
    margin-top:-6px;
}
/* tip position bottom - arrow top */
.tip.pos-t,
.tip.pos-tc {
    top:100%;
    padding-top:8px;
}
.tip.pos-t .tip-wrap:after,
.tip.pos-tc .tip-wrap:after {
    border-bottom-color:rgba(38,39,41,.9);
    bottom:100%;
    left:50%;
    margin-left:-6px;
}
/* tip position left -arrow right */
.tip.pos-r {
    /*right:100%;*/
    padding-right:8px;
}
.tip.pos-r .tip-wrap:after,
.tip.pos-tr .tip-wrap:after {
    border-left-color:rgba(38,39,41,.9);
    left:100%;
    top:50%;
    margin-top:-6px;
}
.tip.pos-tl {
    top:0;
    left:100%;
    padding-left:8px;
    @media (max-width: 991px) {
        top: 100%;
        right: 0;
        left: auto;
        padding-top: 8px;
        padding-left: 0;
    }
}
.tip.pos-tl .tip-wrap:after {
    top:0;
    margin-top:12px;
    @media (max-width: 991px) {
        border-right-color: transparent;
        border-bottom-color: rgba(38, 39, 41, 0.9);
        top: auto;
        bottom: 100%;
        right: 0;
        left: auto;
        margin-top: auto;
        margin-right: 12px;
    }
}

.tip.pos-tr {
    top:0;
    right:100%;
    padding-right:8px;
}
.tip.pos-tr .tip-wrap:after {
    top:0;
    margin-top:12px;
}


/* [ tip info/validate ] */
.tip-info {
    min-width:200px;
}
.tip-info .tip-wrap {
    font-size:12px;
    text-align:left;
    padding:12px;
}
.tip-info h6 {
    color:#fff;
    font-size:12px;
    font-weight:bold;
    margin:0 0 10px;
}
.tip-info p {
    margin:0;
}
.tip-info li {
    margin:3px 0;
}
.tip-info li:first-child {
    margin-top:0;
}
.tip-info li:last-child {
    margin-bottom:0;
}
.tip-info-wrap {
    position:relative;
    display:inline-block;
}
.tip-info-wrap .ico-info_round {
    @include x-svg-size(16);
    color:$color-info;
    top:0;
}
.tip-info-wrap .tip {
    opacity:0;
    visibility:hidden;
}

.tip-info-wrap.active .tip,
.tip-info-wrap:hover .tip {
    opacity:1;
    visibility:visible;
}
.tip-info-wrap .tip.pos-tl {
    top:-5px;
}

.tip-info-wrap.pos-tr {
    position:absolute;
    left:100%;
    top:3px;
}
.tip-info-wrap.pos-tr .tip.pos-tl {
    top:-3px;
}


/* [ tip inline ] */
.tip-inline {
    color:#606365;
    font-size:12px;
    margin:1em 0;

    .ico-info_round {
        @include x-svg-size(13);
        color:$color-info;
    }
    .ico-left {
        margin-right:5px;
    }
}


/* [ tip inline with background ] */
.tip-inline-bg {
    color:#555;
    font-style:italic;
    line-height:1.8em;
    padding:15px 20px;
    background:#eee;

    .ico-info_round {
        margin-left:-14px;
    }
}


/* [ tip static ] */
.tip-static {
    position:relative;
}


/* [ tip size ] */
.tip-small .tip-wrap {
    font-size:12px;
    padding:10px;
}