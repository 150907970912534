.change-password-section .page-wrap {
    max-width:600px;
    width: auto;
}
.change-password-section {
	padding-top: 60px;
	margin: 0 auto !important;
}
.change-password-section p,
.change-password-section li {
    font-size:16px;
}
.change-password-section .content {
    color:#606365;
}
.change-password-section .unit {
    margin:40px 0;
}
.change-password-section .form-label {
    width:170px;
    @media (max-width: 767px) {
    	text-align: left;
    	width: 100%;
    }
}
.change-password-section .form-control {
    margin-left:180px;
    width: auto;
    @media (max-width: 767px) {
    	margin-left: 0;
    }
}