@import '../../../../../shared/web/views/src/scss/__partials/dropdown';

//type menu: add contact */
    .dropdown-menu-contact {
        width:324px;

        @media (max-width: 991px) {
            left: 0;
            &.dropdown-layout.pos-tr .dropdown-wrap:before,
            &.dropdown-layout.pos-tr .dropdown-wrap:after {
                right: calc(100% - 50px);

            }
        }

        .dropdown-bd {
            margin:0;

            li > .dropdown-link {
                padding:10px 15px;
                margin:0;
                text-transform:none;
            }
        }

        .cover .cover-obj {
            @include x-svg-size(30);
        }

        .spacer,
        .cover-hd {
            margin:0;
        }

        .cover-figure {
            padding:6px 15px 5px 0;
        }
    }


//type menu: create event
    .dropdown-menu-create-event {
        width: 640px;
        padding: 15px 0;
        background-color: #fff;
        border-radius: 3px;
        margin-top: 7px;
        border: 1px solid #e5e5e5;
        @media (max-width: 991px) {
            max-width: 350px;
            padding: 10px 0;
            left: 0 !important;
        }

        .dropdown-bd,
        .spacer {
            margin:0;
        }

        .spacer--tabs {
            width: auto;
            margin: 0 40px;
        }

        .dropdown-bd li > .dropdown-link {
            padding:20px 33px;
            text-transform:none;
        }

        .cover-figure {
            padding-top:0;
            padding-bottom:0;
        }
        .cover-caption {
            margin-top:0;
        }
        .cover-hd-large {
            font-size:36px;
        }

        .blend-info {
            width:450px;
            min-width:450px;

            .btn {
                margin:10px 5px 0;
            }
        }

        .blend-meeting-time .btn-wrap {
            margin:0;

            .btn {
                margin:10px 5px;
            }
        }

        &--mobile {
            right: 0;
            @media (max-width: 991px) {
                left: 0;
                width: 455px;
            }
            @media (max-width: 768px) {
                width: min-content;
            }
            .dropdown-wrap {
                &:before,
                &:after {
                    display: none;
                }
            }
        }
    }

    .dashboard-dropdown {
        width: 640px;
        @media (max-width: 992px) {
            left: 0 !important;
            max-width: 350px;
            right: initial;
            left: 0;
            width: 350px;
        }
        .dropdown-wrap {
            background-color: #fff;
            &:before,
            &:after {
                right: 20px !important;
                @media (max-width: 992px) {
                    left: 20px;
                    right: initial !important;
                }
            }
        }
        &--right {
            @media (max-width: 992px) {
                left: auto !important;
            }
            @media (max-width: 576px) {
                right: auto !important;
            }
            .dropdown-wrap {
                &:before,
                &:after {
                    left: initial;
                    right: 20px !important;
                    @media (max-width: 576px) {
                        left: 20px;
                        right: initial !important;
                    }
                }
            }
        }
    }
