$cm: #6ebe3b;
.counter {
    &__number {
        display: flex;
        justify-content: center;
        margin-bottom: 2px;
    }
    &__val {
        background-color: $cm;
        padding: 3px 5px;
        border-radius: 4px;
        margin: 0 .5px;
        text-align: center;
        font-size: 1.875rem;
        font-weight: 700;
        line-height: 1.2;
        width: 34px;
        color: $white;
        font-family: $font-roboto-bold;
    }
    &__label {
        font-size: 1.25rem;
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;
        font-family: $font-roboto-medium;
    }
    &-xs {
        .counter__val {
            width: 24px;
            padding: 2px 3px;
            font-size: 1.25rem;
        }
        .counter__label {
            font-size: .875rem;
        }
    }
}