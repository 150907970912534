.promo {
    &-popup {
        font-family: $font-roboto-regular;
        background-attachment: scroll;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 5px;
        height: 100%;
        width: 100%;
        text-align: center;
        &-link {
            height: 100%;
            width: 100%;
            display: table;
        }

        .block-promo {

            &-no-thanks {
                position: absolute;
                height: 40px;
                width: 100%;
                bottom: 10px;

                .hide-check + label {
                    font-size: 14px;
                    color: #fff;
                    padding: 0;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .hide-check[disabled] + label {
                    cursor: pointer;
                }

                a {
                    line-height: 40px;
                    color: #fff;

                    &:hover {
                        text-decoration: underline;
                    }
                }

            }

            &-checkbox {
                position: absolute;
                height: 40px;
                left: 120px;
                right: 120px;
                bottom: 0;

                .f-holder {
                    line-height: 40px;
                    font-size: 12px;
                    text-align: center;

                    .check {
                        background: transparent;
                        border: 1px solid #858c99;
                        box-shadow: none;
                        width: 14px;
                        height: 14px;
                        visibility: hidden;

                        &::before {
                            top: -1px;
                        }
                    }
                }
            }
        }
        &__content {
            padding: 100px 15px 15px;
            height: 100%;
            @media (max-width: 767px) {
                padding-top: 50px;
            }
        }
        &__header {
            font-size: 45px;
            line-height: 45px;
            font-family: $font-roboto-medium;
            color: #fff;
            margin: 0;
            @media (max-width: 767px) {
                font-size: 35px;
                line-height: 35px;
            }
        }
        &__desc {
            font-size: 20px;
            line-height: 26px;
            color: #fff;
            max-width: 450px;
            margin: 40px auto;
        }
        &__btn {
            font-size: 18px;
            line-height: 18px;
            font-family: $font-roboto-bold;
            display: inline-block;
            margin-top: 60px;
            color:#fff;
            padding: 15px 30px;
            border-radius: 50px;
            text: {
                align: center;
                transform: uppercase;
            }
        }
    }

    &-bar {
        position: relative;
        height: 85px;
        margin-bottom: -10px;
        z-index: 1031;
        -webkit-transition: background-image .5s ease-in-out, margin-top .2s ease-in-out;
        -moz-transition: background-image .5s ease-in-out, margin-top .2s ease-in-out;
        -o-transition: background-image .5s ease-in-out, margin-top .2s ease-in-out;
        transition: background-image .5s ease-in-out, margin-top .2s ease-in-out;

        &-bg {
            background-attachment: scroll;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: #f1f1f1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 75px;
            z-index: 2;
        }

        &-shadow {
            background: #fdc300;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 75px;
            z-index: 1;
            -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
        }
        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            @media (max-width: 767px) {
                flex-direction: column;
            }
        }
        &__content {
            font-family: $font-roboto-medium;
            font-size: 35px;
            color: #fff;
            margin: 0 40px 0 0;
            @media (max-width: 1259px) {
                font-size: 30px;
                line-height: 30px;
            }
            @media (max-width: 767px) {
                margin: 0 0 10px 0;
                font-size: 25px;
                line-height: 25px;
            }
        }
        &__btn {
            font-size: 18px;
            line-height: 18px;
            font-family: $font-roboto-bold;
            display: inline-block;
            color: #fff;
            padding: 15px 30px;
            border-radius: 50px;
            text-align: center;
            text-transform: uppercase;
            margin-left: 40px;
            @media (max-width: 1259px) {
                padding: 10px 20px;
            }
            @media (max-width: 767px) {
                margin-top: 10px;
                margin-left: 0;
            }
        }
    }

    @media (max-width: 1259px) {

        &-bar {
            height: 85px;

            &-bg {
                height: 75px;
            }

            &-shadow { height: 75px; }
        }
    }

    @media (max-width: 767px) {

        &-bar {
            height: 177px;

            &-bg {
                height: 167px;
            }

            &-shadow {
                height: 167px;
            }
        }
    }
}

.banner-promo {
    width: 708px;
    height: 118px;
    margin: 0 auto 20px;
}
.promo-counter {
    .countdown-clock {
        background-color: transparent;
        box-shadow: none;
    }
    .countdown-title {
        display: none;
    }
    .countdown-wrap {
        padding: 0;
    }
    .countdown-time {
        font-size: 26px;
        border-radius: 5px;
        height: 35px;
        width: 35px;
        line-height: 35px;
        @media (max-width: 1259px) {
            font-size: 18px;
        }
        @media (max-width: 767px) {
            height: 25px;
            width: 25px;
            line-height: 25px;
        }
    }
    .countdown-item {
        width: 45px;
        padding: 0 5px;
        @media (max-width: 767px) {
            width: 35px;
            margin: 0 2px;
        }
        &:before {
            @media (max-width: 767px) {
                margin-right: -7px;
            }
        }
    }
    .countdown-label {
        color: #fff;
        text-transform: capitalize;
    }
    &__label {
        font-size: 12px;
        line-height: 12px;
        color: #fff;
        margin: 0 0 8px 0;
    }
}