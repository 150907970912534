.unit,
.unit-hd,
.unit-bd,
.unit-ft {
    display:block;
}
.unit {
    position:relative;
    background:#fff;
    border-radius:3px;
    border:1px solid rgba(0,0,0,0.1);
    //box-shadow:0 0 1px rgba(0,0,0,.22);
}
.statistics-boxes {
    .unit-bd {
        overflow: hidden;
    }
}

.unit-hd {
    color:#fff;
    background:#282b31;
    padding:10px 15px;
    border-top-right-radius:2px;
    border-top-left-radius:2px;

    [data-icon] {
        line-height:1;
        font-size:20px;
    }

    .options {
        float:right;
    }

    & + .unit-bd {
        border-top:1px solid #e3e3e3;
    }
}

.unit-title {
    font-size:16px;

    & + .options {
        margin:4px 0 0 0;
    }
}
.unit-title-small .unit-title {
    font-size:14px;
}
.unit-title-large,
.unit-title-x-large {
    padding-top:5px;
    padding-bottom:5px;
}
.unit-title-large .unit-title {
    font-size:17px;
    letter-spacing:-1px;
}
.unit-title-x-large .unit-title {
    font-size:20px;
    letter-spacing:-1px;
}

.unit-bd {
    position:relative;
    padding:15px;
    min-height:50px;

    &:before, &:after {
        display:table;
        content:' ';
    }

    &:after {
        clear:both;
    }
}

.unit-ft {
    padding:10px 15px;
    border-bottom-right-radius:2px;
    border-bottom-left-radius:2px;
}



/* unit solid color */
.unit-solid .unit-hd {
    background:transparent;
    color:inherit;
    padding-top:15px;
    padding-bottom:0;
}
.unit-solid .unit-hd + .unit-bd {
    border-top-width:0;
    padding-top:0;
}
.unit-solid .unit-title-small {
    padding-top:5px;
    padding-bottom:5px;
}
.unit-solid .unit-title-small + .unit-bd {
    border-top-width:1px;
}


/* inactive/muted boxes */
.unit-muted .unit-hd {
    background:#d8d8d8;
}
.unit-muted {
    color:#d8d8d8;
}

/* efekty hover dla unit */
.unit-hov,
.unit-hov-bordered {
    cursor:pointer;
}
/*.unit-hov:hover .unit-hd {
    background:#6ebe3b;
    background:-webkit-linear-gradient(top,  #79cb45 0%,#6ebe3b 100%);
    background:linear-gradient(to bottom,  #79cb45 0%,#6ebe3b 100%);
}
.hide-check:checked + .unit-hov-bordered,
.unit-hov-bordered:hover,
.unit-hov-bordered.js-hover {
    box-shadow:0 0 0 3px #72c82e;
}
.unit-hov-bordered:hover .unit-hd,
.unit-hov-bordered.js-hover .unit-hd {
    background:#72c82e;
}*/
.hide-check + .unit-hov-bordered {
    padding:0;
}

/* ikony w unit */
.unit .close,
.unit .ico-add,
.unit .ico-check,
.gallery .ico-check,
.component .ico-check {
    display:none;
    position:absolute;
    z-index:7;
    text-align:center;
}
.unit .close {
    top:-9px;
    right:-9px;
}
/*.unit .ico-add,
.unit .ico-check,
.gallery .ico-check,
.component .ico-check {
    background-color:#72c82e;
}
*//* icons position */
.unit-hov-bordered .pos-tr[data-icon],
.gallery-hov-bordered .pos-tr[data-icon],
.component .pos-tr[data-icon] {
    top:-2px;
    right:-2px;
}
.unit-hov-bordered .pos-tl[data-icon],
.gallery-hov-bordered .pos-tl[data-icon],
.component .pos-tl[data-icon] {
    top:-2px;
    left:-2px;
}
.unit-hov-bordered .pos-br[data-icon],
.gallery-hov-bordered .pos-br[data-icon],
.component .pos-br[data-icon] {
    bottom:-2px;
    right:-2px;
}
.unit-hov-bordered .pos-bl[data-icon],
.gallery-hov-bordered .pos-bl[data-icon],
.component .pos-bl[data-icon] {
    bottom:-2px;
    left:-2px;
}
.unit-hov:hover .close,
.unit-hov-bordered:hover .ico-add,
.hide-check:checked + .unit-hov-bordered .ico-check,
.hide-check:checked + .unit-hov-bordered.js-hover .ico-check,
.hide-check:checked + .gallery-hov-bordered .ico-check,
.hide-check:checked + .component .ico-check {
    display:block;
}
// .table-wrap[data-loading='true'] table,
// .table-wrap[data-loading='true'] .table,
.unit[data-loading='true'] {
    pointer-events:none;
    -webkit-touch-callout:none;
    user-select:none;
}
.unit[data-loading='true'] .unit-hd,
.unit[data-loading='true'] .unit-bd {
    opacity:.5;
}


/* [unit as table ] */
.unit-table .unit-hd {
    padding:9px 17px;
}
.unit-table .unit-hd a,
.unit-table .unit-hd a:hover {
    color:#fff;
}
