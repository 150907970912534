.rebranding {
    position: relative;
    $bgColorMain: white;
    $bgColorLighten: #f2f3f7;
    flex: 1;
    &-options {
        padding-bottom: 25px;
    }
    .svg-stroke {
        stroke-width: 1px;
    }
    /* svg */
    .ico-clickmeeting {
        width: 150px;
        height: 50px;
    }
    .ico-clock,
    .ico-upcoming_events,
    .ico-dot_list,
    .ico-download_2,
    .ico-example_text,
    .ico-published_recording {
        color: inherit;
        width: 40px;
        height: 40px;
    }
    &-tools {
        background: #292d38;
        border-bottom: 1px solid #606673;
        position: relative;
        overflow: hidden;
    }
    &-col {
        border-right: 1px solid #3c404a;
        padding-top: 15px;
        @media only screen and (max-width: 992px) {
            border-right: 0;
        }
        &:last-child{
            width: 40%;
            border-right: 0;
        }
        &-divider {
            background: #3c404a;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 1px;
            left: 50%;
            margin-left: -0.5px;
        }
    }
    .image-provider-col {
        @media (max-width: 992px) {
            margin-top: 20px;
        }
    }
    &-panel {
        width: 100%;
        margin: auto;
        &-heading {
            h4 {
                font-family: $font-roboto-bold;
                font-size: 14px;
                margin: 0;
                color: #fff;
                letter-spacing: 0;
            }
            .image-provider-logo {
                width: 95px;
                height: 21px;
            }
            .image-provider-logo.pexels {
                height: 22px;
                width: auto;
            }
        }
        &-body {
            padding: 15px 0 5px;
            &--bg {
                height: 150px;
            }
            &__before {
                background-image: url("/account_panel/images/room/rebranding/image_provider_before_start_bg.png");
                background-repeat: no-repeat;
                background-position: -15px -40px;
                background-size: cover;
                height: 100%;
                width: 100%;
                position: absolute;
                right: 0;
                top: 20px;
                z-index: 2;
                background-color: #292d38;
            }
            &__before-content {
                padding-top: 30px;
                text-align: left;
                color: #ffffff;
                width: 270px;
                margin: 0 auto;
                .check {
                    width: 22px;
                    height: 22px;
                    border-radius: 5px;
                    left: -10px;
                    top: 4px;
                    &:before {
                        font-size: 15px;
                    }
                }
                span {
                    font-size: 16px;
                }
                p {
                    margin: 0;
                    font-size: 12px;
                    line-height: 20px;
                }
                a {
                    padding-left: 25px;
                    font-size: 11px;
                    font-weight: 700;
                }
            }
            .colorpicker-element {
                position: relative;
                width: 100%;
                padding-top: 95px;
            }
            .view {
                height: 95px;
                &-logo {
                    background: #fff;
                }
                &-gradient {
                    &-picker {
                        margin-bottom: 3px;
                        position: relative;
                        overflow: hidden;
                    }
                    &-palette {
                        border: 1px solid #20232b;
                        background: #454b5d;
                        height: 70px;
                    }
                    .color {
                        &-sample {
                            background: #454b5d;
                            border: 1px solid #21252d;
                            width: 18px;
                            height: 18px;
                            float: left;
                        }
                        &-code {
                            padding: 1px 3px;
                            font-size: 11px;
                            color: #292d38;
                            width: 50px;
                            height: 19px;
                            box-shadow: none;
                            float: right;
                        }
                        &-slider {
                            background: #454b5d;
                            margin: 1px 60px 1px 24px;
                            height: 18px;
                        }
                    }
                }
            }
        }
        &-footer {
            overflow: hidden;
            &.logo {
                padding-top: 15px;
            }
            .suggestions {
                font-family: $font-roboto-regular;
                font-size: 10px;
                line-height: 10px;
                margin: 0 0 5px;
                color: #5a6172;
            }
            .list-inline {
                >li {
                    margin-right: 2px;
                    float: left;
                    margin-top: 0;
                    margin-bottom: 0;
                    a {
                        background: #454b5d;
                        border: 1px solid #21252d;
                        display: block;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .btn-link {
                font-family: $font-roboto-regular;
                font-size: 11px;
                float: right;
                text-align: right;
                @media (max-width: 1200px) {
                    float: none;
                    margin-top: 20px;
                    display: block;
                    width: auto;
                }
                @media (max-width: 992px) {
                    float: right;
                    width: 50%;
                    margin-top: 0;
                }
            }
            button {
                font-size: 11px;
                font-weight: 700;
                @media (max-width: 992px) {
                    margin-bottom: 10px;
                }
            }
            .pull-right {
                float: right;
            }
        }
    }
    &-room {
        height: 850px;
        background: #e7e9f0;
        padding: 45px 25px 30px;
        -webkit-border-bottom-right-radius: 2px;
         -webkit-border-bottom-left-radius: 2px;
            -moz-border-radius-bottomright: 2px;
             -moz-border-radius-bottomleft: 2px;
                border-bottom-right-radius: 2px;
                 border-bottom-left-radius: 2px;
             @media only screen and (max-width: 567px) {
                    min-height: 950px;
                }

        &-page {
            background: #fff;
            position: relative;
            overflow: hidden;
            height: 582px;
            -webkit-border-radius: 4px;
               -moz-border-radius: 4px;
                    border-radius: 4px;
            -webkit-box-shadow: 0px 10px 38px 0px rgba(110, 110, 110, 1);
                    box-shadow: 0px 10px 38px 0px rgba(110, 110, 110, 1);
            width: 100%;
        }
        .navbar {
            background: #fff;
            border-bottom: 1px solid #e9ebf3;
            min-height: 60px;
            margin-left: 60px;
            position: relative;
            -webkit-border-top-right-radius: 4px;
                -moz-border-radius-topright: 4px;
                    border-top-right-radius: 4px;
            padding: 0;
            display: block;
            @media (max-width: 768px) {
                margin-left: 40px;
            }
            &-header {
                background: transparent;
                display: table;
                padding: 0;
                margin: 0;
                float: left;
            }
            &-logo {
                position: relative;
                display: table-cell;
                vertical-align: middle;
                padding: 5px 10px 5px 20px;
                height: 60px;
                cursor: default;
                @media (max-width: 992px) {
                    padding: 5px;
                }
                @media (max-width: 768px) {
                    display: none;
                }
                img {
                    vertical-align: middle;
                    max-height: 44px;
                    max-width: none;
                    margin-bottom: 0;
                    @media (max-width: 992px) {
                        max-width: 100px;
                    }
                }
                svg {
                    width: 120px;
                    height: 25px;
                    margin-top: 4px;
                    border: 1px solid orange;
                }
            }
            &-text {
                display: inline-block;
                margin: 0;
                padding: 20px 10px;
                font-size: 16px;
                color: #292d38;
                line-height: 1.3;
                @media (max-width: 992px) {
                    padding: 20px 5px;
                    font-size: 13px;
                }
                @media (max-width: 576px) {
                    font-size: 11px;
                }
            }
            .nav {
                padding-left: 0;
                margin: 0;
                list-style: none;
                >li {
                    position: relative;
                    display: table;
                    float: left;
                    height: 60px;
                    @media (max-width: 576px) {
                        display: none;
                    }
                    &:last-child {
                        @media (max-width: 576px) {
                            display: block;
                        }
                        a {
                        padding-right: 16px;
                        -webkit-border-top-right-radius: 4px;
                            -moz-border-radius-topright: 4px;
                                border-top-right-radius: 4px;
                            @media (max-width: 576px) {
                                padding-top: 15px;
                                padding-right: 5px;
                            }
                        }
                    }
                    >a {
                        position: relative;
                        display: table-cell;
                        vertical-align: middle;
                        padding: 10px 12px;
                        font-size: 11px;
                        color: #a7afc5;
                        cursor: default;
                        @media (max-width: 768px) {
                            padding: 10px 5px;
                        }
                        .svg-wrap {
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 1px;
                            line-height: 1;
                            vertical-align: middle;
                            svg {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 16px;
                                height: 16px;
                                margin-top: -10px;
                                margin-left: -8px;
                                color: #a7afc5;
                                stroke-width: 1;
                            }
                        }
                        .ico {
                            display: inline-block;
                            position: relative;
                            top: 0;
                            &-flag-16 {
                                width: 16px;
                                height: 14px;
                            }
                            &-avatar {
                                background: url("/account_panel/images/room/rebranding/avatar.png") no-repeat center center;
                                width: 35px;
                                height: 30px;
                            }
                        }
                    }
                }
                &-divider {
                    width: 1px;
                    &:after {
                        background: #e8ebf0;
                        content: " ";
                        position: absolute;
                        width: 1px;
                        height: 8px;
                        top: 50%;
                        left: 50%;
                        margin-left: -0.5px;
                        margin-top: -4px;
                    }
                }
                &.navbar-right {
                    float: right;
                }
            }
        }
        .content {
            &-body {
                margin-left: 60px;
                margin-right: 300px;
                padding: 10px;
                height: 90%;
                border: 0;
                @media (max-width: 768px) {
                    margin-left: 40px;
                    margin-right: 0;
                }
                &__mask {
                    background-color: rgba(0,0,0,.4);
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: -2px;
                    bottom: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .sidebar {
            &-left {
                background: #292d38;
                position: absolute;
                width: 60px;
                top: 0;
                bottom: 0;
                left: 0;
                   -webkit-border-top-left-radius: 4px;
                -webkit-border-bottom-left-radius: 4px;
                       -moz-border-radius-topleft: 4px;
                    -moz-border-radius-bottomleft: 4px;
                           border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                @media (max-width: 768px) {
                    width: 40px;
                }
                .nav {
                    margin: 0;
                    padding-left: 0;
                    list-style: none;
                    >li {
                        position: relative;
                        display: table;
                        >a {
                            position: relative;
                            display: table-cell;
                            vertical-align: middle;
                            text-align: center;
                            width: 60px;
                            height: 60px;
                            padding: 3px 0;
                            cursor: default;
                            @media (max-width: 768px) {
                                width: 40px;
                            }
                        }
                    }
                    &-main {
                        >li {
                            &:first-child > a {
                                -webkit-border-top-left-radius: 4px;
                                    -moz-border-radius-topleft: 4px;
                                        border-top-left-radius: 4px;
                            }
                            >a {
                                svg {
                                    width: 28px;
                                    height: 28px;
                                    color: #fff;
                                    stroke-width: 1;
                                    @media (max-width: 768px) {
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                            }
                            &.active {
                                >a {
                                    background-color: #222631;
                                    box-shadow: 2px 0 0 inset;
                                    color: #71bc44;
                                    svg {
                                        color: #71bc44;
                                    }
                                    &:after {
                                        right: 0;
                                        top: 50%;
                                        border: solid transparent;
                                        content: " ";
                                        position: absolute;
                                        border-right-color: #fff;
                                        border-width: 4px;
                                        margin-top: -4px;
                                    }
                                }
                            }
                        }
                    }
                    &-bottom {
                        position: absolute;
                        bottom: 0;
                        >li {
                            &:last-child > a {
                                -webkit-border-bottom-left-radius: 4px;
                                    -moz-border-radius-bottomleft: 4px;
                                        border-bottom-left-radius: 4px;
                            }
                            >a {
                                svg {
                                    width: 28px;
                                    height: 28px;
                                    color: #fff;
                                    stroke-width: 1;
                                    @media (max-width: 768px) {
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                                span {
                                    display: block;
                                    font-size: 9px;
                                    line-height: 9px;
                                    margin-top: 2px;
                                    color: #979eb0;
                                    @media (max-width: 768px) {
                                        font-size: 8px;
                                        line-height: 8px;
                                    }
                                }
                                &.btn {
                                    &-off {
                                        background: #f25359;
                                    }
                                    &-audio-mode {
                                        background: #444956;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-right {
                background: #fff;
                position: absolute;
                width: 345px;
                top: 61px;
                bottom: 0;
                right: 0;
                padding-bottom: 45px;
                box-shadow: 0 0 11px 5px rgba(167, 175, 198, 0.1);
                -webkit-border-bottom-right-radius: 4px;
                -moz-border-radius-bottomright: 4px;
                border-bottom-right-radius: 4px;
                @media (max-width: 768px) {
                    width: 145px;
                }
                .audio-video {
                    background: url("/account_panel/images/room/rebranding/audio-video2.jpg") no-repeat center left;
                    background-size: cover;
                    position: relative;
                    height: 187px;
                    border-bottom: 3px solid #71bc44;
                    @media (max-width: 768px) {
                        height: 100px;
                    }
                }
                .resizeable {
                    &-panel {
                        background: linear-gradient(to bottom,#f4f6fa 0,#eef1f7 100%);
                        position: relative;
                        padding: 0 8px;
                        &-av {
                            background: #292d37;
                            .resizeable-panel-header,
                            .resizeable-panel-header-icon {
                                color: #fff;
                            }
                        }
                        &-chat {
                            &:before {
                                background-color: #9ca6be;
                                content: '';
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                width: 30px;
                                height: 5px;
                                border-radius: 3px;
                            }
                        }
                    }
                    &-panel-header {
                        line-height: 30px;
                        font-size: 11px;
                        color: #a7afc6;
                        text-transform: uppercase;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        &-icon {
                            color: #a7afc6;
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                            &.av-toolbar-icon {
                                background-color: #73bb4c;
                                position: relative;
                                width: 23px;
                                height: 23px;
                                border-radius: 50%;
                                &-disabled {
                                    background-color: #f0545c;
                                }
                                svg {
                                    position: absolute;
                                    width: 17px;
                                    height: 17px;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }
                        &-search {
                            position: relative;
                            background-color: #fff;
                            width: 50px;
                            height: 21px;
                            border-radius: 10px;
                            border: 1px solid #e9ecf3;
                            svg {
                                position: absolute;
                                top: 50%;
                                right: 5px;
                                transform: translateY(-50%);
                                width: 11px;
                                height: 11px;
                                color: #a7afc6;
                            }
                        }
                    }
                }
                .chat {
                    &-form {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 44px;
                        border-top: 1px solid #e9ebf0;
                        textarea {
                            width: calc(100% - 60px);
                            padding: 12px 15px;
                            border: none;
                            line-height: 1.4;
                            min-height: 44px;
                            box-shadow: none;
                            font-size: 12px;
                            resize: none;
                            float: left;
                            @media (max-width: 768px) {
                                padding: 7px;
                                font-size: 10px;
                                line-height: 1.1;
                            }
                        }
                        textarea[disabled] {
                            background: #fff;
                            cursor: default;
                        }
                    }
                    &-typing {
                        position: absolute;
                        left: 15px;
                        bottom: 50px;
                        font-size: 11px;
                        color: #a7afc5;
                        padding-left: 20px;
                        @media (max-width: 768px) {
                            left: 7px;
                            font-size: 10px;
                        }
                        &:before {
                            content:"\2022 \2022 \2022";
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            height: 15px;
                            font-size: 15px;
                            line-height: 16px;
                        }
                    }
                    &-options {
                        float: right;
                        width: 60px;
                        position: relative;
                        padding: 10px 0;
                        line-height: 20px;
                        @media (max-width: 768px) {
                            width: 45px;
                            padding: 7px 0;
                        }
                        .svg-wrap {
                            position: relative;
                            display: inline-block;
                            width: 26px;
                            height: 1px;
                            vertical-align: middle;
                            color: #5c6272;
                            cursor: default;
                            @media (max-width: 768px) {
                                width: 20px;
                            }
                            svg {
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                margin-top: -10px;
                                margin-left: -10px;
                                stroke-width: 1;
                                @media (max-width: 768px) {
                                    width: 15px;
                                    height: 15px;
                                }
                            }
                        }
                    }
                    &-message {
                        display: block;
                        float: left;
                        margin: 0 0 7px;
                        padding: 10px;
                        border: 1px solid #ebf0f7;
                        background: #fff;
                        border-radius: 5px;
                        font-family: "robotoregular" !important;
                        max-width: 100%;
                        word-wrap: break-word;
                        @media (max-width: 768px) {
                            padding: 5px;
                        }
                        &-self {
                            float: right;
                            text-align: right;
                            p {
                                color: #fff !important;
                            }
                        }
                    }
                    &-content {
                        font-family: $font-roboto-regular;
                        font-size: 12px;
                        line-height: 1.3;
                        @media (max-width: 768px) {
                            font-size: 10px;
                            line-height: 1.1;
                        }
                        p {
                            margin: 0 0 5px;
                            color: #292d38;
                        }
                    }
                    &-signature {
                        margin: 0 !important;
                        color: #a7afc5 !important;
                        font-size: 10px;
                        span {
                            display: inline-block;
                        }
                    }
                    &-divider {
                        position: relative;
                        padding: 0 4px;
                        &:after {
                            background: #f1f3f9;
                            content: " ";
                            position: absolute;
                            width: 1px;
                            height: 6px;
                            top: 50%;
                            left: 50%;
                            margin-left: -0.5px;
                            margin-top: -3px;
                        }
                    }
                }
                .branding-main {
                    background-color: #6ebe3b;
                    border-color: #6ebe3b;
                    .chat-signature {
                        color: #ccfead !important;
                    }
                    .chat-divider {
                        &:after {
                            background: #94d66e;
                        }
                    }
                }
                .nav-justified {
                    display: flex;
                }
                .nav-justified .nav-item {
                    text-align: center;
                    -webkit-box-flex: 1;
                        -webkit-flex: 1 1 100%;
                            -ms-flex: 1 1 100%;
                                flex: 1 1 100%;
                }

                .nav-tabs {
                    line-height: 1.5;
                    &.nav-nest {
                        border-bottom: 1px solid #e9ebf0;
                        .nav-item {
                            margin-bottom: -1px;
                        }
                        .nav-link {
                            background: transparent;
                            padding: 12px 15px 10px;
                            border-width: 0 0 2px;
                            border-radius: 0;
                            &.active {
                                border-color: #71bc44;
                            }
                        }
                    }
                    .nav-link {
                        background: #eff2f7;
                        display: block;
                        padding: 12px 15px;
                        text-transform: uppercase;
                        font-size: 11px;
                        color: #a7afc6;
                        cursor: default;
                        border: 2px solid transparent;
                        @media (max-width: 768px) {
                            padding: 6px;
                            font-size: 10px;
                        }
                        &.active {
                            background: transparent;
                            color: #5c6273;
                            .svg-wrap svg {
                                color: #5c6273;
                            }
                        }
                        &:hover {
                            border-color: transparent;
                        }
                        .svg-wrap {
                            position: relative;
                            display: inline-block;
                            width: 28px;
                            height: 1px;
                            vertical-align: middle;
                            svg {
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                margin-top: -10px;
                                margin-left: -10px;
                                color: #a7afc5;
                                stroke-width: 1;
                            }
                        }
                    }
                }
                .tab-content {
                    padding: 15px;
                    position: relative;
                    overflow: hidden;
                    @media (max-width: 768px) {
                        padding: 7px;
                    }
                }
            }
        }
    }
    &-title {
        max-width: 350px;
        margin: 10px auto 30px !important;
        &--mb {
            margin-bottom: 15px !important;
        }
    }
    &-event-date {
        max-width: 320px;
        margin: 0 auto !important;
    }
    &-mockup {
        position: relative;
        padding: 25px 20px 0;
        background-color: #fff;
        margin: 45px auto;
        border-radius: 5px;
        &__logo {
            margin-bottom: 5px;
            max-width: 240px;
            max-height: 40px;
            object-fit: contain;
        }
        &__label {
            position: absolute;
            top: 30px;
            right: -135px;
            width: 135px;
            background-color: #2a2d38;
            font-family: $font-roboto-medium;
            text-transform: uppercase;
            padding: 10px 15px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            text-align: center;
            @media (max-width: 992px) {
                top: 0;
                right: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 5px;
                padding: 5px;
                width: auto;
            }
        }
        &__name {
            color: #ffffff;
            margin: 0;
            font-size: 14px;
            line-height: 14px;
            @media (max-width: 992px) {
                font-size: 10px;
                line-height: 10px;
            }
        }
        &__redirect {
            font-size: 11px;
            line-height: 11px;
            color: #a7afc6;
            display: none;
            @media (max-width: 992px) {
                font-size: 8px;
                line-height: 8px;
            }
        }
        .row {
            margin-left: -5px;
            margin-right: -5px;
        }
        .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .mockup-avatar {
        width: 100%;
        @media (max-width: 576px) {
            width: 54px;
            margin-bottom: 10px;
        }
    }
    .mockup-field {
        background-color: #e9ebf1;
        height: 18px;
        border-radius: 3px;
        border: 0;
        &--short {
            width: 20px;
        }
        &--small {
            border-radius: 5px;
            height: 8px;
        }
        &--profile {
            height: 15px;
        }
        &--medium {
            height: 10px;
        }
        &--mediumH {
            height: 10px;
        }
        &--mediumW {
            width: 100px;
        }
        &--blue {
            border-radius: 5px;
            height: 5px;
            width: 75px;
            background-color: #c0ebfa;
            &.tall {
                height: 10px;
            }
            &.taller {
                height: 15px;
            }
        }
    }
    .mockup-tab {
        border: 1px solid #ecedf2;
        border-radius: 5px;
        padding: 0 5px;
        min-width: 180px;
        &--active {
            color: #fff !important;
        }
        &__element {
            font-size: 12px;
            font-weight: 600;
        }
    }
    @media (max-width: 575px) {
        .mockup-tab {
            min-width: auto;
            width: 100%;
        }
    }
    .mockup-action {
        font-size: 12px;
        font-family: 'robotomedium';
    }
    .mockup-squares {
        &__item {
            width: 14px;
            height: 14px;
            display: inline-block;
            margin-right: 4px;
            &--purple {
                background-color: #ced5e6;
            }
            &--blue {
                background-color: #cae8fb;
            }
            &--green {
                background-color: #acd4e7;
            }
            &--red {
                background-color: #f6d3d1;
            }
        }
    }
    .mockup-numbers {
        margin-top: 10px;
        padding: 15px 0;
        justify-content: center;
        span {
            font-size: 18px;
            font-family: $font-roboto-bold;
            color: #ffffff;
            background-color: #e9ebf1;
            border-radius: 0;
            padding: 3px 6px 5px 6px;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                bottom: 3px;
                background-color: #ffffff;
                height: 3px;
                width: 15px;
                border-radius: 3px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
            &:first-child {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
            &:last-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }
    .mockup-component-bg {
        background-color: $bgColorLighten;
        padding: 20px 0 25px;
        margin: 20px 0;
    }
    .mockup-input {
        background-color: #ffffff;
        height: 25px;
        border-radius: 3px;
        border: 1px solid #e3e6ed;
        width: 100%;
        &.active {
            border: 1px solid #b0d89b;
        }
    }
    .mockup-radio {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background-color: #fff;
        border: 1px solid #e3e6ed;
        margin-right: 5px;
        position: relative;
        &.active {
            .mockup-radio__check {
                position: absolute;
                height: 6px;
                width: 6px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: #6ebe3b;
                border-radius: 100%;
            }
        }
    }
    .mockup-checkbox {
        width: 14px;
        height: 14px;
        border-radius: 3px;
        border: 1px solid #f2f3f6;
        margin-right: 10px;
        text-align: center;
        i {
            color: #6ebe3b;
            position: relative;
            top: -5px;
            font-size: 10px;
        }
    }
    .mockup-button {
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 13px;
        font-family: $font-roboto-medium;
        padding: 11px 22px;
        background-color: #6ebe3b;
        border-radius: 25px;
        margin-top: 25px;
        &--large {
            font-family: $font-roboto-bold;
            padding: 11px 30px;
        }
        &--fb {
            background-color: #3e5c98;
            margin-top: 20px;
            font-size: 10px;
            padding: 5px 10px;
            font-family: $font-roboto-bold;
        }
    }
    .mockup-row-txt {
        max-width: 450px;
        margin: 0 auto;
    }
    .mockup-login-box {
        width: 300px;
        margin: 0 auto;
        @media (max-width: 576px) {
            width: 100%;
        }
    }
    .mockup-icon-list {
        font-size: 35px;
        color: #6ebe3b;
        margin-right: 10px;
    }
    &--3 {
        input[disabled] {
            background-color: $bgColorMain;
        }

        .room-page-middle {
            background: initial;
        }
        .room-footer {
            position: relative!important;
            pointer-events: none;
        }
        .unit {
            background-color: $bgColorLighten;
            border: 0;
            border-radius: 0;
            margin: 0 -10px;
            padding: 10px;
        }
        .room-content {
            background-color: $bgColorMain;
        }
    }
}
.rebranding-tools {
    /* colorpicker
    ****************************************/
    .colorpicker.colorpicker-rebranding.colorpicker-horizontal .colorpicker-saturation {
        height: 220px;
        background-size: 100% 100%;
        position: absolute !important;
        top: 30px;
        left: 0;
        right: 0;
        width: auto;
        margin-bottom: 0 !important;
        border: 1px solid #20232b;
        i {
            border-color: #fff;
            b {
                border-color: rgba(41, 45, 56, 0.2);
                width: 3px;
                height: 3px;
            }
        }
    }
    .colorpicker.colorpicker-rebranding.colorpicker-horizontal .colorpicker-color {
        height: 20px;
        width: 20px;
        overflow: hidden;
        border: 1px solid #21252d;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0;
    }
    .colorpicker.colorpicker-rebranding.colorpicker-horizontal .colorpicker-color div {
        height: 20px;
        width: 20px;
        position: static;
    }
    .colorpicker.colorpicker-rebranding.colorpicker-horizontal .colorpicker-hue {
        position: absolute;
        left: 25px;
        top: 1px;
        height: 18px !important;
        margin-bottom: 0 !important;
    }
    .colorpicker.colorpicker-rebranding.colorpicker-horizontal .colorpicker-hue,
    .colorpicker.colorpicker-rebranding.colorpicker-horizontal .colorpicker-alpha {
        width: 130px;
        height: 18px;
    }
    .rebranding-col .colorpicker.colorpicker-rebranding.colorpicker-horizontal .colorpicker-hue {
        @media only screen and (max-width: 1200px) {
            width: 90px;
        }
        @media only screen and (max-width: 992px) {
            width: 130px;
        }
    }
    .colorpicker.colorpicker-rebranding.colorpicker-horizontal .colorpicker-hue i,
    .colorpicker.colorpicker-rebranding.colorpicker-horizontal .colorpicker-alpha i {
        width: 1px;
        height: 18px;
        &:before {
            content: '';
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            border: 1px solid #fff;
        }
        &:after {
            content: '';
            position: absolute;
            top: -2px;
            right: -2px;
            bottom: -2px;
            left: -2px;
            border: 1px solid rgba(0, 0, 0, 0.11);
        }
    }
    .colorpicker.colorpicker-rebranding {
        border-radius: 0 !important;
        margin-top: 0 !important;
        padding: 0 !important;
        width: auto !important;
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .colorpicker-element  .colorpicker-code {
        padding: 1px 0;
        font-size: 11px;
        color: #292d38;
        width: 50px;
        height: 19px;
        box-shadow: none;
        font-size: 11px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        input {
            padding: 0;
            color: #292d38;
        }
    }
    /* croppie
    ****************************************/
    .cr-boundary {
        width: 100% !important;
        background-color: white;
    }
    .croppie-container {
        position: relative;
        width: 100% !important;
        margin: auto;
        .cr-slider-wrap {
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 170px !important;
            z-index: 1;
            right: 0;
            input[type=range] {
                -webkit-appearance: none;
                margin: 0;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            input[type=range]:focus {
                outline: none;
            }
            input[type=range]::-webkit-slider-runnable-track {
                width: 100%;
                height: 3px;
                cursor: pointer;
                animate: 0.2s;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                background: #a7afc6;
                border-radius: 25px;
                border: 0px solid #000101;
            }
            input[type=range]::-webkit-slider-thumb {
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                border: 0px solid #000000;
                height: 9px;
                width: 9px;
                border-radius: 7px;
                background: #292d38;
                cursor: pointer;
                -webkit-appearance: none;
                margin-top: -3.5px;
            }
            input[type=range]:focus::-webkit-slider-runnable-track {
                background: #a7afc6;
            }
            input[type=range]::-moz-range-track {
                width: 100%;
                height: 3px;
                cursor: pointer;
                animate: 0.2s;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                background: #a7afc6;
                border-radius: 25px;
                border: 0px solid #000101;
            }
            input[type=range]::-moz-range-thumb {
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                border: 0px solid #000000;
                height: 9px;
                width: 9px;
                border-radius: 7px;
                background: #292d38;
                cursor: pointer;
            }
            input[type=range]::-ms-track {
                width: 100%;
                height: 12.8px;
                cursor: pointer;
                animate: 0.2s;
                background: transparent;
                border-color: transparent;
                border-width: 9px 0;
                color: transparent;
            }
            input[type=range]::-ms-fill-lower {
                background: #a7afc6;
                border: 0px solid #000101;
                border-radius: 50px;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
            }
            input[type=range]::-ms-fill-upper {
                background: #a7afc6;
                border: 0px solid #000101;
                border-radius: 50px;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
            }
            input[type=range]::-ms-thumb {
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                border: 0px solid #000000;
                height: 9px;
                width: 9px;
                border-radius: 7px;
                background: #292d38;
                cursor: pointer;
            }
            input[type=range]:focus::-ms-fill-lower {
                background: #a7afc6;
            }
            input[type=range]:focus::-ms-fill-upper {
                background: #a7afc6;
            }
        }
        .cr-viewport,
        .cr-resizer {
            border-width: 0px !important;
            box-shadow: 0 0 2000px 2000px rgba(69, 75, 93, 0.9) !important;
            top: -10px !important;
            cursor: move;
        }
    }
}
#profile-page-tab .vr-list {
    label {
        margin-bottom: 0;
    }
}
.rebranding {
    .regulations-modal-content p {
        margin: 0 0 20px;
    }
    .v--modal-overlay {
        background-color: rgba(49,53,64,0.9) !important;
    }
    .v--modal-box.v--modal {
        height: 450px !important;
        padding-bottom: 50px;
        box-sizing: content-box !important;
        &-box {
            overflow: initial !important;
        }
    }
    .ps__scrollbar-y-rail {
        width: 4px !important;
        background-color: #454b5d !important;
        border-radius: 5px;
        margin-top: 5px;
        opacity: 0.6 !important;
        &:hover {
            width: 4px !important;
        }
    }
    .ps>.ps__scrollbar-y-rail>.ps__scrollbar-y {
        background-color: #a7afc6 !important;
        right: 1px !important;
        border-radius: 0 !important;
        width: 2px !important;
        &:hover {
            width: 2px !important;
        }
    }
}
.appearance-settings {
    .positioned {
        @media (max-width: 767px) {
            text-align: center !important;
        }
    }
}
