.room-page-branded {
    padding-top: 0;
}
.new-webinar-room {
    display: block;
    padding-top: 0!important;


    @media (min-width: 1500px) {

        .container {
            width: 1400px;
        }

    }

    * {
        box-sizing: border-box;
    }

    *:before,
    *:after {
        box-sizing: border-box;
    }

    footer.page-row.page-footer {
        display: none;
    }

    .page-row-expanded {
        //margin-bottom: 50px;
    }

    .page-row {
        display: block;
    }

    .page-middle {
        height: 100%;
    }

}
.buy-addons {

    &-popup {
        display: table;
        width: 100%;
        height: 100%;
    }

    &-middle {
        display: table-cell;
        position: relative;
        text-align: center;
        vertical-align: middle;
        padding: 15px 30px;

        h4 {
            font-family: $font-roboto-medium;
            margin: 30px 0;
        }

        svg {
            width: 100px;
            height: 100px;
            stroke-width: 2px;
            color: #6ebe3b;
        }

    }

}