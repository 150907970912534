.addnew-wrap {
    display:block;
    margin:0 0 20px;
    position:relative;
}
.addnew {
    color:$color-muted;
    text-align:center;
    padding:9px;
    box-sizing:border-box;
    position:relative;

    border-radius:2px;
    background:#f7f7f7;
    border:1px dashed #d3d5d9;

    .dsp-table {
        height:100%;
    }

    .addnew-ico {
       width:46px;
       height:46px;
       display:block;
       margin:5px auto;
    }

    a:hover & {
        border-color:transparent;
        background-color:#fff;

        // border-color:#72c82e;
        // color:#72c82e;
    }
}


.addnew-wrap.small {

    .addnew,
    .addnew-info {
        display:inline-block;
        vertical-align:middle;
    }

    .addnew {
        width:54px;
        height:34px;
    }

    .addnew-ico {
        width:12px;
        height:12px;
        margin:0 auto;
    }

    .addnew-info {
        margin-left:8px;
    }
}


.addnew-wrap.medium .addnew {
    font-size:18px;
}


.addnew-transparent {
    .addnew,
    a:hover .addnew {
        background:none;
    }
}


.addnew-invert {
    .addnew {
        color:#cfcfcf;
        background:#fff;
        border-color:#e3e3e3;
    }
    .addnew-ico {
        opacity:.5;
    }
    a:hover .addnew-ico {
        opacity:1;
    }
}


.addnew-success {

    .addnew {
        border:0;
        color:#fff;

        outline:1px dashed rgba(255,255,255,.4);
        outline-offset:-8px;
        // background:#6ebe3b;
        // background:-webkit-linear-gradient(top,  #79cb45 0%,#6ebe3b 100%);
        // background:linear-gradient(to bottom,  #79cb45 0%,#6ebe3b 100%);

        &:before {
            content:'';
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            outline:1px dashed rgba(0,0,0,.25);
            outline-offset:-9px;
        }
    }

    .addnew-ico {
        color:inherit;
    }

    a:hover .addnew {
        color:#fff;
        // background:#6ebe3b;
        // background:-webkit-linear-gradient(top,  #79cb45 0%,#6ebe3b 100%);
        // background:linear-gradient(to bottom,  #79cb45 0%,#6ebe3b 100%);
    }
}



.addnew-wrap.pos-l {
    position:absolute;
    right:100%;
    top:0;
}
.addnew-wrap.pos-r {
    position:absolute;
    left:100%;
    top:0;
}
.addnew-wrap.pos-r .addnew {
    box-shadow:inset 2px 0 10px -4px rgba(0,0,0,.6);
    border-bottom-left-radius:0;
    border-top-left-radius:0;
}
.addnew-wrap.pos-l .addnew {
    box-shadow:inset -2px 0 10px -4px rgba(0,0,0,.6);
    border-bottom-right-radius:0;
    border-top-right-radius:0;
}

.addnew-wrap .helper.pos-bl3 {
   margin-left:90px;
   width:170px;
   top:45px;
}