.webinar-banner {
    background-attachment: scroll;
    //background-image: url(/account_panel/images/webinar-banners/ken-molay/bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    height: 250px;
    width: 100%;
    display: table;
    padding: 40px 0px;
    
    .cell {
        display: table-cell;
        vertical-align: middle;
    }
    
    .label {
        display: inline-block;
        padding: 10px 15px 10px 117px;
        font-family: 'robotolight';
        font-style: italic;
        font-size: 16px;
        margin: 0px;
        line-height: 1;
        text-transform: none;

        svg {
            position: absolute;
            max-width: 140px;
            height: 28px;
            left: -12px;
            top: 4px;
        }
        
    }
    
    h2 {
        font-size: 40px;
        color: #6ebe3b;
        margin-bottom: 25px;
        margin-top: 10px;
        width: 470px;
    }
    
    p {
        font-family: 'robotolight';
        font-size: 16px !important;
        margin: 15px 0px !important;
        color: #7e7e7f !important;
        
        b {
            font-family: 'robotoregular';
            color: #fff;
        }
        
        .date {
            background: url(/account_panel/images/webinar-banners/clock.png) no-repeat center left;
            padding-left: 20px;
            display: inline-block;
            
            b {
                color: #6ebe3b;
            }
        }
        
        .separation{
            margin: 0px 10px;
            color: #e0e1da;
        }

    }
    
    .btn {
        font-size: 18px;
        margin-right: 15px;
        padding: 10px 30px
    }
    

    .btn-outline {
        color: inherit;
        background-color: transparent;
        background-image: none;
        box-shadow: none;
        -webkit-transition: all .3s ease-in-out;
           -moz-transition: all .3s ease-in-out;
             -o-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
    }

    .btn-success.btn-outline {
        color: #6ebe3b;
        border: 1px solid #6ebe3b;
    }

    .btn-success.btn-outline:hover,
    .btn-success.btn-outline:focus {
        color: #fff;
    }
    
    img {
        vertical-align: middle;
        height: 250px;
    }
    
    * {
      box-sizing: border-box; }

    *:before,
    *:after {
      box-sizing: border-box; }

    .center-block {
      display: block;
      margin-left: auto;
      margin-right: auto; }
    
    .container {
      margin-right: auto;
      margin-left: auto;
      padding-left: 60px;
      padding-right: 60px; }
      .container:before, .container:after {
        content: " ";
        display: table; }
      .container:after {
        clear: both; }

    .row {
      margin-left: -15px;
      margin-right: -15px; }
      .row:before, .row:after {
        content: " ";
        display: table; }
      .row:after {
        clear: both; }
      
    .col-sm-6 {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      float: left;
      width: 50%; }
    .col-sm-img {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      float: left;
      width: 35%; }
    .col-sm-content {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      float: left;
      width: 65%; }

}