@import '../../../../../shared/web/views/src/scss/mixin/_gradient_linear';
@import '../../../../../shared/web/views/src/scss/mixin/_list_init';
@import '../../../../../shared/web/views/src/scss/mixin/svg_size';
@import '../../../../../shared/web/views/src/scss/mixin/_button_default_colorize';

@mixin app-ico-sprite($app-name, $bg:null, $bg-connected:null, $additional:null) {
    .app-#{$app-name} .app-ico {
        @if $bg {
            background-position:$bg;
        } @else {
            @extend %app-ico-fontico;
        }

        @each $prop, $value in $additional {
            #{$prop}: $value;
        }
    }
    .app-connected.app-#{$app-name} .app-ico {
        @if $bg {
            background-position:$bg-connected;
        } @else {
            @extend %app-ico-fontico-connected;
        }
    }
}

@mixin alignCenterContent($isColumn) {
    display: flex;
    align-items: center;
    @if ($isColumn) {
        flex-direction: column;
    }
}