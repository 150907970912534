/* [ forms ] */
.hide-check {
    position: absolute;
    visibility: hidden;
}
.check {
    position: absolute;
    z-index: 2;
    top: 2px;
    left: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #fff;
    overflow: hidden;
    border-radius: 2px;
    box-shadow: inset 1px 1px 0 #f7f7f7, 0 1px 0 rgba(255, 255, 255, 0.75);
    border: 1px solid rgba(0, 0, 0, 0.13);
}
.check:before {
    content: "q";
    /*color:#6ebf3f;*/
    text-align: center;
    font-size: 11px;
    display: none;
    position: relative;
    top: 1px;
}
.hide-check:checked + label .check:before {
    display: block;
}
.hide-check + label {
    padding-left: 25px;
    position: relative;
}
/* checkbox z preawej strony */
.check-right .check {
    right: 0;
    left: auto;
}
.check-right .hide-check + label {
    padding-left: 0;
    padding-right: 25px;
}
.radio {
    border-radius: 16px;
}
.radio:before {
    content: "";
    /*background:#6ebf3f;*/
    border-radius: 7px;
    width: 7px;
    height: 7px;
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="search"],
textarea,
.select {
    width: 100%;
    display: block;
    cursor: text;
    background: #fff;
    border-radius: 2px;
    box-shadow: inset 1px 1px 0 #f7f7f7, 0 1px 0 rgba(255, 255, 255, 0.75);
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-sizing: border-box;
}
input[type="text"],
input[type="number"],
input[type="password"],
input[type="search"],
textarea,
.select,
select,
.form-series-input {
    color: #555;
}
input[type="text"],
input[type="number"],
input[type="password"],
input[type="search"],
textarea,
select,
.form-series-input {
    padding: 0.3em 0.8em;
    height: 34px;
}
select {
    height: 32px;
}
textarea {
    min-height: 96px;
}
.code textarea {
    color: #606365;
    font-family: $font-monospace;
    font-size: 12px;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/*input:focus,
    textarea:focus,
    .select:focus {
        border-color:#6ebe3b;
    }*/
.select {
    position: relative;
    overflow: hidden;
    box-shadow: none;
    &:after {
        content: "";
        pointer-events: none;
        background-repeat: no-repeat;
        background-position: 9px 50%;
        display: block;
        width: 26px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transition: all 0.5s ease-in-out;
        z-index: 1;
    }
    &.dropdown {
        overflow: visible;
        &.show {
            border-color: #6ebe3b;
            z-index: 3;
            &:after {
                transform: rotate(180deg);
            }
        }
        .btn {
            background: #fff;
            border: 1px solid transparent;
            border-radius: inherit;
            padding: 6px 2em 6px 0.8em;
            width: 100%;
            font-size: inherit;
            line-height: normal;
            text-align: left;
            white-space: nowrap;
            color: #555;
            overflow: hidden;
            height: 34px;
            text-overflow: ellipsis;
            &:after {
                background: #fff;
                position: absolute;
                right: 0;
                top: 50%;
                width: 26px;
                height: 26px;
                border: none;
                transform: translateY(-50%);
            }
        }
        .dropdown-menu {
            background: #fff;
            font-size: inherit;
            left: -1px !important;
            right: -1px !important;
            margin-top: -35px;
            margin-bottom: -35px;
            padding: 0;
            border: 1px solid #6ebe3b;
            border-radius: 2px;
            overflow: hidden;
            z-index: 0;
            .dropdown-item {
                padding: 6px 0.8em;
                color: #555;
                &:hover {
                    background: #fafbfd;
                    color: #5c6273;
                }
            }
        }
        .dropdown-menu-v3 {
            max-height: 300px;
            overflow-y: auto;
            .dropdown-item.disabled {
                opacity: 1;
                position: sticky;
                top: 0;
                background-color: #fff;
                color: rgba($color: #555, $alpha: 0.5);
            }
        }
    }
}
.timezone .select {
    width: 205px !important;

    &:after {
        right: 1px;
    }
}
.select2-results .select2-disabled {
    display: none !important;
}

.select,
select {
    cursor: pointer;
}
.select select {
    display: block;
    width: 114%;
    appearance: none;
}

input[disabled],
textarea[disabled],
select[disabled],
.hide-check[disabled] + label,
.select-disabled {
    cursor: not-allowed;
}

input[disabled],
textarea[disabled],
select[disabled],
.hide-check[disabled] + label .check,
input[readonly],
textarea[readonly],
.hide-check[readonly] + label .check,
.select-disabled,
.select-disabled:after {
    background-color: #f5f5f5;
}

.field-required {
    display: none;
    font-size: 12px;
}

.form-group {
    margin: 0 0 25px;
}
.form-control {
    margin: 5px 0;

    @import "./__partials/hyperlink";
    @include hyperlink;
}
.form-control.label-copy {
    .label-default {
        cursor: pointer;
        &:hover {
            background: #6ebe3b;
        }
    }
}
.f-holder {
    margin: 0;
}
.form-label small {
    color: #606365;
    font-size: 12px;
    font-style: italic;
}

.f-short {
    width: 90px;
}
.f-holder.f-short {
    display: inline-block;
}
.f-medium {
    width: 160px;
}
.f-long {
    width: 320px;
}

.lb-holder,
.f-holder {
    position: relative;
}
.form-action {
    text-align: center;
}
.form-action .form-control {
    margin: 13px 0 30px;
}
.form-action label {
    color: #606365;
    font-size: 12px;
}
.form-action label .check {
    top: 0;
}
.expand .form-action .btn {
    padding: 9px 22px;
    min-width: 154px;
}
.form-action dfn {
    font-size: 12px;
    display: block;
    margin: 18px 0 10px;
    &.total-price {
        font-size: 10px;
        margin: 0;
    }
}
fieldset h5 {
    color: #363a3d;
    margin: 0 0 15px;
    letter-spacing: 0;
}
fieldset .lead {
    font-size: 14px;
}

/* [ switch on/off ] */
.switch {
    display: inline-block;
    height: 34px;
    line-height: 34px;
    vertical-align: middle;
    position: relative;
    margin: 0;
}
.switch .slider-toggle {
    opacity: 0;
    position: absolute;
}
.switch .slider-viewport {
    display: block;
    height: 34px;
    width: 67px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 26px;
}
.switch .slider-btn,
.switch .slider-bd,
.switch .slider {
    transition: all 200ms ease-in-out;
}
.switch .slider-btn,
.switch .slider-bd {
    cursor: pointer;
}
.switch .slider {
    width: 200%;
    height: 100%;
    position: relative;
}
.switch .slider-btn {
    position: absolute;
    display: block;
    margin: 0;
    padding: 0;
    top: 4px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
}
.switch .slider-bd {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    float: left;
    height: 100%;
    width: 67px;
    top: 10px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.26);
    border-radius: 26px;
}
.switch .left {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.23);
    /*    background:#6ebe3b;*/
}
.switch .right {
    color: #6e7072;
    text-shadow: 0 1px 0 #fff;
    background: #f7f7f7;
}
.switch .slider-bd span {
    line-height: 34px;
    margin-left: 10px;
    float: left;
}
.switch .right span {
    float: right;
    margin: 0 6px 0 0;
}
.switch .slider-toggle + .slider-viewport > .slider {
    left: -100%;
}
.switch .slider-toggle + .slider-viewport .slider-btn {
    left: 72px;
}
/*.switch .slider-toggle + .slider-viewport .slider-bd {width:67px}*/
.switch .slider-toggle + .slider-viewport .left {
    margin-left: 0;
}
.switch .slider-toggle:checked + .slider-viewport > .slider {
    left: 0;
}
.switch .slider-toggle:checked + .slider-viewport .slider-btn {
    left: 37px;
}
.switch-ml {
    margin-left: 10px;
}

/* switch small size */
.switch-small {
    height: 25px;
    line-height: 25px;
}
.switch-small .slider-viewport {
    height: 25px;
    width: 49px;
    border-radius: 26px;
}
.switch-small .slider-btn {
    top: 3px;
    width: 18px;
    height: 18px;
}
.switch-small .slider-bd {
    font-size: 9px;
    width: 49px;
    top: 10px;
    border-radius: 18px;
}
.switch-small .slider-bd span {
    line-height: 26px;
    margin-left: 7px;
}
.switch-small .right span {
    margin-right: 4px;
}
.switch-small .slider-toggle + .slider-viewport .slider-btn {
    left: 53px;
}
.switch-small .slider-toggle:checked + .slider-viewport .slider-btn {
    left: 28px;
}

/* switch x-small size */
.switch-x-small {
    height: 16px;
    line-height: 16px;
}
.switch-x-small .slider-viewport {
    height: 16px;
    width: 33px;
    border-radius: 8px;
}
.switch-x-small .slider-btn {
    top: 2px;
    width: 12px;
    height: 12px;
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.1),
        0 1px 0 rgba(0, 0, 0, 0.1);
}
.switch-x-small .slider-bd {
    font-size: 8px;
    width: 33px;
    top: 10px;
    border-radius: 18px;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
}
.switch-x-small .slider-bd span {
    line-height: 16px;
    margin-left: 5px;
}
.switch-x-small .right span {
    margin-right: 4px;
}
.switch-x-small .slider-toggle + .slider-viewport .slider-btn {
    left: 35px;
}
.switch-x-small .slider-toggle:checked + .slider-viewport .slider-btn {
    left: 19px;
}

/* [ switch action:add/remove ] */
.switch-action label {
    line-height: 20px;
    text-align: center;
    padding: 0;
    margin: 0;
}
.switch-action .action-delete [data-icon] {
    color: $color-error;
}
.switch-action .action-add [data-icon] {
    color: $color-success;
    font-size: 0.96em;
}
.switch-action.small [data-icon] {
    font-size: 0.85em;
}
.switch-action .hide-check + label .action-delete {
    display: none;
}
.switch-action .hide-check + label .action-add {
    display: block;
}
.switch-action .hide-check:checked + label .action-delete {
    display: block;
}
.switch-action .hide-check:checked + label .action-add {
    display: none;
}
.switch-action.medium [data-icon] {
    font-size: 1.3em;
}
.switch-action.medium [data-icon]:before {
    top: 0;
}
.switch-action.pos-br {
    position: absolute;
    bottom: 8px;
    right: 30px;
}

/* grupy input+ico */
.input-group.pos-right input[type="text"],
.input-group.pos-right input[type="number"],
.input-group.pos-right input[type="password"],
.input-group.pos-right input[type="search"],
.input-group.pos-right textarea {
    padding-right: 40px;
}
.input-group.pos-left input[type="text"],
.input-group.pos-left input[type="number"],
.input-group.pos-left input[type="password"],
.input-group.pos-left input[type="search"],
.input-group.pos-left textarea {
    padding-left: 40px;
}
.input-group-addon {
    font-size: 20px;
    text-align: center;
    min-width: 40px;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    svg {
        color: $color-muted;
    }
}
.input-group-addon.label {
    font-size: inherit;
    line-height: 24px;
    padding: 0 0.7em;
    margin: 0 0.4em;
}
.input-group-addon.label-default {
    background: #c2c2c2;
}
.input-group-addon[data-icon]:before {
    line-height: 1;
}
.input-group.pos-right .input-group-addon {
    right: 0;
}
.input-group.pos-left .input-group-addon {
    left: 0;
}
.input-group.pos-br .input-group-addon {
    right: 0;
    margin: 0 10px 0 0;
    top: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    transform: translateY(0);
}

.code .input-group-addon.label {
    font-size: 10px;
    line-height: 19px;
    min-width: 27px;
}

/*.input-group-addon[data-icon='p'],
    .input-group-addon[data-icon='v'] {
        color:#66b835;
    }
    .input-group-addon[data-icon='q'] {
        color:#6fbf3b;
    }*/
.input-group-addon[data-icon="\2022"] {
    color: #fff;
    font-size: 14px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.6);
    min-width: 22px;
    height: 22px;
    margin-right: 7px;
}
.input-group-addon[data-icon="q"],
.input-group-addon[data-icon="H"],
.f-search .input-group-addon[data-icon="H"] {
    font-size: 17px;
}
.input-group .btn {
    padding: 0;
    margin: 5px;
    width: 25px;
    color: #fff;
}
.small + .input-group-addon {
    font-size: 16px;
}
.input-group-addon.btn[data-icon="q"] {
    font-size: 12px;
}

/* kiedy na inpucie jest preloader ukrywany pozostałe ikony */
.f-holder[data-loading="true"] .input-group-addon[data-icon]:before,
.f-holder[data-loading="true"] .input-group-addon svg {
    visibility: hidden;
}

/* has icon */
.has-icon {
    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    textarea {
        padding-left: 30px;
    }
}
.form-control-icon {
    position: absolute;
    width: 30px;
    height: 34px;
    left: 0;
    color: #a7afc6;

    svg {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        margin-top: -10px;
    }
}

/* float label */
.float-label {
    color: #c2c2c2;
    position: absolute;
    z-index: 2;
    left: 10px;
    top: 6px;
    padding: 0 3px;
    opacity: 0;
    visibility: hidden;
    display: inline-block;
}
.float-label:before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    top: 6px;
    left: 0;
    position: absolute;
    background: #fff;
    z-index: -1;
}
.lb-holder .float-label {
    font-size: 9px;
    opacity: 1;
    visibility: visible;
    transform: translateY(-12px);
}

/* field series */
.form-series-item {
    text-align: center;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 34px;
}
.form-series-item:first-child {
    margin: 0;
}
.form-series-hd {
    color: #737373;
    font-size: 10px;
    display: block;
}
.form-series-bd {
    position: relative;
    margin: 14px 0;
}
.form-series-btn {
    cursor: pointer;
    position: absolute;
    display: block;
    width: 100%;
    line-height: 1;
}
.form-series-btn.pos-tc {
    bottom: 100%;
}
.form-series-btn.pos-bc {
    top: 100%;
}
.form-series-btn [data-icon*="caret-bottom"] {
    border-top-color: #dcdcdc;
}
.form-series-btn [data-icon*="caret-top"] {
    border-bottom-color: #dcdcdc;
}
/* @ skins
    .form-series-btn:hover [data-icon*='caret-bottom'] {
        border-top-color:#6ebe3b;
    }
    .form-series-btn:hover [data-icon*='caret-top'] {
        border-bottom-color:#6ebe3b;
    }*/
.form-series-wrap {
    /*width:100%;
        display:block;*/
    display: inline-block;
    cursor: text;
    background: #fff;
    border-radius: 2px;
    box-shadow: inset 1px 1px 0 #f7f7f7, 0 1px 0 rgba(255, 255, 255, 0.75);
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-sizing: border-box;
}
.form-series {
    display: inline-block;
    position: relative;
    border-left: 1px solid rgba(0, 0, 0, 0.13);
}
.form-series:first-child {
    border-left: 0;
}
.form-series-input {
    padding: 0.2em 0.3em;

    text-align: center;
    width: 34px;
}
.form-series-stop .form-series-addon {
    display: inline-block;
    margin: 0 5px 0 3px;
}
.form-series-stop .dsp-cell:last-child .form-series-addon {
    margin-right: 0;
}

.form-series-medium .form-series-input {
    font-size: 18px;
}
.form-series-small .form-series-input {
    height: 23px;
}

/* [ form inline ] */
.form-inline .form-group {
    display: table;
    margin: 15px 0;
}
.form-inline .table-wide {
    width: 100%;
}
.form-inline .table-wide .btn-wrap {
    width: 20px;
    white-space: nowrap;
}
.form-inline .form-group .form-control,
.form-inline .form-group .form-label,
.form-inline .form-group .btn-wrap {
    display: table-cell;
    vertical-align: middle;
    margin: 0;
    padding: 0 15px 0 0;
    box-sizing: border-box;
}
.form-inline .form-group .error-paid-fix .error {
    position: absolute;
}
.form-inline .form-group > *:last-child {
    padding-right: 0;
}
@media (max-width: 991px) {
    #schedule_event {
        .form-inline .form-group {
            display: block;
        }
        .form-inline .form-group .form-control,
        .form-inline .form-group .form-label,
        .form-inline .form-group .btn-wrap {
            display: block;
            text-align: center;
            padding: 0 0 10px;
        }
        .form-inline .form-group .form-control .f-holder {
            margin: auto;
        }
    }
}
.phone-country {
    .hide-check:checked + label {
        font-weight: bold;
    }
}
.form-inline .form-group.phone-country {
    .form-control {
        vertical-align: top;
        padding: 0;
        width: 730px;

        .f-holder {
            display: inline-block;
            padding-left: 50px;
            width: 190px;
            text-align: left;
        }
    }

    .hide-check:checked + label {
        font-weight: bold;
    }

    .hide-check + label {
        padding-left: 35px;
    }
}

.phone-access {
    padding: 5px 5px 5px 10px;
    display: inline-block;
    vertical-align: middle;
    width: 190px;

    .svg-stroke {
        stroke-width: 1;
        width: 18px;
        height: 18px;
    }
}
.form-inline .form-legend {
    font-size: 22px;
    margin: 0 0 10px 0;
}
.form-inline .form-legend + .form-group {
    margin-top: 8px;
}
.nested .form-inline .form-label {
    padding: 0 3px;
}

/* [ form-inline position ] */
.form-inline-right .form-group {
    margin-right: 0;
    margin-left: auto;
}
.form-inline-center .form-group {
    margin-right: auto;
    margin-left: auto;
}

/* [ form inline-block ] */
.form-inline-block {
    display: table;
    margin: 0;
}
.form-inline-block > .form-group {
    display: table-cell;
    vertical-align: top;
    padding: 0 15px 0 0;
    box-sizing: border-box;
}
.form-inline-block .form-group:last-child {
    padding-right: 0;
}
.form-inline-block .form-control {
    margin: 5px 0 0;
}
.form-inline-block.table-wide {
    width: 100%;
}
.form-inline-block .form-inline .form-group {
    margin: 0;
}
.form-inline .form-group .f-holder-all-to-right {
    display: flex;
    justify-content: flex-end;
}

/* [ form horizontal ] */
.form-horizontal .form-legend {
    /*color:#6ebe3b;*/
    font-size: 22px;
    /*margin:0 0 15px 140px;*/
    margin: 0 0 8px 140px;
    @media (max-width: 768px) {
        margin-left: 0 !important;
    }
}
.form-horizontal .form-group {
    margin: 15px 0;
}
.form-horizontal .form-action {
    margin-bottom: 15px;
}
.form-horizontal .form-label {
    text-align: right;
    float: left;
    margin: 6px 0 0;
    width: 130px;
    @media (max-width: 768px) {
        text-align: left;
        float: none;
        width: auto !important;
    }
}
.form-horizontal .caption {
    display: block;
    margin: 4px 0 0;
}
.form-horizontal .form-label small {
    display: block;
}
.form-horizontal .form-control {
    display: block;
    position: relative;
    /* must be */
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 140px;
    @media (max-width: 768px) {
        margin-left: auto !important;
    }
}
.form-horizontal .form-inline .form-group {
    margin: 0;
}
.form-horizontal .form-inline .form-label {
    width: auto;
    float: none;
}

/* required */
.required .form-label,
.required .f-holder {
    position: relative;
}
.required .form-label:before,
.required .f-holder:before {
    content: "*";
    /*color:#6ebe3b;*/
    font-family: $font-monospace;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    position: absolute;
    left: -15px;
    top: 7px;
    vertical-align: middle;
}
.required .f-holder.f-holder--no-star:before {
    display: none;
}
.required .form-label:before {
    top: 1px;
}
.required .form-label + .form-control .f-holder:before {
    display: none;
}
.form-horizontal .required .form-label:before {
    position: static;
    margin-right: 4px;
}
.form-group.disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
}
.required .form-required .f-holder:before,
.form-required.required .f-holder:before {
    top: 1px;
}

/* size */
input.small,
textarea.small,
select.small {
    font-size: 12px;
    /*padding:.1em .5em;*/
    height: 25px;
}
select.small {
    height: 23px;
    line-height: 0.8em;
}
.input-group .small + .btn {
    min-width: 18px;
    width: 18px;
}
.input-group .small + .btn[data-icon="q"] {
    line-height: 18px;
    font-size: 10px;
    margin: 0;
    right: 5px;
}
input.medium,
textarea.medium,
select.medium {
    font-size: 16px;
}

input.large,
textarea.large,
select.large {
    font-size: 18px;
    height: 40px;
}
select.large {
    height: 38px;
}

/* form errors */
form label.error,
form p.error,
form div.error,
.img-wrap .error,
.avatar-wrap .error {
    display: none !important;
}
.f-error {
    position: relative;
}
.f-error label.error,
.f-error p.error,
.f-error div.error {
    color: #fff;
    font-size: 11px;
    text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
    background: $color-error;
    padding: 3px 4px;
    display: inline-block !important;
    margin: 0;
    opacity: 1;
    z-index: 10;
    border-radius: 2px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-out;
}
.f-error input:focus + .error,
.f-error textarea:focus + .error,
.f-error .error:target {
    opacity: 0;
    visibility: hidden;
}
.f-error input.small + .error,
.f-error textarea.small + .error {
    padding: 1px 4px;
}
.f-error input.large + .error,
.f-error textarea.large + .error {
    font-size: 13px;
    padding: 5px 7px;
}
.f-error input[type="text"],
.f-error input[type="password"],
.f-error input[type="email"],
.f-error textarea,
.f-error .select,
.f-error .select.dropdown .dropdown-menu,
.f-error .check,
.f-error .select2-container .select2-choice,
.f-invalid input[type="text"],
.f-invalid input[type="password"],
.f-invalid input[type="email"],
.f-invalid input[type="number"],
.f-invalid textarea,
.f-invalid .select,
.f-invalid .check,
.f-error.form-series-wrap {
    border-color: $color-error;
}
.f-error .hide-check + label,
.f-error .float-label,
.f-error .form-control-icon {
    color: $color-error;
}
.f-error .field-required {
    display: block;
    color: $color-error;
}
.f-error-small label.error-small,
.f-error-small p.error-small,
.f-error-small div.error-small {
    color: $color-error;
    padding: 0;
    background: transparent;
    top: 100%;
    transform: translateY(0);
}
.f-error-small .error-small.pos-br {
    right: 0;
}
.f-error-small .error-small.pos-bl {
    right: 0;
    left: 0;
}
.f-error-small .error-small.pos-bc {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
}

.form-control__button--append-inner {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 7px;
}

.invalid-feedback {
    .error {
        display: block;
        background-color: $color-error;
        border-radius: 3px;
        padding: 2px 5px;
        margin: -25px 0 5px;
        font-size: 12px;
        color: #fff;
    }
    &.private-label {
        .error {
            margin: 0 0 15px;
        }
    }
}

/* validacja pol w locie */
.f-invalid .input-group-addon {
    color: $color-error;
}

.f-invalid [for="event_details_password"] {
    top: 30%;
}

/* wyszukiwarka w tabelkach */
.f-search .f-holder {
    min-height: 20px;
    min-width: 50px;
}
.f-search .input-group-addon,
.filter .input-group-addon {
    cursor: pointer;
}
.f-search .input-group-addon[data-icon="p"],
.f-search .input-group-addon[data-icon="H"],
.filter .input-group-addon[data-icon="H"] {
    color: #c2c2c2;
}
.table .f-search input[type="text"] {
    position: absolute;
    top: 0;
    right: 0;
}
.f-search input[type="text"] {
    border-radius: 15px;
    box-shadow: none;
    transition: all 0.5s;
}
.f-search input[type="text"].small {
    line-height: 20px;
    height: 20px;
    width: 80px;
}
.f-search input[type="text"].small:focus {
    width: 143px;
}
.f-search .pos-right input[type="text"].small {
    padding: 0.1em 1.5em 0.1em 0.5em;
}
.f-search input[type="text"].small + .input-group-addon[data-icon="p"] {
    font-size: 14px;
    min-width: 25px;
}
.f-search input[type="text"].small + .input-group-addon[data-icon="H"] {
    font-size: 8px;
    min-width: 25px;
}
.f-search,
.filter {
    .ico-search {
        @include x-svg-size(18);
        //color:#c2c2c2;
        top: -2px;
    }

    .small + .input-group-addon {
        min-width: 25px;
    }

    .small + .input-group-addon .ico-search {
        @include x-svg-size(10);
        top: -1px;
    }
}

/* [ list checkboxes row ] */
.list-check .form-group {
    margin: 10px 0;
    display: block;
}

/* [ list checkboxes inline-col  ] */
.list-check-cols .form-group {
    margin: 25px 0 15px;
}
.list-check-cols .form-control {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin: 0 1% 10px 0;
}

/* [ list checkboxes inline  ] */
.list-check-inline .form-group {
    margin: 25px 0 15px;
}
.list-check-inline .form-control {
    display: inline-block;
    vertical-align: top;
    margin: 0 20px 10px 0;
}

/* [ TimezonePicker and DatatimePicker  ] */
.access-type-password__form {
    label {
        margin-bottom: 2px;
        color: #737373;
    }
    input {
        font-size: 16px;
        color: #737373;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        box-shadow: none;
    }
}
