.sidebar {
    
    &-tiles {
        position: fixed;
        display: block;
        width: 85px;
        left: 0;
        top: 20px;
        bottom: 20px;
        z-index: 19999;
        transition: all .3s ease-in-out;
        
        &-hidden {
            left: -85px;
            
            .sidebar-tiles-btn {
                width: 20px;
                height: 36px;
                margin-top: -18px;
                right: -15px;
                
                &:hover {
                    right: -18px;
                    width: 20px;
                }
                
                svg {
                    width: 16px;
                    height: 16px;
                    margin-top: -8px;
                    transform: rotate(180deg);
                }
                
            }

            .notification {
                display: block !important;
            }
            
        }
        
        .navbar-tiles {
            background: #e2e3ea;
            padding: 0;
            margin: 0;
            box-shadow: 0px 12px 38px 0px rgba(16, 36, 95, 0.17);
            
            li {
                background: #fff;
                position: relative;
                display: table;
                list-style: none;
                height: 85px;
                width: 100%;
                border-bottom: 1px solid #e2e3ea;
                
                &:last-child {
                    border-bottom: none;
                }
                
                &.subaccounts {

                    &:hover {

                        .list-subaccounts {
                            display: block;
                            top: -2px;
                            left: 85px;
                            z-index: 2;
                        }

                    }

                }
                
                &.xlivechat {
                    
                    &-expand-right {
                        transform:translateX(0px);
                        transition:transform 0.3s;

                        &.hover {
                            transform:translateX(85px);
                        }

                    }
                    
                }
                
                .livechat-lang {
                    position: absolute;
                    right: 85px;
                    top: 0;
                    bottom: 0;
                    margin: 0;
                    width: 100%;
                    padding: 20px 10px;
                    box-sizing: border-box;
                    text-transform: uppercase;
                    font-size: 9px;
                    color: #a7afc6;
                }
                
                .tile {
                    display: table-cell;
                    vertical-align: middle;
                    position: relative;
                    padding: 5px;
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 9px;
                    color: #a7afc6;
                    transition: all .3s ease-in-out;
                    z-index: 1;
                    
                    &.subaccounts {
                        z-index: 2;
                        
                        &:hover {
                            
                            .list-subaccounts {
                                display: block;
                                left: 85px;
                                
                            }
                            
                        }
                        
                    }
                    
                    svg {
                        display: block;
                        margin: 0 auto 5px;
                        width: 30px;
                        height: 30px;
                        stroke-width: 1.2;
                        color: #6ebe3b;
                        transition: all .3s ease-in-out;
                    }
                    
                    .title {
                        display: block;
                        color: #a7afc6;
                    }
                    
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        transition: all .3s ease-in-out;
                        z-index: -1;
                    }
                    
                    @media screen\0 {
                        &:before {
                            height: 85px;
                        }
                    }
                    
                    &.active {
                        z-index: 0;
                    }
                    
                    &.active,
                    &:hover {
                        color: #fff;
                        
                        .title, 
                        svg {
                            color: #fff;
                        }
                        
                        &:before {
                            background-color: #83c952;
                            background-image: -webkit-linear-gradient(top, #83c952 0%, #58ad2b 100%);
                            background-image:      -o-linear-gradient(top, #83c952 0%, #58ad2b 100%);
                            background-image: -webkit-gradient(linear, left top, left bottom, from(#83c952), to(#58ad2b));
                            background-image:         linear-gradient(to bottom, #83c952 0%, #58ad2b 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff83c952', endColorstr='#ff58ad2b', GradientType=0);
                            filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
                            background-repeat: repeat-x;
                            box-shadow: 0px 12px 35px 0px rgba(16, 36, 95, 0.2);
                            transform: scale(1.05);
                        }
                        
                    }

                    &-notification {
                        position: relative;
                    }

                    .notification {
                        position: absolute;
                        top: 0;
                        right: 15px;
                    }
                    
                }
                
            }
            
        }
        
        &-btn {
            background: #a7afc5;
            position: absolute;
            width: 10px;
            height: 24px;
            top: 50%;
            margin-top: -12px;
            right: -6px;
            border-radius: 2px;
            box-shadow: 0px 4px 14px 0px rgba(94, 107, 143, 0.21);
            transition: all .3s ease-in-out;
            color: #fff;
            z-index: 2;
            
            &:hover {
                background-color: #6ebe3b;
            }
            
            svg {
                position: absolute;
                stroke-width: 1.2;
                right: 0;
                top: 50%;
                width: 10px;
                height: 10px;
                margin-top: -5px;
                color: #fff;
                transition: all .3s ease-in-out;
            }

            .notification {
                position: absolute;
                top: -5px;
                right: -7px;
                display: none;
            }
            
        }

        &.right {
            right: 0;
            left: auto;
            
            @media (max-width: 767px) and (orientation:landscape) {
                top: 5px;
                bottom: calc(100% - 100px);
            }
            @media (max-width: 767px) and (orientation:portrait) {
                top: 70px;
                bottom: calc(100% - 170px);
            }

            &.sidebar-tiles-hidden {
                right: -85px;
                left: auto;

                .sidebar-tiles-btn {
                    left: -15px;
                    right: auto;

                    &:hover {
                        left: -18px;
                    }

                    svg {
                        transform: rotate(0deg);
                    }

                }

            }

            .sidebar-tiles-btn {
                left: -6px;
                right: auto;

                svg {
                    left: 0;
                    right: auto;
                    transform: rotate(180deg);
                }

            }
        }
        .notification {
            background: #ff0000;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            padding: 2px;
            line-height: 10px;
            text-align: center;
            font-family: $font-roboto-medium;
            font-size: 10px;
            color: #fff;
        }
    }
    
    
    &-table {
        display: table;
        height: 100%;
        width: 100%;
        
        &-cell {
            display: table-cell;
            vertical-align: middle;
        }
        
    }

}
