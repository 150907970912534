.ico, .svg-fill, .svg-stroke {
    display:inline-block;
    vertical-align:middle;
    position:relative;
    top:-1px;
}
.svg-fill,
.svg-stroke {
    @include x-svg-size(14);
}
.svg-fill {
    fill:$color-current;
}
.svg-stroke {
    fill:none;
    stroke:$color-current;
    stroke-width:5;
    stroke-miterlimit:10;
}
@for $i from 1 through 5 {
    .svg-stroke-#{$i} {
        stroke-width: #{$i} !important;
    }
}
.ico-left {
    margin-right:8px;
}
.ico-right {
    margin-left:8px;
}