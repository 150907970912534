.modal-webinar-flywheel {
    background: rgba(40, 43, 49, 0.9);

    .img-fluid {
        max-width: 100%;
        height: auto;
    }

    .modal-dialog {
        max-width: 650px;
    }

    .fancybox-close {
        &:before {
            color: #fff;
        }
        @media (max-width: 767px) {
            top: 10px;
            right: 15px;
        }
    }

    .modal-dialog {
        pointer-events: auto;
    }

    .modal-content {
		background: #0c1327;
		border: none;		
		border-radius: 6px;
		box-shadow: none;
		display: block;
		text-decoration: none;
		overflow: hidden;
        &:hover {
            text-decoration: none;
        }
        .img-responsive {
        	margin: auto;
        }
	}

	.modal-body {
        padding: 0;
    }
    .learn-more {
        position: absolute;
        bottom: 35%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        width: 100px;
        height: 25px;
        @media (max-width: 767px) {
            width: 90px;
            height: 20px;
        }
    }
}