.breakout-rooms {
    &-count {
        padding: 0 15px 0 10px;
        margin-left: 7px;
        position: relative;
        white-space: nowrap;
        &:before {
            content: '';
            position: absolute;
            background-color: #e5e5e5;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 8px;
        }
    }
    &-more {
        position: relative;
        padding: 0 15px 0 20px;
        font-size: 12px;
    }
    &-count,
    &-more {
        &:after {
            content: '';
            position: absolute;
            width: 0; 
            height: 0;
            border-style: solid;
            right: 4px;
            top: 50%;
            transform: translateY(-50%);
            margin-right: -2px;
            border-width: 4px 4px 0 4px;
            border-color: #00afec transparent transparent transparent;
        }
        &:hover {
            &:after {
                border-color: #ff800f transparent transparent transparent;
            }
        }
        &.open {
            &:after {
                border-width: 0 4px 4px 4px;
                border-color: transparent transparent #00afec transparent;
            }
            &:hover {
                &:after {
                    border-color: transparent transparent #ff800f transparent;
                }
            }
        }
        &.loader {
            &:after {
                content: '';
                position: absolute;
                width: 10px; 
                height: 10px;
                border: 1px solid rgba(2, 175, 235, 0.4) !important;
                border-top-color: #00afec !important;
                border-radius: 50%;
                right: 4px;
                top: 50%;
                margin-top: -5px;
                animation: loaderBreakoutRooms 1s linear infinite;
            }
        }
    }
    &-item {
        position: relative;
        padding: 8px 0;
        animation: slideUpBreakoutRooms 0.4s ease;
        &:first-child {
            margin-top: 10px;
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 12px;
            height: 22px;
            border-width: 1px;
            border-style: solid;
            border-color: transparent transparent #e5e5e5 #e5e5e5;
        }
        &-desc {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 20px;
            word-break: break-word;
            .cell-desc-small {
                margin-top: 3px;
            }
        }
        &-date,
        &-visitors,
        &-action {
            flex: 1 1 0;
            max-width: 100%;
            width: 100%;
            padding: 0 15px;
        }
        &-action {
            padding-right: 0;
            .cell-action-icons {
                white-space: nowrap;
            }
        }
    }
}
@keyframes loaderBreakoutRooms {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes slideUpBreakoutRooms {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}