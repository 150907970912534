@import "../const/_fonts.scss";

$color-cancel: #f55155;
/*---------------------------------------------------------------------------------------------
[ Confirm elements ]
*/
/* [ alert/dialog ] */
    .alert,
    .dialog {
        color:#5c6273;
        font-size:16px;
    }
    .alert {
        margin:40px auto;
        min-width:200px;
    }
    .alert .close {
        font-size:10px;
        border:0;
        display:block;
        top:0;
        right:0;
        position:absolute;
    }
    .alert .alert-content,
    .dialog .dialog-content {
        background:#fff;
        position:relative;
        border-radius:2px;
    }
    .alert .alert-content {
        padding:25px 30px;
        border:1px solid rgba(0,0,0,0.1);
        //box-shadow:0 0 1px rgba(0,0,0,.22);
    }
    .dialog .dialog-content {
        padding:35px 40px;
    }
    .alert .alert-content:before,
    .dialog .dialog-content:before {
        content:'';
        position:absolute;
        left:30px;
        top:50%;
        margin-top:-30px;
        font-size:67px;
        line-height:60px;
        display:inline-block;
        width:60px;
        text-align: center;
    }
    @media (max-width: 576px) {
        .alert .alert-content:before {
            top: 10px;
            left: 50%;
            margin-left: -30px;
            margin-top: auto;
        }
    }
    .alert .pos-tl.alert-content:before {
        margin-top:0;
        top:15px;
    }
    .dialog .dialog-content:before {
        margin-top:0;
        top:36px;
        @media (max-width: 576px) {
            top: 15px;
            left: 50%;
            margin-left: -30px;
        }
    }
  
    .alert .alert-title,
    .alert strong,
    .dialog .dialog-title,
    .dialog strong {
        color:#363a3d;
        font-weight:normal;
    }
    .alert-title {
        font-size:35px;
        margin: 10px 0;
        padding: 0 !important;
        color: #1B1F2B;
    }
    .alert--tips svg {
        top: 30px !important;
        margin-top: 0 !important;
    }

    .alert--tips .tips-list__element strong {
        font-weight: bold;
    }

    .alert--tips .alert-title {
        font-size: 21px;
        letter-spacing: -0.02em;
    }
    .alert-subtitle {
        font-size: 16px;
        color: #1B1F2B;
        font-family: 'robotoregular',Helvetica,Arial,sans-serif;
        letter-spacing: -0.02em;
    }
    .tips__header {
        margin-bottom: 20px;
        margin-top: 27px;
        font-size: 14px;
        color: #6B7183;
    }
    .tips__list {
        padding: 0;
    }
    .tips-list__element {
        display: flex;
        align-items: baseline;
    }
    .tips-list__element:not(:last-child) {
        margin-bottom: 20px;
    }
    .tips-list__number {
        font-family: 'robotobold',Helvetica,Arial,sans-serif;
        margin-right: 15px;
        background-color: #F1F3F7;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
    }
    .tips-list__text {
        font-family: 'robotoregular',Helvetica,Arial,sans-serif;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        flex: 1;
        text-align: left;
    }
    .dialog-title {
        font-size:24px
    }
    .alert p {
        margin:0;
    }
    .alert .email {
        word-break: break-word;
        border-bottom: 1px solid #5c6273;
    }
    .alert .btn-wrap,
    .dialog .btn-wrap {
        margin-top:25px;
    }
    .dialog .btn-wrap {
        text-align:center;
    }
    .dialog .btn {
        min-width:88px;
        padding-top:10px;
        padding-bottom:10px;
    }

    .alert {
        .alert-content {
            div.alert-confirm-button {
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
            }
        }
    }


/* [ alert/dialog state ] */
    .alert-info .alert-content,
    .alert-success .alert-content,
    .alert-warning .alert-content,
    .alert-error .alert-content,
    .dialog-info .dialog-content,
    .dialog-success .dialog-content,
    .dialog-warning .dialog-content,
    .dialog-error .dialog-content {
        padding-left:115px;
    }
    @media (max-width: 576px) {
        .alert-info .alert-content,
        .alert-success .alert-content,
        .alert-warning .alert-content,
        .alert-error .alert-content,
        .dialog-info .dialog-content,
        .dialog-success .dialog-content,
        .dialog-warning .dialog-content,
        .dialog-error .dialog-content {
            padding: 85px 15px 15px;
            text-align: center;
        }
    }
    .alert-notice {
        position: relative;
        .alert-title {
            margin-bottom: 15px;
            font-family: 'robotomedium';
            font-size: 21px;
            color: #2a2e38;
        }
        .alert-content {
            background-color: rgba(255, 255, 255, .95);
            padding: 25px 25px 25px 125px;
            border-color: transparent;
            border-radius: 4px;
            box-shadow: 0px 20px 90px 0px rgba(54, 58, 61, 0.09);
            p {
                margin-bottom: 15px;
                font-family: 'robotoregular';
                font-size: 14px;
                color: #363a3d;
            }
            &:before {
                content: none;
            }
            svg {
                position: absolute;
                width: 75px;
                height: 75px;
                left: 25px;
                top: 50%;
                transform:translateY(-50%);
                stroke-width: 1px;
            }
            img {
                position: absolute;
                left: 25px;
                top: 50%;
                transform:translateY(-50%);
            }
            .btn {
                padding: 7px 18px;
                font-family: 'robotomedium';
                font-size: 12px;
            }
        }
        .close {
            background-color: transparent;
            border-radius: 0;
            width: 25px;
            font-size: 21px;
            z-index: 1;
            color: #c6c6c6;
            &:hover {
                color: darken(#c6c6c6, 10%);
            }
            &:focus {
                outline: none;
            }
        }
    }
    .alert-info .btn-wrap,
    .alert-success .btn-wrap,
    .alert-warning .btn-wrap,
    .alert-error .btn-wrap,
    .dialog-info .btn-wrap,
    .dialog-success .btn-wrap,
    .dialog-warning .btn-wrap,
    .dialog-error .btn-wrap {
        margin-left:-85px;
        @media (max-width: 576px) {
            margin-left: 0;
        }
    }
    .alert-info .alert-content:before,
    .dialog-info .dialog-content:before {
        content:'8';
        color:$color-info;
    }
    .alert-success .alert-content:before,
    .dialog-success .dialog-content:before {
        content:'v';
        color:$color-success;
    }
    .alert-warning .alert-content:before,
    .dialog-warning .dialog-content:before {
        content:'Z';
        color:$color-warning;
    }
    .alert-error .alert-content:before,
    .dialog-error .dialog-content:before {
        content:'y';
        color:$color-error;
    }

    .alert-svg {
        svg {
            width: 50px !important;
            height: 50px !important;
            stroke-width: 2px;
            position: absolute;
            top: 50%;
            margin-top: -25px;
            left: 32px;
        }
        @media (max-width: 576px) {
            svg {
                top: 10px;
                margin-top: auto;
                left: 50%;
                margin-left: -25px;
            }
        }
        .alert-content:before {
            content: none;
        }
        &.alert-info {
            .alert-content {
                border-color: $color-info;
                svg {
                    color: $color-info;
                }
            }
        }
        &.alert-success {
            .alert-content {
                border-color: $color-success;
                svg {
                    color: $color-success;
                }
            }
        }
        &.alert-warning {
            .alert-content {
                border-color: $color-warning;
                svg {
                    color: $color-warning;
                }
            }
        }
        &.alert-error {
            .alert-content {
                border-color: $color-error;
                svg {
                    color: $color-error;
                }
            }
        }
    }

/* [ notify ] */
    .notify-muted {
        color:#606365;
    }
    .notify-info {
        color:$color-info;
    }
    .notify-success {
        color:$color-success;
    }
    .notify-warning {
        color:$color-warning;
    }
    .notify-error {
        color:$color-error;
    }
    .notify-ico.svg-stroke {
        stroke-width:10;
    }
    .notify-ico.ico-tick,
    .notify-ico.ico-x {
        @include x-svg-size(11);
        stroke-width:18;
    }


/* notify size */
    .notify-large {
        font-size:18px;
        line-height:26px;

        //dorobic opcję: 'has-ico'
        &.notify-muted,
        &.notify-info,
        &.notify-success,
        &.notify-warning,
        &.notify-error {
            padding-left:76px;
            position:relative;
        }

        .notify-ico {
            @include x-svg-size(41);
            position:absolute;
            left:15px;
            top:8px;

            &.ico-x {
                stroke-width:16;
            }
            &.ico-tick {
                stroke-width:12;
            }
        }
    }


/* notify block */
    .notify-block {
        font-size:18px;
        text-align:center;

        .notify-hd {
            font-size:31px;
            line-height:1.4;
            letter-spacing:-1px;
            margin:15px auto;
        }

        .notify-ico {
            @include x-svg-size(95);
            display:block;
            margin:0 auto 15px;
        }

        .svg-stroke {
            stroke-width:10;
        }
    }


/* [ message ] */
    .message {
        color:#fff;
        text-align:center;

        > .in {
            padding:6px 14px;
        }

        span {
            display: inline-block;
            border-radius: 5px;
            padding: 0 4px;
            margin: 0 7px;
        }

        a {
            text-decoration:underline;
        }

        &__restore {
            color: #fff;
            text: {
                transform: uppercase;
                decoration: none !important;
            }
            border-radius: 25px;
            display: inline-block;
            margin-left: 10px;
            padding: 6px 12px;
            font-size: 11px;
            font-family: 'robotomedium';
            line-height: 11px;
            transition: background-color .3s ease-in-out;
            &:hover {
                color: #fff;
            }
        }

        .message-ico {
            width:17px;
            height:17px;
            &.freeze {
                width: 25px;
                height: 25px;
                stroke-width: 3;
            }
        }

        //dedicated AP
        // .form-inline .form-group {
        //     margin-top:0;
        //     margin-bottom:0;
        // }
    }

    .message-desktop-wrapper,
    .message-mobile-wrapper {
        clear: both;
        .message {
            border-radius: 3px;
            .in {
                padding: 10px;
                .form-label {
                    text-align: left !important;
                }
            }
            &.message-info {
                a {
                    color:#fff;
                }
            }
        }
    }
    .message-desktop-wrapper {
        .message-info .form-inline {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .message-mobile-wrapper {
        margin-top: 20px;
        @media (min-width: 992px) {
            display: none;
        }
    }


/* [ message state ] */
    .message-default {
        background-color:#606365;

        a {
            color:#232728;
        }
    }
    .message-success {
        background-color:$color-success;

        a {
            color:#232728;
        }
    }
    .message-warning {
        background-color:#ff8d15;

        a {
            color:#351b00;
        }
    }
    .message-info {
        background-color:$color-info;

        a {
            color:#351b00;
        }
    }
    .message-error {
        background-color:$color-error;

        a {
            color:#3b0f00;
        }
    }
    .message-freeze {
        background-color: $color-info;
        font-family: $font-roboto-bold;
        span {
            background-color: #009ed5;
        }
        a {
            color: #fff;
        }

        button {
            text-decoration: underline;
            color: #fff;
            cursor: pointer;
        }
    }
    .message-freemium {
       background-color: $color-success;
       font-family: $font-roboto-bold;
        span {
            background-color: #5da82d;
        }
        .message__restore {
            border: 2px solid lighten($color-success, 5%);
            &:hover {
                background-color: lighten($color-success, 5%);
            }
        }
        a {
            color: #fff;
        }
    }
    .message-cancel {
        background-color: $color-cancel;
        font-family: $font-roboto-bold;
        span {
            background-color: #e73e42;
        }
        .message__restore {
            border: 2px solid lighten($color-cancel, 5%);
           &:hover {
              background-color: lighten($color-cancel, 5%);
           }
        }
    }

    .message-old-browser {
        background-color: #fdb237;
        padding: 8px 15px;
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font: {
            size: 14px;
            family: $font-roboto-medium;
        }
        @media only screen and (max-width: 767px) {
            font-size: 12px;
        }
        &__svg {
            @media only screen and (max-width: 767px) {
                display: none;
            }
            svg {
                width: 25px;
                height: 25px;
                margin-right: 18px;
                color: #bb7400;
                stroke-width: 1px;
            }
        }
        &__content {
            text-align: left;
            p {
                margin: 0;
            }
            .compatibility {
                color: #bb7400;
            }
        }
        &__btn {
            a {
                display: block;
                background-color: #ea9b19;
                padding: 10px 30px;
                border-radius: 25px;
                margin-left: 25px;
                line-height: 14px;
                color: #ffffff;
                text: {
                    transform: uppercase;
                    align: center;
                    decoration: none;
                }
                transition: box-shadow .5s ease;
                &:hover {
                    box-shadow: 0px 7px 10px 0px rgba(41, 45, 56, 0.3);
                }
            }
        }
        &__close {
            width: 11px;
            height: 11px;
            line-height: 11px;
            color: #ffffff;
            transform: translateX(120px);
            @media only screen and (max-width: 1140px) {
                transform: translateX(0);
                margin-left: 20px;
            }
            svg {
                height: 11px;
                width: 11px;
            }
        }
    }
    .message-communication {
        background-color: #00afec;
        height: 75px;
        @media (max-width: 767px) {
            height: 167px;
        }
        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'robotomedium';
            color: #fff;
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
                max-width: 350px;
                margin: 0 auto;
            }
        }
        &__box {
            padding-left: 55px;
            margin-right: 48px;
            position: relative;
            text-align: left;
            @media (max-width: 767px) {
                padding-left: 0;
                margin-right: 0;
            }
        }
        svg {
            width: 40px;
            height: 40px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            @media (max-width: 767px) {
                width: 25px;
                height: 25px;
                top: auto;
                left: auto;
                right: 0;
                bottom: -30px;
                transform: none;
            }
        }
        h6 {
            font-size: 18px;
            font-family: inherit;
            font-weight: 500;
            margin: 0;
            color: inherit;
            @media (max-width: 992px) {
                font-size: 16px;
            }
        }
        p {
            font-size: 14px;
            font-family: inherit;
            font-weight: 500;
            margin: 0;
            @media (max-width: 767px) {
                font-size: 12px;
                margin-top: 5px;
            }
        }
        &__btn {
            font-size: 14px;
            color: inherit;
            background-color: #008ec5;
            display: block;
            padding: 10px 20px;
            border-radius: 25px;
            line-height: 14px;
            white-space: nowrap;
            text: {
                transform: uppercase;
                align: center;
                decoration: none !important;
            }
            transition: box-shadow .5s ease;
            @media (max-width: 767px) {
                max-width: 200px;
                margin-top: 5px;
                font-size: 12px;
                padding: 4px 15px;
            }
            &:hover {
                color: inherit;
                box-shadow: 0px 7px 10px 0px rgba(41, 45, 56, 0.3);
            }
        }
    }
    .message-addons {
        position: relative;
        text-align: left;
        border-radius: 5px;
        max-width: 430px;
        margin: 0 auto;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 48px;
        .in {
            padding: 10px 15px 10px 50px;
        }
        svg {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            stroke-width: 3px;
            color: #2d79a3;
        }
    }
    .learn-popup {
        &__wrapper {
            max-width: 615px;
            .fancybox-close {
                color: #1b1f2b !important;
                top: 36px;
                right: 28px;
                width: 14px;
                height: 14px;
                line-height: 14px;
                &:before {
                    display: none;
                }
            }
        }
        &__content {
            padding: 28px;
        }
        &__title {
            font: {
                size: 24px;
                family: $font-roboto-regular;
            }
            color: #292d38;
            margin: 0 0 30px;
            width: calc(100% - 20px);
            svg {
                display: inline-block;
                margin-right: 15px;
                height: 25px;
                width: 25px;
                color: #fdb237;
                stroke-width: 1px;
            }
        }
        p {
            font: {
                size: 16px;
                family: $font-roboto-regular;
            }
            line-height: 21px;
            color: #5c6273;
            margin: 0 0 30px;
            &:nth-child(3) {
                margin-bottom: 10px;
            }
            span {
                font-family: $font-roboto-medium;
            }
            a {
                text-decoration: underline;
            }
        }
        &__icons {
            margin-bottom: 25px;
            div {
                display: inline-block;
                margin-right: 8px;
            }
            img {
                display: inline-block;
                width: 19px;
                height: 19px;
                margin-right: 7px;
            }
            a {
                font: {
                    size: 16px;
                    family: $font-roboto-medium;
                }
                text-decoration: underline;
                vertical-align: middle;
            }
        }
        &__icons.reducedList {
            div {
                margin-right: 18px;
                margin-bottom: 5px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        &__btn {
            display: block;
            max-width: 230px;
            margin: 50px auto 10px;
            background-color: $color-success;
            padding: 12px 22px;
            color: #ffffff;
            line-height: 20px;
            font: {
                size: 18px;
                family: $font-roboto-medium;
            }
            text: {
                align: center;
                transform: uppercase;
                decoration: none;
            }
            border-radius: 50px;
            transition: box-shadow .5s ease;
            &:hover {
                box-shadow: 0px 7px 10px 0px rgba(41, 45, 56, 0.3);
                color: #ffffff;
            }
        }
    }
    .notice {
        &-wrap {
            display: none;
            position: fixed;
            top: 60px;
            right: 0;
            left: 0;
            z-index: 9999;
            margin: auto;
            padding: 0 15px;
            max-width: 560px;
            @media (max-width: 767px) {
                display: none !important;
            }
            &--flash-room {
                top: 0;
            }
        }
        &-animated {
            animation-fill-mode: both;
            animation-duration: 1s;
        }
        &-fadeInDown {
          animation-name: noticeFadeInDown;
        }

        &-fadeInUp {
          animation-name: noticeFadeInUp;
        }
    }
    @keyframes noticeFadeInDown {
        from {
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes noticeFadeInUp {
        from {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }

        to {
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }
    }
