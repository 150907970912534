html,
body {
    height:100%;
    background:#f9f9f9;
}
// jesli chcemy wysrodkowac content
//.page-middle {
    // width:100%;
    // display:table-cell;
    // vertical-align:middle 
//}
.page-middle {
    position:relative;
    height: 100%;
}
.page-wrap {
    width:980px;
    padding:0 25px;
    margin:0 auto;
    position:relative;
}
.wide {
    width:100% !important;
    display:block;
    box-sizing:border-box;
}
.main {
    padding:27px 0;
    position:relative;
    z-index:3;

    > section {
        margin:20px auto;
    }

    .page-wrap {
        width:709px;
        &.addons {
            @media only screen and (max-width: 767px) {
                width: auto;
            }
        }
    }
}
.content {
    padding:0 0 18px;
    position:relative;
}


/* [ padding ] */
.in {
    padding:15px;
}
.in-vr {
    padding-top:15px;
    padding-bottom:15px;
}
.in-hr {
    padding-left:15px;
    padding-right:15px;
}


/* [ sticky header & footer ] */
body,
.page-body {
    display:block;
    width:100% !important;
}
.page-row {
    display:table-row;
    height:1px;
}
.page-row-expanded { 
    height:100%;
}


/* [ equal height columns ] */
.dsp-flex-wrap {
    display:-webkit-box;           /* OLD - iOS 6-, Safari 3.1-6 */
    display:-moz-box;              /* OLD - Firefox 19- (doesn't work very well) */
    display:-ms-flexbox;           /* TWEENER - IE 10 */
    display:-webkit-flex;          /* NEW - Chrome */
    display:flex;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}
.dsp-flex {
    -webkit-box-flex:1;            /* OLD - iOS 6-, Safari 3.1-6 */
       -moz-box-flex:1;               /* Firefox 19- */
}


.has-height-full {
height:100%;
box-sizing:border-box;
}

/* [ vertical/horizontal center everything ] */
.dsp-flex-center {
    //-webkit-align-items: center;
    //-ms-flex-align: center;
    //align-items: center;
    //-webkit-justify-content: center;
    //-ms-flex-pack: center;
    //justify-content: center;
    //display: -webkit-flex;
    //display: -ms-flexbox;
    //display: flex;

    align-items:center;
    justify-content:center;
    display:flex;
}


/* [ vertical center ] */
.dsp-table {
    display:table;
    width:100%;
    box-sizing:border-box;
}
.dsp-cell {
    display:table-cell;
    vertical-align:middle;
}


/* [ two-column layout ] */
.col-2 > .col {
    float:left;
    width:50%;
}


/* [ content + sidebar ]*/
.skeleton {
    position:relative;
    border-bottom-right-radius:2px;
     border-bottom-left-radius:2px;
}
.skeleton .sidebar,
.skeleton .testimonials {
    width:204px;
}
.skeleton .tier {
    width:100%;
    padding: 0 15px;
}

/* skeleton 1: aside z prawej */
.skeleton-layout-1 .tier {
    border-bottom-left-radius:2px;
}
.skeleton-layout-1 .sidebar {
    border-left:1px solid #f5f5f5;
    border-bottom-right-radius:2px;
    box-shadow:inset 10px 0 10px -10px rgba(0,0,0,.2);
    @media only screen and (max-width: 992px) {
        display: none;
    }
}
.skeleton-layout-1 .components-title {
    box-shadow:inset 0 -1px 0 rgba(0,0,0,.1), 0 2px 0 rgba(0,0,0,.11);
}

/* skeleton 2: aside z lewej */
.skeleton-layout-2 .tier {
    -webkit-box-ordinal-group:2;   /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-ordinal-group:2;      /* OLD - Firefox 19- */
    -ms-flex-order:2;              /* TWEENER - IE 10 */
    -webkit-order:2;               /* NEW - Chrome */
    order:2;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */  
    border-bottom-right-radius:2px;
}
.skeleton-layout-2 .sidebar {
    -webkit-box-ordinal-group:1;   /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-ordinal-group:1;      /* OLD - Firefox 19- */
    -ms-flex-order:1;              /* TWEENER - IE 10 */
    -webkit-order:1;               /* NEW - Chrome */
    order:1;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
    border-right:1px solid #f5f5f5;
    border-bottom-left-radius:2px;
    box-shadow:1px 0 10px -3px rgba(0,0,0,.2);
}
.skeleton-layout-2 .components-title {
    box-shadow:inset 0 -1px 0 rgba(0,0,0,.1), 0 2px 0 rgba(0,0,0,.11);
}
.single-mask {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    margin-top: -60px;
    z-index: 12;
    background-image:url($src-image + 'freeze/blured_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, .75);
    }
}
.single-mask-popup {
    position: fixed;
    z-index: 13;
    background-color: $white;
    width: 600px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    padding: 50px;
    @media only screen and (max-width: 767px) {
        max-width: 350px;
        padding: 20px 10px;
    }
    svg {
        width: 55px;
        height: 55px;
        stroke-width: 3px;
        color: $color-info;
        display: block;
        margin: 0 auto 20px;
    }
    &__title {
        font: {
            family: $font-roboto-regular;
            size: 24px;
        }
        color: #282b31;
        margin-bottom: 50px;
    }
    &__desc {
        color: #464a55;
        font-size: 16px;
        line-height: 22px;
        margin-top: 0;
        margin-bottom: 40px;
        span {
            display: inline-block;
            background-color: $color-info;
            color: #fff;
            border-radius: 5px;
            padding: 0 4px;
            font-weight: 700;
        }
    }
    button {
        text-transform: uppercase;
        font-family: $font-roboto-medium;
        font-size: 14px !important;
        padding: 16px 22px !important;
    }
    &__close {
        display: block;
        margin: 0 auto;
        margin-top: 23px;
    }
    &__tos {
        font-size: 11px;
        color: #a7afc6;
        margin-top: 20px;
        margin-bottom: 0;
    }
}