.tab-bd {
    position:relative;

    .nav-tabs-opaque-wrap + & [role='tabpanel']:first-of-type .unit:first-of-type {
        border-top-left-radius:0;
    }
    .nav-tabs-justify + & [role='tabpanel']:last-of-type .unit:first-of-type {
        border-top-right-radius:0;
    }

    .unit-bd {
        padding:0;
    }

    .in {
        padding:25px;
    }

    .table {
        margin:0;
        border-bottom-right-radius:0;
         border-bottom-left-radius:0;
    }
}
.tab-ft {
    margin-top:40px;

    .list-inline li {
        vertical-align:middle;
    }

    .btn.has-minwidth {
        min-width:250px;
    }

    .btn.medium {
        top:9px;
    }
}


/* fixed buttons */
.sticky-buttons {
    background-color:#f9f9f9;
    position:fixed;
    z-index:2001;
    bottom:0;
    left:0;
    width:100%;
    padding:10px 0;
    border-top:1px solid #e6e6e6;
    box-shadow:inset 0 1px 0 #fff;
    transform:translateZ(0);
}
.edit-webinar,
.appearance-settings {

    p {
        position: relative;
        font-size: 13px;
        color: #a5a5a5;
        margin: 0;
        padding: 17px 0 17px 45px;

        svg {
            position: absolute;
            width: 28px;
            height: 28px;
            top: 50%;
            margin-top: -14px;
            left: 0;
            stroke-width: 1px;
        }

    }

    .btn.x-large {
        font-size: 21px;
        padding: 15px 40px;
        &.btn-link {
            font-size: 18px;
            padding: 15px 0;
        }
    }

    .list-inline > li {

        @media (max-width: 768px) {
            float: none;
            margin-right: 0;
            display: block;
            text-align: center;
        }
    }

    &.sticky-buttons {
        background-color: #fff;
        border: none;
        box-shadow: 0px 0px 17px 8px rgba(0,0,0,0.1);

        p {
            padding-top: 12px;
            padding-bottom: 12px;

        }

        .btn.x-large {
            padding: 11px 40px;
            font-size: 18px;
            &.btn-link {
                padding: 11px 0;
            }
        }

    }

}
.appearance-settings {
    ul {
        @media (max-width: 767px) {
            text-align: center;
        }
    }
    p {
        @media (max-width: 767px) {
            display: none;
        }
    }
}