/*!
 * Bootstrap v4.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/functions";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/variables";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/mixins";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/root";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/reboot";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/type";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/images";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/code";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/grid";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/tables";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/forms";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/buttons";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/transitions";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/dropdown";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/button-group";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/input-group";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/custom-forms";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/nav";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/navbar";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/card";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/breadcrumb";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/pagination";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/badge";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/jumbotron";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/alert";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/progress";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/media";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/list-group";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/close";
  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/modal";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/tooltip";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/popover";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/carousel";
 @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/utilities";
//  @import "../../../../../../shared/web/views/src/bootstrap-sass/bootstrap/scss/print";
