.beta-room {

    &-panel {
        background: #0b132a;
        background: -moz-linear-gradient(left, #0b132a 0%, #1e2d4f 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, #0b132a), color-stop(100%, #1e2d4f));
        background: -webkit-linear-gradient(left, #0b132a 0%, #1e2d4f 100%);
        background: -o-linear-gradient(left, #0b132a 0%, #1e2d4f 100%);
        background: -ms-linear-gradient(left, #0b132a 0%, #1e2d4f 100%);
        background: linear-gradient(to right, #0b132a 0%, #1e2d4f 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0b132a', endColorstr='#1e2d4f', GradientType=1 );
        height: 70px;
        overflow: hidden;
        
        &-fixed-top {
            position: fixed;
            top: 60px;
            left: 0;
            right: 0;
            z-index: 1020;
            -webkit-transition: top .3s ease-in-out;
               -moz-transition: top .3s ease-in-out;
                 -o-transition: top .3s ease-in-out;
                    transition: top .3s ease-in-out;

            &.nav-fixed {
                top: 0;
            }
        }
        *:before,
        *:after {
          box-sizing: content-box !important;
        }
        &__wrap {
            align-items: center;
        }
    }
    &-fixed {
        padding-top: 132px;
    }
    &-content-left,
    &-content-right {
        display: block;
        position: relative;
        overflow: hidden;
    }
    &-content-left {
        text-align: left;
        h3 {
            font-family: $font-roboto-medium;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
            color: #fff;
        }
    }
    &-content-right {
        text-align: right;
        a {
            font-family: $font-roboto-medium;
            font-size: 14px;
            line-height: 20px;
            display: inline-block;
            position: relative;
            padding: 5px 0 5px 20px;
            margin: 0;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
                color: #00afec;
            }
            svg {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }
    }
    &-content-switch {
        label {
            font-family: $font-roboto-medium;
            font-size: 10px;
            letter-spacing: 2px;
            color: #5773ab;
            cursor: auto;
            margin: 0;
        }
        .switch {
            &-wrap {
                background: #162445;
                padding-top: 3px;
                height: 70px;
                box-shadow: 0px 0px 51px 0px rgba(4, 15, 48, 0.9);
                -webkit-transform: skew(-20deg);
                   -moz-transform: skew(-20deg);
                        transform: skew(-20deg);
            }
            &-inside {
                -webkit-transform: skew(20deg);
                   -moz-transform: skew(20deg);
                        transform: skew(20deg);
            }
            &-slider {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .form-control {
                    margin: 3px 0;
                }
                .old,
                .new {
                    font-family: $font-roboto-bold;
                    letter-spacing: 1px;
                    font-size: 12px;
                    &.active {
                        color: #fff;
                    }
                }
                .old {
                    margin-right: 20px;
                }
                .new {
                    margin-left: 20px;
                    color: #fff;
                    &-wrap {
                        border: 1px solid red;
                    }                    
                    .beta {
                        background: #00afec;
                        padding: 2px 5px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin-top: -9px;
                        margin-left: 4px;
                        text-transform: uppercase;
                        font-size: 9px;
                        -webkit-border-radius: 2px;
                           -moz-border-radius: 2px;
                                border-radius: 2px;
                                
                        &:before {
                            content: "";
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-color: transparent;
                            border-style: solid;
                            top: 50%;
                            left: -4px;
                            margin-top: -5px;
                            border-width: 5px 5px 5px 0;
                            border-right-color: #00afec;
                        }
                        &-wrap {
                            position: relative;
                            display: inline-block;
                            height: 10px;
                            width: 1px;
                        }
                    }                    
                }
                .switch {
                    height: 30px;
                    line-height: 30px;
                    .slider-bd {
                        width: 50px;
                        span {
                            display: none;
                        }
                    }
                    .slider-viewport {
                        height: 30px;
                        width: 50px;
                        padding: 4px 0;
                    }
                    .slider-btn {
                        top: -2px;
                    }
                    .slider-toggle + .slider-viewport .slider-btn {
                        left: 50px;
                    }
                    .slider-toggle:checked + .slider-viewport .slider-btn {
                        left: 24px;
                    }
                }
            }
        }
    }
    &-carousel {
        height: 600px;
        
        .carousel-item {
            height: 600px;
            overflow: hidden;
            &-new-room {
                padding: 55px 0 120px;
                h2 {
                    font-family: $font-roboto-regular;
                    font-size: 26px;
                    color: #1b1f2b;
                    margin-bottom: 35px;
                }
                h4 {
                    font-family: $font-roboto-regular;
                    font-size: 16px;
                    color: #1b1f2b;
                }
                p {
                    font-size: 16px;
                    color: #6b7183;
                    margin-bottom: 25px;
                }
                .feature-circle {
                    padding: 0 28px;
                    &.row {
                        .col-4 {
                            padding: 0 10px;
                        }
                    }
                }
                .img-responsive {
                    margin-bottom: 25px;
                    max-width: 100%;
                    height: auto;
                    box-shadow: 0px 13px 46px 0px rgba(25, 100, 240, 0.46);
                }
            }
            &-start-using {
                padding: 120px 60px 15px;
                h2 {
                    font-family: $font-roboto-regular;
                    font-size: 26px;
                    color: #1b1f2b;
                    margin-bottom: 35px;
                }                
                p {
                    font-size: 16px;
                    color: #6b7183;
                    margin-bottom: 45px;
                }                
                .img-center {
                    margin: 0 auto;
                }                
            }            
        }
        .btn.medium {
            font-family: $font-roboto-medium;
            font-size: 14px;
            padding: 14px 40px;
            letter-spacing: normal;
            &.btn-success {
                background: #1964f0;
                box-shadow: 0px 13px 46px 0px rgba(25, 101, 240, 0.31);
                &:hover {
                    background: darken (#1964f0, 5%);
                }
            }
        }
    }
    &-container {
        padding: 0 15px;
        height: 100%;
        overflow: hidden;
        .list-features {
            padding-left: 0;
            list-style: none;
            margin-top: 30px;
            margin-bottom: 10px;            
            &>li {
                display: inline-block;
                padding: 0;
                margin: 0 8px 10px;                
                svg {
                    width: 24px;
                    height: 24px;
                    color: #a7afc6;
                    stroke-width: 1;
                 }
            }            
        }
        &__svg {
            margin-bottom: 25px;
            color: #1965f0;
            .svg-stroke {
                width: 65px;
                height: 65px;
                stroke-width: 2;
            }
        }
    }
    &-caption {
        position: absolute;
        bottom: 60px;
        left: 30px;
        right: 30px;
    }
}
#beta-tests-modal {
    @media (min-width: 768px) {
        .modal-dialog {
            width: 650px;
            max-width: none;
        }
    }
}