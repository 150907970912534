.event {

    &-group {
        border: 1px solid rgba(0, 0, 0, 0.1);
        /*
        poniższe powoduje, że ostatni dropdown action buttona dla eventu jest przycinany przez obszar event-group
        nie zaobserwowałem negatywnych konsekwencji wyłączenia ukrywania overflow
        */
        /*overflow: hidden;*/
        margin-bottom: 20px;
        -webkit-border-radius: 3px;
           -moz-border-radius: 3px;
                border-radius: 3px;

        .table {
            border: none;
            border-radius: 0;
            margin-bottom: 0;

            thead:first-child tr:first-child > th:first-child,
            tbody:first-child tr:first-child > td:first-child,
            tbody:first-child tr:first-child > th:first-child {
                border-top-left-radius: 0;
            }

            thead:first-child tr:first-child > th:last-child,
            tbody:first-child tr:first-child > td:last-child,
            tbody:first-child tr:first-child > th:last-child {
                border-top-right-radius: 0;
            }

            thead:last-child tr:last-child > th:first-child,
            tfoot tr:last-child > th:first-child,
            tbody:last-child tr:last-child > td:first-child,
            tbody:last-child tr:last-child > th:first-child,
            tfoot:last-child tr:last-child > td:first-child,
            tfoot:last-child tr:last-child > th:first-child {
                border-bottom-left-radius: 0;
            }
            thead:last-child tr:last-child > th:last-child,
            tfoot tr:last-child > th:last-child,
            tbody:last-child tr:last-child > td:last-child,
            tbody:last-child tr:last-child > th:last-child,
            tfoot:last-child tr:last-child > td:last-child,
            tfoot:last-child tr:last-child > th:last-child {
                border-bottom-right-radius: 0;
            }

        }

    }

    &-heading {

        a {
            display: block;
            padding: 12px;
            text-align: center;
            text-transform: uppercase;
            font-size: 11px;
            color: #626567;
            border-top: 1px solid #e3e3e3;

            .arrow {
                position: relative;
                margin-right: 20px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    width: 0;
                    height: 0;
                    border-color: transparent;
                    border-style: solid;
                }

                &.top {
                    &:before {
                        right: -17px;
                        margin-top: -2px;
                        border-width: 4px 4px 0;
                        border-top-color: #626567;
                    }
                }

                &.bottom {
                    &:before {
                        right: -17px;
                        margin-top: -2px;
                        border-width: 0 4px 4px;
                        border-bottom-color: #626567;
                    }
                }

                &.left {
                    &:before {
                        right: -15px;
                        margin-top: -4px;
                        border-width: 4px 0 4px 4px;
                        border-left-color: #626567;
                    }
                }

                &.right {
                    &:before {
                        right: -15px;
                        margin-top: -4px;
                        border-width: 4px 4px 4px 0;
                        border-right-color: #626567;
                    }
                }

            }

        }

    }

}
.applied-promotion {
    padding: 15px 0;
    text-align: center;
    color: #2dd1cc;

    p {
        margin: 0;
    }
}
