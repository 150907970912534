/*---------------------------------------------------------------------------------------------
[ Buttons ]
*/
    .btn {
        cursor:pointer;
        padding:5px 12px;
        line-height:1.2;
        font-size:16px;
        font-weight:normal;
        text-align:center;
        text-shadow:none;
        display:inline-block;
        vertical-align:middle;
        position:relative;
        border:0;
        outline:0;
        border-radius:30px;
        box-sizing:border-box;
    }
    .btn,
    .btn:hover,
    .btn:focus {
        color:#fff;
        text-decoration:none;
        outline: none;
    }
    .btn.nospace {
        border-radius:0;
    }
    .btn.block {
        display:block;
        width:100%;
    }
    .btn.block + .btn.block {
        margin-top:.5em;
    }
    .btn-wrap + .btn-wrap {
        margin-top:10px;
    }
    .btn.disabled,
    .btn[disabled='disabled'],
    fieldset[disabled='disabled'] .btn {
        pointer-events:none;
        cursor:not-allowed;
        opacity:.65;
        box-shadow:none;
    }
    .btn-txt {
        display:inline-block;
        position:relative;
        /*z-index:3;*/
    }
    .btn-txt-sub {
        font-family: 'robotoregular';
        font-size: 9px;
        display: block;
        text-align: left;
        color: #737373;
        word-spacing: 1px;
    }

/* [ btn style: default + flat ] */
    .btn-default {
        @include x-btn-default();
    }
    .btn-default.active {
        box-shadow:inset 0 2px 0 rgba(0,0,0,.1);
    }
    .btn-default:hover,
    .btn-default:focus,
    .btn-default.active,
    .open .dropdown-toggle.btn-default {
        @include x-btn-default-hover();
    }
    .btn-default.disabled,
    .btn-default[disabled='disabled'],
    fieldset[disabled='disabled'] .btn-default,
    .btn-default.disabled:hover,
    .btn-default[disabled='disabled']:hover,
    fieldset[disabled='disabled'] .btn-default:hover,
    .btn-default.disabled.active,
    .btn-default[disabled='disabled'].active,
    fieldset[disabled='disabled'] .btn-default.active {
        background-color:#fafafa;
    }


    .btn-default.flat {
        color:#6e7072;
        background:#ffffff;
    }
    .btn-default.flat:hover,
    .btn-default.flat.active,
    .open .dropdown-toggle.btn-default.flat {
        background:#f3f3f3;
    }
    .btn-default.disabled,
    .btn-default[disabled='disabled'],
    fieldset[disabled='disabled'] .btn-default,
    .btn-default.disabled:hover,
    .btn-default[disabled='disabled']:hover,
    fieldset[disabled='disabled'] .btn-default:hover,
    .btn-default.disabled.active,
    .btn-default[disabled='disabled'].active,
    fieldset[disabled='disabled'] .btn-default.active {
        color:#c2c2c2;
    }


/* [ btn style: success ] */
    .btn-success {
        box-shadow:inset 0 -2px 0 rgba(0,0,0,.16), 0 2px 0 rgba(0,0,0,.11);
        //bg - brand
    }
    .btn-success.active {
        box-shadow:inset 0 2px 0 rgba(0,0,0,.16);
        //bg - brand
    }
    .btn-outline-success {
        background: #fff;
        border: 1px solid #6ebe3b;
        color: #6ebe3b;
    }
    .btn-outline-success:hover {
        background: lighten(#6ebe3b, 45%);
        color: #6ebe3b;
    }


/* [ btn style: primary + flat ] */
    .btn-primary {
        background:#313537;
        background:-webkit-linear-gradient(top,  #363b3d 0%,#313537 100%);
        background:linear-gradient(to bottom,  #363b3d 0%,#313537 100%);
        box-shadow:inset 0 -2px 0 rgba(0,0,0,.63), 0 2px 0 rgba(0,0,0,.11);
    }
    .btn-primary.active {
        box-shadow:inset 0 2px 0 rgba(0,0,0,.63);
    }
    .btn-primary:hover,
    .btn-primary.active,
    .open .dropdown-toggle.btn-primary {
        background:#272b2d;
        background:-webkit-linear-gradient(top,  #2a2e30 0%,#272b2d 100%);
        background:linear-gradient(to bottom,  #2a2e30 0%,#272b2d 100%);
    }
    .btn-primary.disabled,
    .btn-primary[disabled='disabled'],
    fieldset[disabled='disabled'] .btn-primary,
    .btn-primary.disabled:hover,
    .btn-primary[disabled='disabled']:hover,
    fieldset[disabled='disabled'] .btn-primary:hover,
    .btn-primary.disabled.active,
    .btn-primary[disabled='disabled'].active,
    fieldset[disabled='disabled'] .btn-primary.active {
        background-color:#606365;
    }

    .btn-primary.flat {
        background:#18191b;
        box-shadow:0 1px 0 rgba(255,255,255,.1);
    }
    .btn-primary.flat:hover,
    .btn-primary.flat.active,
    .open .dropdown-toggle.btn-primary.flat {
        background:#303032;
    }
    .btn-primary.disabled,
    .btn-primary[disabled='disabled'],
    fieldset[disabled='disabled'] .btn-primary,
    .btn-primary.disabled:hover,
    .btn-primary[disabled='disabled']:hover,
    fieldset[disabled='disabled'] .btn-primary:hover,
    .btn-primary.disabled.active,
    .btn-primary[disabled='disabled'].active,
    fieldset[disabled='disabled'] .btn-primary.active {
        background-color:#464749;
    }

    .btn-outline-primary {
        background: #fff;
        border: 1px solid #313537;
        color: #313537;
    }
    .btn-outline-primary:hover {
        background: lighten(#313537, 70%);
        color: #313537;
    }

    .btn-info {
        background-color:#00adec;
        background-image:-webkit-linear-gradient( to bottom, #00adec 0%, #15c3ff 100%);
        background-image:linear-gradient(90deg, #00adec 0%, #15c3ff 100%);
        box-shadow:inset 0 -2px 0 rgba(0,0,0,.16), 0 2px 0 rgba(0,0,0,.11);
    }
    .btn-info.active {
        box-shadow:inset 0 2px 0 rgba(0,0,0,.16);
    }
    .btn-info:hover,
    .btn-info.active,
    .open .dropdown-toggle.btn-info {
        background:#66d6ff;
        background:-webkit-linear-gradient(top,  #33c9ff 0%,#66d6ff 100%);
        background:linear-gradient(90deg,  #33c9ff 0%,#66d6ff 100%);
    }
    .btn-info.disabled,
    .btn-info[disabled='disabled'],
    fieldset[disabled='disabled'] .btn-info,
    .btn-info.disabled:hover,
    .btn-info[disabled='disabled']:hover,
    fieldset[disabled='disabled'] .btn-info:hover,
    .btn-info.disabled.active,
    .btn-info[disabled='disabled'].active,
    fieldset[disabled='disabled'] .btn-info.active {
        background-color:#0096cc;
    }
    .btn-outline-info {
        background: #fff;
        border: 1px solid #00afec;
        color: #00afec;
    }
    .btn-outline-info:focus,
    .btn-outline-info:hover {
        background: #e3f3f9;
        color: #00afec;
    }

/* [ btn style: warning ] */
    .btn-warning {
        background:#ff8706;
        background:-webkit-linear-gradient(top,  #ff961a 0%,#ff8706 100%);
        background:linear-gradient(to bottom,  #ff961a 0%,#ff8706 100%);
        box-shadow:inset 0 -2px 0 rgba(0,0,0,.16), 0 2px 0 rgba(0,0,0,.11);
    }
    .btn-warning.active {
        box-shadow:inset 0 2px 0 rgba(0,0,0,.16);
    }
    .btn-warning:hover,
    .btn-warning.active,
    .open .dropdown-toggle.btn-warning {
        background:#ff9f07;
        background:-webkit-linear-gradient(top,  #ffab1f 0%,#ff9f07 100%);
        background:linear-gradient(to bottom,  #ffab1f 0%,#ff9f07 100%);
    }
    .btn-warning.disabled,
    .btn-warning[disabled='disabled'],
    fieldset[disabled='disabled'] .btn-warning,
    .btn-warning.disabled:hover,
    .btn-warning[disabled='disabled']:hover,
    fieldset[disabled='disabled'] .btn-warning:hover,
    .btn-warning.disabled.active,
    .btn-warning[disabled='disabled'].active,
    fieldset[disabled='disabled'] .btn-warning.active {
        background-color:#ffab1f;
    }


/* [ btn style: error ] */
    .btn-error {
        background:#ff4200;
        background:-webkit-linear-gradient(top,  #ff530d 0%,#ff4200 100%);
        background:linear-gradient(to bottom,  #ff530d 0%,#ff4200 100%);
        box-shadow:inset 0 -2px 0 rgba(0,0,0,.16), 0 2px 0 rgba(0,0,0,.11);
    }
    .btn-error.active {
        box-shadow:inset 0 2px 0 rgba(0,0,0,.16);
    }
    .btn-error:hover,
    .btn-error.active,
    .open .dropdown-toggle.btn-error {
        background:#ff1c00;
        background:-webkit-linear-gradient(top,  #ff1f04 0%,#ff1c00 100%);
        background:linear-gradient(to bottom,  #ff1f04 0%,#ff1c00 100%);
    }
    .btn-error.disabled,
    .btn-error[disabled='disabled'],
    fieldset[disabled='disabled'] .btn-error,
    .btn-error.disabled:hover,
    .btn-error[disabled='disabled']:hover,
    fieldset[disabled='disabled'] .btn-error:hover,
    .btn-error.disabled.active,
    .btn-error[disabled='disabled'].active,
    fieldset[disabled='disabled'] .btn-error.active {
        background-color:#ff530d;
    }


/* [ btn style: as link + flat ] */
    .btn-link {
        color:$color-link;
    }
    .btn-link:hover,
    .btn-link:focus {
        color:$color-link-hover;
    }
    .btn-link-delete [data-icon='H'],
    .actions .btn-link-delete [data-icon='H'] {
        color:$color-error;
    }

    .btn-link-delete .ico-x,
    .actions .btn-link-delete .ico-x {
        color:$color-error;
        stroke-width:16;
    }

    .btn-link.flat {
        background:#fff;
    }


/* [ btn style: facebook ] */
    .btn-fb {
        background:#4767af;
        background:-webkit-linear-gradient(top,  #4767af 0%,#395799 100%);
        background:linear-gradient(to bottom,  #4767af 0%,#395799 100%);
    }
    .btn-fb:hover {
        background:#5a76b7;
        background:-webkit-linear-gradient(top,  #5a76b7 0%,#4d68a3 100%);
        background:linear-gradient(to bottom,  #5a76b7 0%,#4d68a3 100%);
    }
    .btn-signup-facebook {
      background-color: #ffffff;
      border: 1px solid #4c6aad;
      color: #4c6aad;
      &:hover,
      &:focus,
      &.focus {
        background-color: #4c6aad;
        color: #ffffff;
        box-shadow: 0 5px 12px rgba(76, 106, 173, 0.26);
      }
    }

/* [ btn style: google ] */
    .btn-google {
        background:#DD4B39;
        background:-webkit-linear-gradient(top,  #DD4B39 0%, #d14b39 100%);
        background:linear-gradient(to bottom,  #DD4B39 0%,#d14b39 100%);
    }
    .btn-google:hover {
        background: #e64b39;
        background:-webkit-linear-gradient(top,  #e64b39 0%, #e34d3a 100%);
        background:linear-gradient(to bottom,  #e64b39 0%,#e34d3a 100%);
    }
    .btn-signup-google {
      background-color: #ffffff;
      border: 1px solid #f0473a;
      color: #f0473a;
      &:hover,
      &:focus,
      &.focus {
        background-color: #f0473a;
        color: #ffffff;
        box-shadow: 0 5px 12px rgba(240, 71, 58, 0.26);
      }
    }

/* [ btn style: sso ] */
    .btn-signup-sso {
      background-color: #ffffff;
      border: 1px solid #6ebe3b;
      color: #6ebe3b;
      min-width: 90px;
      padding: 8px 15px !important;
      &:hover,
      &:focus,
      &.focus {
        background-color: #6ebe3b;
        color: #ffffff;
        box-shadow: 0 5px 12px rgba(110, 190, 59, 0.41);
      }
    }

/* [ btn style: gray ] */
    .btn-gray {
        background:#6b7183;
        background:-webkit-linear-gradient(top,  #6b7183 0%, darken(#6b7183, 3%) 100%);
        background:linear-gradient(to bottom,  #6b7183 0%, darken(#6b7183, 3%) 100%);
        box-shadow:inset 0 -2px 0 rgba(0,0,0,.16), 0 2px 0 rgba(0,0,0,.11);
    }
    .btn-gray:hover {
        background:#5c6273;
        background:-webkit-linear-gradient(top,  #5c6273 0%, darken(#5c6273, 3%) 100%);
        background:linear-gradient(to bottom,  #5c6273 0%, darken(#5c6273, 3%) 100%);
    }

/* [ btn style: upload-file ] */
    .btn-upload-file {
        &.btn.large {
            padding: 7px 23px;
            svg {
                width: 25px;
                height: 25px;
            }
        }
        .btn-txt {
            font-size: 18px;
            padding-left: 33px;
            svg {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                stroke-width: 1px;
            }
        }
        &:hover {
            .btn-txt-sub {
                color: inherit;
            }
        }
    }
/* [ btn style: import-from ] */
    .btn-import-from {
        &.btn.large {
            padding: 7px 23px;
        }
        .btn-txt {
            font-size: 18px;
            padding-right: 85px;
            img {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &:hover {
            .btn-txt-sub {
                color: inherit;
            }
        }
    }
/* [ btn size ] */
    .btn {
        svg {
            @include x-svg-size(14);
        }
    }
    .btn-default.small,
    .btn-primary.small,
    .btn-success.small,
    .btn-warning.small,
    .btn-error.small,
    .btn-default.x-small,
    .btn-primary.x-small,
    .btn-success.x-small,
    .btn-warning.x-small,
    .btn-error.x-small {
        box-shadow:inset 0 -1px 0 rgba(0,0,0,.16);
    }
    .btn.x-large {
        font-size:25px;
        padding:12px 20px;
        letter-spacing:-1px;

        svg {
            @include x-svg-size(20);
        }
    }
    .btn.large {
        font-size:21px;
        padding:11px 24px;
        letter-spacing:-1px;

        svg {
            @include x-svg-size(18);
        }
    }
    .btn.medium {
        font-size:18px;
        padding:10px 24px;
        letter-spacing:-1px;

        svg {
            @include x-svg-size(16);
        }
    }
    .btn.normal {
        font-size:inherit;

        svg {
            @include x-svg-size(12);
        }
    }
    .btn-link.normal {
        padding:0;
        border-radius:0;
        top:-1px;
    }
    .btn.small {
        font-size:13px;
        padding:4px 10px;

        svg {
            @include x-svg-size(10);
        }
    }
    .btn.x-small {
        font-size:10px;
        line-height:14px;
        padding:2px 8px;

        svg {
            @include x-svg-size(8);
        }
    }
    .btn.small .ico-left,
    .btn.x-small .ico-left {
        margin-right:4px;
    }
    .btn.small .ico-right,
    .btn.x-small .ico-right {
        margin-left:4px;
    }
    .btn.small,
    .btn.x-small {
        letter-spacing:0;

        svg {
            stroke-width:8;
        }
    }

    .btn-fb.x-small {
        font-size: 11px;
        font-family: 'robotomedium';
        padding: 5px 10px;
        svg {
            width: 12px;
            height: 12px;
        }
    }


    .btn-wrap + .btn-wrap {
        margin-top:10px;
    }

    .btn.x-small [data-icon]{
        font-size:.7em
    }
    .btn.small [data-icon] {
        font-size:.85em
    }
    .btn.large [data-icon],
    .btn.x-large [data-icon]  {
        font-size:.9em
    }
    .btn-link-delete [data-icon] {
        font-size:.8em;
    }
    .btn.radius-large {
        border-radius: 50%;
    }
    .btn.radius-medium {
        border-radius: 30px;
    }
    .btn.radius-small {
        border-radius: 5px;
    }
    .btn.radius-x-small {
        border-radius: 3px;
    }


/* [ btn state: loading ] */
    .spinner-btn {
        z-index:2;
        margin-left:auto;
        left:auto;
    }
    .btn.large .spinner-btn,
    .btn.x-large .spinner-btn {
        width:18px;
        height:18px;
        margin-top:-9px;
    }
    .btn.small .spinner-btn,
    .btn.x-small .spinner-btn {
        width:10px;
        height:10px;
        margin-top:-5px;
    }

    .btn-default[data-loading='true'] {
        background:#f2f2f2;
    }
    .btn-primary[data-loading='true'] {
        background:#484a4c;
    }
    .btn-success[data-loading='true'] {
        background:#74c540;
    }
    .btn-warning[data-loading='true'] {
        background:#ff8e10;
    }
    .btn-error[data-loading='true'] {
        background:#ff4909;
    }
    .btn-default .spinner-btn:not(:required):before,
    .btn-link .spinner-btn:not(:required):before {
        border:1px solid #d7d7d7;
        border-top-color:$color-success;
    }



/* [ btn effect: zoom-out ] */
    .effect-zoom-out,
    .effect-zoom-out .spinner-btn,
    .effect-zoom-out .btn-txt {
        transition:opacity .3s ease, visibility .3s ease, .3s ease transform;
    }
    .effect-zoom-out .spinner-btn {
        left:50%;
        margin-left:-7px;
        transform:scale(2.5);
    }
    .effect-zoom-out.large .spinner-btn,
    .effect-zoom-out.x-large .spinner-btn {
        margin-left:-9px;
    }
    .effect-zoom-out.small .spinner-btn,
    .effect-zoom-out.x-small .spinner-btn {
        margin-left:-5px;
    }

    .effect-zoom-out {
        overflow:hidden;
    }
    .effect-zoom-out[data-loading='true'] {
        cursor:default;
        pointer-events:none;
    }
    .effect-zoom-out[data-loading='true'] .btn-txt {
        opacity:0;
        transform:scale(.5);
    }
    .effect-zoom-out[data-loading='true'] .spinner-btn {
        opacity:1;
        transform:none;
    }


/* [ btn effect: expand-right ] */
    .effect-expand-left,
    .effect-expand-left .spinner-btn,
    .effect-expand-left .btn-txt,
    .effect-expand-right,
    .effect-expand-right .spinner-btn,
    .effect-expand-right .btn-txt  {
        transition:padding .3s cubic-bezier(0.175, 0.885, 0.32, 1.275), right .3s cubic-bezier(0.175, 0.885, 0.32, 1.275), left .3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    .effect-expand-right .spinner-btn {
        right:8px;
    }
    .btn.large.effect-expand-right .spinner-btn,
    .btn.x-large.effect-expand-right .spinner-btn {
        right:14px;
    }
    .btn.small.effect-expand-right .spinner-btn,
    .btn.x-small.effect-expand-right .spinner-btn {
        right:7px;
    }
    .btn-choice .hide-check + .effect-expand-right[data-loading='true'],
    .effect-expand-right[data-loading='true'] {
        padding-right:30px;
    }
    .btn.small.effect-expand-right[data-loading='true'],
    .btn.x-small.effect-expand-right[data-loading='true'] {
        padding-right:22px;
    }
    .btn.large.effect-expand-right[data-loading='true'],
    .btn.x-large.effect-expand-right[data-loading='true'] {
        padding-right:42px;
    }
    .effect-expand-right[data-loading='true'] .spinner-btn {
        opacity:1;
    }


/* [ btn effect: expand-left ] */
    .effect-expand-left .spinner-btn {
        left:8px;
    }
    .btn.large.effect-expand-left .spinner-btn,
    .btn.x-large.effect-expand-left .spinner-btn {
        left:14px;
    }
    .btn.small.effect-expand-left .spinner-btn,
    .btn.x-small.effect-expand-left .spinner-btn {
        left:7px;
    }
    .btn-choice .hide-check + .effect-expand-left[data-loading='true'],
    .effect-expand-left[data-loading='true'] {
        padding-left:30px;
    }
    .btn.small.effect-expand-left[data-loading='true'],
    .btn.x-small.effect-expand-left[data-loading='true'] {
        padding-left:22px;
    }
    .btn.large.effect-expand-left[data-loading='true'],
    .btn.x-large.effect-expand-left[data-loading='true'] {
        padding-left:42px;
    }
    .effect-expand-left[data-loading='true'] .spinner-btn {
        opacity:1;
    }


/* [ btn effect: expand-enlarge ] */
    .btn.effect-enlarge {
        white-space:nowrap;
        padding-left:.5em;
        padding-right:.5em;
    }
    .effect-enlarge .ico-left,
    .btn.small.effect-enlarge .ico-left,
    .btn.x-small.effect-enlarge .ico-left {
        margin-right:0;
    }
    .effect-enlarge .btn-txt {
        vertical-align:middle;
        opacity:0;
        max-width:0;
        overflow:hidden;
    }
    .effect-enlarge:hover {
        max-width:250px !important;
    }
    [lang="de"] .effect-enlarge:hover {
        max-width:315px !important;
    }
    .effect-enlarge:hover .btn-txt {
        opacity:1;
        max-width:200px;
        margin:0 .4em;
    }
    [lang="de"] .effect-enlarge:hover .btn-txt {
        max-width: 250px;
    }
    .effect-enlarge {
        max-width:30px;
        min-width:30px;
    }
    .btn.x-large.effect-enlarge {
        max-width:56px;
        min-width:56px;

        svg.ico-left {
            margin-left:5px;
        }
    }
    .btn.large.effect-enlarge {
        max-width:49px;
        min-width:49px;

        svg.ico-left {
            margin-left:3px;
        }
    }
    .btn.medium.effect-enlarge {
        max-width:44px;
        min-width:44px;

        svg.ico-left {
            margin-left:3px;
        }
    }
    .btn.normal.effect-enlarge {
        max-width:27px;
        min-width:27px;

        svg.ico-left {
            margin-left:1px;
        }
    }
    .btn.small.effect-enlarge {
        max-width:24px;
        min-width:24px;
    }
    .btn.x-small.effect-enlarge {
        max-width:19px;
        min-width:19px;
    }

    

    .effect-enlarge {
        transition:max-width .3s ease-in-out, padding .3s ease-in-out;
    }
    .effect-enlarge .btn-txt {
        transition:opacity .3s ease-in-out, max-width .3s ease-in-out, margin .3s ease-in-out;
    }

@-moz-document url-prefix() {
    .effect-enlarge {
        max-width:32px;
        min-width:32px;
    }
    .btn.x-large.effect-enlarge {
        max-width:58px;
        min-width:58px;
    }
    .btn.large.effect-enlarge {
        max-width:52px;
        min-width:52px;
    }
    .btn.medium.effect-enlarge {
        max-width:46px;
        min-width:46px;
    }
    .btn.normal.effect-enlarge {
        max-width:30px;
        min-width:30px;
    }
    .btn.small.effect-enlarge {
        max-width:27px;
        min-width:27px;
        
        svg.ico-left {
            margin-left:2px;
        }
    }
    .btn.x-small.effect-enlarge {
        max-width:21px;
        min-width:21px;

        svg.ico-left {
            margin-left:2px;
        }
    }
}
