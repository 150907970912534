.langs-section .col-2 .col:first-child {
    width:49%;
}
.langs-section .col-2 .col:last-child {
    margin-top:6px;
}
.langs-section .list-choose .item {
    margin:8px 0;
}
.langs-section .list-choose .item:hover {
    opacity:.7;
    cursor:pointer;
}
[lang='ru'] .livechat {
    font-size:8px;
}

/* [ DE/ES/RU ] */
[lang='de'] .alert-dialplan .alert-title,
[lang='es'] .alert-dialplan .alert-title,
[lang='ru'] .alert-dialplan .alert-title {
    font-size:19px;
}
[lang='de'] .alert-dialplan .helper {
    font-size:13px;
}
/*[lang='de'] .alert-upgrade .btn-wrap {
    width:149px;
    white-space:normal;
}
[lang='de'] .alert-upgrade .btn {
    font-size:16px;
    padding:5px 10px;
}
[lang='es'] .alert-upgrade .alert-title,
[lang='ru'] .alert-upgrade .alert-title {
    font-size:22px;
}*/
[lang='de'] .dialog .btn {
    font-size:11px;
}
[lang='ru'] .dialog .btn {
    font-size:14px;
}
[lang='ru'] .unit-title-large .unit-title {
    font-size:15px;
}
[lang='de'] h2 + .nav-pills-wrap .nav-pills .spacer,
[lang='es'] h2 + .nav-pills-wrap .nav-pills .spacer,
[lang='ru'] h2 + .nav-pills-wrap .nav-pills .spacer {
    margin:0 5px;
}
[lang='ru'] .dropdown-menu .cover-hd-large {
    font-size:23px;
}
[lang='ru'] .dropdown-menu-create-event .blend-info .btn {
    font-size:14px;
}
[lang='de'] .statistics-section .caption-small,
[lang='es'] .list-tiles small {
    font-size:11px;
}
[lang='ru'] .list-tiles.medium h3 {
    max-width:106px;
}
[lang='de'] .session-choosen-section .table th,
[lang='es'] .session-choosen-section .table th {
    font-size:13px;
}
[lang='ru'] .session-choosen-section .table th,
[lang='ru'] .import-contact-section .vr-list li {
    font-size:12px;
}
[lang='ru'] .import-contact-section .vr-list {
    padding-bottom:20px;
}
[lang='ru'] .table th .ico-right {
    margin-left:4px;
}
[lang='de'] .session-choosen-section .desc,
[lang='es'] .session-choosen-section .desc,
[lang='ru'] .session-choosen-section .desc {
    width:310px;
}
[lang='de'] .invite-section .btn-choice .hide-check + .btn {
    font-size:14px;
    line-height:19px;
}
[lang='de'] .component-presenter .name,
[lang='de'] .component-reply .name,
[lang='es'] .component-presenter .name,
[lang='es'] .component-reply .name,
[lang='pl'] .component-presenter .name {
    font-size:11px;
}

[lang='pl'], [lang='de'], [lang='es'] {

    .account-details-section dt {
        width:140px;
        font-size:14px;
    }

    .account-details-section dd {
        margin-left:150px;
    }
}

[lang='de'] .list-pictogram,
[lang='es'] .list-pictogram,
[lang='de'] .key .info,
[lang='es'] .key .info,
[lang='ru'] .key .info,
[lang='pl'] .list-tiles-box .tiles-title {
    font-size:12px;
}
[lang='es'] .key {
    height:110px;
}
[lang='ru'] .key .info {
    line-height:.8em;
}
[lang='de'] .list-tiles-box .tiles-title,
[lang='ru'] .list-tiles-box .tiles-title {
    font-size:14px;
}
[lang='es'] .list-tiles-box .tiles-title {
    font-size:13px;
}
[lang='es'] .create-event-section .nav-tabs-opaque a,
[lang='es'] .room-editor .nav-tabs-opaque a {
    white-space:nowrap;
}
[lang='ru'] .statistics-page .nav-tabs-opaque a,
[lang='de'] .statistics-page .nav-tabs-opaque a {
    font-size:11px;
}
[lang='de'] .welldone-section h3 {
    font-size:130px;
}
[lang='es'] .welldone-section h3 {
    font-size:140px;
}
[lang='es'] .welldone-section .btn-wrap .btn.x-large,
[lang='pl'] .welldone-section .btn-wrap .btn.x-large {
    font-size:22px;
}
[lang='de'] .welldone-section .btn-wrap .btn.x-large {
    font-size:17px;
}
[lang='ru'] select.large,
[lang='ru'] .welldone-section .btn-wrap .btn.x-large {
    font-size:15px;
}
[lang='de'] .welldone-section .social,
[lang='pl'] .welldone-section .social {
    font-size:14px;
}
[lang='ru'] .welldone-section .social dt {
    margin-top:4px;
}
[lang='ru'] .welldone-section .social dt,
[lang='ru'] .welldone-section .social {
    font-size:13px;
}
[lang='de'] .personal-info .list-editable-label,
[lang='de'] .personal-info .dl-horizontal dt,
[lang='ru'] .personal-info .list-editable-label,
[lang='ru'] .personal-info .dl-horizontal dt {
    width:95px;
}
[lang='de'] .personal-info .list-editable-label + .list-editable-control,
[lang='de'] .personal-info .dl-horizontal dd,
[lang='ru'] .personal-info .list-editable-label + .list-editable-control,
[lang='ru'] .personal-info .dl-horizontal dd {
    margin-left:110px;
}
[lang='de'] .personal-info .list-editable-label + .list-editable-control .form-block,
[lang='ru'] .personal-info .list-editable-label + .list-editable-control .form-block {
    margin-right:120px;
}
[lang='de'] .personal-info .list-editable-hov .f-edit,
[lang='ru'] .personal-info .list-editable-hov .f-edit {
    margin-right:95px;
}
[lang='de'] .subaccounts-new-section .form-horizontal .form-label {
    width:150px;
}
[lang='de'] .subaccounts-new-section .form-horizontal .form-control {
    margin-left:160px;
}
[lang='ru'] .subaccounts-new-section .form-horizontal .form-label {
    width:180px;
}
[lang='ru'] .subaccounts-new-section .form-horizontal .form-legend,
[lang='ru'] .subaccounts-new-section .form-horizontal .form-control {
    margin-left:190px;
}
[lang='es'] .integrations-plugin .app-hd,
[lang='ru'] .integrations-plugin .app-hd {
    width:63%;
}
[lang='es'] .integrations-plugin .app-bd,
[lang='ru'] .integrations-plugin .app-bd {
    width:36%;
}
[lang='ru'] .integrations-plugin .app-name {
    font-size:16px;
}
[lang='pl'] .c-collapsed-info-num {
    font-size:13px;
}
[lang='ru'] .c-collapsed-info-num {
    font-size:11px;
}
[lang='ru'] .helper {
    font-size:13px;
}
[lang='pl'] .helper h4 {
    font-size:32px;
}
[lang='ru'] .helper h4 {
    font-size:20px;
}
[lang='ru'] .presenters-section .table .title {
    width:95px;
}
[lang='ru'] .recordings-section thead th {
    font-size:11px;
}
[lang='ru'] .recordings-section thead .file-info {
    white-space:nowrap;
}
[lang='ru'] .addons-section .slider-step-wrap {
    margin-top:120px;
}
[lang='ru'] .addons-section .list-pictogram .list-pictogram-hd {
    font-size: 12px;
}
[lang='ru'] .input-group-addon.label {
    font-size:10px;
}
[lang='ru'] .social-expand .social > li {
    margin:26px 10px;
}
[lang='ru'] .social-medium,
[lang='ru'] .social-medium .btn-link {
    font-size:14px;
}
[lang='pl'] .social-expand .helper.pos-tr h4 {
    font-size:19px;
}
[lang='ru'] .event-details-section dt {
    width:195px;
    font-size:16px;
}
[lang='ru'] .event-details-section dd {
    margin-left:220px;
}
[lang='ru'] .table tfoot .form-control {
    width:190px;
}
[lang='ru'] .account-settings-section .header h2 {
    font-size:32px;
}
[lang='ru'] .page-header .btn.x-large {
    font-size:18px;
}
[lang='pl'] .nested-txt-medium,
[lang='de'] .nested-txt-medium {
    font-size:15px;
}
[lang='es'] .nested-txt-medium {
    font-size:14px;
}
[lang='ru'] .nested-txt-medium {
    font-size:13px;
}
[lang='es'] .payment-section .nested-card-expired,
[lang='ru'] .payment-section .nested-card-expired,
[lang='de'] .payment-section .nested-card-expired {
    padding-right:0;
    padding-left:0;
    font-size:11px;
}
[lang='pl'] .rebranding .room-footer {
    font-size:13px;
}

[lang='pl'] .statistics-page .summary-section .unit-title,
[lang='de'] .statistics-page .summary-section .unit-title,
[lang='es'] .statistics-page .summary-section .unit-title,
[lang='ru'] .statistics-page .summary-section .unit-title {
    font-size:12px;
}

[lang='br'], [lang='pt'] {
    .key .info {
        font-size: 11px;
        line-height: 1.2;
    }
}