.statistics-section .col-wrap,
.addons-boxes.col-wrap {
    margin-left:-15px;
    text-align:center;
    position:relative;
}
.statistics-section .col {
    float:left;
    width:184px !important;
    max-width: none;
    flex-grow: 0;
    flex-basis: auto;
    @media only screen and (max-width: 768px) {
        flex-basis: 50%;
    }
    @media only screen and (max-width: 568px) {
        flex-basis: 100%;
    }
}
.statistics-section .col[data-box="devices"] .dsp-cell {
    padding: 0 15px;
    overflow: hidden;;
}
.statistics-section .content {
    padding:0;
}
.meetings-section .statistics-section-row .truncate {
    max-width: 180px;
    display: inline-block;
    vertical-align: middle;
}
.meetings-section .lead {
    margin: 45px 0;
    text-align: center;
}
/**/
.statistics-section .unit-bd {
    padding:0;
    min-height:105px;
    width:100%;
    box-sizing:border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.statistics-section .dsp-cell {
    width:100%;
    height:auto;
}
.statistics-section .addnew {
    height:142px;
}
.statistics-section .emboss {
    font-size:55px;
    line-height:1.2;
}
.statistics-section .emboss-empty {
    color:#d8d8d8;
}
.statistics-section .caption {
    text-align:center;
    display:block;
    position:absolute;
    bottom:3px;
    left:0;
    right:0;
}
.statistics-section .caption-small strong {
    font-size:14px;
}
.statistics-section .rating-wrap {
    margin-top:15px;
}
.statistics-section .rating-wrap .caption-small {
    position:static;
}
/*.statistics-section .unit-muted:hover {
    color:#72c82e;
}*/
.statistics-section .nav-highcharts {
    line-height:1.4;
    position:absolute;
    bottom:0;
    z-index:10;
    display:inline-block;
    padding:0 5px;
}
.statistics-section .nav-next {
    right:0;
}
.statistics-section .nav-prev {
    left:0;
}

/* statistics section details */
.statistics-section-details .col {
    width:226px;
}
.statistics-section .highcharts-legend {
    font-size:12px;
}
.statistics-section .unit-title-large,
.statistics-section .unit-title-x-large {
    padding-right:5px;
    padding-left:5px;
}


/* choose a session */
/*.session-choosen-section .helper.pos-bl4 {
    left:338px;
    bottom:100%;
}*/
/* przycinanie znakow */
.session-choosen-section .desc {
    width: 50%;
}
.session-choosen-section .desc p {
    margin:3px 0;
}
.statistics-section-row {
    word-break: break-word;
}
.statistics-section-desc {
    flex: 0 0 50%;
    max-width: 50%;
    min-width: 400px;
}
.statistics-section-date,
.statistics-section-visitors,
.statistics-section-action {
    flex: 1 1 0;
    max-width: 100%;
    width: 100%;
    padding: 0 15px;
}
.statistics-section-action {
    padding-right: 0;
}
.statistics-section-url {
    max-width: 405px;
}