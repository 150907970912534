[data-icon*='caret'] {
    display:inline-block;
    width:0;
    height:0;
    line-height:0;
    vertical-align:middle;
    content:'';
    border:4px solid transparent;
}
[data-icon*='caret']:before {
    display:none;
}
[data-icon*='caret-bottom'] {
    border-bottom:0;
    border-top-color:$color-inherit;
}
[data-icon*='caret-left'] {
    border-right-color:$color-inherit;
    border-left:0;
}
[data-icon*='caret-right'] {
    border-left-color:$color-inherit;
    border-right:0;
}
[data-icon*='caret-top'] {
    border-bottom-color:$color-inherit;
    border-top:0;
    margin-top:-2px;
}