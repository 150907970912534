$webrtcGrey: #6b7183;
$webrtcSnow: #fff;
$webrtcDark: #022943;
$webrtcBlackLess: #1b1f2b;
$webrtcBtn: #1964f0;
$webrtcBtnHover: darken($webrtcBtn, 5%);
$webrtcSvg: $webrtcBtn;

.webrtc-beta {
    &-carousel {
        height: 600px;
        .carousel-item {
            height: 600px;
            overflow-x: hidden;
            overflow-y: auto;
            &-webrtc-beta {
                background-image: url($src-image + 'popup/webrtc-beta/webrtc-modal-bg.png');
                background-position: top center;
                background-repeat: no-repeat;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                padding-top: 55px;
                padding-bottom: 230px;
            }
            &-webrtc-beta-thanks {
                background-image: url($src-image + 'popup/webrtc-beta/webrtc-modal-start-using-bg.png');
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 100px;
                padding-bottom: 140px;
            }
        }
    }
    &-container {
        margin: 0 30px;
        &__svg {
            margin-bottom: 35px;
            color: $webrtcSvg;
            .svg-stroke {
                width: 65px;
                height: 65px;
                stroke-width: 2;
            }
        }
        &__label {
            font-family: $font-roboto-medium;
            font-size: 14px;
            color: $webrtcSnow;
            background-color: $webrtcDark;
            padding: 0 8px;
            text-transform: uppercase;
            margin: 0 auto 20px;
            display: inline-block;
            border-radius: 4px;
        }
        &__title {
            font-family: $font-roboto-regular;
            font-size: 26px;
            line-height: 36px;
            color: $webrtcSnow;
            margin: 0 0 50px;
            &--dark {
                color: $webrtcBlackLess;
                margin-bottom: 30px;
            }
        }
        &__desc {
            font-family: $font-roboto-regular;
            font-size: 16px;
            line-height: 24px;
            color: $webrtcSnow;
            margin-bottom: 30px;
            &--grey {
                color: $webrtcGrey;
                margin-bottom: 15px;
            }
        }
        img {
            width: 100%;
        }
    }
    &-caption {
        position: absolute;
        bottom: 65px;
        left: 30px;
        right: 30px;
        &__btn {
            font-family: $font-roboto-medium;
            font-size: 14px !important;
            padding: 15px 40px !important;
            box-shadow: 0px 13px 46px 0px rgba(25, 101, 240, 0.31);
            letter-spacing: normal !important;
            &.btn-success {
                background: $webrtcBtn;
                &:hover {
                    background: $webrtcBtnHover;
                }
            }
        }
    }
}
#webrtc-beta-modal {
    @media (min-width: 768px) {
        .modal-dialog {
            width: 650px;
            max-width: none;
        }
    }
}