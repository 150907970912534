.btn-group,
    .btn-choice {
        position:relative;
        display:inline-block;
        vertical-align:middle;
    }
    .btn-choice {
        border-radius:30px;
    }
    .btn-choice-x-small {
        border-radius:2px;
    }
    .btn-group > .btn,
    .btn-choice > .btn {
        position:relative;
        float:left;
        background-clip:padding-box;
    }
    .btn-group > .btn {
        box-shadow:inset 1px 0 0 rgba(255,255,255,.78), -1px 2px 0 rgba(0,0,0,.1);
    }
    .btn-choice > .btn {
        border-radius:30px;
        box-shadow:none;
    }
    .btn-choice-x-small > .btn {
        border-radius:2px;
    }
    .btn-group > .btn:first-child {
        box-shadow:inset 1px 0 0 rgba(255,255,255,.78), 0 2px 0 rgba(0,0,0,.1);
    }

    .btn-group .btn + .btn, .btn-choice .btn + .btn,
    .btn-group .btn + .btn-group, .btn-choice .btn + .btn-group,
    .btn-group .btn-group + .btn, .btn-choice .btn-choice + .btn,
    .btn-group .btn-group + .btn-group, .btn-choice .btn-choice + .btn-choice {
        margin:0;
    }
    .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
    .btn-choice > .btn:not(.start):not(.stop) {
        border-radius:0;
    }
    .btn-group > .btn:first-child,
    .btn-choice > .start {
        margin-left:0;
    }
    .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle),
    .btn-choice > .start:not(.stop):not(.force-not-first) {
        border-top-right-radius:0;
        border-bottom-right-radius:0;
    }
    .btn-group > .btn:last-child:not(:first-child),
    .btn-group > .dropdown-toggle:not(:first-child),
    .btn-choice > .stop:not(.start):not(.force-not-last) {
        border-bottom-left-radius:0;
        border-top-left-radius:0;
    }
    .btn-choice > .force-not-first {
      border-top-left-radius:0;
      border-bottom-left-radius:0;
    }
    .btn-choice > .force-not-last {
      border-top-right-radius:0;
      border-bottom-right-radius:0;
    }
    .btn-choice.paid {
      margin-left: -4px;
      border-left: solid 1px rgba(255, 255, 255, 0.78);
    }
    .btn-group > .btn-group {
        float:left;
    }
    .btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
        border-radius:0;
    }
    .btn-group > .btn-group:first-child > .btn:last-child,
    .btn-group > .btn-group:first-child > .dropdown-toggle,
    .btn-choice > .btn-choice:first-child > .stop {
        border-top-right-radius:0;
        border-bottom-right-radius:0;
    }
    .btn-group > .btn-group:last-child > .btn:first-child,
    .btn-choice > .btn-choice:last-child > .start {
        border-bottom-left-radius:0;
        border-top-left-radius:0;
        box-shadow:inset 1px 0 0 rgba(255,255,255,.78), -1px 2px 0 rgba(0,0,0,.1);
    }
    .btn-group > .btn + .dropdown-toggle {
        padding-right: 8px;
        padding-left: 8px;
    }


/* [ button choice ] */
    .btn-choice.no-change .hide-check:checked + .btn,
    .btn-choice.no-change .hide-check:checked + .btn.stop,
    .btn-choice.no-change .hide-check:checked + .btn.start {
        @include x-btn-default();
    }
    .btn-choice .hide-check + label,
    .btn-choice .hide-check + label [data-icon],
    .btn-choice .hide-check + label [data-icon]:hover,
    .btn-choice.no-change .hide-check:checked + .btn,
    .btn-choice.no-change .hide-check:checked + .btn [data-icon],
    .btn-choice.no-change .hide-check:checked + .btn [data-icon]:hover {
        color:#363a3d;
        font-size:16px;
        text-shadow:none;
    }

    .btn-choice .hide-check + label:hover {
        color:$color-link;
    }
    .btn-choice .hide-check + label.disabled-fake:hover {
        color: inherit;
        cursor: default;
    }

/* no-change : nie zmienia wygladu zaznaczonego elementu do momentu, kiedy klasa nie zostanie usunięta */
    .btn-choice.no-change .hide-check:checked + .btn,
    .btn-choice.no-change .hide-check:checked + .btn.stop,
    .btn-choice.no-change .hide-check:checked + .btn.start {
        box-shadow:none;
    }
    .btn-choice .hide-check:checked + label,
    .btn-choice .hide-check:checked + label [data-icon],
    .btn-choice .hide-check:checked + label [data-icon]:hover {
        color:#a5a7a8;
        text-shadow:none;
    }
    .btn-choice .hide-check + label {
        box-shadow:inset 1px 0 0 rgba(255,255,255,.78), -1px 2px 0 rgba(0,0,0,.1);
    }
    .btn-choice .hide-check + .start {
        box-shadow:inset 1px 0 0 rgba(255,255,255,.78), 0 2px 0 rgba(0,0,0,.1);
    }
    .btn-choice .hide-check:checked + label {
        background:#313537;
        background:-webkit-linear-gradient(top,  #363b3d 0%,#313537 100%);
        background:linear-gradient(to bottom,  #363b3d 0%,#313537 100%);
        box-shadow:0 2px 0 rgba(0,0,0,.63);
    }

    .btn-choice .btn,
    .btn-choice .btn [data-icon]:before {
        font-size:16px;
    }
    .btn-choice .btn [data-icon]:before {
        top:-1px;
    }
    .btn-choice-success .hide-check:checked + label,
    .btn-choice-success .hide-check:checked + label [data-icon],
    .btn-choice-success .hide-check:checked + label [data-icon]:hover {
        color:#fff;
    }
    .btn-choice-success .hide-check:checked + label {
        /*background:#6ebe3b;
        background:-webkit-linear-gradient(top,  #79cb45 0%,#6ebe3b 100%);
        background:linear-gradient(to bottom,  #79cb45 0%,#6ebe3b 100%);*/
        box-shadow:inset 0 2px 0 rgba(0,0,0,.16), 0 2px 0 rgba(0,0,0,.11);
    }


/* [ button choice size ] */
    .btn-choice .hide-check + .btn {
        padding:5px 12px;
        height:29px;
    }
    .btn-choice-medium .hide-check + .btn {
        padding:8px 12px;
        height:35px;
    }
    .btn-choice-large .hide-check + .btn {
        font-size:18px;
        padding:13px 22px;
        height:45px;
    }
    .btn-choice-x-small .hide-check + .btn {
        padding:2px 9px 0;
        height:22px;
    }


/* [ button choice - tip ] */
    .btn-choice .tip.pos-t {
        visibility:hidden;
        left:50%;
        transform:translateX(-50%);
    }

.dialog li .btn-success {
    color: #ffffff;
    &:hover {
        color: #ffffff;
    }
}