@import '../../../../../shared/web/views/src/scss/__partials/cover';
    /* [ empty ]  */
    .cover-empty {

        .expand & .cover-figure {
            color:$color-muted;
        }
    }


    /* wypozycjonowana zaslepka w skeleton */
    .skeleton.blend .tier,
    .skeleton.blend .sidebar {
        opacity:.4;
    }
    .skeleton.blend .blend-info {
        max-width:580px;
        top:10%;
        left:50%;
        transform:translate(-50%,0);
    }
    .cover-content .link-profile-page {
        background-color: #fff;
        position: absolute;
        border-radius: 2px;
        top: .3em;
        bottom: .3em;
        left: .8em;
        right: .8em;
        overflow: hidden;
        white-space: nowrap;
        line-height: 28px;
    }