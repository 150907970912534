.contact-add-section .col .in {
    padding-top:13px;
}
.contact-add-section .col-2 {
    margin-left:170px;
}
.contact-add-section .avatar-wrap {
    display:block;
    margin:0 auto;
}
.contact-section .page-wrap {
    width:923px;
}
/*.contact-section .components {
    height:100%;
    position:absolute;
    top:0;
    bottom:0;
}*/
.contact-section .unit-bd {
    padding:0;
}
.contact-section .personal-info-wrap .unit-ft {
    border-top:1px solid #f5f5f5;
    width: 100%;
    position:absolute;
    bottom:0;
    right:0;
    box-sizing:border-box;
}
.contact-section .tier,
.contact-section .dsp-table.specify-height {
    min-height:674px;
}
.contact-section .tier .dsp-empty {
    width: 100%;
}
.contact-section .components-body .content {
    padding:0;
}
.contact-section .components-body .nano {
    height:486px;
}
.contact-section .options .helper.pos-tl {
    top:60px;
    min-width:200px;
}
.contact-section .dsp-flex-wrap {
    @media (max-width: 991px) {
        flex-direction: column;
    }
    &.skeleton {
        .tier {
            @media (max-width: 991px) {
                width: auto;
            }
            @media (max-width: 767px) {
                min-height: auto;
            }
        }
        .sidebar {
            width: 300px;
            @media (max-width: 1200px) {
                width: 250px;
            }
            @media (max-width: 991px) {
                width: auto;
                dl {
                    margin-bottom: 0;
                }
            }
        }
    }
    .components-title {
        padding: 5px 25px 5px 15px;
    }
    .components-body .nano {
        width: auto;
    }
}
/* [ quick ] */
.book-wrap .filter .form-control {
    padding:23px 0;
    margin:0;
    border-bottom:1px solid #f7f7f7;
}
.book-wrap {
    @media (min-width: 768px) {
        border-right: 1px solid #f7f7f7;
    }
}
.book-wrap .book {
    margin:0 0 20px;
    @media (max-width: 767px) {
        min-height: 200px;
        height: auto;
        &.nano > .nano-content {
            position: absolute;
        }
    }
}
.personal-info-wrap {
    min-height:676px;
    @media (max-width: 991px) {
        min-height: auto;
    }
    @media (max-width: 767px) {
        .dropdown-list-file {
            width: 300px;
            right: 50%;
            transform: translateX(50%);
        }
        .dropdown-layout.pos-tr .dropdown-wrap:before,
        .dropdown-layout.pos-tr .dropdown-wrap:after {
            right: 50%;
            transform: translateX(50%);
        }
        .dropdown-list-file .cell-action,
        .dropdown-list-file .desc {
            width: auto;
        }
    }
    .personal-info .in {
        padding-left: 0;
        padding-right: 0;
    }
}
.personal-info .in {
    padding:23px 20px 60px;
}
.personal-info .cover-figure {
    padding:0 10px 0 0;
}
.personal-info .cover-hd {
    font-size:24px;
    font-weight:normal;
    line-height:1.4em;
    margin-top:0;
    word-break: break-word;
}
.personal-info .social {
    width:22px;
    padding-right:10px;
}
.personal-info .options {
    text-align:left;
    float:none;
}
.personal-info .list-editable-label,
.personal-info dt {
    color:#606365;
}
.personal-info .list-editable-label,
.personal-info .dl-horizontal dt {
    width:150px;
    margin:0;
    letter-spacing:0;
}
@media (max-width: 991px) {
    .personal-info .list-editable-label,
    .personal-info .dl-horizontal dt {
        text-align: left;
        float: none;
        width: auto;
        margin: 0 5px;
    }
}
.personal-info .list-editable-label + .list-editable-control,
.personal-info .dl-horizontal dd {
    margin-left:165px;
    -ms-word-break:break-all;
    word-break:break-word;
    word-break:keep-all;
    word-wrap:break-word;
    -webkit-hyphens:auto;
       -moz-hyphens:auto;
        -ms-hyphens:auto;
            hyphens:auto;
}
@media (max-width: 991px) {
    .personal-info .list-editable-label + .list-editable-control,
    .personal-info .dl-horizontal dd{
        margin: 0 5px;
    }
    .personal-info .list-editable-label + .list-editable-control .form-block {
        margin: -4px 115px -1px 0;
    }
}
.personal-info .list-editable {
    margin-top:30px;
}


/* [ advanced ] */
.adv-search .unit-bd {
    padding:23px;
}
.segment-hd {
    .lead {
        font-size:13px;
        width:48%;
    }
    &.form-inline .form-group .form-label,
    &.form-inline .form-group .form-control {
        @media (max-width: 767px) {
            display: block;
        }
    }
    &.form-inline .form-group .form-label {
        @media (max-width: 767px) {
            padding: 0 0 15px;
            &.lead {
                width: 100%;
            }
        }
    }
    &.form-inline .form-group .form-control {
        @media (max-width: 767px) {
            .select2-container .select2-choice > .select2-chosen {
                white-space: normal;
            }
        }
    }
}
.adv-search .form-group {
    margin:10px 0 20px;
}
.adv-search .separate-title {
    margin:15px auto;
}
.adv-search .col-operator .addnew-wrap {
    margin:0;
}
.adv-search .col-operator .addnew-wrap.small .addnew {
    width: 100%;
}
.adv-search .col-delete {
    position:absolute;
    left:calc(100% - 15px);
    top:5px;
    z-index:3;
}
.adv-search .inplace {
    text-align:right;
}
.adv-search .inplace-form {
    margin-top:3px;
}
.adv-search {
    @media (max-width: 1280px) {
        margin-right: 60px;
    }
}
.search-contact-section {
    .overlap {
        &.pos-tr {
            @media (max-width: 1280px) {
                left: calc(100% - 60px);
            }
        }
        @media (max-width: 767px) {
            &.large .overlap-info {
                font-size: 18px;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}
.search-contact-section .name {
    width:180px;
}
.search-contact-section .email {
    width:190px;
}
.search-contact-section .cell-action {
    width:80px;
}
.search-contact-section .tip.pos-bc {
    @media (max-width: 1600px) {
        left: auto;
        right: 0;
        transform: none;
        .tip-wrap:after {
            left: calc(100% - 30px);
        }
    }
}