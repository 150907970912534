$screen-old-phones: '320px';
$screen-xs-max: '576px';
$screen-sm-max: '768px';
$screen-md-max: '992px';
$screen-lg-max: '1260px';
$screen-mac15: '1440px';

@mixin old {
    @media only screen and (max-width: #{$screen-old-phones}) {
        @content;
    }
}
@mixin xs {
    @media only screen and (max-width: #{$screen-xs-max}) {
        @content;
    }
}
@mixin sm {
    @media only screen and (max-width: #{$screen-sm-max}) {
        @content;
    }
}
@mixin md {
    @media only screen and (max-width: #{$screen-md-max}) {
        @content;
    }
}
@mixin lg {
    @media only screen and (max-width: #{$screen-lg-max}) {
        @content;
    }
}
@mixin mac15 {
    @media only screen and (max-width: #{$screen-mac15}) {
        @content;
    }
}

@mixin min-old {
    @media only screen and (min-width: #{$screen-old-phones}) {
        @content;
    }
}
@mixin min-xs {
    @media only screen and (min-width: #{$screen-xs-max}) {
        @content;
    }
}
@mixin min-sm {
    @media only screen and (min-width: #{$screen-sm-max}) {
        @content;
    }
}
@mixin min-md {
    @media only screen and (min-width: #{$screen-md-max}) {
        @content;
    }
}
@mixin min-lg {
    @media only screen and (min-width: #{$screen-lg-max}) {
        @content;
    }
}
@mixin min-mac15 {
    @media only screen and (min-width: #{$screen-mac15}) {
        @content;
    }
}
