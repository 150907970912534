.event-details-main .nav-tabs-justify {
    .nav-tabs-opaque {
        display: flex;
        li {
            display: inline-flex;
            flex: 1 1 auto;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }
}
.event-details-section dt {
    font-size:18px;
    line-height:1.2;
    width:165px;
    @media (max-width: 768px) {
        width: 100% !important;
        float: none;
        text-align: left;
        margin-bottom: 10px;
    }
}
.event-details-section dd {
    color:#555;
    margin:0 0 25px 190px;
    @media (max-width: 768px) {
        margin:0 0 25px 50px !important;
    }
}
.event-details-section dd .txt-featured {
    font-size:26px !important;
}
.event-details-section {
    .list-caret-item {
        color:#606365;
        .ui-slider-output[type='text'], .ui-slider-output {
            max-width: 45px;
        }
    }
    .list-caret-automated {
        margin-right: 30%;
        padding: 4px 0;
        @media (max-width: 991px) {
            margin-right: auto;
        }
        .list-caret-item {
            display: inline-block;
            padding: 1px 20px 1px 30px;
            time {
                color: #4c5052;
            }
        }
    }
    @media (max-width: 768px) {
        .vr-list li {
            display: block;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .vr-list .spacer {
            display: none;
        }
    }
} 
.list-caret-url-presenter {
    .message-info {
        display: none;
        background-color: #e5f7fd;
        color: #5c8391;
        padding: 0 5px 2px;
        border-radius: 4px;
        font-family: $font-roboto-light;
        font-size: 12px;
        width: fit-content;
        margin-left: 30px;
        svg {
            color: #00afec;
        }
    }
    .list-caret-item {
        &:before {
            top: 16px;
        }
        .open {
            display: inline-block;
            color: #737373;
            cursor: unset;
            margin-right: 8px;
        }
        strong {
            display: none;
            font-family: $font-roboto-regular;
            color: #737373 !important;
            word-break: break-word;
            .label {
                font-family: $font-roboto-medium;
                font-size: 12px;
                top: -2px;
                margin: 0;
            }
        }
    }
}
.event-details-section .list-caret-item strong,
.event-details-section .list-caret-item time {
    color:#363a3d;
    font-weight:normal;
}
.event-details-section .dl-horizontal {
    margin:0;
}
.event-details-section .reminders,
.event-details-social .expand .form-action {
    margin-bottom:0;
}
.event-details-social .social .list-inline {
    position:relative;
    top:-5px;
    @media (max-width: 768px) {
        top: auto;
    }
}
.event-details-social > .in {
    padding-top:0;
    padding-bottom:0;
}
.event-details-social .expand dd {
    margin-right:190px;
}
.event-registration-section .registration-info-preview {

    .list-caret {
        color:#606365;
        font-size:20px;
        margin-bottom:15px;
    }
    .truncate {
        max-width:650px;
    }
}
.event-registration-section .results {
    @media (max-width: 768px) {
        padding-left: 34px;
    }

    .name {
        width:310px;
    }
    .cell-icon + .name {
        width:260px;
    }
    .cell-date {
        width:180px;
    }
}

.event-invite-section .table .email {
    width:300px;
}
.event-invite-section .table .cell-date {
    width:200px;
}
.event-invite-section th:first-child,
.event-invite-section td:first-child {
    padding-left:25px;
}
.event-invite-section .email .name {
    padding:2px 0;
}
.event-invite-section .email .name [class*='social-ico-'] {
    position:relative;
    margin-top:-2px;
}
.event-invite-section .email .name .highlight[data-icon] {
    font-size:18px;
    line-height:1;
    vertical-align:middle;
}
.event-invite-section th:last-child,
.event-invite-section td:last-child {
    padding-right:25px;
}
.event-invite-section .cell-action .notify {
    padding: 0 10px;
    white-space: nowrap;
}
// .event-invite-stats .col {
//     float:left;
//     width:42%;
// }
// .event-invite-stats .col:last-child {
//     width:55%;
//     margin-left:3%;
// }
.event-invite-stats h4 {
    margin-bottom:15px;
}
.event-invite-stats h5 {
    color:#606365;
    letter-spacing:0;
    margin:0 0 13px;
    padding:0 0 5px;
    border-bottom:1px solid #f2f2f2;
}
.event-invite-stats .social > li {
    margin-right:13px;
    @media (max-width: 991px) {
        margin-bottom: 10px;
    }
}
.event-invite-stats .social > li:last-child {
    margin-right:0;
}
.event-invite-stats .social > li span {
    font-size:24px;
    vertical-align:middle;
}
.event-invite-stats .ico-left {
    margin-right:5px;
}
.list-presenters {
    margin:-20px 0 0 -40px;
    position:relative;
}
.list-presenters .col {
    margin:20px 0 0 40px;
    float:left;
}
.list-presenters .avatar {
    width:162px;
    height:162px;
}
.list-presenters .avatar [data-icon='P'] {
    font-size:160px;
}
.list-presenters .thumb-title {
    color:#555;
    margin-top:5px;
}
.list-presenters {
    .addnew-wrap {
        margin:0 0 10px;

        &.pos-r {
            top:20px;
            margin-left:25px;
            width:125px;
        }

        & + .thumb-title {
            color:#cfcfcf;
        }
    }
    .addnew {
        height:172px;
        width:140px;
    }
}
.event-embed-section textarea {
    min-height:80px;
}
.event-embed-section h3 + p {
    color:#606365;
}
.room-embed {
    position:relative;
}
.event-embed-section .room-embed .helper.pos-tl {
    margin-top:0;
}
// .room-embed .col-wrap {
//     margin-left:-2%;
// }
// .room-embed .col {
//     float:left;
//     width:80%;
//     margin:0 0 0 2%;
// }
// .room-embed .col:first-child {
//     width:15%;
// }
.room-embed .form-inline .form-label {
    text-align:right;
    @media (max-width: 768px) {
        width: 100px;
    }
}
.room-embed .form-inline .form-control {
    width:70px;
    @media (max-width: 768px) {
        width: auto;
    }
}
.room-embed .form-inline .form-group {
    margin:5px 0 12px;
}
.room-embed .helper {
    width:195px;
}
.room-embed + .room-widgets > .in {
    padding-top:0;
}


.room-widgets .unit-bd {
    text-align:center;
    padding:10px;
    display:table;
    height:135px;
    width:100%;
    box-sizing:border-box;
}
// .room-widgets .col-wrap {
//     margin-left:-2%;
// }
// .room-widgets .col {
//     float:left;
//     width:48%;
//     margin:0 0 0 2%;
// }

.room-widgets .widget-box {
    position:relative;
}
.room-widgets .widget-box .btn-wrap {
    margin-bottom:30px;
}
.room-widgets .widget-box .form-group {
    color:#555;
    font-size:12px;
    margin:0;
    position:absolute;
    z-index:4;
    top: 100px;
    left: 15px;
    right: 15px;
    .f-holder {
        text-align: center;
    }
}

/* widgets - form custom */
.form-custom-widget .form-horizontal .form-label {
    width:250px;
    @media (max-width: 991px) {
        text-align: left;
        float: none;
        width: 100%;
    }
}
.form-custom-widget .form-horizontal .form-control {
    margin-left:270px;
    margin-right:200px;
    @media (max-width: 991px) {
        margin: 0;
    }
}
.form-custom-widget .col-wrap-check {
    margin-top:30px;
}
.form-custom-widget .col-wrap-check .check {
    top:3px;
}
.form-custom-widget .col-wrap-check .f-holder {
    float:left;
    width:50%;
    @media (max-width: 575px) {
        float: none;
        width: 100%;
    }
}
.form-custom-widget .row-wrap-check {
    font-size:24px;
    margin:0 200px 10px 270px;
    @media (max-width: 991px) {
        margin: 0 0 10px;
    }
}
.form-custom-widget .row-wrap-check .check {
    top:10px;
}
.form-custom-widget .col-wrap-check,
.form-custom-widget .form-label {
    font-size:16px;
}
.event-details-main .helper.pos-tl {
    top:100%;
    margin:20px 0 0 15px;
}
.event-details-main .page-wrap {
    width:923px;
}
.event-details-main .tab-ft .list-inline {
    & > li {
        &:last-child {
            float: right;
        }
    }
    @media (max-width: 768px) {
        text-align: center;
        & > li {
            display: block;
            margin-right: 0;
            .effect-enlarge {
                max-width: 250px !important;
                .btn-txt {
                    opacity: 1;
                    max-width: 200px;
                    margin: 0 .4em;
                }
            }
            &:first-child {
                margin-bottom: 15px;
            }
            &:last-child {
                float: none;
                display: block;
                margin-top: 15px;
            }
        }
    }
}
