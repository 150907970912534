/*---------------------------------------------------------------------------------------------
[ Dashboard - Claim tutorial reward ]
*/
.claim {
    
    &-head {
        background: #f5f6fb;
        padding: 100px 0 110px;
        text-align: center;
        
        .main {
            padding: 0;
        }
        
        h2 {
            font-family: 'robotobold', sans-serif;
            font-size: 52px;
            color: #505a7d;
        }
        
        p {
            margin-bottom: 30px;
            font-family: $font-roboto-light;
            font-size: 25px;
            color: #979eb4;
            
            span {
                font-family: 'robotobold', sans-serif;
                color: #6ebe3b;
            }
            
        }
        
    }
    
    &-reward {
        background: #fff;
        padding-bottom: 80px;
        text-align: center;
        
        form {
            margin-top: -148px;
        }
        
        &-btn {
            margin-top: 70px;
            
            .btn.disabled,
            .btn[disabled='disabled'],
            fieldset[disabled='disabled'] .btn {
                box-shadow:inset 0 -2px 0 rgba(0,0,0,.1), 0 2px 0 rgba(0,0,0,.05);
            }
            
            .btn-success.disabled {
                background: #e0e2ee;
                background: -webkit-linear-gradient(top,  #f5f6fb 0%,#e0e2ee 100%);
                background: linear-gradient(to bottom,  #f5f6fb 0%,#e0e2ee 100%);
            }
            
        }
    }
    
    &-radio {
        position: relative;
        display: inline-block;
        margin: 0 15px;
        
        label {
            padding: 240px 0 10px;
            width: 200px;
            font-family: $font-roboto-light;
            font-size: 21px;
            color: #505a7d;
        }
        
        &:hover .claim-radio-circle {
            box-shadow: 0px 10px 18px 0px rgba(40, 45, 56, 0.1);
        }
        
        input[type=radio]:checked ~ .claim-radio-circle {
            background: #6ebe3b;
            
            svg {
                color: #fff;
            }
        }
        
        &-circle {
            background: #fff;
            position: absolute;
            border-radius: 100%;
            height: 200px;
            width: 200px;
            z-index: -1;
            top: 20px;
            left: 50%;
            margin-left: -100px;
            box-shadow: 0px 10px 18px 0px rgba(40, 45, 56, 0.03);
            -webkit-transition: all .25s ease-in-out;
                    transition: all .25s ease-in-out;
            
            svg {
                position: absolute;
                top:50%;
                left: 50%;
                width: 70px;
                height: 70px;
                margin-top: -35px;
                margin-left: -35px;
                color: #6ebe3b;
                -webkit-transition: all .25s ease-in-out;
                        transition: all .25s ease-in-out;
                stroke-width: 3;
            }
                                

        }
        
    }
    
}

