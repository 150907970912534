@import '../../../../../shared/web/views/src/scss/__partials/table';


/* komorka tabeli zawierajaca date */
    .cell-date {
        width:130px;
        text-align:center;
        position: relative;

        &-wrap {
            display: table;
            width: 100%;
            min-height: 70px;
        }

        &-middle {
            display: table-cell;
            vertical-align: middle;
        }

    }
    .cell-date time {
        font-size: 11px;
        display: block;
        margin-top: -2px;
    }
    .cell-date strong {
        color:#363a3d;
        display:block;
        margin: -2px 0 3px;
        line-height: 1;
    }
    .cell-date [data-icon] {
        font-size:30px;
        color:#c2c2c2;
        line-height:1;
    }
    .cell-date [data-icon='B'] {
        font-size:46px;
    }
    .cell-date [data-icon='B'] + * {
        margin-top:-10px;
    }
    .cell-date .svg-stroke {
        color: #c2c2c2;
        width: 35px;
        height: 35px;
        stroke-width: 2px;
    }
    
/* jesli w tabeli wystepuje miniatura lub duza ikona */
    .thumb {
        text-align:center;
    }
    .thumb .figure-wrap,
    .thumb .avatar-wrap {
        display:block;
    }
    .thumb [data-icon]:not([data-icon="P"]) {
        color:#c2c2c2;
        font-size:65px;
        line-height:1;
        display:inline-block;
    }
    .thumb [data-icon='b']:before {
        top:-5px;
    }

/* blednie wygenerowana miniatura */
    .warning .figure-obj,
    .warning .thumb [data-icon] {
        color:$color-warning;
    }
    .error .figure-obj,
    .error .thumb [data-icon]  {
        color:$color-error;
    }




.delete .actions [data-icon='H']:before {
    top:-4px;
}


/*---------------------------------------------------------------------------------------------
[ uploading/converting ]
*/
    .uploading .list-hd,
    .converting .list-hd {
        color:$color-success;
        font-size:12px;
        float:left;
        min-width:60px;
    }
    .uploading .load-state,
    .converting .load-state {
        margin:5px 0 0;
    }
    .load-state [data-icon] {
        color:$color-error;
        font-size:10px;
        vertical-align:middle;
    }
    .load-state [data-icon]:before {
        top:-2px;
    }
    .uploading .progress,
    .converting .progress {
        float:left;
        margin:6px 8px 0 0;
        width:195px;
        height:8px;
    }
    .uploading .progress:before,
    .converting .progress:before {
        display:none;
    }
    .uploading .progress {
        background:#f2f2f2;
    }
    .uploading .progress-bar {
        background:#72c82e;
    }
    .converting .progress {
        background:#72c82e;
    }
    .converting .progress-bar {
        background:#5ba81f;
    }
    /*.uploading .img-thumb,
    .converting .img-thumb {
        min-width:79px;
        min-height:59px;
        background:#e0e0e0 url($src-image + 'indicators/loader_table_thumb_24.gif') no-repeat 50% 50%;
    }*/
    .converting .load-state [data-icon='H'] {
        visibility:hidden;
    }


/* uploading / converting w tabeli */
    table .uploading td,
    table .converting td,
    table .uploading h6,
    table .uploading h6 a,
    table .converting h6,
    table .converting h6 a,
    .converting .cell-action-icons a,
    .uploading .cell-action-icons a,
    .converting .cell-action-icons [data-icon],
    .uploading .cell-action-icons [data-icon],
    table .converting strong,
    table .converting .vr-list,
    .converting .cell-desc-small,
    .uploading .cell-desc-small {
        color:#c2c2c2;
    }
    table .uploading h6,
    table .uploading a,
    table .converting h6,
    table .converting a {
        pointer-events:none;
        cursor:not-allowed;
    }
    .uploading .cell-action .btn,
    .converting .cell-action .btn {
        color:#fff;
        background:#c2c2c2;
        opacity:.5;
        box-shadow:inset 0 1px 0 rgba(0,0,0,.3);
    }
