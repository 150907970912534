.main .timeline {
    margin:0;
}
.timeline .page-wrap {
    width:810px;
    position:relative;
    @media (max-width: 992px) {
        width: 720px;
    }
    @media (max-width: 768px) {
        width: 540px;
        padding: 0;
    }
    @media (max-width: 576px) {
        width: 100%;
    }
}
.timeline .col-wrap {
    margin:0 -37px;
    @media (max-width: 768px) {
        margin: 0;
    }
}
.timeline .col,
.timeline .timeline-col {
    float:left;
    width:368px;
    margin:15px 27px 0;
    @media (max-width: 992px) {
        width: 318px;
        margin-right: 37px;
        margin-left: 37px;
    }
    @media (max-width: 768px) {
        width: 100%;
        float: none;
        margin-right: 0;
        margin-left: 0;
        padding: 0 15px;
    }
}
.timeline .unit {
    margin:0;
}
.timeline section {
    padding:30px 0;
}
.timeline .col:nth-child(odd),
.timeline .timeline-col:nth-child(odd)  {
    clear:both;
}
.timeline .col:nth-child(even),
.timeline .timeline-col:nth-child(even) {
    margin-top:55px;
    float:right;
    clear:right;
    @media (max-width: 768px) {
        margin-top: 15px;
        float: none;
    }
}
.timeline .col:nth-child(even),
.timeline .timeline-col:nth-child(even) .point {
    left:auto;
    right:100%;
}


.timeline .line {
    position:absolute;
    width:12px;
    z-index:1;
    top:0;
    bottom:80px;
    left:50%;
    margin-left:-6px;
    background:url($src-image + 'timeline/line.gif') repeat-y 0 0;
    @media (max-width: 768px) {
        display: none;
    }
}
.timeline .line:before,
.timeline .line:after {
    content:'';
    display:block;
    position:absolute;
    z-index:2;
}
.timeline .line:before {
    background:url($src-image + 'timeline/line_top.png') no-repeat 0 0;
    width:34px;
    height:22px;
    top:-15px;
    left:50%;
    margin-left:-17px;
    display:none;
}
.timeline .line:after {
    background:url($src-image + 'timeline/line_bottom.png') no-repeat 0 0;
    width:12px;
    height:57px;
    bottom:-56px;
    left:50%;
    margin-left:-6px;
}
.timeline .future .line:after {
    display:none;
}
.timeline .future .line:before {
    display:block;
}
.timeline .future .line {
    bottom:-30px;
    top:35px;
}

/**/
.timeline hr {
    margin:0;
}
.timeline h3,
.timeline h4 {
    margin:0 0 5px;
    -ms-word-break:break-all;
    word-break:break-word;
    word-break:keep-all;
    word-wrap:break-word;
    hyphens:auto;
    @media (max-width: 992px) {
        font-size: 20px;
    }
}
.timeline h3 a {
    color:#363a3d;

    &:hover {
        color:$color-link;
    }
}
.timeline .canceled h3 {
    color:#888;
    font-size:30px;
    line-height:1.2;

    em {
        color:#d8d8d8;
        font-size:22px;
    }
}

.timeline .list-inline [data-icon]:before {
    font-size:18px;
    top:1px;
}
.timeline .list-inline [data-icon='K']:before {
    font-size:16px;
    top:0;
}


.timeline .btn-group .x-small [data-icon*='caret-bottom'] {
    margin:0;
    vertical-align:baseline;
}


.timeline .badge {
    letter-spacing:0;
    background: #6ebe3b;
    background: -webkit-linear-gradient(top,  #8dcf52 0%,#5eb438 100%);
    background: linear-gradient(to bottom,  #8dcf52 0%,#5eb438 100%);
    box-shadow:inset 0 -2px 0 rgba(0,0,0,.16), 0 2px 0 rgba(0,0,0,.11);
    min-height:18px;
    min-width:18px;
    line-height:12px;
    position:relative;
    top:1px;
}
.timeline .future .badge {
    background: #6a6a6a;
    background: -webkit-linear-gradient(top,  #7d7d7d 0%,#656565 100%);
    background: linear-gradient(to bottom,  #7d7d7d 0%,#656565 100%);
}
.timeline .canceled .badge {
    background:#d8d8d8;
    background: -webkit-linear-gradient(top,  #e2e2e2 0%,#d2d2d2 100%);
    background: linear-gradient(to bottom,  #e2e2e2 0%,#d2d2d2 100%);
}
.timeline .live .badge {
    font-size:8px;
    color:#fff;
    font-weight:normal;
    text-transform:uppercase;
    padding:1px 4px;
    min-height:15px;
    top:-3px;
    position:relative;
    background:#ff4200;
    background: -webkit-linear-gradient(top,  #ff5107 0%,#ff420b 100%);
    background: linear-gradient(to bottom,  #ff5107 0%,#ff420b 100%);
}

.timeline .btn-now,
.timeline .past {
    position:relative;
    z-index:3;
}
.timeline .past {
    padding-top:0;
    top:-14px;
}
.timeline .past .page-wrap {
    min-height:120px;
}
.timeline .past .btn-wrap {
    margin-top:30px;
}
.timeline .past .btn-now {
    margin:0;   
}
.timeline .past.live {
    top:-78px;
}
.timeline .past.live .btn-now {
    display:none;
}
.timeline .past .unit-bd {
    padding:0;
}
.timeline .future {
    background:#f7f7f7;
}
.timeline .future .caption {
    margin:10px 0 0;
}
.timeline .future .page-wrap {
    min-height:35px;
}
.timeline .future .unit-hd {
    background-color:#464749;
}
.timeline .canceled .unit-hd {
    background:#d8d8d8;
}
.timeline .canceled .unit-bd {
    padding:15px;
}

/*.timeline .unit-hd [data-icon]:before {
    color:#6ebe3b;
}*/
.timeline .future .unit-hd [data-icon]:before {
    color:#b1b1b1;
}
.timeline .live .unit-hd [data-icon]:before {
    color:#ff4200;
}
.timeline .canceled .unit-hd [data-icon]:before {
    color:#fff;
}


.timeline .point {
    width:96px;
    text-align:center;
    height:28px;
    position:absolute;
    z-index:3;
    top:50px;
    left:100%;
    @media (max-width: 992px) {
        width: 86px;
    }
    @media (max-width: 768px) {
        display: none;
    }
}
.timeline .point:before,
.timeline .point:after {
    content:'';
    width:0;
    height:0;
    display:block;
    position:absolute;
    left:0;
    top:1px;
    border:10px solid transparent;
}
.timeline .point:before {
    border-left-color:#fff;
    z-index:2;
    left:-1px;
}
.timeline .point:after {
    border-left-color:rgba(0,0,0,0.1);
    z-index:1;
}
.timeline .col:nth-child(even) .point:before,
.timeline .col:nth-child(even) .point:after,
.timeline .timeline-col:nth-child(even) .point:before,
.timeline .timeline-col:nth-child(even) .point:after {
    border-left-color:transparent;
    left:auto;
    right:0;
}
.timeline .col:nth-child(even) .point:before,
.timeline .timeline-col:nth-child(even) .point:before {
    border-right-color:#fff;
    right:-1px;
}
.timeline .col:nth-child(even) .point:after,
.timeline .timeline-col:nth-child(even) .point:after {
    border-right-color:rgba(0,0,0,0.1);
}
.timeline .timeline-col .unit-title {
    @media (max-width: 992px) {
        font-size: 16px;
    }
}

.timeline .helper {
    width:200px;
}
.timeline .helper.pos-br {
    bottom:192px;
}
.timeline .helper.pos-bl {
    bottom:100%;
    margin:0 0 23px 25px;
}
/* zabezpieczenie na load more events */
.timeline .col + .col + .col .helper {
    display:none;
}


/* zaslepka dla timeline */
.timeline .unit .btn-wrap {
    margin-top:10px;
}


.timeline-empty {
    background:#fff;
}
.timeline-empty .timeline {
    background:url($src-image + 'timeline/empty.jpg') no-repeat 50% 0;
    height:1045px;
    padding-top:115px;
}
.timeline .diagram-mobile {
    @media (max-width: 576px) {
        overflow-x: scroll;
    }
}