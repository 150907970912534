.statistics-page {
    padding-top:23px;
    &.meetings-section {
        padding-top: 0;
    }
}
.statistics-page .unit {
    margin-bottom:32px;
}
.statistics-page {
    .nav-tabs-opaque {
        svg {
            stroke-width: 1px;
        }
    }
}
.polls-section .unit .unit:last-child {
    margin-bottom:0;
}
.polls-section .poll-figure {
    display:inline-block;
    margin:-10px 0 15px;
}
.polls-section .poll-figure .figure {
    width:138px;
    height:76px;
}
.polls-section .poll-figure img {
    position:absolute;
    top:50%;
    transform:translateY(-50%);
}
.polls-section .list-choose .poll-figure {
    margin:5px 0 0 16px;
}

.polls-rate-section .col-progress {
    width:154px;
}
.polls-rate-section .col-info {
    width:145px;
}
.fancybox-skin .polls-rate-section .btn-link {
    font-size:inherit;
    padding-right:0;
    padding-left:0;
    top:-1px;
}


.change-password-section .content {
    padding-bottom:40px;
}
.change-password-section .btn {
    min-width:205px;
}


.api-section .api-row,
.api-section .api-add-row {
    margin:25px auto;
    max-width:482px;
}
.api-section .form-legend {
    max-width:480px;
}
.api-section .form-group {
    margin:5px 0;
}
.api-section .api-row .form-control:last-child {
    width:67px;
}
.api-section .addnew-wrap {
    padding:5px 0;
    margin-bottom:0;
}

.advance-section .page-wrap {
    width:980px;
}
.advance-section .btn.large {
    min-width:104px;
    padding:6px 22px;
}
.advance-section .col-wrap {
    margin:-26px 0 4% -5%;
}
.advance-section .col {
    display:inline-block;
    float:none;
    vertical-align:top;
    width:45%;
    margin-left:5%;
    margin-top:60px;
    box-sizing: border-box;
}
.advance-section {
    &__option {
        margin-top: 60px;
        box-sizing: border-box;
    }
}
.advance-section .cover {
    margin:0;

    .check {
        top:3px;
    }
}
.advance-section .cover-content {
    vertical-align:top;
}
.advance-section .content {
    padding-bottom:60px;
}