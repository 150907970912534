.book {
    height:575px;
}
.book.nano > .nano-content {
    padding:0;
}
.book .letter {
    color:#c2c2c2;
    font-size:18px;
    line-height:1.2;
    margin:17px 0 10px;
    border-bottom:2px solid #e5e5e5;
}
.book .letter:first-child {
    margin-top:0;
    padding-top:10px;
}
.book .name {
    cursor:pointer;
    font-size:16px;
    padding:2px 20px;
    position:relative;
}
.book .name.social {
    padding-left:49px;
}
.book .name:before {
    font-size:12px;
    position:absolute;
    right:7px;
    top:6px;
    display:none;
}
.book .name:hover {
    background-color:rgba(205,205,205,.1);
}
.book .name.active,
.book .name.active:hover {
    color:#fff;
    /*background-color:#6ebe3b;*/
}
.book .name.active:before {
    display:block;
}
.book .social [class*='social-ico-'] {
    position:absolute;
    left:20px;
    top:4px;
}
