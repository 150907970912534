body {
    -webkit-font-smoothing:antialiased;
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%;
    -webkit-tap-highlight-color:transparent;
}

strong {font-weight:bold;}
sup {font-size:.6em; line-height:1; vertical-align:.42em;}
code {font-family:$font-monospace}

a {
    color:$color-link;
    text-decoration:none;

    &:focus {
        color:$color-link;
        text-decoration:none;
        outline: none;
    }
    
    &:hover {
        color:$color-link-hover;
        text-decoration:none;
    }
}

hr,
.spacer {
    margin:15px 0;
    padding:0;
    height:0;
    font-size:0;
    line-height:0;
    width:100%;
    border:0;
    background:none;
}

ul, ol {padding-left:40px; margin-bottom: 0;}
ul {list-style-type:disc;}
ol {list-style-type:decimal;}
i[data-icon],
i.ico {font-style:normal;}

label {
    cursor:pointer;
    display:inline-block;
}
input, textarea, select, button {
    resize:none;
    margin:0;
    padding:0;
    border:0;
    outline:none;
    font-size:1em;
    font-family:inherit;
    font-size:inherit;
    line-height:inherit;
    background:transparent;
    appearance:none;
    box-sizing:border-box;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
    display:none;
}
select {
    -webkit-appearance:button;
       -moz-appearance:button;
            appearance:button;
}

::placeholder {color:#c2c2c2}

///*::selection {background:#c9e1b9}
//::-moz-selection {background:#c9e1b9}*/

.fR {float:right}
.fL {float:left}
.tC {text-align:center !important;}
.tR {text-align:right}
.tL {text-align:left !important;}
.tB {font-weight:bold;}
.text-uppercase {text-transform:uppercase;}
.nowrap {white-space:nowrap;}
.break-word {
    -ms-word-break:break-all;
    word-break:keep-all;
    word-wrap:break-word;
    hyphens:auto;
}
.hide {display:none !important}
.dsp-hidden {visibility:hidden;}
.nospace {margin:0 !important; padding:0 !important;}
.truncate {
    text-overflow:ellipsis;
    white-space:nowrap;
    display:block;
    overflow:hidden;
    padding-bottom:2px;
    &--medium {
        max-width: 335px;
    }
}
.cf {
    *zoom:1; /* IE < 8 */

    &:before,
    &:after {
        content:'.';
        display:block;
        height:0;
        overflow:hidden;
    }

    &:after {
        clear:both;
    }
}
.positioned {
    position:relative;
}
.positioned-content {
    position:absolute;
    min-width:300px;
    text-align:left;
}
.positioned-content.pos-tl {
    left:100%;
    top:50%;
    transform:translateY(-50%);
}
.is-blocked {
    pointer-events:none;
    cursor:not-allowed;
}
.cursor-move {
    cursor:move !important;
}
.hidden {
    display: none !important;
}
.lang-wrapper {
    .change-lang {
        .tip-wrap {
            &:after {
                margin-right: 0;
                border-width: 5px;
                right: 50px !important;
                left: initial !important;
            }
        }
        li {
            display: inline-block;
            margin: 0;
            width: 50%;
        }
    }
}