/* [ rating ] */
    .rating,
    .rating a:hover,
    .rating .current {
        background:url(/shared/images/__partials/rating/size_x24.png) 0 0;
    }
    .rating {
        position:relative;
        width:133px;
        height:22px;
        overflow:hidden;
        list-style:none;
        background-position:top left;
        margin:0 auto;
        padding:0;
    }
    .rating li {
        display:inline;
    }
    .rating a,
    .rating .current {
        position:absolute;
        top:0;
        left:0;
        text-indent:-9000px;
        height:22px;
        line-height:22px;
        outline:none;
        overflow:hidden;
        border:none;
    }
    .rating a:hover {
        background-position:0 -24px;
    }
    .rating a.rate-1 {
        width:20%;
        z-index:6;
    }
    .rating a.rate-2 {
        width:40%;
        z-index:5;
    }
    .rating a.rate-3 {
        width:60%;
        z-index:4;
    }
    .rating a.rate-4 {
        width:80%;
        z-index:3;
    }
    .rating a.rate-5 {
        width:100%;
        z-index:2;
    }
    .rating .current {
        z-index:1;
    }
    .rating-success .current {
        background-position:0 -24px;
    }
    .rating-warning .current {
        background-position:0 -48px;
    }
    .rating-error .current {
        background-position:0 -72px;
    }


/* rating size */
    .rating.small,
    .rating.small a:hover,
    .rating.small .current {
        background:url(/shared/images/__partials/rating/size_x18.png) 0 0;
    }
    .rating.small {
        width:98px;
        height:17px;
    }
    .rating.small a,
    .rating.small .current {
        height:17px;
        line-height:17px;
    }
    .rating.small a:hover {
        background-position:0 -18px;
    }
    .rating-success.small .current {
        background-position:0 -18px;
    }
    .rating-warning.small .current {
        background-position:0 -36px;
    }
    .rating-error.small .current {
        background-position:0 -53px;
    }