.main {
    position: relative;

    .delete-panel {
        background-color: #282b31;
        color: #ffffff;
        width: 210px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;;
        height: 30px;
        opacity: 0.9;
        font-size: 90%;
        position: fixed;
        right: 40px;
        top: 90%;
        z-index: 12;
        width: auto;

        div {
            display: inline-block;
            position: relative;
        }
        .files-count {
            float: left;
            text-align: right;
            padding-top: 5px;
            margin-right: 20px;
            margin-left: 20px;
            padding-right: 20px;
            i {
                opacity: 0.6;
            }
        }
        .files-delete {
            color: #ff4200;
            width: 80px;
            padding-top: 5px;
            float: left;
            font-weight: 600;
            cursor: pointer;
        }
        .files-count + .files-delete:before {
            content: '';
            border-left: 1px solid #ffffff;
            position: absolute;
            height: 25%;
            left: -8px;
            top: 45%;
            opacity: 0.4;
        }
    }

    .delete-panel-static {
        position: absolute;
        bottom: 40px;
        right: 40px;
    }
}
/* files-section */
.files-section .header {
    .options {
        margin-top: 15px;
        margin-bottom: 15px;
        @media (max-width: 768px) {
            margin-bottom: 0;
        }
        + h2 {
            margin-bottom: 10px;
        }
    }
    .progress {
        width:250px;
        @media (max-width: 768px) {
            width: 200px;
            float: none;
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
}

.files-section {
    label{
        padding-left: 0;
        margin: 0;
        i.check {
            position: static;
            z-index: 0;
            top: 2px;
            left: 0;
        }
    }
    label.select-all {
        line-height: 18px;
    }
    .cell-action {
        .btn-choice {
            width: max-content;
        }
    }
    .file-selection {
        width: 40px;
    }
}

/* list */
    .files-list-section .header .options,
    .files-thumbs-section .header .options {
        margin:0;
    }
    .files-list-section .progress {
        width:194px;
    }
    .files-list-section .desc {
        width:330px;
    }
    .files-list-section .figure {
        width:78px;
        height:58px;

        .figure-obj {
            position:absolute;
            top:50%;
            transform:translateY(-50%);
        }
    }


/* thumbs */
    .files-thumbs-section .col-wrap {
        margin-left:-17px;
    }
    .files-thumbs-section .col {
        float:left;
        width:120px;
        margin:0 0 15px 18px;
    }
    .files-thumbs-section .figure {
        width:120px;
        height:120px;
    }
    .files-thumbs-section .thumb-title {
        width:116px;
        margin-left:-6px;
    }


/*---------------------------------------------------------------------------------------------
[ My recordings section ]
*/

.recordings-section {
    .progress {
        width:250px;
        float: left;
        margin-right: 20px;
        @media (max-width: 768px) {
            width: 200px;
            float: none;
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
    .file-selection {
        width: 40px;
        padding-right: 0 !important;
    }
    .options {
        margin-top: 15px;
        margin-bottom: 15px;
        @media (max-width: 768px) {
            margin-bottom: 0;
        }
    }
    .ui-tabs-nav {
        float: left;
        margin-top: 2px;
        @media (max-width: 768px) {
            margin-top: 15px;
        }
    }
    .nav-tabs-opaque svg {
        stroke-width: 1px;
    }
}

.recordings-section {
    .hide-check + label {
        position: static;
    }
}

.recordings-section .lead {
    margin-top:0;
}
// .recordings-section table,
.recordings-section .error .desc p,
.recordings-section .warning .desc p,
// .files-list-section table,
.files-list-section .error .desc p,
.files-list-section .warning .desc p {
    color:#555;
}

.files-list-section .file-selection {
    width: 50px;
}

.files-list-section {
    width: 750px;
}

.recordings-section td,
.files-list-section td {
    padding-top:12px;
    padding-bottom:10px;
}

.recordings-section .thumb,
.recordings-section .thumb > *:first-child {
    width:100px;
}

.files-section .thumb,
.files-section .thumb > *:first-child,
.files-list-section .thumb {
    width:120px;
}
.recordings-section .thumb svg {
    height: 40px;
    stroke-width: 1;
    color: #6ebe3b;
}
.files-list-section thead .thumb {
    text-align:left;
}
.recordings-section .thumb [data-icon] {
    height:75px;
}
.files-section .thumb > *:first-child,
.recordings-section .thumb > *:first-child {
    display: block;
    margin: auto;
}
.recordings-section .thumb .figure-wrap {
    margin-bottom: 0;
}

.recordings-section .desc {
    width: 280px;
    .truncate {
        max-width: 280px;
    }
}

.files-section .desc {
    width:314px;
    .truncate {
        max-width:314px;
    }
}

.recordings-section .cell-action {
    vertical-align: middle;
    width: 470px;
}
.files-section .cell-action {
    width:175px;
}

.recordings-section .file-info {
    width:100px;
}

.files-section .file-info {
    width:130px;
}
.chat-histories-section .desc {
    width: 70%;
}
.chat-histories-section .desc .truncate {
    width:428px;
}
.chat-histories-section .cell-action {
    width:110px;
    white-space: nowrap;
}
.chat-histories-section .cell-date {
    width: 130px;
    white-space: nowrap;
}
.chat-histories-section-row {
    word-break: break-all;
}
.chat-histories-section-desc {
    flex: 0 0 70%;
    max-width: 70%;
    min-width: 400px;
}
.chat-histories-section-action {
    flex: 1 1 0;
    max-width: 100%;
    width: 100%;
    padding: 0 0 0 15px;
    .cell-action-icons {
        white-space: nowrap;
    }
}
.chat-histories-section-data {
    max-width: 405px;
}
.recordings-edit-section .page-wrap {
    width:1024px;
}
.recordings-edit-section .helper.pos-tr2 {
    bottom:50px;
    margin-right:-102px;
}
.recordings-edit-section .helper.pos-tl2 {
    bottom:45px;
    margin-left:-102px;
}
#fancybox_recorder {
    height: 100%;
    #strobemediaplayback {
        height:100%;
    }
}
