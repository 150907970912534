.dsp-none {
    display:none;
}
.integrate-state .btn-link,
.list-subaccounts .btn {
    transition:opacity .15s ease-in-out, visibility .15s ease-in-out;
}
.tip,
.helper .ico-close {
    -webkit-transition:opacity .15s ease-in-out, visibility .15s ease-in-out;
         -o-transition:opacity .15s ease-in-out, visibility .15s ease-in-out;
            transition:opacity .15s ease-in-out, visibility .15s ease-in-out;   
}
.helper {
    transition:all .15s ease-out;
    display: none !important;
}
.float-label {
    transition:opacity 0s ease-in, all .15s linear;
}
.social-animate [class*='social-ico-'] {
    transition:all .9s ease-in-out;
}
.integrate-state .no-integrate svg {
    transition:all 1s ease-in-out;
}
input[type='text'],
input[type='number'],
input[type='password'],
textarea,
.select {
    transition:border .15s ease-in-out;

}
/**/
.list-tiles.x-small .unit-circle + h3 {
    font-size:0;
    opacity:0;
    margin:0;
}
.list-tiles.large .unit-circle + h3 {
    opacity:1;
}
.list-tiles .unit-circle + h3  {
    transition:font-size .2s ease-in-out, opacity .1s ease-in-out, margin .1s ease-in-out;
}
.list-tiles.large .col,
.list-tiles.x-small .col {
    transition:margin .6s ease-in-out;
}
.list-tiles.x-small .unit-circle,
.list-tiles.large .unit-circle,
.list-tiles.x-small .unit-circle svg,
.list-tiles.large .unit-circle svg {
    transition:width .5s ease-in-out, height .5s ease-in-out, font-size .5s ease-in-out;
}
.alert-dialplan a:hover [data-icon='R'] {
    animation:transformation .5s ease-in .2s infinite alternate;
}


@keyframes transformation {
      0% {transform:translateX(0);}
    100% {transform:translateX(5px);}
}
@keyframes spinner {
    to {transform:rotate(360deg);}
}