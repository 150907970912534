.started-section .header h2 {
    font-size:63px;
    letter-spacing:-2px;
    margin:28px 0 0;
}
.started-section .started-ft {
    text-align:center;
    font-size:24px;
    margin:40px 0 10px;
}
.started-section .started-ft p {
    margin:0;
}
.started-section .started-ft .btn {
    color:#d5d5d5;
    padding:0 10px;
    top:-1px;
}
.list-pictogram .disabled {
    opacity:1
}
.list-pictogram .disabled .list-pictogram-hd,
.list-pictogram .disabled .list-pictogram-bd,
.list-pictogram .disabled .pictogram {
    color:#e1e1e2;
}
.dashboard-module .page-header {
    .list-inline {
        margin-bottom: -1.5rem;
    }

    .col-lg:nth-child(2) {
        flex-grow: 3;
    }
}
