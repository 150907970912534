/* own CSS */
@-moz-document url-prefix() {
    // https://bugzilla.mozilla.org/show_bug.cgi?id=644832
    //Flash content doesn't render when scale transform is applied to parent element.
    .fancybox-wrap {
        -webkit-transform:none;
                transform:none;
    }
}
.fancybox-skin {
    padding:0 !important;
    background:#f7f7f7;
    border-radius:3px;
    margin: 50px 0;
    @media (min-width: 768px) {
        margin: 0 50px;
    }
}
.fancybox-opened .fancybox-skin {
    box-shadow:0 3px 13px rgba(0,0,0,.3);
}
.fancybox-close {
    text-align:center;
    line-height:31px;
    top:0;
    right:-47px;
    z-index:8050;
    opacity:.8;
    @media (max-width: 767px) {
        top: -50px;
        right: 0;
    }
}
.fancybox-close:hover {
    opacity:1;
}
.fancybox-close:before {
    content:'\C7';
    display:inline-block;
    font-size:36px;
    line-height:1;
}
.fancybox-close,
.fancybox-close:hover {
    color:#fff;
}
.fancybox-nav {
    width:120px;
}
.fancybox-prev {
    left:-120px;
}
.fancybox-next {
    right:-120px;
}
.fancybox-nav span {
    visibility: visible;
    width:120px;
    height:134px;
    margin-top:-67px;

    text-align:center;
    font-size:82px;
    text-shadow:0 1px 0 rgba(255,255,255,.1);
    color:#303030;
}
.fancybox-nav span:before {
    display:inline-block;
    padding:2px;
}
.fancybox-prev span:before {
    content:'Q';
}
.fancybox-next span:before {
    content:'R';
}
.fancybox-nav:hover span {
    color:#fbfbfb;
    text-shadow:0 1px 1px rgba(0,0,0,.48);
}
.fancybox-tmp {
    visibility:hidden;
}
.fancybox-lock {
    overflow: hidden !important;
}
.fancybox-overlay {
    background:rgba(39,40,42,.9);
}
.fancybox-title {
    margin:0;
    padding:0;
    font-size:30px;
    line-height:1.4;
    letter-spacing:-1px;
}
.fancybox-title-float-wrap {
    display:none;
}


/* Dedicated popups */
    .fancybox-title-inside-wrap {
        text-align:center;
        padding:12px 28px;
        border-top-right-radius:2px;
        border-top-left-radius:2px;
        @media (max-width: 767px) {
            padding:12px 50px;
        }
    }
    .fancybox-content {
        padding:28px;
    }

    .fancybox-close, 
    .fancybox-title {
        .popup-with-head &,
        .popup-special & {
            color:#fff;
        }
    }


.popup-special {

    .fancybox-title-inside-wrap {
        text-align:left;
        padding:8px 36px 8px 12px;
        background:#282b32;
    }

    .fancybox-title {
        font-size:16px;
        letter-spacing:0;
    }

    .fancybox-close {
        top:0;
        right:11px;
        vertical-align:middle;
        line-height:36px;

        &:before {
            font-size:16px;
        }
    }

    .fancybox-skin {
        background:#ededed;
    }

    .fancybox-outer {
        padding:0;
        margin:0;
    }
}


    /*.popup-with-head .fancybox-close,
    .popup-with-head .fancybox-title-inside-wrap {
        background:#6ebe3b;
    }*/


    .fancybox-content .desc {
        margin-right:20px;
    }
    .fancybox-content .desc p:first-child {
        margin-top:0;
    }
    .fancybox-content .subtitle {
        font-size:35px;
    }
    .fancybox-content .btn-wrap {
        margin-top:30px;
    }


    // odstep dla formularzy w fancyboxie
    .fancybox-form {
       padding:0 60px;

        .form-label {
            text-transform:uppercase;
            font-size:12px;
        }
    }


/* [ popup - files - preview ] */
    .popup-file-preview.dsp-table {
        height:100%;
    }
    .popup-file-preview img {
        max-height:360px;
        max-width:100%;
    }
    .popup-file-preview [data-icon] {
        color:$color-muted;
        font-size:190px;
    }
    .popup-file-preview .gv_panel {
        overflow-y:auto
    }
    .popup-file-preview .gv_panel img {
        max-height:inherit;
        position:static;
        max-width:inherit;
    }