.ico-flag-16 {
    height:13px;
    width:16px;
    background:url($src-image + 'flags/flag_16.png?ver=1') no-repeat 0 0;
}
.ico-flag-16.flag-en,/* {
    background-position:0 0;
}*/
.ico-flag-16.flag-us {
    background-position:0 -23px;
}
.ico-flag-16.flag-it {
    background-position:0 -44px;
}
.ico-flag-16.flag-cn {
    background-position:0 -65px;
}
.ico-flag-16.flag-pl {
    background-position:0 -86px;
}
.ico-flag-16.flag-de {
    background-position:0 -107px;
}
.ico-flag-16.flag-ru {
    background-position:0 -128px;
}
.ico-flag-16.flag-se {
    background-position:0 -149px;
}
.ico-flag-16.flag-es {
    background-position:0 -170px;
}
.ico-flag-16.flag-cs {
    background-position:0 -191px;
}
.ico-flag-16.flag-pt {
    background-position:0 -212px;
}
.ico-flag-16.flag-fr {
    background-position:0 -233px;
}
.ico-flag-16.flag-nl {
    background-position:0 -254px;
}
.ico-flag-16.flag-br {
    background-position:0 -275px;
}
.ico-flag-16.flag-ch {
    background-position:0 -296px;
}
.ico-flag-16.flag-sv {
    background-position:0 -296px;
}
.ico-flag-16.flag-zh {
    background-position:0 -317px;
}

/* [ size: 30px ] */
.ico-flag-30 {
    width:34px;
    height:26px;
    background:url($src-image + 'flags/flag_30.png?ver=1') no-repeat 0 0;
}
.ico-flag-30.flag-en {
    background-position:0 0;
}
.ico-flag-30.flag-us {
    background-position:0 -32px;
}
.ico-flag-30.flag-it {
    background-position:0 -64px;
}
.ico-flag-30.flag-cn {
    background-position:0 -96px;
}
.ico-flag-30.flag-pl {
    background-position:0 -128px;
}
.ico-flag-30.flag-de {
    background-position:0 -160px;
}
.ico-flag-30.flag-ru {
    background-position:0 -192px;
}
.ico-flag-30.flag-se {
    background-position:0 -223px;
}
.ico-flag-30.flag-es {
    background-position:0 -255px;
}
.ico-flag-30.flag-cs {
    background-position:0 -287px;
}
.ico-flag-30.flag-pt {
    background-position:0 -319px;
}
.ico-flag-30.flag-fr {
    background-position:0 -351px;
}
.ico-flag-30.flag-nl {
    background-position:0 -383px;
}
.ico-flag-30.flag-br {
    background-position:0 -415px;
}
.ico-flag-30.flag-ch {
    background-position:0 -447px;
}
.ico-flag-30.flag-zh {
    background-position:0 -479px;
}