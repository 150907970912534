#pricing {

    * {
        box-sizing: border-box;
    }

    h2 {
        font-size: 45px;
        font-size: 2.8125rem;
        color: #505a7d;
    }
    
    .btn-options {
        border: 1px solid #6ebe3b;
        display: inline-block;
        position: relative;
        padding: 2px 3px;
        margin: 60px auto 20px;
        -webkit-border-radius: 25px;
           -moz-border-radius: 25px;
                border-radius: 25px;
        width: auto;
        overflow: visible;
                
        .btn {
            font-size: 15px;
            padding: 5px 25px;
            
            &.btn-off {
                color: #6ebe3b;
                box-shadow: none;
                border: 1px solid transparent;
                
                &:hover {
                    border: 1px solid #6ebe3b;
                }
                
            }
            
        }
        
        &.select {
            background: transparent;
            box-shadow: none;
            
            &:after {
                content: none;
            }
            
            .label {
                
                &-save {
                    background: #ff4200;
                }
                
                &.top.label-save .label-arrow {
                    border-top-color: #ff4200;
                }
            }
            
        }
        
    }

    .label {
        
        &-save-wrap,
        &-promo-annual-wrap,
        &-promo-monthly-wrap {
            position: absolute;
            left: -80px;
            top: 50%;
            margin-top: -50px;
            min-width: 280px;
            text-align: center;
        }
        
        &-promo-monthly-wrap {
            left: auto;
            right: -80px;
        }
        
        &-save,
        &-promo-annual,
        &-promo-monthly {
            display: inline-block;
            font-family:'robotoregular',sans-serif;
            font-size: 12px;
            line-height: 1;
            padding: 5px 8px;
            -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
                    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
        }
        
        &-arrow {
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
        }
        
        &.top .label-arrow {
            bottom: -5px;
            left: 50%;
            margin-left: -5px;
            border-width: 5px 5px 0;
            border-top-color: #c6c6c6;
            border-left-color: transparent;
        }
        
        &-save {
            background: #c6c6c6; 
        }
        
        &.top.label-save .label-arrow {
            border-top-color: #c6c6c6;
        }

        &-promo-annual {
            background: #666;
        }
        
        &.top.label-promo-annual .label-arrow {
            border-top-color: #666;
        }
        
        &-promo-monthly {
            background: red;
        }
        
        &.top.label-promo-monthly .label-arrow {
            border-top-color: red;
        }
        
    }
    
    &.upgrade-form {
        padding: 0;
        
        .panel-btn-options {
            float: left;
            
            .btn-options {
                margin: 20px 30px 0;
            }
            
        }
        
        @media (max-width: 991px) {
            .panel-btn-options {
                float: none;
                text-align: center;
                
                .btn-options {
                    margin: 40px auto 0;
                }
                
            }
            
        }
        
    }
    
    .wrap-package-plans {
        width: 100%;
    }
    
    .btn-package-plans {
        font-family: 'robotolight';
        position: relative;
        padding-right: 15px;
        cursor: pointer;
        margin: auto;
        
        &.plans-close:after,
        &.plans-open:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            top: 50%;
            margin-top: -1px;
            right: 2px;
        }
        
        &.plans-close:after {
            border-width: 4px 4px 0;
            border-top-color: #00afec;
        }
        
        &.plans-close:hover:after {
            border-top-color: #ff800f; 
        }
        
        &.plans-open:after {
            border-width: 0px 4px 4px;
            border-bottom-color: #00afec;
        }
        
        &.plans-open:hover:after {
            border-bottom-color: #ff800f; 
        }

    }
    
}

.pricing {
    
    &-wrap {
        position: relative;
        
        &.clearfix {
            
            &:before,
            &:after {
                content: " ";
                display: table;
            }
            
            &:after {
                clear: both;
            }
            
        }
        
        &.btn-options-disabled {
            
            &.panel-1,
            &.panel-2 {
                
                .col-panel {
                    
                    .panel {margin-top: 50px;}
                    
                }
                
                .billing-summary-block {
                    padding-top: 85px;
                }
                
                @media (max-width: 991px) {

                    .billing-summary-block {
                        padding-top: 40px;
                    }

                }
                
            }
            
        }
        
        &.panel-2 {
            
            .col-panel {
                width: 50%;
                
                .panel {margin-top: 10px;}
            }
            
            @media (max-width: 575px) {
                .col-panel {width: 100%;}
            }
            
            .billing-summary-block {
                padding-top: 160px;
            }
            
            @media (max-width: 991px) {
                
                .billing-summary-block {
                    padding-top: 40px;
                }
                
            }
            
        }
        
        &.panel-1 {
            
            .col-panel {
                width: 100%;
                float: none;
                margin-right: auto;
                margin-left: auto;

                &.active {
                    margin-left: 0px;
                    margin-right: 0px;
                }
                
                .panel {margin-top: 10px;}
                
            }
            
            .billing-summary-block {
                padding-top: 160px;
            }

            @media (max-width: 991px) {
                
                .billing-summary-block {
                    padding-top: 40px;
                }
                
            }
            
        }
        
    }
    
    &-block {
        display: table;
        position: relative;
        margin: 5px auto;
        padding: 0 0 15px;
        
        &.clearfix {
            
            &:before,
            &:after {
                content: " ";
                display: table;
            }
            
            &:after {
                clear: both;
            }
            
        }
        
        .col-panel {
            padding: 0px;
            margin-left: 0;
            margin-right: 0;
            
            width: 50%;
            float: left;
            position: relative;
            min-height: 1px;
            
            
            .panel {
                background-color: #fff;
                border: 1px solid #eff0f3;
                margin-top: 40px;
                margin-bottom: 0px;
                -webkit-box-shadow: 0 0 18px 0px rgba(39, 95, 222, 0.05);
                        box-shadow: 0 0 18px 0px rgba(39, 95, 222, 0.05);
            }
        }
        
    }
    
    @media (max-width: 575px) {
        
        &-block {
            width: 100%
        }
        
    }
    
    &-popup {
        display: none;
        height: 100%;
        
        &-body {
            display: table;
            width: 100%;
            height: 100%;
            
            .cell {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                padding: 30px;
                
                &.accept,
                &.error,
                &.error-card {
                    
                    .icon {
                        margin-bottom: 30px;
                        
                        svg {
                            width: 80px;
                            height: 80px;
                            stroke-width: 3px;
                        }
                    }
                    
                    h2 {
                        font-size: 30px;
                        color: #505b7c;
                        margin-bottom: 60px;
                    }
                    
                    a {
                        font-family: $font-roboto-light; 
                        font-size: 21px;
                    }
                    
                }
                
                &.accept {
                    .icon {
                        svg { color: #6ebe3b; }
                    }
                }
                
                &.error,
                &.error-card {
                    .icon {
                        svg { color: #ff4200; }
                    }
                }
                
                h3 {
                    font-size: 24px;
                    color: #282b31;
                    margin: 0 0 30px;
                }
                
                p {
                    font-family: $font-roboto-light;
                    font-size: 16px;
                    color: #5c6273;
                    margin-bottom: 30px;
                    
                    b {
                        font-weight: bold;
                    }
                    
                }
                
                .btn.large {
                    text-transform: uppercase;
                    padding: 15px 45px;
                }
                
                .pricing-val {
                    margin-bottom: 30px;

                    h2 {
                        font-size: 38px;
                        margin: 0;
                        color: #6ebe3b;
                    }

                    p {
                        font-size: 12px;
                        margin: 0;
                        color: #929292;

                        b {
                            font-weight: bold;
                        }

                    }

                }
                
            }
            
        }
        
    }
    
    &-processing {
        
        h3 {
            font-size: 30px !important;
            color: #505b7c !important;
            margin: 30px 0 0!important;
        }
        
        &-spinner {
            position: relative;
            display: inline-block;
            height: 70px;

            .blend {
                min-height: auto;
                width: 30px;
                height: 30px;
                position: absolute;
                bottom: 3px;
                right: -7px;

                &:before {
                    background: none;
                }

            }
            
            .spinner:not(:required):before {
                border-width: 3px;
            }
            
            .spinner-size-medium {
                margin: -13px 0 0 -13px;
                width: 26px;
                height: 26px;
            }

            svg {
                width: 70px;
                height: 70px;
                color: #a7afc6;
                stroke-width: 3px;
            }

        }
        
    }
    
}
