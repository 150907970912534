.user-survey-popup {
    @media screen and (max-width: 767px) {
        width: 85% !important;
        .fancybox-inner {
            width: 100% !important;
        }
        .fancybox-close {
            top: -4px;
            right: -28px;
            &:before {
                font-size: 24px;
            }
        }
    }
}
.new-room {

    &-feedback {

        &-popup {
            display: table;
            width: 100%;
            height: 100%;
            min-width: 550px;
            @media screen and (max-width: 767px) {
                min-width: 320px;
            }
            @media screen and (max-width: 567px) {
                min-width: 100%;
            }
        }
        
        &-middle {
            display: table-cell;
            position: relative;
            vertical-align: middle;
            padding: 15px;
            @media screen and (max-width: 567px) {
                padding: 10px;
            }
        }
        
        &-form {
            text-align: center;
            
            h2 {
                font-family: $font-roboto-medium;
                font-size: 24px;
                color: #0f1831;
                @media screen and (max-width: 767px) {
                    font-size: 18px;
                    margin-bottom: 20px;
                }
                @media screen and (max-width: 567px) {
                    font-size: 16px;
                    line-height: 16px;
                }
                svg {
                    width: 40px;
                    height: 40px;
                    stroke-width: 1px;
                    color: #6ebe3b;
                    @media screen and (max-width: 767px) {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            
            p {
                font-family: $font-roboto-light;
                font-size: 16px;
                color: #a7afc6;
                @media screen and (max-width: 767px) {
                    font-size: 14px;
                    margin: 15px 0;
                }
            }
            .btn {
                
                &.x-large {
                    padding: 20px 50px;
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 0;
                    @media screen and (max-width: 567px) {
                        padding: 15px 30px;
                    }
                }
                
                &-feedback {
                    background: #0c142d;
                    background:-webkit-linear-gradient(top,  #203054 0%,#0c142d 100%);
                    background:linear-gradient(to bottom,  #203054 0%,#0c142d 100%);
                    box-shadow: 0px 12px 35px 0px rgba(16, 36, 95, 0.41);

                    &:hover {
                        background: lighten(#0c142d, 4%);
                        background:-webkit-linear-gradient(top,  lighten(#203054, 4%) 0%,lighten(#0c142d, 4%) 100%);
                        background:linear-gradient(to bottom,  lighten(#203054, 4%) 0%,lighten(#0c142d, 4%) 100%);
                    }

                }
                
            }
            
            .form-group {
                margin-left: 70px;
                margin-right: 70px;
                position: relative;
                margin-bottom: 35px;
                @media screen and (max-width: 767px) {
                    margin-bottom: 20px;
                }
                @media screen and (max-width: 567px) {
                    margin: 0 0 20px;
                }
                &.has-error {
                    
                    textarea {
                        border-color: #ff4200;
                    }
                    
                }
                
                textarea {
                    min-height: 120px;
                    background: transparent;
                    box-shadow: none;
                    border-radius: 4px;
                    font-size: 18px;
                    @media screen and (max-width: 767px) {
                        font-size: 14px;
                    }
                    &::placeholder {
                        font-size: 18px;
                        color: #a7afc6;
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                        }
                    }
                    
                }
                
            }
            
            .label {
                
                &-rate {
                    vertical-align: middle;
                    font-family: $font-roboto-light;
                    font-size: 18px;
                    color: #5c6273;
                    cursor: auto;
                    @media screen and (max-width: 767px) {
                        font-size: 16px;
                        display: block;
                    }
                    @media screen and (max-width: 567px) {
                        font-size: 14px;
                    }
                }
                
                &-very-good {
                    vertical-align: middle;
                    text-transform: uppercase;
                    font-size: 12px;
                    color: #a7afc6;
                    cursor: auto;
                    @media screen and (max-width: 567px) {
                        margin-top: 0.5rem;
                    }
                }
                
            }
            
            .help-block {
                position: absolute;
                bottom: -25px;
                left: 0;
                right: 0;
                
                font-size: 12px;
                margin: 0;
                @media screen and (max-width: 767px) {
                    bottom: -15px;
                }
                &.has-error {
                    color: #ff4200;
                }
                
            }
            
        }
        
        &-finish {
            padding: 50px 0 100px;
            text-align: center;
            
            h2 {
                font-family: $font-roboto-medium;
                font-size: 34px;
                color: #6ebe3b;
            }
            
            svg {
                width: 100px;
                height: 100px;
                stroke-width: 1px;
                color: #6ebe3b;
            }
            
            .btn {
                color: #00afec;

                &.x-large {
                    padding: 15px 20px;
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 0;
                }

                &-wrap {
                    position: absolute;
                    bottom: 40px;
                    left: 0;
                    right: 0;
                        
                }

                &-feedback-finish {
                    border: 1px solid #e7e9f0;

                    &:hover {
                        border: 1px solid darken(#e7e9f0, 4%);
                    }

                }

            }
            
        }
        
        &-stars {
            background: url('../../../../../homepage/images/landing/new-webinar-room/feedback-stars.png') no-repeat 6px 0px;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 224px;
            height: 31px;
            margin: 0 10px;
            
            .radio-inline {
                background-image: url('../../../../../homepage/images/landing/new-webinar-room/feedback-stars.png');
                background-position: 6px 31px;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                top: 0;
                width: 43px;
                height: 31px;
                text-decoration: none;
                text-indent:-9999px;
                z-index: 0;
                
                &.one {
                    width: 45px;
                    z-index: 5;
                    
                    &.checked,
                    &:hover {
                        background-position: 6px -31px;
                    }
                    
                }
                
                &.two {
                    width: 90px;
                    z-index: 4;
                    
                    &.checked,
                    &:hover {
                        background-position: 6px -62px;
                    }
                    
                }
                
                &.three{
                    width: 135px; 
                    z-index: 3;
                    
                    &.checked,
                    &:hover {
                        background-position: 6px -93px;
                    }
                    
                }

                &.four{
                    width: 180px; 
                    z-index: 2;
                    
                    &.checked,
                    &:hover {
                        background-position: 6px -124px;
                    }
                    
                }
                
                &.five{
                    width: 225px; 
                    z-index: 1;
                    
                    &.checked,
                    &:hover {
                        background-position: 6px -155px;
                    }
                    
                }
                
            }
            
        }
        
    }
    
}

