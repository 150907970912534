.billing {

  &-add-ons-section,
  &-account-section,
  &-payment-section {
    margin: 0 0 30px;

    .page-wrap-row {
      border-bottom: 1px solid #e4e5e5;
      position: relative;
      display: table;
      padding: 15px 0;
      margin: 0 auto;
      width: 100%;

      .cell {
        display: table-cell;
        vertical-align: baseline;
        @media only screen and (max-width: 767px) {
          vertical-align: middle;
        }
        &-middle {
          vertical-align: middle;
        }

        &-title {
          width: 200px;
          font-size: 16px;
          @media (max-width: 575px) {
            width: 140px;
            font-size: 14px;
          }
        }

        .txt {

          &-val {
            display: inline-block;
            font-size: 23px;
            color: #6ebe3b;
            padding: 0 10px;
            @media (max-width: 575px) {
              font-size: 16px;
            }
          }

          &-include {
            display: inline-block;
            font-size: 12px;
            color: #666;
            @media (max-width: 575px) {
              padding: 0 10px;
            }
          }

          &-deliver {
            display: inline-block;
            height: 8px;
            width: auto;
            border: 0;
            border-right: 1px solid #d1d1d1;
            margin: 0 5px;
            vertical-align: middle;
          }

        }

      }

    }

    .expand {
      margin-top: -1px;
      margin-bottom: 0;

      .main-arrow {
        right: 55px;
      }

      .content {
        padding: 0 0 50px;
      }

      .page-wrap {

        &.buy-now {
          padding-top: 30px;

          .btn.large {
            text-transform: uppercase;
            padding: 16px 48px;
          }

          p {
            font-size: 12px;
            margin: 15px auto 0;
            color: #929292;
            width: 400px;
          }

        }

      }

    }

  }

  &-add-ons-section {
    .page-wrap-row {
      border: none;

      .icon,
      .title,
      .price,
      .active,
      .action {
        display: inline-block;
        padding: 0 15px;
        vertical-align: middle;
      }

      .icon {
        padding: 0;
        color: #c2c2c2;

        .svg-fill,
        .svg-stroke {
          width: 50px;
          height: 50px;
        }

      }

      .title {
        font-size: 20px;
      }

      .price {
        font-size: 20px;
        color: #6ebe3b;
      }

      .active {
          b {font-weight: bold}
      }

    }
  }

  &-pricing {

    .container {
      width: auto;
      padding-left: auto;
      padding-right: auto;
    }

    .row {
      margin-left: auto;
      margin-right: auto;

      &:after {
        clear: none;
      }

    }

  }

  &-summary {

    &-block {
      padding-top: 195px;

      .block-header{
        padding-bottom: 15px;

        h2 {
          margin: 0;
          font-size: 21px;
          color: #282b31;
        }

      }

      .summary-confirm {
        border-top: 2px solid #cecece;
        text-align: right;
        padding-top: 10px;

        h2 {
          margin: 0;
          color: #6ebe3b;
          @media (max-width: 575px) {
            font-size: 25px;
          }
        }

        p {
          margin: 0;
          font-size: 12px;
          color: #929292;
        }

      }

      .table-responsive {
        border-top: 1px solid #cdcdcd;
      }

      .summary-table {
        margin: 8px 0;

        > tbody > tr > td {
          font-size: 12px;
          padding: 5px 0;
          border: none;

          &.tR {
            font-weight: bold;
          }

        }

      }
      .summary {

        &-all {
          position: relative;
          padding-left: 5px;
          padding-right: 15px;
        }

        &-close:after,
        &-open:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-color: transparent;
          border-style: solid;
          top: 50%;
          margin-top: -1px;
          right: 2px;
        }

        &-close:after {
          border-width: 4px 4px 0;
          border-top-color: #00afec;
        }

        &-close:hover:after {
          border-top-color: #ff800f;
        }

        &-open:after {
          border-width: 0px 4px 4px;
          border-bottom-color: #00afec;
        }

        &-open:hover:after {
          border-bottom-color: #ff800f;
        }

      }

      .buy-now {
        padding-top: 30px;

        p {
          margin: 15px 0 0;
          font-size: 12px;
          color: #929292;
        }

      }

    }

  }

}

.updateDataset {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}
  // freeze and calncel account
.vr-list--freeze {
    li {
      text-align: left;
      @media only screen and (max-width: 767px) {
        display: block;
      }
      &.spacer {
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
    }
}
.freeze_cta {
    color: $color-info;
    cursor: pointer;
}
.cancel_cta {
    color: $color-error;
    &:hover {
      color: $color-error;
      text-decoration: underline;
    }
}
.freemium_cta {
  color: #5da82d;
  &:hover {
    color: #5da82d;
    text-decoration: underline;
  }
}
.freeze_cta,
.cancel_cta,
.freemium_cta {
    position: relative;
    display: inline-block;
    padding-left: 24px;
    svg {
      stroke-width: 1px;
      width: 16px;
      height: 16px;
      color: inherit;
      margin-right: 4px;
      position: absolute;
      left: 0;
      top: 2px;
    }
}
.freeze-tooltip {
    min-width: 370px;
    @media only screen and (max-width: 767px) {
      left: 8px !important;
      min-width: 200px;
    }
    &__content {
        background-color: #2b2e3a;
        border-radius: 5px;
        display: flex;
        align-items: center;
        svg {
            width: 25px;
            height: 25px;
            color: $color-info;
            margin-right: 12px;
            position: static;
        }
    }
    &__txt {
        font-family: $font-roboto-regular;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        width: 95%;
    }
}
#freeze-popup .modal-dialog,
#freemium-cancel-popup .modal-dialog {
    max-width: 600px;
    @media only screen and (max-width: 767px) {
      max-width: 350px;
    }
}
.freeze-popup-content {
    font-family: $font-roboto-regular;
    padding: 40px 50px;
    height: 600px;
    @media only screen and (max-width: 767px) {
      height: auto;
      padding: 20px 25px;
    }
    &__close {
        color: #ffffff !important;
        @media only screen and (max-width: 767px) {
          right: -37px;
        }
    }
    .frozen-completed {
      padding-top: 20px;
      @media only screen and (max-width: 767px) {
        padding-top: 0;
      }
    }
    svg {
        color: $color-info;
        width: 50px;
        height: 50px;
        stroke-width: 3px;
        display: block;
        margin: 0 auto 20px;
    }
    &__title {
        font-family: $font-roboto-medium;
        font-size: 21px;
        color: #282b31;
        text-align: center;
        margin-top: 0;
        margin-bottom: 15px;
        &--welcome {
          margin-bottom: 50px;
        }
    }
    &__text {
        color: #464a55;
        font-size: 14px;
        font-family: inherit;
        line-height: 20px;
        margin: 0;
    }
    &__list {
        list-style-type: none;
        padding-left: 0;
        margin-top: 10px;
        margin-bottom: 30px;
        li {
          line-height: 30px;
          padding-left: 30px;
          position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 12px;
                left: 12px;
                width: 6px;
                height: 6px;
                border-radius: 100%;
                background-color: $color-info;
                display: block;
            }
        }
        &--cancelFreemium {
          li {
            &:before {
              background-color: #6ebe3b;
            }
          }
        }
    }
    &__freeze {
      $colorStart: rgb(0,175,236);
      $colorEnd: rgb(31,197,255);

      font-family: $font-roboto-medium;
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      text-align: center;
      color: #ffffff;
      background-image: linear-gradient( 90deg, $colorStart 0%, $colorEnd 100%);
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.11),inset 0px -2px 0px 0px rgba(0, 0, 0, 0.16);
      border: 0;
      border-radius: 50px;
      display: block;
      margin: 25px auto;
      padding: 15px 25px;
      cursor: pointer;
      &:hover{
        background-image: linear-gradient(90deg, darken($colorStart, 5) 0%, darken($colorEnd, 5) 100%);
      }
      &--closeFreemium {
        $colorStart: rgb(248, 85, 89);
        $colorEnd: rgb(234, 73, 76);
        background-image: linear-gradient( 90deg, $colorStart 0%, $colorEnd 100%);
        letter-spacing: initial;
        &:hover{
          background-image: linear-gradient(90deg, darken($colorStart, 5) 0%, darken($colorEnd, 5) 100%);
        }
      }
    }
    &__tos {
      font-size: 11px;
      color: #a7afc6;
      margin-top: 20px;
      margin-bottom: 0;
    }
    &__freezed-btn.btn {
      font-family: $font-roboto-regular;
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      color: $color-info;
      border: 2px solid #f3f4f7;
      border-radius: 50px;
      padding: 17px 55px;
      display: block;
      max-width: 200px;
      margin: 0 auto;
      &--closeFreemium {
        display: inline-block;
      }
      &--welcome {
        font-family: $font-roboto-medium;
        padding: 17px;
        margin-top: 40px;
      }
    }
    &--closeFreemium {
      height: auto;
      min-height: 500px;
      svg {
        color: $color-warning;
        stroke-width: 2px;
      }
      .popup-stay {
        text-transform: uppercase;
        font-family: $font-roboto-bold;
        font-size: 12px;
        &--close {
          display: block;
          margin: 35px auto 0;
          border-radius: 50px;
          padding: 15px 25px;
          color: #ffffff;
          background-color: #6ebe3b;
          transition: background-color 0.5s ease-in-out;
          max-width: 200px;
          &:hover {
            background-color: #62a737;
          }
        }
      }
      textarea {
        height: 80px;
        margin: 30px 0;
        border: 1px solid #dde0e9;
        border-radius: 5px;
        resize: none;
        &::placeholder {
          color: #737373;
          font: {
            family: $font-roboto-regular;
            size: 14px;
            style: italic;
          }
        }
      }
    }
    &--welcome {
      height: auto;
      padding-bottom: 60px;
      svg {
        color: $color-success;
        width: 60px;
        height: 60px;
      }
    }
    &--reset_height {
      height: auto;
      min-height: auto;
    }
    .btn-popup-link {
      text-transform: uppercase;
      display: block;
      margin: 0 auto;
      font-size: 14px;
      font-family: $font-roboto-medium;
    }
}
