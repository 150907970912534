.banner-progress-bar {
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid #E1E5EE;
    max-width: 678px;
    @media only screen and (max-width: 768px) {
        padding: 20px;
    }
    &__content {
        font-family: 'robotoregular';
        max-width: 305px;
        margin-right: 20px;
        @media only screen and (max-width: 768px) {
            margin: 10px 0;
        }
    }
    &__title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 5px;
        color: $color-darken;
        font-weight: 400;
    }
    &__desc {
        font-size: 12px;
        line-height: 20px;
        margin: 0;
        color: $color-gray-blue;
    }
    .btn {
        font-size: 12px;
        padding: 9px 14px;
        font-weight: 700;
    }
    .progress {
        max-width: 171px;
        margin: 11px 20px 0 0;
        @media only screen and (max-width: 768px) {
            margin: 11px 0 0 0;
        }
    }
}