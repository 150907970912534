/* [ Avatar ] */
.avatar-wrap {
    display:inline-block;
}
.avatar-wrap .avatar-decorate,
.avatar-decorate img {
    display:block;
    margin:0 auto;
}
.avatar-decorate {
    border:3px solid #fff;
    display:inline-block;
    box-shadow:0 0 0 1px rgba(0,0,0,0.1);
    border-radius:50%;
    position:relative;

    img {
        max-width:100%;
    }
}

.avatar {
    width:58px;
    height:58px;
    display:block;
    text-align:center;
    background:#f2f3f7;
    position:relative;
    overflow:hidden;

    &:before {
        opacity:0;
        visibility:hidden;
        content:'';
        display:block;
        position:absolute;
        z-index:3;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background-color:#e0e0e0;
    }

    [data-icon='P'] {
        color:#e6e7ee;
        font-size:52px;
        line-height:.8;
        position:absolute;
        top: 50%;
        left:50%;
        z-index:1;
        bottom:-12px;
        transform: translate(-50%, -50%);
    }

    img {
        display:inline-block;
        position:absolute;
        max-width:100%;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
    }

    .avatar-action {
        color:#fff;
        display:table;
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:2;

        svg {
            @include x-svg-size(24);
            display:block;
            margin:0 auto;
        }

        .ico-pencil {
            color: #fff;
        }

        .ico-x {
            color:$color-error;
        }
    }

    .dsp-cell {
        opacity:0;
        color: #fff;
        border-radius: 50%;
        &.change-photo,
        &.delete-photo {
            border-radius: 0;
            svg {
                cursor: pointer;
            }
        }
        &.delete-photo {
            svg {
                stroke-width: 6px !important;
            }
        }
    }
    a:hover .dsp-cell {
        opacity:1;
    }
}


/* [ Avatar size ] */
.avatar.x-small {
    overflow:hidden;
    height:16px;
    width:16px;

    [data-icon='P'] {
        font-size:9px;
        bottom: 0;
    }
}

.avatar.small {
    height:44px;
    width:44px;

    [data-icon='P'] {
        font-size:38px;
        bottom:-8px;
    }
}

.avatar.medium {
    height:80px;
    width:80px;

    [data-icon='P'] {
        font-size:75px;
        bottom:-20px;
    }

    .avatar-action {
        font-size:12px;
    }
}

.avatar.large {
    height:140px;
    width:140px;
    border-width:5px;

    [data-icon='P'] {
        font-size:130px;
        bottom:-35px;
    }
}

.avatar.x-large {
    height:188px;
    width:188px;
    border-width:5px;

    [data-icon='P'] {
        font-size:175px;
        bottom:-40px;
    }
}

.avatar.large,
.avatar.x-large {

    .avatar-action {
        font-size:16px;

        svg {
            @include x-svg-size(38);
        }
    }
}


/* avatar error */
.avatar-wrap.f-error {
    box-shadow:0 0 0 2px $color-error;
    border-radius:2px;
}
.avatar-wrap.f-error .error {
    z-index:10;
    max-width:90%;
    transform:translateY(0);
}
.avatar-wrap .error.pos-tr {
    top:0;
    right:0;
    border-radius:0 2px 0 2px;
}
.avatar-wrap .error.pos-tl {
    top:0;
    left:0;
    right:auto;
    border-radius:2px 0 2px 0;
}
.avatar-wrap .error.pos-bl {
    top:auto;
    bottom:0;
    left:0;
    right:auto;
    border-radius:0 2px 0 2px;
}
.avatar-wrap .error.pos-br {
    top:auto;
    bottom:0;
    right:0;
    border-radius:2px 0 2px 0;
}

/* animation */
.avatar .dsp-cell,
.avatar:before,
.avatar[data-loading='true'] [data-icon='P'] {
    transition:opacity .15s ease-in-out, visibility .15s ease-in-out;
}