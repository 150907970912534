.list-subaccounts {
    position: absolute;
    display: none;
    padding: 0;
    top: -2px;
    left: -293px;
    width: 293px;
    font-size: 14px;

    .unit {
        position: relative;
        border-radius: 0;
        border-width: 0;
        box-shadow: 0px 12px 35px 0px rgba(16, 36, 95, 0.2);

        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            top: 40px;
            left: -5px;
            border-width: 5px 5px 5px 0;
            border-right-color: #fff;
        }

    }

}
                    
.list-subaccounts .unit-hd {
    background:none;
    border-radius:0;
    padding:15px 9px;
}
.list-subaccounts .nano {
    height:245px;
}
.list-subaccounts .nano > .nano-content {
    padding:0;
}
.list-subaccounts .nano > .nano-content li {
    display: block;
    width: auto;
    height: auto;
}
.list-subaccounts .cover {
    padding:8px 9px;
}
.list-subaccounts .unit-hd + .unit-bd,
.list-subaccounts .unit-ft,
.list-subaccounts .cover {
    border-top:1px solid #f7f7f7;
}
.list-subaccounts .cover:first-child {
    border-top:0;
}
.list-subaccounts .unit-bd .cover:not(.is-logged):hover {
    background-color:#fbfbfb;
}
.list-subaccounts .truncate {
    width:160px;
}
.list-subaccounts .cover-figure {
    padding:0 8px 0 0;
}
.list-subaccounts .cover-figure .avatar-wrap {
    display:block;
    .avatar {
        border-radius:50%;
        &.x-small [data-icon='P'] {
            font-size:15px;
            bottom:-2px;
        }
    }
}
.list-subaccounts .btn {
    line-height:1.1;
    min-width:60px;
    max-width:91px;
    font-size:11px;
    float:right;
}
.list-subaccounts .info {
    color:#c7c8ca;
    font-style:italic;
    cursor:text;
}
.list-subaccounts .info,
.list-subaccounts .is-logged .btn {
    display:none;
}
.list-subaccounts .is-logged .info {
    display:block;
}
.list-subaccounts .is-logged .cover-content {
    font-family:'robotobold',sans-serif;
}
.list-subaccounts .overlap {
    font-size: 9px;
    margin: 0;
}
.list-subaccounts .overlap .ico-subaccounts{
    @include x-svg-size(30);
    margin:2px auto;
    stroke-width: 3.4px;
}
.list-subaccounts .overlap span{
    color: #a7afc6 !important;
    font-family: $font-roboto-medium;
    font-size: 9px;
}
.list-subaccounts .f-search input[type='text'] {
    position:static;
}
