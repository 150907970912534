/* [ wysiwig ] */
[data-wysiwig='true'] {
    overflow:auto;
}


.intro {
    font-size:16px;
    line-height:1.4em;
}


/* [ popup - complete data - step 1 ] */
.popup-complete-data .col:first-child {
    width:57%;
}
.popup-complete-data .col:last-child {
    width:43%;
}
.popup-complete-data .col-thumb figure {
    margin:18px 15px 0 0;
}
.popup-complete-data .alternate-link {
    margin-bottom:0;
}
.popup-complete-data .form-inline .btn {
    min-width:50px;
}

/* [ popup - complete your profile ] */
.popup-complete-profile .upload-image-file .choose-photo {
    margin-top:60px;
}
.popup-complete-profile .upload-image-cam .canvas {
    margin-top:30px;
}
.popup-complete-profile .col-thumb {
    float:left;
    margin:15px 0 0;
    width: auto;
}
.popup-complete-profile .col-data {
    margin:20px 0 0 250px;
    width: auto;
}
.popup-complete-profile .list-inline li {
    vertical-align:middle;
}
.popup-complete-profile hr,
.popup-complete-profile .spacer {
    margin:20px 0 25px;
}
.popup-complete-profile .btn-wrap {
    margin-top:18px;
}
.popup-complete-profile .ico-social-in a,
.popup-complete-profile .ico-social-fb a {
    display:block;
    margin-left:10px;
    height:27px;
    overflow:hidden;
    text-indent:-999em;
    background:url($src-image + 'popup/account_complete_data/ico_social.png') no-repeat;
}
.popup-complete-profile .ico-social-in a {
    background-position:0 0;
    width:100px;
}
.popup-complete-profile .ico-social-fb a {
    background-position:-124px 0;
    width:116px;
}



/* [ popup - add presenter ] */
.popup-presenter .event-presenter {
    position:relative;
}
.popup-presenter .form-inline .form-group {
    margin:5px 0 20px;
}
.popup-presenter .event-presenter-about {
    font-size:14px;
    padding:10px 0;
    min-height:116px;
}
.popup-presenter .event-presenter-details {
    margin:0 0 10px;
}
.popup-presenter .event-presenter-avatar {
    margin:0;
    position:absolute;
    left:0;
    top:0;
    padding: 0 15px 15px 15px;
    @media (max-width: 768px) {
        position: relative;
        margin: 0 auto;
        width: 218px;
        float: none;
    }
}
.popup-presenter h4 {
    margin:0 0 10px;
    font:bold 16px 'robotoregular', Helvetica, Arial,sans-serif;
    letter-spacing:0
}
.popup-presenter .event-presenter .event-presenter-header {
    margin-left:0;
}
.popup-presenter .event-presenter-about p {
    margin:0;
}
.popup-presenter .event-presenter-about textarea {
    height:148px;
}
.popup-presenter .event-presenter-info {
    margin-left:225px;
    @media (max-width: 768px) {
        margin-left: auto;
    }
}
.popup-presenter .btn.x-large {
    min-width:108px;
}
.popup-presenter .options {
    color:#555;
    margin:15px 0 0 -100px;
    @media (max-width: 768px) {
        float: none;
        margin: 15px auto 0;
        text-align: center;
    }
}
.popup-presenter .social {
    position:relative;
}
.popup-presenter .social dt {
    color:#606365;
    font:14px 'robotoregular', Helvetica, Arial,sans-serif;
    letter-spacing:0;
    margin-right:5px;
}
.popup-presenter .social .dropdown-info.pos-tl {
    left:-7px;
    min-width:460px;
    white-space:nowrap;
}
.popup-presenter .social .dropdown-bd {
    padding:5px 10px;
}
.popup-presenter .social .form-group {
    margin:0;
}
.popup-presenter .social .form-label {
    color:#606365;
}

.popup-presenter .social-ico-fb + .dropdown-info.pos-tl .dropdown-wrap:after,
.popup-presenter .social-ico-fb + .dropdown-info.pos-tl .dropdown-wrap:before {
    left:103px;
}
.popup-presenter .social-ico-twitter + .dropdown-info.pos-tl .dropdown-wrap:after,
.popup-presenter .social-ico-twitter + .dropdown-info.pos-tl .dropdown-wrap:before {
    left:138px;
}
.popup-presenter .social-ico-googleplus + .dropdown-info.pos-tl .dropdown-wrap:after,
.popup-presenter .social-ico-googleplus + .dropdown-info.pos-tl .dropdown-wrap:before {
    left:174px;
}

.popup-presenter .upload-image-file .choose-photo {
    margin-top:100px;
}
.popup-presenter .upload-image-cam .canvas {
    margin-top:80px;
}


/* [ popup - account verification ] */
.popup-account-verify .fancybox-title {
    font-size:50px;
}
.popup-account-verify .btn {
    min-width:50px;
}
.popup-account-verify .inplace {
    margin-top:30px;
}
.popup-account-verify .inplace-form {
    margin-top:11px;
    white-space:nowrap;
}


/* [ popup - choose template ] */
.popup-choose-template .expand {
    margin-bottom:32px;
}
.popup-choose-template .expand .main-arrow {
    left:50%;
    margin-left:-11px;
}
.popup-choose-template .list-component-images li {
    margin:0;
    display:inline-block;
    vertical-align:top;
}
.popup-choose-template .list-component-images .f-holder {
    margin:0 7px;
}
.popup-choose-template .list-component-images .unit {
    max-width:192px;
    min-height:100px;
    height:auto;
}



/* [ contacts- groups ] */
.popup-groups .group-name {
    margin:20px auto 40px;
    max-width:470px;
}
.popup-groups .group-name .pictogram-item {
    margin:0 auto 50px;
}
.popup-groups .group-name .list-pictogram-hd {
    font-size:30px;
}


/* [ introduce subaccounts ] */
.popup-subaccounts-intro {
    text-align:center;
}
.popup-subaccounts-intro .subtitle {
    font-size:42px;
    color:#6ebe3b;
}
.popup-subaccounts-intro p {
    font-size:21px;
}
.popup-subaccounts-intro strong {
    font-weight:normal;
}
.popup-subaccounts-intro .video {
    margin:0 0 0 -28px;
}


/* [ Toll free ] */
.popup-account-balance {
    text-align:center;
}
.popup-account-balance .fancybox-title-inside-wrap .dialog-title {
    color: #fff;
    margin: 0;
    font-size: 30px;
    line-height: 1.4;
    letter-spacing: -1px;
}
.popup-account-balance.dialog .dialog-content {
    background: #f7f7f7;
    padding: 28px;
    color: #363a3d;
}
.popup-account-balance p {
    font-size:24px;
}
.popup-account-balance .list-tiles .col {
    width:146px;
    margin:15px 10px;
}
.popup-account-balance .list-tiles .unit {
    height:122px;
    line-height: 1.4;
}
.popup-account-balance .list-tiles .unit-bd {
    height:90px;
}
.popup-account-balance .list-tiles .tiles-info {
    font-size:46px;
}
.popup-account-balance .list-tiles .unit-hd {
    color:#737373;
}
.popup-account-balance .unit-hov-bordered:hover .unit-hd {
    background-color:transparent;
}
.popup-account-balance .btn {
    min-width:155px;
}


/* [ migrate ] */
.popup-welcome-info .fancybox-content,
.popup-account-migrate .fancybox-content {
    color:#464a55;
    font-size:16px;
    line-height:1.5;
    padding:20px 55px;
}
.popup-welcome-info .subtitle,
.popup-account-migrate .subtitle {
    font-size:49px;
    color:#6ebe3b;
    margin:0 0 23px;
}
.popup-account-migrate .btn {
    min-width:88px;
}
.popup-account-migrate .list-pictogram-horizontal .pictogram-item {
    text-align:left;
    margin:17px 0 17px 80px;
}
.popup-account-migrate .list-pictogram-hd {
    color:#464a55;
    font-size:30px;
}

.popup-welcome-info em {
    font-style:italic;
    padding:0 3px;
    background-color:#faf6ee;
}
.popup-welcome-info p {
    margin:24px 0;
}
.popup-welcome-info .vouchers {
    padding-right:185px;
    position:relative;
}
.popup-welcome-info .vouchers:after {
    content:'';
    display:inline-block;
    width:183px;
    height:177px;
    background:url($src-image + 'popup/account_migrate/amazon_vouchers.png') no-repeat 0 0;
    position:absolute;
    top:50%;
    right:0;
    -webkit-transform:translateY(-50%);
        -ms-transform:translateY(-50%);
            transform:translateY(-50%);
}

.signature {
    margin-bottom:0;
}
.signature strong {
    color:#6ebe3b;
    display:block;
    font-weight:normal;
}

/* [ referral program terms ] */
.popup-referral-tos .fancybox-title em {
    font-size:24px;
}
.popup-referral-tos ol {
    padding-left:0;
    list-style:none;
    counter-reset:step;
}
.popup-referral-tos li {
    margin:0 0 40px;
}
.popup-referral-tos li:before {
    content:'\000A7 ' counter(step);
    counter-increment:step;
    font-size:20px;
    text-align:center;
    display:block;
    margin:0 0 20px;
}

/* [ popup New features Capping ] */

#new_features_popup {
cursor: pointer;
display: none;
}

#new_features_popup .fancybox-container {
cursor: pointer;
display: block;
height: 480px;
}

#new_features_popup img {
max-width: 100%;
position: absolute;
bottom: 0px;
border-bottom-left-radius: 3px;
border-bottom-right-radius: 3px;
}

#new_features_popup h3 {
padding: 35px 45px;
padding-bottom: 0px;
text-align: center;
font-size: 22px;
}

#new_features_popup h4 {
font-family: 'robotoregular';
color: #6ebe3b;
font-size: 14px;
margin: 0px;
text-transform: uppercase;
letter-spacing: 2px;
text-align: center;
}


/* [ popup survey ] */
.popup-survey-wrap .fancybox-skin {
    background-color:#ffffff;
}
.popup-survey {
    font-size:22px;
    font-family:'robotolight',sans-serif;

    strong {
        font-family:'robotomedium',sans-serif;
    }

    input {
        font-size:initial;
    }

    .fancybox-content {
        padding:40px 60px;
    }
    .survey-content {
        min-height:370px;
        position:relative;
        z-index:3;
    }

    .survey-hd {
        font-size:32px;

        .caption {
            font-style:italic;
            margin-left:7px;
        }
    }

    .btn-success {
        min-width:190px;
    }

    .survey-step-2 {
        .form-group {
            margin: 5px 0;
            font-size: 90%;
        }
    }
}

/* [ popup survey sections ] */
    .survey-start,
    .survey-finish {

        &:before {
            content:'';
            display:block;
            position:absolute;
            z-index:2;
            right:0;
            top:50%;
            transform:translateY(-50%);
            background:0 0 no-repeat;
        }

        .survey-content {
            padding-right:220px;
        }

        .survey-bd {
            margin:60px 0;
        }
    }


    .survey-start {

        &:before {
            background-image:url($src-image + 'popup/survey/ico_survey.png');
            width:324px;
            height:438px;
        }

        .survey-content {
            padding-top:22px;
        }

        .caption-small {
            position:absolute;
            bottom:20px;
            left:60px;
            right:60px;
        }
    }


    .survey-finish {

        &:before {
            background-image:url($src-image + 'popup/survey/ico_success.png');
            width:260px;
            height:306px;
        }

        .survey-content {
            padding-top:32px;
        }
    }


    .survey-form {
        fieldset {
            display:none;
        }

        .survey-content {
            padding-left:30px;
        }

        .survey-hd {
            min-height:77px;
        }

        .survey-hd:before {
            color:$color-success;
            text-align:right;
            font-family:'robotomedium',sans-serif;
            content:attr(data-step) '.';
            display:inline-block;
            width:56px;
            margin:0 15px 0 -70px;
        }
        .note {
            font-size:12px;
            font-style:italic;
        }
        .hide-check + label {
            padding-left:46px;
        }
        .check {
            top:6px;
            width:24px;
            height:24px;
        }
        .check:before {
            font-size:20px;
            line-height:1.2;
        }
        .radio:before {
            width:14px;
            height:14px;
        }
        .f-other {
            margin:10px 0 0 46px;
        }

        .btn-wrap {
            position:absolute;
            bottom:46px;
            left:0;
            right:0;
        }
    }

.addons-subaccouts-section .col {
    padding-top:20px;
}