/* no user select */
    .btn-group,
    .btn-choice ,
    .no-user-select,
    .hide-check + label,
    .disabled,
    .disabled-simple,
    .blend,
    .lock {
        -webkit-touch-callout:none;
        user-select:none;
    }
    .no-user-select [contenteditable] {
        user-select:auto;
    }


/* [ spinner ] */
    .spinner:before {
        content:'';
        width:100%;
        height:100%;
        display:block;
        white-space:nowrap;
        box-sizing:border-box;
        background:url($src-image + 'indicators/loader_default_16.gif') no-repeat 50% 50%; /* fallback */
    }
    .spinner:not(:required):before {
        background:none;
        border-radius:50%;
        border:1px solid #d7d7d7;
        border-top-color:$color-success;
        animation:spinner .9s linear infinite;
    }
    .spinner-wrap,
    .spinner {
        display:inline-block;
        pointer-events:none;
        position:absolute;
        z-index:6;
        top:50%;
        left:50%;
    }
    .spinner {
        margin:-7px 0 0 -7px;
        width:14px;
        height:14px;
        opacity:0;
        visibility:hidden;
    }
    .spinner-wrap .spinner {
        position:static;
        display:block;
        margin:0 auto 5px;
    }
    .spinner-wrap {
        line-height:1.1;
        min-width:80px;
        text-align:center;
        transform:translate(-50%,-50%);
    }
    .spinner-avatar:not(:required):before,
    .spinner-figure:not(:required):before,
    .spinner-social:not(:required):before {
        border-color:rgba(255,255,255,.4);
        border-top-color:#fff;
    }
    .spinner-btn:not(:required):before {
        border-color:rgba(255,255,255,.2);
        border-top-color:rgba(255,255,255,.8);
    }
    .avatar[data-loading='true'] [data-icon='P'],
    .figure-wrap .spinner-wrap {
        opacity:0;
        visibility:hidden;
    }
    .spinner-info small {
        font-size:10px;
    }
    .btn[data-loading='true'] .spinner-btn,
    .f-holder[data-loading='true'] .spinner-addon,
    .integrate-state[data-loading='true'] .spinner-social,
    .figure-wrap[data-loading='true'] .figure:before,
    .figure-wrap[data-loading='true'] .spinner-figure,
    .figure-wrap[data-loading='true'] .spinner-wrap,
    .avatar[data-loading='true']:before,
    .avatar[data-loading='true'] .spinner-avatar,
    .blend[data-loading='true'] .spinner-blend,
    .table-wrap[data-loading='true'] .spinner-table,
    .unit[data-loading='true'] .spinner-unit {
        opacity:1;
        visibility:visible;
    }


/* [ spinner size ] */
    .spinner-size-medium {
        margin:-10px 0 0 -10px;
        width:20px;
        height:20px;
    }


/* [ disabled elements ] */
    .disabled-simple {
        pointer-events:none;
        opacity:0.5;
    }
    .disabled {
        @extend .disabled-simple;
        position:relative;

        &:before {
            content:'';
            display:block;
            position:absolute;
            top:0;
            left:0;
            bottom:0;
            right:0;
            z-index:100;
        }
    }
    .disabled-fake {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: block;
            height: 100%;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            background: linear-gradient(to bottom, #fff 0%, #f3f3f3 100%);
            opacity: .5;
        }
    }


/* [ blend ] */
    .blend {
        position:relative;
        min-height:200px;

        &:before {
            content:'';
            display:block;
            position:absolute;
            top:0;
            right:0;
            left:0;
            bottom:0;
            z-index:30;
            background:rgba(255,255,255,.7);
        }
    }
    .blend-transparent:before {
        background:none;
    }
    .blend-dark:before {
        background:rgba(39,40,42,.9);
    }
    .blend-info {
        color:#555;
        text-align:center;
        padding:50px 23px 15px;
        max-width:200px;
        /*background:url($src-image + 'indicators/loader_gray_blend_48.gif') no-repeat 50% 15px;*/
        z-index:31;
        display:none;
        position:absolute;
        top:50%;
        left:50%;
        border-radius:2px;
        transform:translate(-50%,-50%);
    }
    .blend-info .spinner-blend {
        top:20px;
        margin:0 0 0 -10px;
    }
    .blend .blend-info {
        display:inline-block;
    } 
    body.blend:before {
        position:fixed;
        z-index:8000;
    }
    body.blend .blend-info {
        z-index:8001;
    }
    .blend-info.blend-info-bg {
        background-color:#f6f6f6;
    }


/* [ blend with blur ] */
    .blend-blur {
        min-height:inherit;
    }
    .blend-blur.blend-dark:before {
        background:rgba(179,179,179,.6);
        opacity:.1;
    }
    .blend-blur .disabled {
        opacity:.5;
        filter:blur(4px);
    }
    .blend-blur .blend-info p {
        margin:10px 0 0;
    }
    .blend-info-clear {
        padding:0;
        /*background-image:none;*/
    }
    .blend-info-noloading {
        /*background-image:none;*/
        padding-top:15px;
    }
    .blend-info-clear .spinner-blend,
    .blend-info-noloading .spinner-blend {
        display:none;
    }
    .blend-blur .blend-info-noloading,
    .blend-blur .blend-info-clear {
        color:#606365;
        max-width:350px;
    }


/* [ lock site - needed an extra div ] */
    .lock {
        cursor:default;
        position:fixed;
        z-index:8002;
        top:0;
        right:0;
        bottom:0;
        left:0;
    }


/* [ special layer to propagate events ] */
    .propagate {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index:30;
    }

