.webinar-popup {
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 5px;
    height: 100%;
    width: 100%;
        
    &-link {
        height: 100%;
        width: 100%;
        display: table;
    }
    
    .block-webinar {
        
        &-no-thanks {
            position: absolute;
            height: 40px;
            width: 100%;
            bottom: 10px;
            
            .hide-check + label {
                font-size: 14px;
                color: #fff;
                padding: 0;
                margin: 0;

                &:hover {
                    text-decoration: underline;
                }
            }

            .hide-check[disabled] + label {
                cursor:pointer;
            }
            
            a {
                line-height: 40px;
                color: #fff;
                
                &:hover {
                    text-decoration: underline;
                }
                
                &.terms {
                    position: absolute;
                    font-family: $font-roboto-regular;
                    right: 20px;
                    color: #bee8fd;
                    font-size: 12px;

                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            
        }
        
        &-checkbox {
            position: absolute;
            height: 40px;
            left: 120px;
            right: 120px;
            bottom: 0;

            .f-holder {
                line-height: 40px;
                font-size: 12px;
                text-align: center;
                
                .check {
                    background: transparent;
                    border: 1px solid #858c99;
                    box-shadow: none;
                    width: 14px;
                    height: 14px;
                    visibility: hidden;
                    
                    &::before {
                        top: -1px;
                    }
                    
                }
                
            }
            
        }
    
    }
    
    &.en { }
    &.pl { }
    &.ru { }
    &.es { }
    &.de { }
    &.fr { }
    
}

.banner-webinar {
    width: 708px;
    height: 118px;
    margin: 0 auto 20px;
    
    &.en {}
    &.pl {}
    &.ru {}
    &.es {}
    &.de {}
    &.fr {}
}

.fancybox-skin.webinar {
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
}
