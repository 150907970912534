.ico-toolkit {
    display:block;
    position:relative;
    width:14px;
    height:14px;
    background:#fff url($src-image + 'room/editor_tools.png') no-repeat 0 50px;
    border:1px solid #f8f8f8;
    overflow:hidden;
}
.tool-nocolor {
    background-position:0 0;
}
.tool-bottomright {
    background-position:0 -20px;
}
.tool-fullscreen {
    background-position:0 -40px;
}
.tool-center {
    background-position:0 -60px;
}
.tool-topleft {
    background-position:0 -80px;
}
.tool-topright {
    background-position:0 -100px;
}
.tool-bottomleft {
    background-position:0 -120px;
}
.tool-repeat {
    background-position:0 -140px;
}
.tool-repeatx {
    background-position:0 -160px;
}
.tool-repeaty {
    background-position:0 -180px;
}

.tool-holder {
    position:relative;
    vertical-align:middle;
    display:inline-block;
    padding:3px 16px 3px 3px;
    background:#fff no-repeat 100% 50% url(data:image/gif;base64,R0lGODlhCwADAIABAG6+OwAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS41LWMwMjEgNzkuMTU0OTExLCAyMDEzLzEwLzI5LTExOjQ3OjE2ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjgzMmEzZTA4LWQ4YWMtNGRkNS04NTAzLTEwYjM3NjY5OWFhMyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDozRDBBRjFEREI5QTQxMUUzQkQ5RDk3RkEzNDEzOTI4QSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozRDBBRjFEQ0I5QTQxMUUzQkQ5RDk3RkEzNDEzOTI4QSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowZmE0M2FhNS1mNjYyLTQ3ZWUtOGVmYy1kNTExNjg2N2NhMmEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ODMyYTNlMDgtZDhhYy00ZGQ1LTg1MDMtMTBiMzc2Njk5YWEzIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAQAAAQAsAAAAAAsAAwAAAgiEHalx6+BUAQA7);
    border-radius:2px;
    border:1px solid #ccc;
    // box-shadow:0 0 1px rgba(0,0,0,.2);
}
.dropdown-tool-option {

    .dropdown-bd {
        font-size:12px;
    }

    .form-group {
        margin:5px 0;

        .form-label {
            padding-right:5px;
        }
    }

    .check {
        top:1px;
    }

    .error {
        border-color: #ff4200;
    }
}
.dropdown-form-edit {
    width:263px;
    @media (max-width: 576px) { 
        width: 233px;
    }
}
.dropdown-logo {
    top:350px;left:200px
}
// .tool-option {
//     color:#606365;
// }
// .tool-option,
// .tool-option .btn.x-small {
//     font-size:12px;
// }
// .tool-option .form-group {
//     margin:5px 0;
// }
// .tool-option .form-group .form-label {
//     padding-right:5px;
// }
// .tool-option .check {
//     top:1px;
// }
.tool-bx {
    font-size:11px;
    position:absolute;
    z-index:999;
    top:-1px;
    left:-1px;
    background:#fff;
    border-radius:2px;
    border:1px solid rgba(0,0,0,0.1);
    //box-shadow:0 1px 2px rgba(0,0,0,.2);
}
.tool-bx .in {
    padding:4px;
}
.tool-bx ul,
.tool-bx li {
    @include x-list-init;
}
.tool-bx ul {
    margin:3px 0;
}
.tool-bx li {
    white-space:nowrap;
    padding:2px 10px 2px 3px;
    cursor:pointer;
}
.tool-bx li:hover {
    background:#f2f2f2;
}
.tool-bx .ico-toolkit {
    display:inline-block;
    vertical-align:middle;
}
.dropdown-tool-option .figure,
.cropzoom {
    margin:0 auto;
    width:130px;
    height:130px;
    /*background:#6ebe3b;*/
    outline:1px solid transparent;
    /*border:2px solid #6ebe3b;*/
    box-shadow:none;
    border-radius:0;
}
.tool-image .tool-bx {
    width:138px;
}
.tool-image .ico-toolkit img {
    position:absolute;
    top:50%;
    left:0;
    transform:translateY(-50%);
}
.dropdown-tool-option .colorpicker {
    position:absolute;
    top:0;
    left:0;
    min-width: auto;
    padding: 1px;
}
.dropdown-tool-option .colorpicker .sp-replacer {
    padding:0;
    margin:0;
    height:auto;
    border:0;
    background:transparent;
    box-shadow:none;
    display:block;
}
.dropdown-tool-option .colorpicker .sp-preview {
    width:14px;
    height:14px;
    margin:1px 17px 1px 2px;
}
.dropdown-tool-option .colorpicker .sp-preview .sp-preview-inner {
    position: absolute;
    border: 1px solid #f8f8f8;
}
.dropdown-tool-option .colorpicker .sp-replacer:hover .sp-preview {
    opacity:1;
}
.dropdown-logo .img-thumb {
    width:166px;
    height:60px;
}
/*.tool-timezone .form-control {
    width:175px;
}*/
/*.tool-datepicker .form-control {
    width:165px;
}*/
.dropdown-tool-option .form-inline .spacer {
    width:1px;
    height:44px;
    display:inline-block;
    border:0;
    border-right:1px solid #ededed;
    margin:-10px 0 -16px;
}
.dropdown-tool-option .tool-count {
    width:60px;
}
