.banner-component {
    border-radius: 5px;
    padding: 20px 40px;
    @media only screen and (max-width: 768px) {
        padding: 20px;
    }
    &.bg-variant-light {
        background-color: $color-white;
        border: 1px solid #E1E5EE
    }
    &.bg-variant-dark {
        background-color: $color-darken;
        border: 1px solid $color-darken;
    }
    &.bg-variant-info {
        background-color: $color-info;
        border: 1px solid $color-info;
    }
    &__svg {
        flex-shrink: 0;
        @media only screen and (max-width: 768px) {
            margin-bottom: 20px;
        }
        svg {
            width: 40px;
            height: 40px;
            &.color-info {
                fill: $color-info;
            }
            &.color-success {
                fill: $color-success;
            }
            &.color-light {
                fill: $color-white;
            }
            &.stroke-info {
                stroke: $color-info;
            }
            &.stroke-success {
                stroke: $color-success;
            }
            &.stroke-light {
                stroke: $color-white;
            }
            &.stroke-big {
                stroke-width: 5;
            }
        }
    }
    &__content {
        margin-inline: 40px;
        @media only screen and (max-width: 768px) {
            margin-bottom: 20px;
            margin-inline: 0;
        }
        h3 {
            font-size: 21px;
            line-height: 28px;
            margin-bottom: 5px;
            font-family: 'robotoregular';
            @at-root .bg-variant-light & {
                color: $color-darken;
            }
            @at-root .bg-variant-dark &, .bg-variant-info & {
                color: $color-white;
            }
        }
        p {
            font-size: 14px;
            line-height: 22px;
            margin: 0;
            @at-root .bg-variant-light & {
                color: $color-gray-blue;
            }
            @at-root .bg-variant-dark & {
                color: #6B7183;
            }
            @at-root .bg-variant-info & {
                color: $color-white;
            }
        }
    }
    &__btn {
        flex-shrink: 0;
        a {
            font-size: 14px !important;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: -0.01em !important;
            box-shadow: none;
        }
    }
}

.banner-blend {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(42, 45, 55, 0.7);
}
.banner-branding {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 700px;
    @media only screen and (max-width: 992px) {
        width: 85%;
    }
}
