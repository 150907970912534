html {
    visibility:hidden; /* nie pokazujemy AP w iframe. jesli kontent nie wyswietla sie w iframe - zdejmujemy hidden. */
    font-size:100.01%;
}
body {
    color:$color-base;
    font:14px/1.6 $font-roboto-regular;
}
h1, h2, h3, h4, h5, h6 {
    color:$color-base;
    font-weight:normal;
    line-height:1.2;
    margin-bottom:27px;

    small {
        font-size:0.6em;
        letter-spacing:0;
    }
}

h2 {font-size:35px; letter-spacing:-1px;}
h3 {font-size:26px; margin-bottom:20px;}
h4 {font-size:20px; margin-bottom:15px;}
h5 {font-size:18px; color:#232728;}
h6 {font-size:inherit; font-weight:bold; letter-spacing:0}

h1, h3, h4 {
    em {
        display:block;
        letter-spacing:-1px;
    }
}

h1 {
    font-size:52px;
    letter-spacing:-2px;

    em {
        font-size:34px;/*0.654em*/
    }
}
///*h2 em {color:#6ebe3b;}*/

p {margin:15px 0 22px;}

hr,
.spacer {
    border-top:1px solid #e4e5e5;
    border-bottom:1px solid #fff;
}
blockquote {color:#606365; margin:10px 0;}
q {color:#555; font-style:italic;}

.form-inline .break-word {
    word-break:break-all;
    word-break:keep-all;
    word-wrap:break-word;
}
