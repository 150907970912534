%app-layout {
    display:inline-block;
    vertical-align:middle;
}

.app-integration-item {
    display: flex;
    align-items: center;
    margin: 0 -10px;
    padding: 17px 10px;
    line-height: 1;
    border-bottom: 1px solid #dedede;
    cursor: pointer;

    &:hover {
        background-color: #e5e5e5;

        .app[data-expand="true"] & {
        background: none;
        }
    }

    .app[data-expand="true"] &,
    .app:last-child & {
        border-bottom-color: transparent;
    }

    .app-header {
        display: flex;
        flex-basis: 43%;
        align-items: center;
        
        &.plugin{
            flex-basis: 50%;
        }

        .icon-wrapper {
            min-width: 40px;
            margin-right: 14px;
            margin-left: 4px;
        }
    }

    .app-description {
        flex: 1;
        font-size: 16px;
        text-align: right;
        color: #606365;
        &.plugin{
            flex-basis: 50%;
        }
    }
}

.app-item {
    line-height:1;
    cursor:pointer;
    border-bottom:1px solid #dedede;
    padding:17px 10px;
    margin:0 -10px;

    &:hover {
        background-color:#e5e5e5;

        .app[data-expand='true'] & {
            background:none;
        }
    }

    .app[data-expand='true'] &,
    .app:last-child & {
        border-bottom-color:transparent;
    }
}

.app-bd {
    @extend %app-layout;
    color:#606365;
    font-size:16px;
    text-align:right;
    width:56%;
    &.plugin{
        width: 50%;
    }
}
.app-hd {
    @extend %app-layout;
    width:43%;
    &.plugin{
        width: 50%;
    }
}
.app-name {
    @extend %app-layout;
    font-size:24px;
    margin:0;

    .label.small {
        display: inline-block;
        font-family: $font-roboto-regular;
        font-size: 10px;
        line-height: 14px;
        max-width: 125px;
        white-space: normal;
    }

}

.app-ico-status {
    color:$color-muted;
    font-size:20px;
    top:1px;

    .app-connected & {
        color:$color-success;

        &:before {
            content:'v';
        }
    }
}

.app-ico {
    background: url($src-image + 'integrations/icons_v11.png') no-repeat;
    width:40px;
    height:33px;
    font-size:38px;
    line-height:.7;
    text-align:center;
}
%app-ico-fontico {
    color:#b0b0b0;
    background:none;
}
%app-ico-fontico-connected {
    color:$color-success; //BRAND!!
}
//       app-ico-sprite(app-name,       bg,        bg-connected,   additional)
@include app-ico-sprite(facebook,       0    0,    -54px    0);
@include app-ico-sprite(twitter,        0  -68px,  -54px  -68px);
@include app-ico-sprite(linkedin,       0  -138px, -54px  -138px);
@include app-ico-sprite(salesforce,     0  -211px, -54px  -211px);
@include app-ico-sprite(wordpress,      0  -281px, -54px  -281px);
@include app-ico-sprite(pipedrive,      0  -1511px, -50px  -1511px);
@include app-ico-sprite(getresponse,    0  -349px, -54px  -349px);
@include app-ico-sprite(dropbox,        0  -419px, -54px  -419px);
@include app-ico-sprite(googledrive,    0  -489px, -54px  -489px);
@include app-ico-sprite(skydrive,       0  -558px, -54px  -558px);
@include app-ico-sprite(youtube,        0  -626px, -54px  -626px);
@include app-ico-sprite(vimeo,          0  -695px, -54px  -695px);
@include app-ico-sprite(google,         0  -765px, -54px  -765px);
@include app-ico-sprite(microsoft_outlook,      0  -1821px, -50px  -1821px);
@include app-ico-sprite(chrome,         0  -907px, -54px  -907px);
@include app-ico-sprite(desktop-sharing,0  -977px, -54px  -977px);
@include app-ico-sprite(slack,          0 -1255px, -54px -1255px, (height:32px));
@include app-ico-sprite(hubspot,        0 -1327px, -54px -1327px, (height:41px, margin-bottom:-10px, top:-8px));
@include app-ico-sprite(infusionsoft,   0 -1394px, -54px -1394px, (height:41px, margin-bottom:-10px, top:-8px));
@include app-ico-sprite(googleanalytics,0 -1460px, -48px -1460px, (height:32px));
@include app-ico-sprite(googletagmanager,0 -1618px, -48px -1618px, (height:34px));
//@include app-ico-sprite(copypaste,      null, null);
//@include app-ico-sprite(contacts,       null, null);
//@include app-ico-sprite(email,          null, null);
@include app-ico-sprite(copypaste,      0 -1187px, -54px -1187px, (height:32px));
@include app-ico-sprite(contacts,       0 -1047px, -54px -1047px, (height:34px));
@include app-ico-sprite(email,          0 -1113px, -54px -1113px);
@include app-ico-sprite(paypal,         0 -1675px, -44px -1675px);
@include app-ico-sprite(zapier,         0 -1718px, -44px -1718px);
@include app-ico-sprite(payu,           0 -1767px, -44px -1767px);

@include app-ico-sprite(linkedininsight,0  -138px, -54px  -138px);
@include app-ico-sprite(stripe,         0  -1875px, -44px  -1875px);
@include app-ico-sprite(tiktoktracker,  -3px -1929px, -47px -1929px, (height:33px));
@include app-ico-sprite(cookiescript,   -3px -1977px, -47px -1977px, (height:34px));
@include app-ico-sprite(facebookpixel,  0 -2029px, -48px -2029px, (height: 34px));
@include app-ico-sprite(paypalrest,     0 -1675px, -44px -1675px);
@include app-ico-sprite(fakturownia,    0 -2079px, -44px -2079px);
@include app-ico-sprite(efakturierung, 0 -2079px, -44px -2079px);

.app,
.access-type {
    .expand {
        margin-bottom:0;

        .main-arrow {
            left:32px;
        }

        .content {
            padding:30px;
            @media only screen and (max-width: 767px) {
                padding: 0;
            }

            &.integrations {

                .integration-id {
                    color: #333;
                }

                .list-check-cols {

                    .form-group {
                        margin: 20px 0 5px;
                    }

                    .form-control {
                        font-size: 16px;
                        width: auto;
                        margin: 0 30px 10px 0;

                        .check {
                            top: 3px;
                        }

                    }

                }

                .form-inline {
                    width: 535px;
                    margin: 0 auto;

                    &.form-paypal {
                        margin: 25px 200px 0 0;
                        width: auto;
                        @media (max-width: 991px) {
                            margin: 25px 0 0 100px;
                        }
                        @media (max-width: 767px) {
                            margin: 25px auto;
                        }

                        .form-label {
                            text-align: right;
                            vertical-align: top;
                            padding-top: 5px;
                            @media (max-width: 991px) {
                                text-align: left !important;
                            }
                        }

                        .form-control {
                            vertical-align: top;
                            text-align: left !important;
                        }

                        .error-paid-fix {
                            padding-bottom: 20px;
                        }

                        .form-currency {
                            .f-holder {
                                width: 135px;
                                display: inline-block;
                                input {
                                    height: 34px;
                                    box-shadow: none;
                                }
                            }
                        }

                        .form-price {

                            .f-holder {
                                display: inline-block;
                                vertical-align: middle;
                                width: 135px;
                                input {
                                    height: 34px;
                                    box-shadow: none;
                                }
                            }

                            .tip-info-wrap {
                                display: inline-block;
                                vertical-align: middle;

                                .svg-fill {
                                    width: 20px;
                                    height: 20px;
                                    color: #00afec;
                                }

                            }

                        }

                    }

                    .form-group {
                        margin: 5px 0 15px;
                    }

                    .form-group .btn-wrap {
                        vertical-align: top;
                        padding-top: 0;
                    }

                }

                .btn-wrap {
                    margin-top: 0;
                    padding-top: 10px;

                    .btn ~ a {
                        margin-left: 30px;
                    }

                    .btn-link {
                        padding: 7px 0;

                        .ico-info-integrations {
                            width: 22px;
                            height: 22px;

                            &.ico-left {
                                margin-right: 5px;
                            }

                        }

                        .ico-participant {
                            stroke-width: 8;
                        }

                        &:focus {
                            color: #00afec;
                        }

                        &.btn-link-delete {
                            color: #ff4200;
                        }

                        &.btn-zapier {
                            margin-left: 40px;

                            &:before {
                                background: #c9c9c9;
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: -20px;
                                width: 1px;
                                height: 15px;
                                transform: translateY(-50%);
                            }

                        }

                    }

                    &.btn-paypal {
                        border: 1px solid #dbdbdb;
                        border-radius: 3px;
                        text-align: right;
                        padding: 10px 10px 8px;
                        width: 320px;

                        p {
                            margin-bottom: 10px;
                            font-size: 12px;
                        }
                    }

                }

            }

        }

        p {
            margin:0 0 15px;
        }

        .btn.medium {
            padding: 7px 20px;
            [data-icon*='caret'] {
                vertical-align: middle;
            }
        }

        .img {

            &-paypal {
                margin-bottom: 15px;
            }

            &-paypal-payment {
                float:right;
            }

        }

    }

}
.payu-integrate {
    &__additionalManual {
        p {
            font-family: 'robotolight';
            font-size: 16px;
            color: #616161;
            margin: 0 0 15px;
            &:last-child {
                margin-bottom: 24px;
            }
        }
        ul {
            margin-bottom: 24px;
        }
        li {
            font-family: 'robotolight';
            font-size: 16px;
            color: #616161;
            margin: 0;
        }
    }

}
