.subaccounts-add-section .add-section .form-group > .form-control {
    margin-left: 155px;
    margin-right: 155px;
}
.subaccounts-add-section .form-label {
    color: #606365;
    width: 145px;
    margin-top: 0;
}
.subaccounts-add-section .add-section .form-group > .check-list {
    margin-right: 20px;
}
.subaccounts-add-section .check-list .check {
    top: 1px;
}

.subaccounts-section .options {
    margin-top: -25px;
}
.subaccounts-section .name {
    width: 280px;
}
.subaccounts-section .email {
    width: 180px;
}
.subaccounts-section .plan {
    .tip-info-wrap .ico-info_round {
        color: inherit;
        top: -1px;
    }
}
