.page-header {
    padding:40px 0;
    margin: 0;
    background:#fff;
    border-bottom:1px solid #e6e6e6;

    .page-wrap {
        width:926px;
        position:relative;
        display:table;
    }
    
    &-btn {
        position: relative;
        .list-inline > li {
            @media (max-width: 576px) {
                display: block;
            }
        }
    }

    h1 {
        font-size: 34px;
        line-height:1em;
        padding:0;
        margin: 0;
    }

    p {
        margin: 10px 0 0;
        font-size: 12px;
        color: #737373;
    }

    .page-header-ico {
        @include x-svg-size(48);
        margin: 8px 25px 8px 0;
        color:#6ebe3b;
        &.ico-option-addons.svg-stroke {
            stroke-width: 1;
        }
    }
    .ondemand .page-header-ico { color: #2e6fde }
    .automated .page-header-ico { color: #0b23a0 }
    .meeting .page-header-ico, .webinar .page-header-ico { color: #6ebe3b }
    h2 {
        //*color:#6ebe3b;*/
        margin:0 0 20px;
    }

    .lead {
        font-size:18px;
        margin:-5px 0 0;
    }
    
    label {
        margin-bottom: 0;
    }
    
}

.page-header .helper.pos-tl-large {
    top: 54px;
    margin: 0 0 0 -650px;
    width: 220px;
}
[lang='en'] .page-header .helper.pos-tl-large {
    margin: 0 0 0 -544px;
    width: 160px;
}
[lang='de'] .page-header .helper.pos-tl-large {
    margin: 0 0 0 -605px;
}
[lang='es'] .page-header .helper.pos-tl-large {
    margin: 0 0 0 -580px;
    width: 180px;
}
[lang='fr'] .page-header .helper.pos-tl-large {
    margin: 0 0 0 -665px;
    width: 220px;
}
[lang='br'] .page-header .helper.pos-tl-large {
    margin: 0 0 0 -562px;
    width: 220px;
}
[lang='pl'] .page-header .helper.pos-tl-large {
    margin: 0 0 0 -650px;
    width: 220px;
}
[lang='ru'] .page-header .helper.pos-tl-large {
    margin: 22px 0 0 -441px;
    width: 220px;
}
.page-header .dsp-cell {
    text-align:right;
    float:none;
}
.page-header .btn [data-icon='w'] {
    font-size:.75em;

    &:before {
        top:-3px;
    }
}
.page-header .form-group {
    margin:0 0 0 auto;
}
.page-header .form-label {
    color:#6e7072;
    font-size:18px;
    letter-spacing:-1px;
}