/*---------------------------------------------------------------------------------------------
[ Table ]
*/
    .table-wrap {
        position:relative;
    }
    table {
        max-width:100%;
        width:100%;
        background-color:transparent;
        border-collapse:collapse;
        border-spacing:0;

        th, td {
            padding:9px 15px 9px 0;
            text-align:left;
            vertical-align:middle;
            box-sizing:border-box;

            &:first-child {
                padding-left:10px;
            }
        }

        td {
            padding:15px 15px 15px 0;
            border-top:1px solid #e3e3e3;
        }
    }
    .table {
        width:100%;
        margin-bottom:20px;
        border-collapse: separate;
        *border-collapse: collapse;
        background:#fff;
        border-radius:3px;
        border:1px solid rgba(0,0,0,0.1);
        //box-shadow:0 0 1px rgba(0,0,0,.22);

        th {
            color:#fff;
            background:#282b31;

            a {
                color:$color-inherit;
                white-space: nowrap;
            }

        }

        tfoot td {
            line-height:22px;
            background:#ededed;
            padding-top:10px;
            padding-bottom:10px;
        }
    }
    .table thead:first-child tr:first-child > th:first-child,
    .table tbody:first-child tr:first-child > td:first-child,
    .table tbody:first-child tr:first-child > th:first-child {
         border-top-left-radius:2px;
    }
    .table thead:first-child tr:first-child > th:last-child,
    .table tbody:first-child tr:first-child > td:last-child,
    .table tbody:first-child tr:first-child > th:last-child {
        border-top-right-radius:2px;
    }
    .table thead:last-child tr:last-child > th:first-child,
    .table tfoot tr:last-child > th:first-child,
    .table tbody:last-child tr:last-child > td:first-child,
    .table tbody:last-child tr:last-child > th:first-child,
    .table tfoot:last-child tr:last-child > td:first-child,
    .table tfoot:last-child tr:last-child > th:first-child {
        border-bottom-left-radius:2px;
    }
    .table thead:last-child tr:last-child > th:last-child,
    .table tfoot tr:last-child > th:last-child,
    .table tbody:last-child tr:last-child > td:last-child,
    .table tbody:last-child tr:last-child > th:last-child,
    .table tfoot:last-child tr:last-child > td:last-child,
    .table tfoot:last-child tr:last-child > th:last-child {
        border-bottom-right-radius:2px;
    }
    .table tfoot + tbody:last-child tr:last-child td:first-child {
        border-bottom-left-radius:0;
    }
    .table tfoot + tbody:last-child tr:last-child td:last-child {
        border-bottom-right-radius:0;
    }
    .table caption + thead tr:first-child th:first-child,
    .table caption + tbody tr:first-child td:first-child,
    .table colgroup + thead tr:first-child th:first-child,
    .table colgroup + tbody tr:first-child td:first-child {
        border-top-left-radius:2px;
    }
    .table caption + thead tr:first-child th:last-child,
    .table caption + tbody tr:first-child td:last-child,
    .table colgroup + thead tr:first-child th:last-child,
    .table colgroup + tbody tr:first-child td:last-child {
        border-top-right-radius:2px;
    }


/* [ table equal cols ] */
    .table-equal {
        //table-layout:fixed;
        th {
            &:not(.active) {
                [data-icon*="caret"] {
                    display: none;
                }
            }

            .c-arrow [data-icon] {
                display: inherit;
            }
        }
    }


/* [ table vertical border ] */
    .table-stripped {
        td, th {
            //color:#606365;
            padding:5px 20px;
            border:0;
            border-right:1px solid #e3e3e3;

            &:first-child {
                padding-left:8px;
            }

            &:last-child {
                border:0;
                padding-right:7px;
            }
        }

        th {
            padding-top:0;

            .f-holder {
                margin:0 -0.6em;
            }

            &:last-child .f-holder {
                margin-right:-0.4em;
            }
        }

    }


/* [ table no border + overlap ] */
    .table-flat {
        position:relative;
    }
    .table-flat .table {
        //box-shadow:none;
        border-width:0;
        border-radius:0;
    }
    .table-flat thead:first-child tr:first-child > th:first-child {
        border-top-left-radius:0;
    }
    .table-flat thead:first-child tr:first-child > th:last-child {
        border-top-right-radius:0;
    }


/* [ table state: loading ] */
    .table-wrap[data-loading='true'] .table,
    .table-wrap[data-loading='true'] table {
        opacity:.3;
        pointer-events:none;
        -webkit-touch-callout:none;
        user-select:none;
    }
    .table-wrap[data-loading='true'] .table tbody,
    .table-wrap[data-loading='true'] table tbody {
        min-height:50px;
    }


/* [ table elements ] */
    .table tfoot .form-group {
        margin:-5px 0;
    }
    .table tfoot .form-label {
        color:#606365;
    }
    .table tfoot .form-control {
        width:160px;
    }
    table th .vr-list .spacer {
        border-right:1px solid #3d3e41;
        margin:0 5px;
    }
    table h6 {
        margin:0;
    }
    table h6 a {
        color:#282b31;
    }
    table h6 a:hover {
        color:$color-link;
    }
    /* bledy w tabeli */
    table .warning h6,
    table .warning h6 a {
        color:$color-warning;
    }
    table .error h6,
    table .error h6 a {
        color:$color-error;
    }

/* wrapper opisu pod nagłówkiem w komórce tabeli .desc */
    .cell-desc-small {
        font-size:12px;
        line-height:1.4;
        margin:6px 0 0;
        color:#606365;
    }


/* komorka tabeli zawierajaca akcje dla wiersza */
    .cell-action {
        text-align:right;
        @media (max-width: 768px) {
            width: 174px;
        }
    }


    .cell-action-icons {

        a {
            color:#6e7072;

            &:hover {
                color:#73c13c; //color brand!!!

                .ico-x {
                    color:$color-error;
                }
            }
        }

        [class*='svg-'] {
            @include x-svg-size(18);
        }

        .ico-pencil {
            stroke-width:10;
        }
    }


    .cell-action-icons [data-icon] {
        vertical-align:middle;
    }
    .cell-action-icons [data-icon] {
        color:#6e7072;
        font-size:20px;
        cursor:pointer;
    }
    .cell-action-icons [data-icon]:hover {
        color:#73c13c; //color brand!!!
    }
    .cell-action-icons [data-icon='H'] {
        font-size:14px;

        &:hover {
            color:$color-error;
        }
    }


    .cell-action .btn-play {
        text-shadow:0 -1px 0 rgba(0,0,0,.21);
        padding:0;
        font-size:13px;
        padding:0;
        width:26px;
        height:26px;
        line-height:26px;
        margin-top:-1px;

        & :before {
            left:1px;
        }

        &, &:hover {
            color:#fff;
        }
    }


/* komorka tabeli zawierajaca checkboxa */
    .cell-icon {
        width:48px;
        @media (max-width: 768px) {
            width: 30px;
        }
    }
    .cell-icon .hide-check + label {
        display:inline;
        padding-left:18px;
    }


/* komorka tabeli zawierajaca informacje o pliku np. typ/waga */
    .file-info {
        text-align:center;
    }
    tbody .file-info {
        font-size:16px;
    }
    tbody .file-title .truncate {
        max-width: 335px;
    }

/* zaznaczenie calego wiersza w tabeli */
    table .active td {
        background:#f7f7f7;
    }

/* podswietlanie wiersza */
    .highlight-row [data-selector="contact-row"]:hover td {
        background:#fafafa;
    }

/* operations, links table(tfoot) */
    .table .operations {
        float:right;
    }
    