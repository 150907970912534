.panel {
    
    &-pricing {
        
        .list-group {
            padding-left: 0;
        }
        
        .list-group-item {
            font-family: $font-roboto-regular;
            font-size: 13px;
            padding: 0px;
            border: 1px solid #fafafa;
            border-width: 1px 0;
            color: #606365;
            display: table;
            width: 100%;
            height: 35px;
            text-align: center;
            margin-bottom: -1px;
            
            &:last-child {
                border-bottom: 0;
            }
            
            .cell {
                display: table-cell;
                position: relative;
                padding: 2px 15px;
                vertical-align: middle;
                line-height: 1.1;
                &:hover {
                    text-decoration: none;
                    svg {
                        fill: #1db0e9;
                    }
                }
            }
            
            a {
                color: #5b6583;
                svg  {
                    fill: #cdd2dd;
                    margin-left: 5px;
                    transition: fill .3s ease-in-out;
                }
            }
            .tip {
                max-width: 300px;
            }
            .tip-info {
                min-width: 300px;
                @media (max-width: 992px) {
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 100%;
                }
            }
            .tip-wrap {
                background-color: #1f232f;
                font-size: 11px;
                color: #fff;
                line-height: 18px;
                padding: 14px 18px;
                border-radius: 5px;
            }
            .tip.pos-tl .tip-wrap:after {
                margin-top: 16px;
                @media (max-width: 992px) {
                    margin-top: -12px;
                    right: 50%;
                    border-bottom-color: rgba(38, 39, 41, 0.9);
                    border-right-color: transparent;
                }
            }
        }

        .best-deal {
            border: 2px solid #6ebe3b;
            position: relative;
            &__title {
                color: #fff;
                text-transform: uppercase;
                text-align: center;
                font-size: 12px;
                background-color: #6ebe3b;
                display: block;
                position: absolute;
                top: -30px;
                left: -2px;
                height: 28px;
                line-height: 28px;
                width: calc(100% + 4px);
            }
        }

        .tooltip {
            min-width: 250px;
            
            .tooltip-inner {
                min-width: 250px;
                text-align: left;
                padding: 15px;
                -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
                        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
            }
            
        }
        
        .panel {

            &-heading {
                //background-color:  #fff;
                border-bottom: 1px solid #f1f3f7;
                border-radius: 0;
                padding: 10px 15px;
                color: #333333;
                display: table;
                height: 165px;
                width: 100%;

                .cell {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    
                    &.upgrade-form {
                        
                        p {
                            margin: 0 15px 10px;
                        }
                        
                    }
                    
                }

                h3 {
                    font-family: $font-roboto-regular;
                    font-size: 21px;
                    color: #292d38;
                    margin: 10px 0px 20px;
                }

                p {
                    font-size: 12px;
                    color: #6b7183;
                    margin: 0 0 10px;
                    
                    b {
                        font-weight: bold;
                    }
                    
                }
                
                &-badge {
                    background: url('../../images/promo/flashSale2018/sale.png') no-repeat center center;
                    background-size: 45%;
                    position: absolute;
                    top: 20px;
                    left: 0;
                    right: 0;
                    height: 32px;
                    
                    &-break {
                        padding-top: 35px;
                    }
                    
                }

            }

            &-body {
                display: table;
                width: 100%;
                padding: 10px 15px;

                .cell {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    height: 300px;
                    height: 100px;
                    
                    &.upgrade-form {
                        height: 100px;
                    }
                    
                }
                
                .info-box {
                    font-family: $font-roboto-regular;
                    font-size: 12px;
                    color: #929292;
                    min-height: 20px;
                    display: table;
                    width: 100%;
                    
                    p {
                        display: table-cell;
                        vertical-align: middle;
                    }
                }

                .value-box {
                    position: relative;
                    margin-top: 10px;
                    margin-bottom: 5px;

                    .attendees-text {
                        display: inline-block;
                        color: #6b7183;
                        &:first-child {
                            margin-right: 3px;
                        }
                        &:last-child {
                            margin-left: 3px;
                        }
                    }

                    p.range {
                        margin: 0px;
                        font-size: 25px;
                        line-height: 65px;
                        color: #6ebe3b;

                        span.value {
                            display: inline-block;
                            position: relative;
                            padding-left: 28px;

                            svg {
                                position: absolute;
                                top: 50%;
                                left: 2px;
                                max-width: 24px;
                                height: 20px;
                                margin-top: -10px;
                            }

                        }
                        a {
                            color: #6ebe3b;
                            
                            &:hover {
                                text-decoration: none;
                            }
                            
                        }

                    }

                    .btn {
                        
                        &-pricing {
                            background: #fff;
                            border: 1px solid #6ebe3b;
                            font-family: 'robotolight';
                            font-size: 25px;
                            color: #6ebe3b;
                            margin: 10px auto;
                            padding: 6px 30px 6px 35px;
                            width: 115px;
                            box-shadow: none;
                            transition: all .3s ease-in-out;
                            
                            &.btn-active {
                                background: #6ebe3b;
                                color: #fff;
                            }
                            
                            &.dropdown-toggle::after {
                                content: none;
                            }
                            
                        }
                        
                        &-group {
                            position: relative;

                            &.open {

                                .dropdown-menu {
                                    display: block;
                                }

                            }

                            .dropdown-menu {
                                background: #fff;
                                position: absolute;
                                display: none;
                                top: 100%;
                                min-width: 100%;
                                margin-top: 0;
                                padding: 4px;
                                border-radius: 0 0 3px 3px;
                                box-shadow: 0 3px 18px rgba(103, 130, 191, 0.26);
                                z-index: 1;

                                li > span {
                                    display: block;
                                    font-family: $font-roboto-light;
                                    font-size: 25px;
                                    color: #6ebe3b;
                                    cursor: pointer;
                                    text-align: center;

                                    &:hover {
                                        background-color: #f4f6fb;
                                    }

                                }

                            }

                        }

                        svg {
                            position: absolute;
                            top: 50%;
                            left: 10px;
                            width: 24px;
                            height: 20px;
                            margin-top: -10px;
                            stroke-width: 2;
                        }

                        .caret {
                            position: absolute;
                            top: 50%;
                            margin-top: -1px;
                            right: 15px;
                            width: 0;
                            height: 0;
                            vertical-align: middle;
                            border-top: 4px solid;
                            border-right: 4px solid transparent;
                            border-left: 4px solid transparent;
                        }
                        
                    }
                    
                }

                .price-box {
                    position: relative;
                    padding: 5px 0px;
                    margin-top: 5px;

                    .offer-package-price {
                        font-size: 40px;
                        font-family: 'robotolight';
                        color: #292d38;

                        .price-currency {
                            margin: 0 5px;
                            vertical-align: 7px;
                            font-family: 'robotoregular';
                            font-size: 20px;
                        }
                    }

                    p {
                        margin-bottom: 15px;
                        color: #677183;

                        &.old-price {
                            margin-bottom: 5px;

                            b {
                                color: #505b7c;
                                position: relative;
                                padding: 0px 3px;

                                &:after {
                                    border-top: 1px solid #ff4200;
                                    content: "";
                                    height: 0;
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    width: 100%;
                                }

                            }

                        }

                    }

                }

                .btn-box {
                    margin-top: 10px;
                    padding: 5px 0px;

                    .btn {
                        padding: 12px 30px
                    }

                }

            }

            &-footer {
                background-color: #fff;
                border-color: #f1f3f7;
                border-radius: 0;
            }

        }
        
    }
    
}

