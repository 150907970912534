.whats-new {
    
    .box {
        position: relative;
        border-bottom: 1px solid #dbdfe7;
        margin: 40px 0 60px;
        transition: all .3s ease-in-out;
        z-index: 1;
        
        &:before {
            border-radius: 2px;
            content: '';
            position: absolute;
            top: -25px;
            left: -25px;
            right: -25px;
            bottom: -25px;
            transition: all .3s ease-in-out;
            z-index: -1;
        }
        
        &.active,
        &:hover {
            cursor: pointer;
            
            &:before {
                background: #fff;
                top: -25px;
                left: -25px;
                right: -25px;
                bottom: -25px;
                box-shadow: 0px 20px 49.2px 10.8px rgba(167, 175, 198, 0.18);
                z-index: -1;
            }
                        
        }
        
        .label {
            font-family: $font-roboto-medium;
            margin-left: 0;
            font-size: 12px;
            
            &-date {
                color: #a7afc6 !important;
            }
            
        }
        
        &-header {
            padding: 0;
            
            h3 {
                position: relative;
                padding-right: 20px;
                margin: 5px 0;
                color: #292d38;
                
                svg {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    top: 50%;
                    margin-top: -10px;
                    right: 0;
                    color: #a7afc6;
                    stroke-width: 2px;
                    transition: all .3s ease-in-out;
                }
                
                &:hover {
                    
                    svg {
                        color: #6ebe3b;
                    }
                    
                }
                
                &.open {
                    
                    svg {
                       transform: rotate(180deg); 
                    }
                    
                }
                
            }
            
        }
        
        &-body {
            padding: 15px 0 0;
            overflow: hidden;
            
            p {
                margin: 0 0 20px;
                font-family: $font-roboto-light;
                color: #5c6273;
            }
            
            .pull-left {
                margin-right: 25px;
                margin-bottom: 20px;
            }
            
            .pull-right {
                margin-left: 25px;
                margin-bottom: 20px;
            }
            
            .btn {
                
                &-options {
                    margin-top: 40px;
                    
                    .mobile-apple-ico {
                        width: 135px;
                        height: 40px;
                        color: #000;
                    }
                    
                    .spacer {
                        border: 0;
                        margin: 0 20px;
                        vertical-align: middle;
                        
                        &:after {
                            content: '|';
                            display: inline-block;
                            font-size: 16px;
                            color: #dbdee7;
                            vertical-align: middle;
                        }
                        
                    }
                    
                }
                
                &-link {
                    font-family: $font-roboto-regular;
                    padding-left: 0;
                    padding-right: 0;
                }
                
            }
            
        }
        
            

            
        
        &-more {
            display: none;
        }
        
    }
    
    &.page-header {
        
        .page-wrap {
            width: 709px;
        }
        
        h1 {
            font-size: 24px;
            padding: 0 0 0 41px;
        }
        
        p {
            margin-bottom: 0;
            font-family: $font-roboto-light;
            color: #a7afc6;
        }
        
        .page-header-ico {
            width: 25px;
            height: 25px;
            margin: 0 0 0 -36px;
        }
        
    }
    
}