.navbar {
    transition: top .3s ease-in-out;
    
    &-brand {
        text-indent: -999em;
    }
    
    &-header {
        background: #f1f1f1 url($src-image + 'extras/pattern.jpg') repeat 0 0;
        text-align: center;
        padding-top: 0;
        transition: top .3s ease-in-out;

        &.navbar-fixed-top {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            z-index: 1030;
        }

        &.nav-hide {
            top: -60px;
        }
        
    }
    &-collapse.show {
        max-height: 70vh;
        overflow-y: scroll;
    }
    &-dark .navbar-toggler {
        border: none;
        color: #fff;
        
        svg {
            width: 30px;
            height: 30px;
        }

        &:hover,
        &:focus {
            background: none;
            outline: none;
            color: #6ebe3b;
        }

    }
    
    &-dark .navbar-nav .nav-link {
        color: #fff;
    }

    &-dark .navbar-nav .nav-item {
        position: relative;

        @media (min-width: 992px) {
            display: flex;
            align-items: center;
            min-height: 44px;
            flex: 0 0 auto;
        }
    }

    &-dark .navbar-nav .nav-wrap {
        position: relative;
        display: inline-block;
    }
    
    &-dark .navbar-nav .btn-account {
        background: #0f1219;
        margin: 4px 0.5rem;
        box-shadow: 0 1px 0 #383a43;
        white-space: nowrap;
        
        &:hover,
        &:focus {
            background: #171a23;
        }

        &.active {
            background: #6ebe3b;
            &:hover,
            &:focus {
                background: #65a73c;
            }
        }
        
        &.dropdown-toggle::after {
            margin-left: 8px;
            line-height: 0;
            vertical-align: middle;
        }
        
        .avatar-wrap {
            vertical-align: middle;
            margin-right: 5px;
            
            .avatar {
                
                &.x-small {
                    background:none;
                    border-radius: 50%;

                    [data-icon='P'] {
                        font-size:16px;
                        bottom: -3px;
                    }
                    
                }
                
            }
            
        }
        
        .create-event,
        .username {
            display:inline-block;
            vertical-align:middle;
            line-height:20px;
        }
        .username {
            max-width: 110px;
        }        
    }

    &-dark .navbar-nav .nav-link.nav-link-lang {
        text-transform: uppercase;
        white-space: nowrap;
        line-height: 14px;
        svg {
            stroke-width: 1;
            color: #6c7182;
        }
    }

    &-dark .navbar-nav .nav-link.nav-link-sub {
        line-height: 16px;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem; 
        
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 0px 5px 5px;
            border-bottom-color: #fff;
            bottom: 0;
            left: 50%;
            margin-left: -5px;
            display: none;
        }
        
    }
    &-dark .navbar-nav .nav-link.nav-link-sub,
    &-dark .navbar-nav .nav-link.nav-link-lang,
    &-dark .navbar-nav .btn-account {
        font-size: 12px;
    }
    @media (min-width: 992px) {

        &-dark .navbar-nav {
            align-items: center;
        }

        &-dark .navbar-nav .nav-link.nav-link-sub {
            
            &:after {
                bottom: -8px;
            }
        }
        
    }
    
    &-dark .navbar-nav .nav-link.nav-link-sub.active,
    &-dark .navbar-nav .show > .nav-link.nav-link-sub {
        
        &:after {
            display: block;
        }
        
    }
    
    &-dark .navbar-nav .nav-link.active,
    &-dark .navbar-nav .nav-link:hover, 
    &-dark .navbar-nav .nav-link:focus {
        color: #6ebe3b;
    }
    
    &-dark .navbar-nav .nav-link {
        
        &-active-free,
        &-active-free:hover,
        &-active-free:focus {
            color: #ff8d0e;
        }
        
    }
    
    &-nav .nav-item {
        
        .badge {
            font-size: 11px;
            color: #fff;

            &:hover {
                color: #fff;
            }

            &-recording {
                background: none;
                position: absolute;
                right: 0px;
                top: 4px;
                min-height: 12px;
                min-width: 12px;
                line-height: 11px;
                font-size: 9px;

                svg {
                   position: absolute;
                   width: 12px;
                   height: 12px;
                   top: 50%;
                   margin-top: -6px;
                   left: 50%;
                   margin-left: -6px;
                   color: #ff4200;
                }
                
            }

            &-messages {
                position: absolute;
                top: 0px;
                left: -1px;
                z-index: 1;

                @media (max-width: 991px) {
                    top: 0;
                    left: auto;
                }

            }
            
        }
        
        .dropdown-menu.dropdown-menu-sub {
            
            .badge-recording {
                position: relative;
                right: auto;
                top: auto;
                margin-left: 5px;
            }
            
        }
        
    }
    
    &-nav .dropdown-menu.dropdown-menu-sub {
        background: #fcfcfc;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0;
        font-size: 14px;
        
        .dropdown-item {
            position: relative;
            padding: 5px 30px;
            text-transform: uppercase;
            font-size: 12px;
            color: #606365;
            
            &:hover,
            &:focus {
                background-color: #f5f5f5;
            }
            
        }
        
    }
    
    &-nav .dropdown-menu.dropdown-menu-create-event,
    &-nav .dropdown-menu.dropdown-menu-lang,
    &-nav .dropdown-menu.dropdown-menu-account {
        padding: 5px 0;
        background: #fcfcfc;
        border-radius: 2px;
        border: 1px solid #e5e5e5;
        
        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 0px 7px 7px;
            border-bottom-color: #e5e5e5;
            top: -7px;
            right: 15px;
        }
        
        &:after {
            top: -6px;
            border-bottom-color: #fff;
        }
        
        .dropdown-header {
            padding: 12px 18px;
            
            .dropdown-menu-user {
                padding: 0;
                
                .avatar {
                    border-width: 2px;
                    width: 50px;
                    height: 50px;
                    
                    [data-icon='P'] {
                        font-size: 45px;
                        bottom: -8px;
                    }
                    
                }
                
                .account-balance-info {
                    color: #363a3d;
                    font-size: 16px;
                    display: block;
                    margin-top: 8px;
                }
                
            }
            
            .name {
                font-family: $font-roboto-light;
                font-size: 28px;
                line-height: 23px;
                letter-spacing: -1px;
                max-width: 212px;
                color: #6ebe3b;
            }
            
        }

        .dropdown-item {
            position: relative;
            padding: 5px 30px;
            text-transform: uppercase;
            font-size: 12px;
            color: #606365;

            &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-color: transparent;
                border-style: solid;
                border-width: 3px 0px 3px 3px;
                border-left-color: #6ebe3b;
                top: 50%;
                margin-top: -3px;
                left: 15px;
            }

            &:hover,
            &:focus {
                background-color: #f5f5f5;
            }
            
            &.has-own-ico {
                
                &:before {
                    content: none;
                }
                
                svg {
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    top: 9px;
                    left: 11px;
                    stroke-width: 12;
                }
                
            }

        }

        .dropdown-divider {
            border-color: #e4e5e5;
            margin: 5px 0;
        }

    }

    &-nav .dropdown-menu.dropdown-menu-lang {

        &:before,
        &:after {
            right: 10px;
        }

        .dropdown-item {
            padding: 5px 34px;
            font-size: 14px;

            svg {
                position: absolute;
                width: 10px;
                height: 10px;
                color: #1b1f2a;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
            }

            &:before {
                content: none;
            }

        }

    }

    &-nav .dropdown-menu.dropdown-menu-create-event {
        padding: 0;
        background: #fff;
    }
    
    @media (min-width: 992px) {
    
        &-nav .dropdown-menu.dropdown-menu-sub {
            background: rgba(38,39,41,.95);
            left: 50%;
            transform: translateX(-50%);
            border: none;
            margin-top: 11px;
            box-shadow: 0 1px 0 rgba(0,0,0,.45);

            .dropdown-item {
                color: #fff;
                padding: 8px 14px;
                text-transform: none;
                font-size: 14px;

                &:hover,
                &:focus,
                &:active {
                    background-color: #6ebe3b;
                }
                
                &:first-child {
                    border-top-right-radius: 2px;
                    border-top-left-radius: 2px;
                }
                
                &:last-child {
                    border-bottom-right-radius: 2px;
                    border-bottom-left-radius: 2px;
                }

            }

        }

        &-nav .dropdown-menu.dropdown-menu-lang,
        &-nav .dropdown-menu.dropdown-menu-create-event,
        &-nav .dropdown-menu.dropdown-menu-account {
            border-radius: 3px;
            margin-top: 7px;
            min-width: 315px;
        }

        &-nav .dropdown-menu.dropdown-menu-lang {
            min-width: auto;
        }

        &-nav .dropdown-menu.dropdown-menu-create-event {
            min-width: 640px;
            width: auto;
            padding: 15px 0;
        }
        
    }
    
    @media (max-width: 991px) {

        &-nav .dropdown-menu.dropdown-menu-lang,
        &-nav .dropdown-menu.dropdown-menu-create-event,
        &-nav .dropdown-menu.dropdown-menu-account {
            position: relative;
            margin: 10px auto 0;
            width: auto;
            max-width: 350px;
            
            &:before,
            &:after {
                right:50%;
                margin-right: -7px;
            }
            
        }

        &-nav .dropdown-menu.dropdown-menu-create-event {
            max-width: 100%;
            padding: 10px 0;
        }

        &-nav .dropdown-menu.dropdown-menu-lang {
            text-align: center;

            .dropdown-item {
                border-radius: 25px;
                display: inline-block;
                width: 90px;
                margin: 2px 10%;
                font-size: 18px;
                &.active-lang {
                    background-color: #f5f5f5;
                }
            }

        }

    }
    @media (max-width: 1200px) {

        &-dark .navbar-nav .nav-link.nav-link-sub,
        &-dark .navbar-nav .nav-link.nav-link-lang,
        &-dark .navbar-nav .btn-account {
            font-size: 10px;
        }

        &-dark .navbar-nav .nav-link.nav-link-sub {
            line-height: 14px;
        }

        &-dark .navbar-nav .nav-link.nav-link-sub,
        &-dark .navbar-nav .nav-link.nav-link-lang {
            padding-right: 0.375rem;
            padding-left: 0.375rem;
        }

        &-dark .navbar-nav .btn-account {
            margin-right: 0.375rem;
            margin-left: 0.375rem;
        }

    }
    @media (max-width: 991px) {
        &-dark .navbar-nav .nav-link.nav-link-sub,
        &-dark .navbar-nav .nav-link.nav-link-lang,
        &-dark .navbar-nav .btn-account {
            font-size: 18px;
        }
        &-dark .navbar-nav .btn-account {
            margin-top: .5rem;
            margin-bottom: .5rem;
        }
        &-dark .navbar-nav .nav-link.nav-link-sub {
            line-height: 1.6;
            padding-top: .5rem;
            padding-bottom: .5rem;
            &.active:after {
                content: none;
            }
        }
    }
}

[lang='pl'] .navbar-dark .navbar-nav .nav-link.nav-link-sub,
[lang='pl'] .navbar-dark .navbar-nav .nav-link.nav-link-lang,
[lang='pl'] .navbar-dark .navbar-nav .btn-account {
    font-size: 14px;
    flex: 0 1 auto;
    @media (max-width: 991px) {
        font-size: 18px;
    }
}

[lang='en'] .navbar-dark .navbar-nav .nav-link.nav-link-sub,
[lang='en'] .navbar-dark .navbar-nav .nav-link.nav-link-lang,
[lang='en'] .navbar-dark .navbar-nav .btn-account {
    font-size: 14px;
    flex: 0 1 auto;
    @media (max-width: 991px) {
        font-size: 18px;
    }
}