.main--reset {
    padding: 0;
    height: 100%;
}
.tip-info--large {
    min-width: 300px;
}
.h-100 {
    height: 100%;
    box-sizing: border-box;
}
.verify-section {
    background-image: url($src-image + "verify/verify_account.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    margin: 0 auto !important;
    @media all and (max-width: 767px) {
        background-image: none;
    }
    &__content {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 550px;
        @media all and (max-width: 767px) {
            padding-top: 50px;
            top: initial;
            left: initial;
            transform: translate(0, 0);
        }
    }
    &__logo {
        width: 160px;
        height: 30px;
        margin-bottom: 40px;
    }
    &__title {
        font: {
            family: $font-roboto-light;
            size: 42px;
        }
        color: #292d38;
        margin: 0 0 50px;
    }
    &__desc {
        font: {
            family: $font-roboto-light;
            size: 18px;
        }
        line-height: 26px;
        color: #5c6273;
        margin-bottom: 35px;
        strong {
            color: #292d38;
            word-wrap: break-word;
        }
    }
    &__again {
        position: absolute;
        bottom: -165px;
        div {
            margin-bottom: 0;
            margin-top: 0;
            font: {
                family: $font-roboto-light;
                size: 16px;
            }
            line-height: 24px;
            color: #a7afc6;
        }
    }
    &__action {
        color: #00aeef !important;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
    }
    &__resend {
        position: absolute;
        bottom: -55px;
        display: none;
        img {
            vertical-align: bottom;
            width: 25px;
        }
        p {
            display: inline-block;
            vertical-align: middle;
            margin: 0 15px;
            font: {
                family: $font-roboto-medium;
                size: 14px;
            }
            line-height: 14px;
            text-transform: uppercase;
            color: #6ebe3b;
        }
        a {
            display: inline-block;
            vertical-align: middle;
            width: 50px;
            text: {
                align: center;
                transform: uppercase;
            }
            border: 1px solid #f2f3f7;
            border-radius: 50px;
            font: {
                family: $font-roboto-medium;
                size: 14px;
            }
        }
    }
    &__field {
        border-radius: 5px !important;
        display: inline-block !important;
        height: 25px !important;
        width: 210px !important;
        background-color: #ffffff;
        padding-left: 10px;
        border: 2px solid transparent;
        &::placeholder {
            font: {
                family: $font-roboto-regular;
                font-style: italic;
                size: 12px;
            }
            color: #a7afc6;
        }
    }
    &__submit {
        display: inline-block;
        position: relative;
        width: 50px;
        margin-left: 10px;
        background-color: transparent;
        border: 1px solid #565c69;
        border-radius: 50px;
        color: #00afec;
        cursor: pointer;
        text: {
            align: center;
            transform: uppercase;
        }
        font: {
            family: $font-roboto-medium;
            size: 14px;
        }
    }
}
