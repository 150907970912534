.component-absolute {
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
}
.components {
    background:#f1f1f1  url($src-image + 'bg_pattern_components.jpg') repeat 0 0;
}
.components-title {
    cursor:pointer;
    font-weight:bold;
    display:block;
    padding:5px 15px;
    position:relative;
    /*background:#6ebe3b;
    background:-webkit-linear-gradient(top,  #79cb45 0%,#6ebe3b 100%);
    background:linear-gradient(to bottom,  #79cb45 0%,#6ebe3b 100%);*/
    /*box-shadow:inset 0 -2px 0 rgba(0,0,0,.16), 0 2px 0 rgba(0,0,0,.11);*/
}
.components-title,
.components-title:hover {
    color:#fff;
}
.components-title [data-icon*='caret'] {
    position:absolute;
    top:50%;
    margin-top:-2px;
    right:12px;
}
.components .gallery .figure {
    width:176px;
    height:112px;
}
.components-body {
    padding:15px 0;
}
.components .f-holder {
    margin:0;
}
.component-wrap {
    color:#bcbcbc;
    min-height:32px;
}
.component-wrap .dsp-cell {
    font-weight:bold;
}
.component {
    cursor:move;
}
.hide-check + .component,
.component {
    line-height:1.2;
    display:block;
    padding:8px 10px;
    background:#fff;
    border:1px dashed #c2c2c2;
    border-radius:2px;
    box-sizing:border-box;
}
.list-component-images.list-tiles .unit-bd {
    padding:11px 13px;
    min-height:100px;
}
.list-component-images.list-tiles .unit {
    height:auto;
    background:#fff;
    background:-webkit-linear-gradient(top,  #ffffff 0%,#ffffff 80%,#fefefe 81%,#f7f7f7 100%);
    background:linear-gradient(to bottom,  #ffffff 0%,#ffffff 80%,#fefefe 81%,#f7f7f7 100%);
    box-shadow:inset 0 -1px 1px #fff, 0 0 0 1px rgba(218,218,218,.4);
}
.list-component-images.list-tiles .hide-check:checked + .unit .unit-bd {
    background:#f9f9f9;
    box-shadow:inset 1px 1px 3px rgba(0,0,0,.3);
    border-radius:2px;
}


.component .special-line,
.component .special-line:after {
    content:'';
    height:3px;
    display:block;
    background:#bcbcbc;
}
.component .special-line {
    margin:5px 0 10px;
    width:80px;
}
.component .special-line:after {
    position:relative;
    top:6px;
    width:80%;
}
    /* ie break word fix */
.component .dsp-table {
    table-layout:fixed;
}
.component .ico-wrap-end {
    width:10px;
    padding-left:5px;
}
    /* //end of ie break word fix */
.component-wrap .ico-large svg {
    @include x-svg-size(28);
    stroke-width:5;
    top:0;
}
.component-wrap .ico-wrap {
    line-height:1;
    text-align:center;
    width:35px;
    padding-right:5px;
}
.component-wrap .avatar-wrap {
    display:block;
}
.component-wrap .avatar {
    background:#bcbcbc;
    width:30px;
    height:30px;
    border-radius:50%;
}
.component-wrap .avatar [data-icon='P'] {
    font-size:34px;
    bottom:-8px;
}
.component-presenter,
.component-presenter:hover .component {
    color:#bcbcbc;
}
.component-presenter .dropdown {
    display:block;
}
.component-presenter > .dropdown:hover > .component {
    border-style:solid solid dashed;
}

.component-presenter .dropdown-component {
    width:100%;
    position:absolute;
    top:100%;
    z-index:1000;
}
.component-presenter .dropdown-component .component {
    margin:-2px 0 0;
    border-style:dashed solid;
}
.dropdown-component .dropdown-wrap {
    background:#fff;
    border:0;
    border-radius:0;
}
.component-presenter .dropdown-component .component:last-child {
    border-bottom-style:solid;
}
.component-presenter .name {
    display:block;
    -ms-word-break:break-all;
    word-break:break-word;
    word-break:keep-all;
    word-wrap:break-word;
    -webkit-hyphens:auto;
       -moz-hyphens:auto;
        -ms-hyphens:auto;
            hyphens:auto;
}
.component-presenter .component {
    padding-top:7px;
    padding-bottom:7px;
}
.component-presenter .dropdown-layout ul.list-unstyled {
    overflow: scroll;
    height: 450px;
}

.component-check .component,
.component-radio .component {
    width:30px;
    height:30px;
    padding:0;
    display:block;
}
.component-check .ico-wrap .ico-tick {
    top:4px;
    stroke-width:14;
}
.component-radio .component {
    border-radius:15px;
}
.component-radio .component .ico {
    width:12px;
    height:12px;
    background-color:$color-current;
    border-radius:6px;
    display:block;
    margin:9px 0 0 8px;
}
/*.component-wrap:hover,
.component-presenter .component:hover {
    color:#6ebe3b;
}
.component-wrap:hover .component {
    border-color:#6ebe3b;
}
.component:hover .avatar,
.component:hover .special-line,
.component:hover .special-line:after,
.component-radio:hover .component .ico {
    background:#6ebe3b;
}*/
.component-template .component {
    min-height:100px;
    padding:10px;
}
.hide-check:checked + .component {
    border:1px solid transparent;
    /*border:1px solid #6ebe3b;*/
    border-radius:2px;
    /*box-shadow:0 0 0 1px #6ebe3b;*/
}
.component-title {
    color:#363a3d;
    font-size:18px;
    font-weight:normal;
    display:block;
    margin:15px auto 0;
}
.move-section .component-wrap .component {
    border-color:#c2c2c2;
}


.list-component-images,
.list-component-images > li,
.list-component,
.list-component > li {
    @include x-list-init;
}
.list-component li {
    position:relative;
}
.list-component .list-component-item {
    display:block;
    line-height:1.2;
    padding:6px 15px;
}
.list-component a.list-component-item:hover,
.list-component-item.active {
    background:rgba(205,205,205,.2);
}
.list-component-title {
    color:#555;
    font-size:16px;
    display:block;
}
.list-component-title a {
    color:inherit;
}
.list-component-date {
    /*color:#6ebe3b;*/
    font-size:12px;
}
.list-component .btn-link-delete {
    position:absolute;
    top:6px;
    right:5px;
    z-index:2;
    transition:all .3s ease;
}
.list-component .btn-link-delete:hover {
    transform:scale(1.5);
}

.list-component-images,
.list-component-images > li {
    @include x-list-init;
}

.list-component-images > li {
    margin:15px;
}
.list-component-images > li:first-child {
    margin-top:0;
}
.list-component-images > li:last-child {
    margin-bottom:0;
}
.components-body .nano {
    /*height:518px;*/
    height:485px;
    width:204px;
    padding:0;
}
.components-body .nano > .nano-pane {
    background:none;
}
.components-body .nano > .nano-content {
    padding:0;
}
