.settings-page {
    padding-bottom:30px;
    .navbar-header {
    	margin-bottom: 30px;
    }
}
.settings-page .nav-tabs-icons .page-wrap {
    width:970px;
}
.account-settings-section .page-wrap {
    width:618px;
}
.account-settings-section .helper.pos-br2 {
    margin-right:55px
}
.account-settings-section .helper.pos-tl2 {
    margin-top:-15px;
}
.account-settings-section .list-editable .helper.pos-tl2 {
    left:100%;
    top:100%;
    width:180px;
}