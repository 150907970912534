.success {
    color:$color-success;
}
.warning {
    color:$color-warning;
}
.error {
    color:$color-error;
}
dfn .price {
    color:#fff;
    font-weight:bold;
    padding:0 3px;
    /*background:#6ebe3b;*/
}
/* [ wyroznienia tekstu ] */
/*.highlight {
    color:#6ebe3b !important;
}*/


/* [ text modification ] */
.txt-featured {
    font-size:23px !important;
    line-height:27px;
}
.font-12 {
    font-size:12px;
}
.font-18 {
    font-size:18px;
}
.font-20 {
    font-size:20px;
}
.h-legend {
    /*color:#6ebe3b;*/
    font-size:16px;
    letter-spacing:0;
}


/* [ lead ] */
.lead {
    color:#606365;
    font-size:16px;
}
.lead strong {
    color:#555;
    font-weight:normal;
}
h2 + .lead {
    margin-top:-5px;
}


/* [ header separate ] */
.separate-title {
    position:relative;
    margin:30px auto;
}
.separate-title:before {
    content:'';
    display:block;
    width:100%;
    height:2px;
    background:#e0e0e0;
    position:absolute;
    top:50%;
    z-index:1;
}
.separate-title-in {
    color:#606365;
    font-size:18px;
    display:inline-block;
    padding:0 10px 0 0;
    position:relative;
    z-index:2;
    background:#f9f9f9;
}
.separate-title.tC .separate-title-in {
    padding:0 10px;
}
.separate-title.tR .separate-title-in {
    padding:0 0 0 10px;
}
.unit .separate-title-in {
    background:#fff;
}


/* [ caption ] */
.caption,
.caption-small {
    color:#c2c2c2;
}
.caption {
    margin:8px 0 0;
}
.caption-small {
    font-size:12px;
    margin:5px 0 0;

    svg {
        @include x-svg-size(10);
    }
}
.caption-small .ico-left {
    margin-right:5px;
}


/* [ icons ] */
@import '../../../../../shared/web/views/src/scss/__partials/icon';
.ico-search,
.ico-download,
//.ico-pencil,
.ico-x,
.ico-tick {
    stroke-width:10;
}


.ico-product-webinar,
.ico-product-meeting {
    // stroke:#6ebe3b;
    @include x-svg-size(110);
    display:block;
}
.ico-product-meeting-arrows,
.ico-product-webinar-arrows {
    stroke:#c2c2c2;
    stroke-linecap:round;
}
.ico-product-webinar *,
.ico-product-meeting * {
    fill:none;
    stroke-linejoin:round;
    stroke-miterlimit:10;
}
.ico-product-webinar * {
    stroke-width:2;
}
.ico-product-meeting * {
    stroke-width:1.5;
}
.ico-product-meeting .mike,
.ico-product-webinar .mike {
    stroke-width:1.6;
}
.ico-product-webinar-arrows * {
    stroke-width:1.6;
    stroke-linecap:round;
}
.ico-product-webinar .tight,
.ico-product-meeting .user {
    stroke-width:1.8;
}
.ico-product-meeting .tight {
    stroke-width:1.4;
}

.ico-add,
.ico-check {
    display:inline-block;
    color:#fff;
    cursor:pointer;
    width:21px;
    height:21px;
    border-radius:2px;
}
.close {
    color:#bcbdbf;
    font-size:6px;
    line-height:16px;
    font-weight:bold;
    cursor:pointer;
    display:none;
    width:22px;
    height:22px;
    background:#fff;
    border:4px solid #77c843;
    border-radius:15px;
}


/* [ status ] */
.status {
    .ico {
        width:11px;
        height:11px;
        border-radius:50%;
        box-shadow:inset 2px 0 0 rgba(0,0,0,.04);
        background-color:$color-current;
    }

    &-default {
        color:$color-muted;
    }

    &-success {
        color:$color-success;
    }

    &-warning {
        color:$color-warning;
    }

    &-error {
        color:$color-error;
    }
}


/* [ labels ] */
.label {
    display:inline;
    padding:.2em .6em;
    margin:0 .6em;
    font-size:75%;
    font-weight:normal;
    letter-spacing:0;
    color:#fff !important;
    text-align:center;
    text-transform:uppercase;
    white-space:nowrap;
    vertical-align:middle;
    border-radius:.25em;
    position: relative;

    &:empty {
        display:none;
    }
}
.label[href]:hover,
.label[href]:focus {
    color:#fff;
    text-decoration:none;
    cursor:pointer;
}
.label-default {
    background-color:#d8d8d8;
}
.label-default[href]:hover,
.label-default[href]:focus {
    background-color:#dbdcdc;
}
.label-primary {
    background-color:#232728;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
    background-color:#3c3d3f;
}
.label-success {
    background-color:$color-success;
}
.label-success[href]:hover,
.label-success[href]:focus {
    background-color:#73c13c;
}
.label-info {
    background-color:$color-info;
}
.label-info[href]:hover,
.label-info[href]:focus {
    background-color:#31b0d5;
}
.label-warning {
    background-color:#ffb321;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
    background-color:#ffbb38;
}
.label-automated {
    background-color:#0b23a0;
}
.label-automated[href]:hover,
.label-automated[href]:focus {
    background-color:#0b23a0;
}
.label-onDemand {
    background-color:#2e6fde;
}
.label-onDemand[href]:hover,
.label-onDemand[href]:focus {
    background-color:#2e6fde;
}
.label-liveWebinar {
    background-color:#6ebe3b;
}
.label-liveWebinar[href]:hover,
.label-liveWebinar[href]:focus {
    background-color:#6ebe3b;
}
.label-meeting {
    background-color:#323638;
}
.label-meeting[href]:hover,
.label-meeting[href]:focus {
    background-color:#323638;
}
.label-error {
  background-color:$color-error;
}
.label-error[href]:hover,
.label-error[href]:focus {
  background-color:#ff581e;
}
.label-live {
    text-shadow:0 -1px 0 rgba(0,0,0,.15);
    background-color:$color-error;
}
.label-live[href]:hover,
.label-live[href]:focus {
    background-color:#ff6526;
}
.label-recurring {
    background-color:#a7afc6;
}
.label-recurring[href]:hover,
.label-recurring[href]:focus {
    background-color:#a7afc6;
}
.label-encryption {
    background-color:#a7afc6;
}
.label-encryption[href]:hover,
.label-encryption[href]:focus {
    background-color:#a7afc6;
}
.label-result {
    color:#a8947b;
    font-size:14px;
    line-height:1.2;
    text-align:left;
    text-transform:none;
    display:inline-block;
    background-color:#ebe3d9;
    position:relative;
    padding:.2em 18px .2em 5px !important;
    margin:2px 0 2px 2px;

    .truncate {
        max-width:200px;
    }

    .small {
        font-size:11px;
        opacity:.7;
    }

    .vr-list .spacer {
        margin:0 5px;
        border-right-color:#a8947b;
    }
}
.label-result .ico-close {
    color:#cfc2b2;
    padding:3px;
    cursor:pointer;
    display:inline-block;
    position:absolute;
    right:0;
    top:50%;
    transform:translateY(-50%);
    outline:none;

    .ico-x {
        @include x-svg-size(8);
        display:block;
        top:0;
        stroke-width:16px;
    }
}
.label-result .ico-close {
    right:3px;
}
.label-result .ico-close:hover {
    color:#a8947b;
}


/* [ labels size ] */
.label.small {
    font-size:9px;
}


/* [ badge ] */
.badge {
    color:#fff;
    padding:0 2px 0;
    font-size:11px;
    font-weight:bold;
    letter-spacing:0;
    line-height:18px;
    text-align:center;
    vertical-align:baseline;
    white-space:nowrap;
    display:inline-block;
    min-height:19px;
    min-width:19px;
    border-radius:16px;
    background:#ff4200;
    background: -moz-linear-gradient(top,  #ff5107 0%, #ff420b 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ff5107), color-stop(100%,#ff420b));
    background: -webkit-linear-gradient(top,  #ff5107 0%,#ff420b 100%);
    background: -o-linear-gradient(top,  #ff5107 0%,#ff420b 100%);
    background: -ms-linear-gradient(top,  #ff5107 0%,#ff420b 100%);
    background: linear-gradient(to bottom,  #ff5107 0%,#ff420b 100%);
    box-sizing:border-box;
}
/*.badge:empty {
    display:none;
}*/


/* [ price view ] */
.pack-price {
    font-size:40px;
    letter-spacing:-1px;
    line-height:1;
    padding:4px 0;
}
.pack-time {
    text-align:left;
    font-size:21px;
    line-height:.8em;
    margin-left:3px;
}
.pack-entity {
    margin-right:3px;
    letter-spacing:-1px;
}
.pack-price > span {
    display:inline-block;
}
.pack-month {
    font-size:13px;
    display:block;
}
.link-copied {
    background-color: #272a36;
    color: #fff;
    text-transform: initial;
    font-size: 11px;
    line-height: 11px;
    padding: 3px 8px;
    border-radius: 5px;
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    svg {
      color: $color-success !important;
      margin-right: 6px !important;
      width: 12px !important;
      height: 12px !important;
    }
  }
