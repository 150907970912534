//* [ colors ] */
//* page colors */
//$color-base:#363a3d; - dedicated
$color-primary: #232728;
$color-success: #6ebe3b;
$color-warning: #f8a64f;
$color-error: #ff4200;
$color-info: #00afec;
$color-muted: #d8d8d8;
$color-current: currentColor;
$color-inherit: inherit;
$color-cm: $color-success;
$color-black: #000;
$color-darken: #1b1f2b;
$color-dark: #292d38;
$color-gray: #5c6273;
$color-gray-blue: #a7afc6;
$color-white: #fff;
$color-white-dirty: #ecedf2;

//$color-brand:???

//* links colors */
$color-link: #00afec;
$color-link-hover: #ff800f;

//* social colors */
$color-social-rss: #ff800f;
$color-social-hubspot: #f87620;
$color-social-twitter: #00afec;
$color-social-facebook: #395799;
$color-social-linkedin: #0177b5;
$color-social-google: #4688f1;
$color-social-googleplus: #d61e1f;
$color-social-youtube: #e22821;

$color-base: #363a3d;
