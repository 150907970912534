.messages-section tbody tr {/* klikamy na cala sekcje */
    cursor:pointer;
}
.messages-section .cell-action {
    width:80px;
}
.messages-section .thumb {
    width:85px;

    [data-icon] {
        font-size:40px;

        &:before {
            top:-3px;
        }
    }
}
.messages-section .desc {
    max-width:320px;
    @media (max-width: 767px) {
        max-width:220px;
    }
}
