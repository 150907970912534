.page-footer {
    color:#232728;
    clear:both;
    width:100%;
    background:#fff;
    .page-wrap {
        width:780px;
    }
    .footer {
        color:#232728;
        padding:38px 0;
        font-size:14px;
    }
    hr {
        margin: 25px 0;
        @media screen and (max-width: 767px) {
            margin: 10px 0;
        }
    }
    .vr-list {
        padding: 0 15px;
    }
    .links {
        a {
            text-decoration:underline;
            &:hover {
                text-decoration:none;
            }
        }
        .spacer {
            border:0;
            margin:0 8px 0 6px;
            height:auto;
            &:after {
                content:'\25aa';
                display:inline-block;
                font-size:10px;
                color:#d8d8d8;
                vertical-align:middle;
            }
        }
    }
    .copy-wrap {
        float: left;
    }
    .social {
        text-align: right;
        @media screen and (max-width: 1200px) {
            text-align: left;
            margin-top: 20px;
        }
        @media screen and (max-width: 767px) {
            margin-top: 10px;
        }
        &__title {
            font-size: 18px;
            font-family: $font-roboto-light;
            display: inline-block;
            vertical-align: middle;
            margin: 0 15px 0 0;
            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
        }
        &__list {
            display: inline-block;
        }
    }
    .logotype {
        display:inline-block;
        vertical-align:middle;
        text-indent:-999em;
        overflow:hidden;
        @media screen and (max-width: 767px) {
            height: auto;
            width: 108px;
        }
    }
    .language {
        text-align: right;
        @media screen and (max-width: 1200px) {
            text-align: left;
            margin-bottom: 20px;
        }
        @media screen and (max-width: 767px) {
            margin-bottom: 10px;
        }
        &__title,
        &__wrap {
            display: inline-block;
            vertical-align: middle;
        }
        &__title {
            margin: 0 18px 0 0;
        }
    }
    .copy {
        @media screen and (max-width: 767px) {
            font-size: 14px;
            display: block;
            margin-top: 5px;
        }
    }
    .ico-flag img {
        display:block;
    }
    [lang='ru'] .page-footer .social {
        text-align:right;
    }
    [lang='ru'] .page-footer .social dt {
        font-size:16px;
        display:block;
        margin:-12px 0 0;
    }
}
