.create-event-section,
.room-editor {
    .page-wrap {
        width:923px;
    }

    /* [ tab registration ]
    FF disabled input fix
    http://stackoverflow.com/questions/3100319/event-on-a-disabled-input */
    .component-relative {
        position:relative;
    }
    .create-event-section {
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
    }

    header > ul {
        margin: 0;

        li > div {
            text-align: right;
        }
    }

    .share-profile-page {
        margin: -20px 0;
        @media (max-width: 767px) {
            margin-bottom: 0;
        }
    }

    .header__share {
        input {
            font-size: 11px;
            background-color: #ffffff;
            color: #bebebe;
            border-radius: 5px
        }

        .form-control {
            display: inline-block;
            width: 200px;
        }

        .f-holder.small .input-group-addon{
            font-size: 11px;
            line-height: 150%;
            padding: 0;
        }

        span {
            margin-right: 7px;
            color: #bebebe;
        }

        .copy {
            font-family: 'robotomedium';
            user-select: none;
        }
    }

}

.profile-page-section,
.emails-section {
    .page-wrap {
        @media (max-width: 992px) {
            width: 709px;
        }
        @media (max-width: 767px) {
            width: 100%;
            padding: 0;
        }
        .content {
            @media (max-width: 767px) {
                padding: 0 15px;
            }
        }
    }
    .in {
        h4 {
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
}
.profile-page-section {
    .rebranding {
        @media (max-width: 576px) {
            width: 100%;
        }
    }
}

/* [ details ] */

.access-type .btn-choice .btn {
    min-width: 90px;
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 5px;
}
.access-type .btn-choice .tip {
    min-width:140px;
    text-align:left;
    opacity:0;
}
.access-type .btn-choice .btn:hover .tip {
    opacity:1;
    visibility:visible;
}
.access-type .btn-choice .btn .label {
    position: absolute;
    font-weight: bold;
    font-size: 10px;
    right: -12px;
    top: -8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.21);
}
.access-type .form-group,
.room-type .form-group {
    margin-top:0;
    margin-bottom:0;
}
.room-type .btn-choice .btn {
    min-width:248px;
}
.room-type .input-group [data-icon='o']:before {
    /*color:#6ebe3b;*/
}
.room-type .expand .f-holder {
    width:190px;
}
.room-type .expand [data-schedule-type='permanent'],
.access-type .expand [data-access='token'] {
    min-height:34px;
    margin:-5px 80px;
}
.room-name .form-inline {
    margin-right:35px;
}
.room-name .table-equal {
    width:100%;
}
.room-name .form-label {
    text-align:right;
}
.room-name .form-control {
    vertical-align:top;
}
.room-name .form-group-room-name {
    margin-top:0;
}
.room-name .form-group-room-name .form-control {
    width:545px;
}
.room-name .form-group-room-url {
    margin-bottom:0;
}
.room-name .form-group-room-url .form-label {
    padding-right:3px;
}
.room-name .form-group-room-url .form-control {
    width:223px;
}
.room-name .helper.pos-br3 {
    top:12px;
    min-width:140px;
}
.create-event-section .details .form-label.txt-featured {
    width:240px;
    text-align:right;
}
#schedule_event {
    .room-type .form-group {
        margin: 0 auto;
    }
    @media (max-width: 991px) {
        .room-name .form-inline {
            margin-right: 0;
        }
        .room-name .form-label {
            text-align: center;
        }
        .room-name .form-group-room-name .form-control {
            width: auto;
        }
        .room-name .form-group-room-url .form-label {
            padding: 0 0 5px;
        }
        .room-name .form-group-room-url .form-control {
            margin: auto;
        }
        .form-label.txt-featured {
            width: auto;
            padding: 0 0 15px;
            text-align: center;
        }
    }
    @media (max-width: 767px) {
        .btn-choice {
            display: inline-block;
        }
        .btn-choice > .btn {
            float: none;
        }
        .btn-choice > .start:not(.stop):not(.force-not-first) {
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }
        .btn-choice > .stop:not(.start):not(.force-not-last) {
            border-bottom-left-radius: inherit;
            border-top-left-radius: inherit;
        }
        .btn-choice > .btn:not(.start):not(.stop) {
            border-radius: inherit;
        }
        .access-type .btn-choice .btn {
            margin: 0 10px 5px;
            width: 40%;
        }
    }
    @media (max-width: 540px) {
        .access-type .btn-choice .btn {
            margin: 0 0 5px;
            width: auto;
            min-width: 248px;
        }
    }
}
/* toll free */
.toll-free-type .form-inline .form-group {
    margin-top:0;
    margin-bottom:0;
}
.toll-free-type .expand {
    color:#4e5153
}
.toll-free-type .expand .form-inline {
    margin-top:30px;
    margin-bottom:30px;
}
.toll-free-type .expand .f-short {
    width:70px;
}
.toll-free-type .expand hr {
    border:0;
    border-bottom:1px solid rgba(0,0,0,0.1);
    margin:26px auto;
    width:40%;
}


/* thankyou page - well done */
.welldone-section {
    .page-wrap {
        width: 750px;
        @media (max-width: 991px) {
            width: 100%;
            padding: 0;
        }
    }
    h3 {
        /*color:#6ebe3b;*/
        font-size:158px;
        font-weight:normal;
        text-align:center;
        text-shadow:0 -1px rgba(0,0,0,.14), 0 2px #fff;
        margin:-20px 0 31px;
        transform:rotate(-3deg);
        @media (max-width: 768px) {
            font-size: 78px !important;
        }
    }
    .btn-wrap .btn {
        margin:0 5px;
        min-width:220px;
        @media (max-width: 991px) {
            margin:0 5px 20px;
        }
    }
    .social {
        color:#606365;
        font-size:14px;
        margin:25px auto;
        @media (max-width: 768px) {
            text-align: center;
        }
        dd {
            line-height:30px;
            display:block;
        }
        [class*='social-ico-'] {
            width:30px;
            height:30px;
            svg {
                @include x-svg-size(14);
                margin-top:-7px;
                margin-left:-7px;
            }
        }
        dt {
            float:left;
            @media (max-width: 768px) {
                display: none;
            }
        }
        .list-inline {
            display: block;
            li {
                @media (max-width: 768px) {
                    margin-bottom: 10px;
                }
                &:last-child {
                    margin-right: 10px;
                    @media (max-width: 768px) {
                        float: none !important;;
                    }
                }
            }
        }
        .btn {
            font-size: 12px;
            padding: 7px 8px 5px;
            svg {
                &.ico-profile {
                    width: 20px;
                    height: 20px;
                    stroke-width: 1px;
                }
                &.ico-mail {
                    width: 15px;
                    height: 20px;
                }
            }
        }
        .tip-info {
            min-width: auto;
            max-width: none;
            .tip-wrap {
                font-size: 11px;
                line-height: 18px;
                padding: 4px 8px;
                svg.ico-mark-check {
                    color: #6ebe3b;
                }
                p {
                    min-width: 290px;
                    &.nowrap {
                        min-width: auto;
                    }
                }
            }
        }
    }
    .special-link {
        border-radius:3em;
        background:#fff;
        display:inline-block;
        padding-left:.7em;
    }
    .conflict-events {
        &-title {
            font-family: 'robotoregular';
            font-size: 16px;
            text-align: center;
            margin: 0 auto 15px;
            color: #737373;
            svg {
                width: 26px;
                height: 26px;
                stroke-width: 2;
                color: #ff4200;
            }
        }
        &-body {
            background: #fff;
            border-radius: 3px;
            padding: 5px 25px;
            margin-bottom: 30px;
        }
        &-item {
            padding: 10px 0;
            border-bottom: 1px solid #eaeaea;
            font-size: 16px;
            color: #373737;
            @media (max-width: 768px) {
                padding: 7px 0;
                font-size: 13px;
            }
            &:last-child {
                border: none;
            }
        }
        &-datatime {
            color: #ff4200;
            @media (max-width: 768px) {
                float: none !important;
                display: block;
                font-size: 12px;
            }
        }
        &-info {
            position: relative;
            padding-left: 60px;
            margin-bottom: 15px;
            @media (max-width: 768px) {
                padding-left: 0;
            }
            svg {
                position: absolute;
                top: 5px;
                left: 15px;
                width: 30px;
                height: 30px;
                stroke-width: 3;
                color: #00afec;
                @media (max-width: 768px) {
                    display: none;
                }
            }
            &-title {
                text-decoration: underline;
                font-size: 16px;
                margin: 0;
                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
            &-desc {
                font-size: 12px;
                color: #737373;
                margin: 0 0 5px;
            }
            &--right {
                @media (max-width: 768px) {
                    text-align: right;
                }
            }
        }
    }
}
[data-block-events='true'] {
    pointer-events:none;
}
.registration-section header .form-group,
.agenda-section header .form-group,
.room-editor header .form-group {
    margin:0;
}
.registration-section header .vr-list li,
.agenda-section header .vr-list li,
.room-editor header .vr-list li {
    vertical-align:middle;
}
.registration-section .rebranding .room-page {
    height:auto;
}
.registration-section .rebranding .room-page,
.registration-section .rebranding .room-page-middle {
    min-height:718px;
}
.agenda-section .rebranding .room-page-middle {
    height:100%;
}


.registration-settings-section {
    color:#606365;
}
.registration-settings-section .form-control {
    float:right;
    margin:0;
}
.registration-settings-section .in {
    padding:0;
    margin:25px;
}
.registration-settings-section .signature {
    border-top:1px solid #e4e5e5;
    font-style:italic;
    padding-top:15px;
}
.registration-settings-section p {
    margin-top:0;
}
.registration-settings-section p:last-child {
    margin:0;
}
.registration-settings-section .registration-settings-hd {
    font-size:20px;
    margin:25px 25px 0;
}
.registration-settings-section h4 {
    font-size:17px;
    letter-spacing:0;
    margin:0 0 10px;
    min-height:28px;
}

/* [ rebranding / agenda / registration ] */
.skeleton .room-page .navbar .page-wrap,
.skeleton .room-page .page-wrap {
    padding:0;
    margin:0;
    width:100%;
}

/* [ room page - structure ] */
.rebranding .room-main {
    padding:25px;
    @media (max-width: 576px) {
        padding: 10px;
    }
}
.rebranding .room-page,
.rebranding .room-page-middle {
    display:block;
    padding:0;
}
.rebranding .room-page .room-content {
    padding:10px;
    margin:0;
    max-width: 854px;    
}
#profile-page-tab .rebranding .room-page .room-content {
    max-width: 637px;
}
/*.rebranding .room-content[data-loading='true'] {
    background-position:50% 50%;
    background-repeat:no-repeat;
    background-image:url($src-image + 'indicators/loader_room_content_48.gif');
    min-height:300px;
}*/

/* drag & drop dla sekcji */
.rebranding section {
    position:relative;
    border: 2px dashed transparent;
    &.component-youtube_embeded {
        margin: 15px 0;
    }
    .select.dropdown {
        .dropdown-menu {
            display: none;
        }
        &.show:after {
            transform: none;
        }
    }
}
.rebranding .room-page-middle {
    padding-bottom:81px;
    box-sizing:border-box;
}
.rebranding .room-page .navbar {
    position:static;
    padding-left:25px;
}
.rebranding .room-footer .page-wrap {
    width:auto;
}
.rebranding section.move-section,
.rebranding [data-editor='component'] > td > table {
    cursor:move;
    border:2px dashed transparent;
    box-sizing:border-box;
}
.rebranding section.move-section,
.rebranding .move-section > td > table {
    background:rgba(255,255,255,.5);
}
.rebranding .action-wrap {
    display:none;
}
.move-section > td {
    position:relative;
}
.move-section .action-wrap {
    display:block;
}
.move-section .action-wrap .actions {
    overflow:hidden;
    /*background:#6ebe3b;*/
    padding:3px;
}
.action-wrap .pos-tr {
    position:absolute;
    top:-2px;
    right:-2px;
    z-index:6;
}
.action-wrap .pos-tr .actions {
    border-bottom-left-radius:2px;
}
.action-wrap li {
    font-size:11px;
    line-height:24px;
    text-align:center;
    float:left;
    min-width:12px;
    height:24px;
    margin:2px;
    padding:0 5px;
    border-radius:12px;
    background:#fff;
    cursor:pointer;

    svg {
        @include x-svg-size(14);
        margin:0 auto;
        pointer-events:none;
        /*color:#6ebe3b;*/
    }
    .ico-pencil,
    .ico-move,
    .ico-tick,
    .ico-x {
        stroke-width:8 !important;
    }
    .ico-x {
        color:$color-error;
    }
}


/* [ rebranding tab ] */
.rebranding-section object {
    display:block;
}
.rebranding-section .room-page {
    min-height:580px;
}
.rebranding-section .room-main,
.rebranding-section .room-page-middle {
    padding:0;
/*    background-image:url($src-image + 'indicators/loader_room_48.gif');
    background-repeat:no-repeat;
    background-position:50% 50%;*/
}
.rebranding-section .room-page .page-wrap {
    height:540px;
}
.rebranding-section .list-tiles .unit {
    overflow:hidden;
}

.rebranding-section .room-page .navbar {
    top:0;
    left:0;
    right:0;
    z-index:9999;
    height:39px;
    box-shadow:inset 0 -1px 0 rgba(0,0,0,.15);
    color:#fff;
    padding-left:15px;
}
.rebranding-section .navbar .room-name {
    color:inherit;
    font-size:14px;
    font-family:inherit;
    font-weight:bold;
    letter-spacing:0;
    line-height:38px;
    float:left;
    margin:0 5px 0 25px;
    max-width:220px;
}
.rebranding-section .navbar .position-menu {
    top:4px;
    right:15px;
}
.rebranding-section .pod-workspace {
    position:absolute;
    top:10px;
    left:10px;
    right:10px;
    bottom:10px;
}
.rebranding-section .tip-info {
    min-width:inherit;
    white-space:nowrap;
}
.registration-info-preview {
    &__title {
        display: inline-block;
        vertical-align: middle;
    }
    &__options {
        list-style-type: none;
        display: inline-block;
        vertical-align: middle;
        padding-left: 0;
        li {
            display: inline-block;
            padding-left: 10px;
            a {
                font-size: 12px;
            }
        }
    }
    &__edit {
        li {
            font-size: 16px;
        }
    }
}

.rebranding--3 {
    position: relative;
}

.calendar-picker--registration-limit input {
    background-color: unset;
    height: 33.99px !important;
}
