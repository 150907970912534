.dialplan-section .page-wrap {
    width:923px;
}
.dialplan-section .col-wrap {
    margin-left:-29px;
}
.dialplan-section .col {
    float:left;
    width:448px;
    margin:0 0 33px 28px;
}
.dialplan-section h3 {
    font-size:24px;
}
.dialplan-section .unit {
    margin:20px auto 40px;
}
.dialplan-section .unit-hd {
    background:none;
    color:inherit;
}
.dialplan-section .unit-hd h3 {
    margin:16px 0;
}
.dialplan-section .map-wrap {
    height:500px;
    min-width:310px;
    max-width:800px;
    @media (max-width: 991px) {
        height:350px;
    }
    @media (max-width: 767px) {
        height: 250px;
    }
    @media (max-width: 575px) {
        height: 200px;
        min-width: 270px;
    }
}
.dialplan-section .list-caret {
    margin-bottom:10px;
    display: flex;
    flex-wrap: wrap;

    > li {
        width:33.3333%;
        @media (max-width: 991px) {
            width: 50%;
        }
        @media (max-width: 575px) {
            width: 100%;
        }
    }

    .caption-small {
        display:block;
        margin-top:-5px;
        color:$color-success;
    }

    .active {
        color:#fff;
        background-color:$color-success;

        .caption-small {
            color:inherit;
        }
    }
}
.dialplan-section {
    &__map-wrapper {
        .unit-ft {
            h4 {
                @media (max-width: 992px) {
                    margin-top: 10px;
                }
            }
        }
        .unit-hd,
        .unit-bd,
        hr {
            @media (max-width: 992px) {
                display: none;
            }
        }
    }
}

.keypad ol {
    padding:0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.keypad .unit-hd {
    background:none;
}
.keypad .unit-title {
    font-size:22px;
}
.keypad .unit-bd {
    border:0;
    background:#e9e9e9;
}
.key {
    color:#c7c7c7;
    text-align:center;
    line-height:1;
    float:left;
    list-style:none;
    width: calc(33.33333% - 1px);
    height:105px;
    margin:1px 0 0 1px;
    background:#fff;
    padding:0 3px;
    box-sizing:border-box;
}
.key:last-child {
    border-bottom-right-radius:2px;
}
.key.star {
    border-bottom-left-radius:2px;
}
.key .num {
    font-size:60px;
    padding-top:10px;
    height:60px;
    display:inline-block;
    position:relative;
}
.key .num:before {
    content:'*';
    font-size:30px;
    display:inline-block;
    position:absolute;
    top:15px;
    right:100%;
}
.key.star .num {
    padding-top:15px;
}
.key.star .num:before {
    display:none;
}
.key .info {
    font-size:13px;
    display:none;
    text-shadow:none;
}
.key .info small {
    font-size:10px;
}
.key.active {
    color:#fff;
    text-shadow:0 -1px 0 rgba(0,0,0,.26);
    box-shadow:inset 0 -1px 2px rgba(0,0,0,.4);
}
.key.active .info {
    display:block;
}
/*.keypad-presenter .unit-title {
    color:#6ebe3b;
}
.keypad-presenter .key.active {
    background:#6ebe3b;
    background:-webkit-linear-gradient(45deg,  #5ab230 0%,#82ca46 100%);
    background:linear-gradient(45deg,  #5ab230 0%,#82ca46 100%);
}*/
.keypad-participant .unit-title {
    color:#a3ab9e;
}
.keypad-participant .key.active {
    background:#a3ab9e;
    background:-webkit-linear-gradient(45deg,  #a2aa9d 0%,#bec4bb 100%);
    background:linear-gradient(45deg,  #a2aa9d 0%,#bec4bb 100%);
}
.keypad .tip-inline {
    font-size:14px;
}
.keypad .dl-horizontal {
    font-size:16px;
}
.keypad dd {
    color:#606365;
    margin:0 0 10px;
}
.keypad dt {
    width:auto;
    margin-right:5px;
}
.dialplan-module .page-header {

    .list-inline {
        margin-bottom: -1.5rem;
    }

    .col-lg:nth-child(2) {
        flex-grow: 3;
    }
}
