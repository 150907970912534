/*---------------------------------------------------------------------------------------------
[ Cover ]
*/
    .cover {
        display:block;
    }
    .cover-figure,
    .cover-content {
        display:table-cell;
    }
    .cover-figure {
        color:#e4e4e4;
        vertical-align:top;
        text-align:center;
        padding:8px 20px 8px 0;

        .cover-right & {
            padding:8px 0 8px 20px;
            float:right;
        }
    }
    .cover-obj {
        @include x-svg-size(60);
        display:block;
        margin:0 auto;
    }
    .cover-caption {
        color:#aeaeae;
        margin-top:10px;
        font-size:11px;
    }
    .cover-content {
        vertical-align:middle;
    }
    .cover-hd {
        font-weight:bold;
        display:block;
        margin:5px 0;

        .hide-check + label {
            display:block;
        }
    }
    .cover-hd,
    .cover strong {
        color:#292d38;
    }
    .cover-content p {
        margin:10px 0 5px;
    }
    .cover-content p + .btn-wrap {
       margin-top:15px;
    }


/* [ block ]  */
    .cover-block {

        > .cover-content {
            display:block;
            text-align:center;

            p {
                margin:5px 0 0;
            }
        }

        > .cover-figure {
            display:block;
            padding:0;
            margin:0 auto 20px;
        }

        .btn-wrap {
            margin:25px 0 0;
        }
    }

/* [ empty ]  */
    .cover-empty {
        margin:50px auto;

        .cover-content {
            color:#555;
        }

        table &,
        .table &,
        .unit-table & {
            display:table;
            max-width:580px;
            // font-size:14px;
        }

        // dedicated AP
        // .expand & .cover-figure {
        //     color:$color-muted;
        // }
    }


/* [ cover center ] */
    .cover-center {
        display:table;
        margin-right:auto;
        margin-left:auto;

        &.cover-empty {
            max-width:580px;
        }
    }


/* [ cover size ] */
    .cover-large .cover-obj {
        @include x-svg-size(100);
        stroke-width:3;
    }
    .cover-hd-medium {
        font-size:16px;
        letter-spacing:0;
    }
    .cover-hd-large {
        font-size:28px;
        font-weight:normal;
        letter-spacing:-1px;
    }

    .cover-invites-info {
        margin: 80px auto 20px;
        
        p {
            margin: 0;
            color: #a7afc6;
        }
    }