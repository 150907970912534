.modal {
    &-exit,
    &-communications,
    &-profile-page,
    &__contact {
        .modal-dialog {
            max-width: 650px;
            pointer-events: auto;
            @media (max-width: 767px) {
                margin-top: 3.75rem;
                margin-bottom: 3.75rem;
            }
        }
        .fancybox-close {
            &:before {
                color: #fff;
            }

            @media (max-width: 767px) {
                top: -50px;
                right: 0;
            }
        }
        .modal-body {
            padding: 0;
        }
    }
    &-exit {
        background: rgba(40, 43, 49, 0.9);
        .img-fluid {
            max-width: 100%;
            height: auto;
        }
        .modal-content {
            background: #0c1327;
            border: none;       
            border-radius: 6px;
            box-shadow: none;
            display: block;
            text-decoration: none;
            overflow: hidden;
        }
    }
    &-communications {
        font-family: 'robotolight';
        .modal-content {
            background-color: #fff;
            padding: 40px 30px 30px;
            border: none;
            @media (max-width: 576px) {
                padding: 30px 20px 20px;
            }
        }
        &__icon {
            width: 35px;
            height: 35px;
            color: #6ebe3b;
            &--large {
                width: 50px;
                height: 50px;
                margin-bottom: 30px;
            }
        }
        &__title {
            color: #373a3d;
            font-size: 28px;
            line-height: 34px;
            margin: 0 0 30px 0;
        }
        &__text {
            color: #8c8f91;
            font-size: 18px;
            line-height: 24px;
            margin: 0 0 20px 0;
            &--mb {
                margin-bottom: 35px;
                @media (max-width: 576px) {
                    margin-bottom: 25px;
                }
            }
            &--small {
                font-size: 14px;
                line-height: 18px;
            }
        }
        &-tips {
            display: flex;
            justify-content: space-around;
            margin: 50px 0;
            @media (max-width: 576px) {
                flex-direction: column;
                margin: 40px 0 0;
                align-items: center;
            }
            &__box {
                max-width: 230px;
                @media (max-width: 576px) {
                    margin-bottom: 40px;
                }
                svg {
                    margin-bottom: 20px;
                }
            }
        }
        &__faq {
            max-width: 310px;
            margin: 0 auto;
            font-family: 'robotoregular';
            a {
                text-decoration: underline;
                color: inherit;
            }
        }
    }
    &-profile-page {
        .modal-content {
            padding: 40px 75px;
        }
        &__title {
            font-family: 'robotobold';
            font-size: 34px;
            line-height: 34px;
            color: #1b1f2b;
            margin: 0 0 20px;
        }
        &__desc {
            font-family: 'robotoregular';
            font-size: 15px;
            line-height: 20px;
            color: #6b7183;
            margin: 0 0 25px;
        }
        iframe {
            border-radius: 4px;
            box-shadow: 0px 11px 27px 0px rgba(107, 113, 131, 0.2);
        }
        &__footer {
            margin-top: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__settings {
            font-family: 'robotomedium';
            font-size: 15px;
            text-transform: uppercase;
            svg {
                height: 24px;
                width: 24px;
                margin-right: 11px;
            }
        }
    }
    &__contact {
        @include media-breakpoint-up(lg) {
            &.modal-lg {
                max-width: 650px;
            }
        }
        &.modal-dialog {
            pointer-events: auto;
        }
        .fancybox-close {
            top: auto;
            @media (max-width: 767px) {
                top: 0;
            }
        }
        &-list {
            padding: 35px 45px;
            border: none;
            border-radius: 3px;
            min-height: 650px;
            @media (max-width: 576px) {
                padding: 35px 15px;
            }
        }
    }
}