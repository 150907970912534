.add-section .unit {
    margin:0 0 50px;
    border-bottom-right-radius:0;
     border-bottom-left-radius:0;
}
.add-section .bg,
.add-section .bg:after,
.add-section .bg:before {
    background-image:url($src-image + 'bg_add_section.png');
    position:absolute;
    display:block;
}
.add-section .bg {
    background-position:50% 100%;
    background-repeat:repeat-x;
    top:100%;
    height:25px;
    left:4px;
    right:4px;
    z-index:2;
    margin-top:-1px;
}
.add-section .bg:after,
.add-section .bg:before {
    content:'';
    width:5px;
    height:25px;
    background-position:0 0;
    background-repeat:no-repeat;
    bottom:0;
    z-index:3;
}
.add-section .bg:after {
    background-position:100% 0;
    right:-5px;
}
.add-section .bg:before {
    background-position:0 0;
    left:-5px;
}
