.loading-picture {
    background: #fff;
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
    margin: 0 auto;
    padding: 15px 15px 30px;

    .crop {
        position: relative;
        padding: 0 !important;
        z-index: 1;

        &__body {
            padding: 0 !important;
        }

        &__svg {
            position: relative;
            width: 40%;
            max-width: 300px;
            height: 40px;
            margin: 0 auto;
            color: #5c6273;
            z-index: -1;

            .scale {
                position: absolute;
                top: 52%;
                transform:translateY(-50%);
                stroke-width: 1px;

                &-left {
                    left: -17px;
                    width: 12px;
                    height: 12px;
                }

                &-right {
                    right: -25px;
                    width: 20px;
                    height: 20px;
                }

            }

        }

    }

    .cr-slider {
        vertical-align: middle;
    }

    .croppie-container {

        .cr-slider-wrap {
            margin: 10px auto;
            width: 40%;
            max-width: 300px;

            input[type=range]:focus {
                outline: none;
            }
            input[type=range]::-webkit-slider-runnable-track {
                width: 100%;
                height: 4px;
                cursor: pointer;
                animate: 0.2s;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                background: #dce0ea;
                border-radius: 25px;
                border: 0px solid #000101;
            }
            input[type=range]::-webkit-slider-thumb {
                box-shadow: 0px 2px 5px 0px rgba(184, 188, 199, 0.33);
                border: 0px solid #000000;
                height: 12px;
                width: 12px;
                border-radius: 7px;
                background: #ffffff;
                cursor: pointer;
                -webkit-appearance: none;
                margin-top: -4.5px;
            }
            input[type=range]:focus::-webkit-slider-runnable-track {
                background: #dce0ea;
            }
            input[type=range]::-moz-range-track {
                width: 100%;
                height: 4px;
                cursor: pointer;
                animate: 0.2s;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                background: #dce0ea;
                border-radius: 25px;
                border: 0px solid #000101;
            }
            input[type=range]::-moz-range-thumb {
                box-shadow: 0px 2px 5px 0px rgba(184, 188, 199, 0.33);
                border: 0px solid #000000;
                height: 12px;
                width: 12px;
                border-radius: 7px;
                background: #ffffff;
                cursor: pointer;
            }
            input[type=range]::-ms-track {
                width: 100%;
                height: 12.8px;
                cursor: pointer;
                animate: 0.2s;
                background: transparent;
                border-color: transparent;
                border-width: 9px 0;
                color: transparent;
            }
            input[type=range]::-ms-fill-lower {
                background: #dce0ea;
                border: 0px solid #000101;
                border-radius: 50px;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
            }
            input[type=range]::-ms-fill-upper {
                background: #dce0ea;
                border: 0px solid #000101;
                border-radius: 50px;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
            }
            input[type=range]::-ms-thumb {
                box-shadow: 0px 2px 5px 0px rgba(184, 188, 199, 0.33);
                border: 0px solid #000000;
                height: 12px;
                width: 12px;
                border-radius: 7px;
                background: #ffffff;
                cursor: pointer;
            }
            input[type=range]:focus::-ms-fill-lower {
                background: #dce0ea;
            }
            input[type=range]:focus::-ms-fill-upper {
                background: #dce0ea;
            }
        }

        .cr-viewport,
        .cr-resizer {
            border: 1px solid #b2b2b2;
            box-shadow: 0 0 2000px 2000px rgba(247, 247, 247, 0.6);
        }

    }

}