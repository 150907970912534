$cm: #6ebe3b;
$white: #fff;
$black: #000;
.banner {
    background-position: center;
    background-repeat: no-repeat;
    background-color: $white;
    position: relative;
    height: 75px;
    z-index: 1;
    box-shadow: 0 0 10px 3px rgba($black, 0.15);
    @media (max-width: 767px) {
        height: 167px;
    }
    &__link {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
    &__content {
        display: flex;
        justify-content: center;
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
    &__desc {
        align-self: center;
        color: $white;
        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }
    &__counter {
        align-self: center;
        margin-top: 5px;
        margin-right: 30px;
        @media (max-width: 992px) {
            margin-right: 20px;
        }
        @media (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
}
.btn-banner {
    align-self: center;
    background-color: $cm;
    border-color: $cm;
    color: $white;
    line-height: 1.7;
    font-family: $font-roboto-medium;
}
