/*---------------------------------------------------------------------------------------------
[ Mod: Figure ]
*/
.figure-wrap,
.gallery {
    display:inline-block;
}
.figure-wrap {
    text-align:center;

    &,
    .img-decorate {
        box-sizing:border-box;
    }

    .spinner-wrap {
        color:#fff;
    }
}
.figure {
    display:block;
    position:relative;
    overflow:hidden;
    margin:0 auto;

    &:before {
        opacity:0;
        visibility:hidden;
        content:'';
        display:block;
        position:absolute;
        z-index:3;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background-color:#e0e0e0;
    }

    .dsp-table {
        height:100%;
    }
}
.figure-obj {
    color:$color-muted;
    font-size:60px;
    line-height:1;

    display:block;
    margin:0 auto;
    max-width:100%;
    position:static;

    .cover-figure & {
        margin-bottom:0;
    }
}
//.figure-obj-middle {
//    position:relative;
//    top:50%;
//    -webkit-transform:translateY(-50%);
//        -ms-transform:translateY(-50%);
//            transform:translateY(-50%);
//}


/* [ ikonki akcji dla obrazka ] */
    .figure .actions {
        & {
            color:#fff;
            text-align:center;
            font-size:40px;

            border-radius:2px;
            background:#6ebe3b; //brand

            position:absolute;
            z-index:2;
            top:-3px;
            left:-3px;
            right:-3px;
            bottom:-3px;

            opacity:0;
            visibility:hidden;
        }

        a {
            color:$color-current;

            &:hover {
                color:rgba(0,0,0,0.5);

                .ico-x {
                    color:$color-error;
                }
            }
        }

        [class*='svg-'] {
            @include x-svg-size(26);
            margin:0 1px;
        }

    }
    .figure:hover .actions {
        opacity:1;
        visibility:visible;
    }
    .figure .vertical-center {
        position:absolute;
        left:0;
        right:0;
        top:50%;
        -webkit-transform:translateY(-50%);
            -ms-transform:translateY(-50%);
                transform:translateY(-50%);
    }


/* Figure loading state */
    .figure-wrap[data-loading='true'] {
        pointer-events:none;
        cursor:not-allowed;

            .thumb-title {
                color:#969696;
            }

        .thumb & .figure {/* cell table */
            min-width:78px;
            min-height:58px;
        }
    }


/* [ thumb gallery (with title) ] */
    .figure .ico-check {
        display:none;
        position:absolute;
        z-index:7;
        text-align:center;
    }
    .hide-check + .gallery {
        padding:0;
    }
    .figure .actions,
    // .files-thumbs-section .figure-wrap:hover .thumb-title,
    .gallery-hov-bordered:hover .thumb-title,
    .hide-check:checked + .gallery-hov-bordered .thumb-title {
        color:#fff;
        //background:#6ebe3b; /* brand */
    }
    .gallery-hov-bordered:hover .img-decorate,
    .hide-check:checked + .gallery-hov-bordered .img-decorate {
        //border-color:#6ebe3b; /* brand */
        box-shadow:none;
    }

    /* gallery size */
        .gallery .figure {
            width:120px;
            height:120px;
        }
        .gallery.x-large .figure {
            width:284px;
            height:388px;
        }
        .gallery.x-large .thumb-title {
            font-size:18px;
            width:166px;
        }


/* animation */
.figure .actions {
    transition:opacity .15s ease-in-out, visibility .15s ease-in-out;
}