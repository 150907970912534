.social dt,
.social dd,
[class*="social-ico-"] {
    display: inline-block;
    vertical-align: middle;
}
.social dt {
    font-size: 20px;
    margin-right: 15px;
    letter-spacing: -1px;
}
[class*="social-ico-"] {
    color: #fff;
    text-align: center;
    width: 22px;
    height: 22px;
    overflow: hidden;
    position: relative;
    background: #eee;
    border-radius: 50%;

    svg {
        @include x-svg-size(12);
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -6px;
        margin-left: -6px;
        // transform:translate(-50%,-50%); firefox bug! transform-origin
    }

    .svg-fill {
        fill: #ffffff;
    }

    .svg-stroke {
        stroke: #ffffff;
    }

    .ico-mail {
        stroke-width: 8;
    }
    .ico-x,
    .ico-tick {
        stroke-width: 16;
    }
}

.social-ico-googleplus svg {
    @include x-svg-size(14);
    margin-top: -7px;
    margin-left: -7px;
}
.social a:hover {
    opacity: 0.9;
}

.social-ico-fb,
.hide-check:checked + .social-ico-fb,
.hide-check + .social-ico-fb.integrated {
    background: $color-social-facebook;
}
.social-ico-fb + div {
    color: $color-social-facebook;
}
.social-ico-twitter,
.hide-check:checked + .social-ico-twitter,
.hide-check + .social-ico-twitter.integrated {
    background: $color-social-twitter;
}
.social-ico-twitter + div {
    color: $color-social-twitter;
}
.social-ico-google,
.hide-check:checked + .social-ico-google,
.hide-check + .social-ico-google.integrated {
    background: $color-social-google;
}
.social-ico-google + div {
    color: $color-social-google;
}
.social-ico-googleplus,
.hide-check:checked + .social-ico-googleplus,
.hide-check + .social-ico-googleplus.integrated {
    background: $color-social-googleplus;
}
.social-ico-googleplus + div {
    color: $color-social-googleplus;
}
.social-ico-hubspot,
.hide-check:checked + .social-ico-hubspot,
.hide-check + .social-ico-hubspot.integrated {
    background: $color-social-hubspot;
}
.social-ico-hubspot + div {
    color: $color-social-hubspot;
}
.social-ico-read {
    background: $color-social-rss;
}
.social-ico-read + div {
    color: $color-social-rss;
}
.social-ico-linkedin,
.hide-check:checked + .social-ico-linkedin,
.hide-check + .social-ico-linkedin.integrated {
    background: $color-social-linkedin;
}
.social-ico-linkedin + div {
    color: $color-social-linkedin;
}
.social-ico-youtube,
.hide-check:checked + .social-ico-youtube,
.hide-check + .social-ico-youtube.integrated {
    background: $color-social-youtube;
}
.social-ico-youtube + div {
    color: $color-social-youtube;
}
.social-ico-contact {
    background: #c2c2c2;
}
.social-ico-contact + div {
    color: #c2c2c2;
}
.social-ico-success {
    background: $color-success;
}
.social-ico-success + div {
    color: $color-success;
}
.social-ico-error {
    background: $color-error;
}
.social-ico-error + div {
    color: $color-error;
}
.social-ico-warning {
    background: $color-warning;
}
.social-ico-warning + div {
    color: $color-warning;
}
.social-ico-default {
    background: #d8d8d8;
}
.social-ico-default + div {
    color: #d8d8d8;
}
.social-ico-info {
    background: $color-info;
}

.hide-check + [class*="social-ico-"] {
    background-color: #e5e5e5;
}
.hide-check + [class*="social-ico-"]:hover {
    opacity: 0.8;
}
.hide-check:checked + [class*="social-ico-"] {
    opacity: 1;
}

.social .no-integrate,
.integrate-state .hide-check + .no-integrate {
    background-color: #c0c0c0;
}

.social-expand [class*="social-ico-"] {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.14),
        0 2px 0 rgba(255, 255, 255, 0.75);
}
.social-expand .social > li {
    margin: 26px 15px;
}
.social-expand [class*="social-ico-"] + div {
    display: inline-block;
    vertical-align: middle;
    color: inherit;
}
.social-expand .toggle-hide {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 2em;
}
.social-expand .social > li > div,
.social-expand .social > li .btn {
    color: inherit;
    display: inline-block;
    padding: 0;
}
.social-expand .helper.pos-tr {
    margin-top: 75px;
    right: 80%;
    min-width: 230px;
}
.social-expand .helper.pos-tr .helper-bd:before {
    top: -45px;
}

.social-medium,
.social-medium .btn-link {
    color: #606365;
    font-size: 18px;
}
.social-medium {
    small {
        font-size: 14px;
    }

    [class*="social-ico-"] {
        width: 34px;
        height: 34px;

        svg {
            @include x-svg-size(16);
            margin-top: -8px;
            margin-left: -8px;
        }
    }
}

.social-large {
    font-size: 24px;
    margin: 30px 0;

    [class*="social-ico-"] {
        width: 122px;
        height: 122px;

        svg {
            @include x-svg-size(60);
            margin-top: -30px;
            margin-left: -30px;
        }
    }
}

/* loading / integrating social */
.social .no-integrate {
    position: relative;
}

/* stan integracji - pokazujemy loader w trakcie wykonywania integracji */
.integrate-state {
    pointer-events: none;
}
.integrate-state .no-integrate svg {
    opacity: 0;
}
.integrate-state .no-integrate .spinner-social {
    opacity: 1;
    visibility: visible;
}
.integrate-state .btn-txt div {
    opacity: 0.3;
}

.social-progress [class*="social-ico-"] {
    background: #eee;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
