@import '../../../../../shared/web/views/src/scss/__partials/prompt';
/* [ message ] */
    .message {
        .form-inline .form-group {
            margin-top:0;
            margin-bottom:0;
        }
    }

@import '../../../../../shared/web/views/src/scss/__partials/rating';


/* [ progress ] */
    .progress {
        color:#fff;
        font-size:12px;
        text-align:center;
        position:relative;
        background:#ededed;
        height:23px;
        box-shadow:0 1px 0 #fff;
    }
    .progress,
    .progress:before,
    .progress-bar {
        border-radius:30px;
    }
    .progress:before {
        content:'';
        display:block;
        position:absolute;
        z-index:3;
        top:0;
        left:0;
        right:0;
        bottom:0;
        box-shadow:inset 0 1px 2px rgba(0,0,0,.1);
    }
    .progress-bar {
        position:relative;
        z-index:2;
        height:100%;
        max-width:100%;
    }
    .progress-bar.default {
        background:#d7d7d7;
        background:-webkit-linear-gradient(top,  #dcdcdc 0%,#d7d7d7 100%);
        background:linear-gradient(to bottom,  #dcdcdc 0%,#d7d7d7 100%);
    }
    .progress-bar.error {
        background:#ff4200;
        background:-webkit-linear-gradient(top,  #ff530d 0%,#ff4200 100%);
        background:linear-gradient(to bottom,  #ff530d 0%,#ff4200 100%);
    }
    .progress-bar.warning {
        background:#ff8706;
        background:-webkit-linear-gradient(top,  #ff961a 0%,#ff8706 100%);
        background:linear-gradient(to bottom,  #ff961a 0%,#ff8706 100%);
    }
    .progress-bar.success {
        background:#6ebe3b;
        background:-webkit-linear-gradient(top,  #79cb45 0%,#6ebe3b 100%);
        background:linear-gradient(to bottom,  #79cb45 0%,#6ebe3b 100%);
    }
    .progress-title {
        color:#b1b1b1;
        line-height:22px;
        text-align:center;
        display:block;
        overflow:hidden;
        white-space:nowrap;
        word-wrap:break-word;
        position:absolute;
        left:0;
        right:0;
        bottom:100%;
        z-index:1;
    }
    .header .progress {
        margin-right:13px;
        float:left;
    }
    .progress .tip {
        opacity:0;
        visibility:hidden;
        right:0;
        right:-16px;
    }
    .progress:hover .tip {
        opacity:1;
        visibility:visible;
    }


/* [ progress radial ] */
    .progress-radial {
        color:#3d464d;
        font-size:21px;
        letter-spacing:-2px;
        position:relative;
        width:58px;
        height:58px;
        background:#fff;
        border-radius:50%;
        box-shadow:inset 0 0 0 2px #f7f7f7;
    }
    .progress-radial .mask {
        width:50%;
        height:100%;
        overflow:hidden;
        position:absolute;
        z-index:2;
    }
    .progress-radial .fill {
        border-radius:999px;
        position:absolute;
        width:100%;
        height:100%;
        -webkit-backface-visibility:hidden;
        backface-visibility:hidden;
    }
    .progress-radial .mask-left {
        left:0;
    }
    .progress-radial .mask-left .fill {
        left:100%;
        border-bottom-left-radius:0;
           border-top-left-radius:0;
        transform-origin:0 50%;
    }
    .progress-radial .mask-right {
        left:50%;
    }
    .progress-radial .mask-right .fill {
        left:-100%;
        border-bottom-right-radius:0;
           border-top-right-radius:0;
        transform-origin:100% 50%;
    }
    .progress-radial:after {
        content:"";
        background:#fff;
        position:absolute;
        z-index:3;
        width:54px;
        height:54px;
        border-radius:50%;
        top:2px;
        left:2px;
        display:block;
    }
    .progress-radial .status {
        position:absolute;
        z-index:10;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
    }


/* [ list checked ] */
    .list-checked,
    .list-checked > li {
        @include x-list-init;
    }
    .list-checked {
        margin:20px 0;
    }
    .list-checked > li {
        margin:.6em 0;
        padding-left:2.4em;
        position:relative;
    }
    .list-checked > li:before {
        content:'q';
        position:absolute;
        left:.8em;
        top:0;
    }
    .list-checked-default > li:before {
        color:#c2c2c2;
    }
    .list-checked-success > li:before {
        color:$color-success;
    }
    .list-checked-error > li:before {
        color:$color-error;
    }


/* [ lista dla validacji pol formularzy ] */
    .list-valid-notify,
    .list-valid-notify > li {
        @include x-list-init;
    }
    .list-valid-notify > li {
        padding-left:1.5em;
    }
    .list-valid-notify > li [data-icon] {
        float:left;
        margin:0 0 0 -1.5em;
    }
    .list-valid-notify .notify-muted {
        color:#d8d8d8;
    }