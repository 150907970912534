.import-copy .table-equal th,
.import-copy .table-equal td,
.import-file .table-equal th,
.import-file .table-equal td {
    width:180px;
}
.import-file .specify-height {
    height:355px;
}

.import-section .import-copy .in-specify {
    padding:36px 75px;
    @media (max-width: 767px) {
        padding:15px 15px 30px;
    }
}
.import-section .vr-list li {
    vertical-align:middle;

    input {
        width: 50px;
    }

    .select2-container {
        width: 150px;
    }
    
    @media (max-width: 767px) {
        display: block;
        &.spacer {
            border: none;
        }
        input {
            width: auto;
        }
        .tip-info-wrap.right .tip.pos-tc {
            left: auto;
            transform: none;
            right: -10px;
            .tip-wrap:after {
                left: 90%;
            }
        }
    }
}
.import-section .swiper-container,
.import-section .swiper-scrollbar {
    width: 100%;
}
.import-section .swiper-container {
    .truncate {
        max-width: 200px;
    }
}
.import-section svg {
    stroke-width: 1px;
}

    /* [ import form file ] */
    .popup-import .swiper-container,
    .popup-import .swiper-scrollbar {
        width:730px;
    }